import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs/index";
import {SharedService} from "./shared.service";
import {environment} from "../../environments/environment";
import {takeUntil} from "rxjs/operators";
import {CustomModalWrapper} from "../components/modals/enel-modal/enel-modal.component";
import {ModalDatePickerData} from "../classes/modalDatePickerData";
import {CustomTaskModalComponent} from "../components/modals/custom-task-modal/custom-task-modal.component";
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';
import * as Moment from "moment";
import * as mTZ from "moment-timezone";
import * as _ from 'lodash';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class EventManagementService {

  api_url;
  constructor(private http: HttpClient, private sharedService: SharedService, private router : Router) {
    this.api_url = this.router.url.indexOf('/utility') > -1 ? environment.utility_api_url : environment.api_url;
  }

  get(url, params) : Observable<any>{
    const p = this.sharedService.transformParams(params);
    return this.http.get(this.api_url + url, p);
  }

  patch(url, body, params): Observable<any> {
    const p = this.sharedService.transformParams(params);
    return this.http.patch(this.api_url + url, body, p);
  }

  put(url, body, params): Observable<any> {
    const p = this.sharedService.transformParams(params);
    return this.http.put(this.api_url + url, body, p);
  }

  post(url, body, params) : Observable<any>{
    const p = this.sharedService.transformParams(params);
    return this.http.post(this.api_url + url, body, p);
  }

  delete(url, params) : Observable<any>{
    const p = this.sharedService.transformParams(params);
    return this.http.delete(this.api_url + url, p);
  }

  getSelectedTasks(allCustomTasks, idOnly:boolean, next) {
    let selected = [];

    //After getting the selected IDs, send them to our next function
    const doneSelecting = _.after(allCustomTasks.length, () => {
      next(selected);
    });

    //Loop through all of the custom tasks we're looking at and see which ones are selected
    allCustomTasks.forEach(task => {
      if(task.selectedInGrid) {
        if(idOnly)
          selected.push(task.custom_task_id);
        else
          selected.push(task);
      }
      doneSelecting();
    });
  }

  bulkCancel(allCustomTasks, ngUnsubscribe, next): void {
    const controller = this;

    controller.getSelectedTasks(allCustomTasks, true, function(selectedIDs) {

      if(selectedIDs.length > 0) {

        controller.sharedService.activateModal({
          headerText: "Cancel Custom Tasks",
          bodyText: "Cancelling " + selectedIDs.length + " Custom Tasks. Are you sure?",
          buttonText: 'Cancel Custom Tasks',
          cancelText: 'No',
          allowCancel: true,
          confirmFunction: function () {

            controller.post('/v1/custom_tasks/cancel', {custom_task_ids:selectedIDs}, {}).pipe(takeUntil(ngUnsubscribe)
            ).subscribe(response => {
                controller.sharedService.popSuccess("Successfully cancelled Custom Tasks. Updates may take up to 1 minute to display on this page.");
                next();
              },
              error => {
                controller.sharedService.popError("Failed to cancel Custom Tasks!");
                console.dir(error);
                next();
              });
          }
        });
      } else {
        controller.sharedService.popError("Must select at least one task!");
      }
    })

  }

  bulkUpdate(allCustomTasks, ngUnsubscribe, max_event_duration, start, full_time_zone, createableFilters, createableTemplates, next): void {
    const controller = this;

    controller.getSelectedTasks(allCustomTasks, false, function (selectedTasks) {

      if(selectedTasks.length > 0) {
        //let start = controller.eventObj.event_start_dttm_utc;
        let timePickerData = new ModalDatePickerData(null, 'Schedule', moment().subtract('15', 'minutes').toISOString(), moment(start).add('60', 'minutes').add(max_event_duration, 'ms').toISOString(), 0, false, true, true, false);

        controller.sharedService.activateModal({
          headerText: "Update Custom Tasks",
          bodyText: "Updating " + selectedTasks.length + " Custom Tasks.",
          customContent: new CustomModalWrapper(CustomTaskModalComponent, {
            style: {
              'width': '50%',
              'mid-width': '450px'
            },
            program_time_zone: full_time_zone,
            timePickerData: timePickerData,
            allFilters: createableFilters,
            allTemplates: createableTemplates,
            updateTask: true,
            event_start: start,
            event_end: null,
            buttonText: 'Save',
            showTemplate: true,
            task: selectedTasks[0],
            confirmFunction: function (task) {

              let done = _.after(selectedTasks.length, () => {

                controller.put('/v1/custom_tasks/', selectedTasks, {}).pipe(takeUntil(ngUnsubscribe)
                ).subscribe(response => {
                    controller.sharedService.popSuccess("Successfully updated Custom Tasks. Updates may take up to 1 minute to display on this page.");
                    next();
                  },
                  error => {
                    controller.sharedService.popError("Failed to update Custom Tasks!");
                    console.dir(error);
                    next();
                  });
              });

              //The modal returns a single task to us, since we're making them update all selected tasks with the same values
              //So we loop through all of the tasks they originally selected and set their values to the values they picked in the modal
              _.forEach(selectedTasks, t => {

                t = Object.assign(t, task);

                done();
              });
            }
          })
        });
      } else {
        controller.sharedService.popError("Must select at least one task!");
      }

    });
  }

  bulkDoNow(allCustomTasks, ngUnsubscribe, next): void {
    const controller = this;

    controller.getSelectedTasks(allCustomTasks, false, function (selectedTasks) {

      if(selectedTasks.length > 0) {
        let tempTask = {
          scheduled_time: moment().toISOString(),
          automated: true
        };

        controller.sharedService.activateModal({
          headerText: "Send Now", bodyText: "Sending " + selectedTasks.length + " Custom Tasks. Are you sure?", allowCancel: true,
          confirmFunction: function () {

            let done = _.after(selectedTasks.length, () => {
              controller.put('/v1/custom_tasks/', selectedTasks, {}).pipe(takeUntil(ngUnsubscribe)
              ).subscribe(response => {
                  controller.sharedService.popSuccess("Successfully sent Custom Tasks. Updates may take up to 1 minute to display on this page.");
                  next();
                },
                error => {
                  controller.sharedService.popError("Failed to send Custom Task!");
                  console.dir(error);
                  next();
                });
            });

            //The modal returns a single task to us, since we're making them update all selected tasks with the same values
            //So we loop through all of the tasks they originally selected and set their values to the values they picked in the modal
            _.forEach(selectedTasks, t => {

              t = Object.assign(t, tempTask);

              done();
            });

          }
        });
      } else {
        controller.sharedService.popError("Must select at least one task!");
      }
    });

  }
}
