import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Subject } from "rxjs/internal/Subject";
import { takeUntil } from "rxjs/operators";
import { Operator } from "../../classes/operator";
import { Product } from "../../classes/product";
import { Program } from "../../classes/program";
import { EventManagementService } from "../../services/event-management.service";
import { SharedService } from "../../services/shared.service";

@Component({
  selector: 'app-hierarchy-selector',
  templateUrl: './hierarchy-selector.component.pug',
  styleUrls: ['./hierarchy-selector.component.scss']
})
export class HierarchySelectorComponent implements OnInit {
  @Input() arguments;
  @Output() filterEvents : EventEmitter<any> = new EventEmitter();
  @Output() onOperatorSelectEvent : EventEmitter<any> = new EventEmitter();
  @Output() onProgramSelectEvent : EventEmitter<any> = new EventEmitter();
  @Output() onProductSelectEvent : EventEmitter<any> = new EventEmitter();

  constructor(private sharedService: SharedService, private eventManagementService: EventManagementService, private route: ActivatedRoute) { }

  private ngUnsubscribe: Subject<any> = new Subject();

  allOperators: Array<Operator> = null;
  allPrograms: Array<Program> = null;
  allProducts: Array<Product> = null;

  loadedPrograms:boolean = false;
  loadedProducts:boolean = false;

  selectedOperator: Operator = null;
  selectedProgram: Program = null;
  selectedProduct: Product = null;

  programDropdownList = [];
  selectedPrograms = [];
  dropdownSettings: IDropdownSettings = {};

  params;

  ngOnInit(): void {

    const controller = this;

    //GET OPERATORS
    controller.eventManagementService.get('/v1/operator_hierarchy', null).pipe(takeUntil(this.ngUnsubscribe)
    ).subscribe(response => {

      controller.allOperators = response.data;
      this.sharedService.sortAlphabetically(controller.allOperators, "display_label");

      if(controller.allOperators.length === 1) {
        controller.selectedOperator = controller.allOperators[0];
        controller.onOperatorSelect({});
      }

      controller.selectFromParams();

    }, error => {
      this.sharedService.popError("Error getting Product Hierarchy");
      console.dir("Error getting operators: ");
      console.dir(error);
    });

    this.selectedPrograms = [];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'display_label',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 3,
    };
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  selectFromParams():void {
    this.params = this.route.snapshot.queryParams;

    if(this.params['operator']) {
      let selectedOp = _.find(this.allOperators, op => {
        return op.id === this.params['operator'];
      });

      if(selectedOp) {
        this.selectedOperator = selectedOp;
        this.onOperatorSelect({});

        if(this.params['program']) {
          let selectedProg = _.find(this.allPrograms, prog => {
            return prog.id === this.params['program'];
          });

          if(selectedProg) {
            this.selectedProgram = selectedProg;
            this.onProgramSelect({});

            if(this.params['product']) {
              let selectedProd = _.find(this.allProducts, prod => {
                return prod.id === this.params['product'];
              });

              if(selectedProd) {
                this.selectedProduct = selectedProd;
                this.onProductSelect({});
              }
            }
          }
        }
      }
    }
  }

  onOperatorSelect(event): void {
    const cont = this;
    cont.onOperatorSelectEvent.emit();

    cont.loadedProducts = false;

    cont.selectedProgram = null;
    cont.selectedProduct = null;
    cont.selectedPrograms = [];

    cont.allPrograms = cont.selectedOperator.programs;
    const parentPrograms = _.filter(cont.selectedOperator.programs, function(prog){
      return prog.show;
    });
    cont.programDropdownList = this.sharedService.sortAlphabetically(parentPrograms, 'display_label');

    cont.loadedPrograms = true;

    this.sharedService.sortAlphabetically(cont.allPrograms, "display_label");

    if(cont.allPrograms.length === 1) {
      cont.selectedProgram = cont.allPrograms[0];
      cont.selectedPrograms.push(cont.allPrograms[0]);

      cont.onProgramSelect({});
    }
  }

  onProgramSelect(event):void {
    const cont = this;
    cont.onProgramSelectEvent.emit();

    cont.selectedProduct = null;
    cont.allProducts = cont.selectedProgram.products;

    this.sharedService.sortAlphabetically(cont.allProducts, "display_label");

    cont.loadedProducts = true;

    if(cont.allProducts.length === 1) {
      cont.selectedProduct = cont.allProducts[0];
      cont.onProductSelect({});
    }
  }

  getProductsUnderProgram(programID):Array<Product> {
    let foundP = _.find(this.allPrograms, prog => { return prog.id === programID});

    return foundP.products;
  }

  onMultiProgramSelect(event):void {
    const cont = this;
    cont.onProgramSelectEvent.emit();
  }

  onSelectAll(event):void {
    const cont = this;
    cont.selectedPrograms = event;
    cont.onProgramSelectEvent.emit();
  }

  onProductSelect(event):void {
    const cont = this;
    cont.onProductSelectEvent.emit();

    //Get the max event duration for this product
    cont.eventManagementService.get('/v1/product/' + cont.selectedProduct.id, {}).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      response => {
        cont.selectedProduct = Object.assign(this.selectedProduct, response.data);
        this.filterEvents.emit({operator: cont.selectedOperator.id, program: cont.selectedProgram.id, product: cont.selectedProduct.id});
      },
      error => {
        console.dir("Error getting product");
        console.dir(error);
      }
    );
  }
}
