import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';
import { CookieService } from "ngx-cookie-service";
import { IMqttServiceOptions, MqttConnectionState } from "ngx-mqtt";
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { EPSEvent } from '../classes/event';
import { EventNode } from '../classes/event_node';
import { LoaderActivator } from "../classes/loader_activator";
import { KeyPerformanceMetric, Product } from '../classes/product';
import { User } from "../classes/user";
import { EventManagementService } from "./event-management.service";
import { UserService } from "./user.service";


export enum ListTab {
  LIST,
  AGGREGATED,
  CREATE,
  DETAILS,
  ADMIN,
  UTILITY_LIST,
  UTILITY_DETAILS,
  UTILITY_CREATE,
  UTILITY_NODEDETAILS,
  NODEDETAILS,
  TRAINING_LIST,
  TRAINING_AGGREGATED,
  TRAINING_DETAILS,
  TRAINING_CREATE,
  TRAINING_NODEDETAILS
}

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  static me;
  static preciseDateFormat:string = "YYYY/MM/DD, HH:mm:ss z";
  static dateFormat:string = "YYYY/MM/DD, HH:mm z";
  static preciseTimeFormat:string = "HH:mm:ss z";
  static timeFormat:string = "HH:mm z";
  selectedTZ:number = 1;
  private tzPref = new Subject<any>();
  comProductsMap:{};
  currentListTab: ListTab = ListTab.LIST;

  constructor(public translate: TranslateService, private toastr: ToastrService, private cookieService: CookieService, private router: Router, private userService: UserService, private route: ActivatedRoute) {
    SharedService.me = this;
    //translate.setDefaultLang('en_AU');
    this.selectedTZ = +this.getCookie('etz') || 1;
    this.comProductsMap = {};
  }

  //------------MODAL STUFF
  // Observable string sources
  private modalActivatedSource = new Subject<any>();
  private passwordModalActivatedSource = new Subject<any>();
  // Observable string streams
  modalActivated$ = this.modalActivatedSource.asObservable();
  passwordModalActivated$ = this.passwordModalActivatedSource.asObservable();


  setListTab(tab:ListTab) {
    if(tab !== undefined) {
      this.currentListTab = tab;
      if(
        this.currentListTab === ListTab.LIST ||
        this.currentListTab === ListTab.AGGREGATED ||
        this.currentListTab === ListTab.TRAINING_LIST ||
        this.currentListTab === ListTab.TRAINING_AGGREGATED
      ) {
        this.buildParams();
      }
    }
  }

  buildParams() {
    let view;
    let isTraining = false;
    switch (this.currentListTab) {
      case ListTab.AGGREGATED:
        view = 'aggregated';
        break;
      case ListTab.LIST:
        view = 'list';
        break;
      case ListTab.TRAINING_LIST:
        view = 'list';
        isTraining = true;
        break;
      case ListTab.TRAINING_AGGREGATED:
        view = 'aggregated';
        isTraining = true;
        break;
    }

    let params = Object.assign({}, view !== 'list' ? this.route.snapshot.queryParams : {}, {view: view});

    if(isTraining){
      this.updateParams('training', params);

    } else {
      this.updateParams('', params);

    }
  }

  getListTab() {
    return this.currentListTab;
  }

  updateParams(route, qp: {[k: string]: any}, next = null): void {
    this.router.navigate([route], {replaceUrl: true, queryParams: qp}).then(() => {

      if(next)
        next();
    });
  }

  // Service message commands
  activateModal(change: any) {
    this.modalActivatedSource.next(change);
  }
  activatePasswordModal(change: any) {
    this.passwordModalActivatedSource.next(change);
  }

  //------------LOADING SCREEN STUFF
  private loaderActivatedSource = new Subject<any>();
  loaderActivated$ = this.loaderActivatedSource.asObservable();

  activateLoader(activator: LoaderActivator) {
    this.loaderActivatedSource.next(activator);
  }

  //------------COOKIE STUFF
  setCookie(name, value):void {
    this.cookieService.set(name, value);
  }

  getCookie(name): string {
    return this.cookieService.get(name);
  }

  //-------------OTHER STUFF
  sortAlphabetically(array, field) {
    array.sort(function(a, b){

      if(!a[field] || a[field].toLowerCase() < b[field].toLowerCase()) { return -1; }
      if(a[field].toLowerCase() > b[field].toLowerCase()) { return 1; }
      return 0;
    });

    return array;
  }

  sortByDate(array, field) {
    let num = 0;
    array.sort( (a, b) => {
      if(!a){
        num = 1;
      }else if(!b) {
        num = -1;
      }
      else {
        const aDate = moment(a);
        const bDate = moment(b);
        num = aDate.isBefore(bDate) ? -1 : 1;
      }
      return num
    });
    return array
  }

  sort(array, field) {
    array.sort(function(a, b){
      if(a[field] < b[field]) { return -1; }
      if(a[field] > b[field]) { return 1; }
      return 0;
    });

    return array;
  }

  transformParams(params) {

    if(!params) {
      return {};
    }

    // Remove any nulls or undefineds. HttpClient doesn't like them
    Object.keys(params).forEach(function(param) {
      if (params[param] === null || params[param] === undefined) {
        delete params[param];
      }
    });

    // HttpClient expects this formatting
    return { params: params };
  }

  popError(message) {
    this.toastr.error(message);
  }

  popSuccess(message) {
    this.toastr.success(message);
  }

  setEventStatus(event:EPSEvent) {
    if(event.cancelled == true) {
      event.status = 'Cancelled';
    } else if(event.event_paused && event.event_paused === true) {
      event.status = 'Paused';
    } else {
      event.status = event.event_end_dttm_utc == null || moment().subtract(1, 'hours').isSameOrBefore(event.event_end_dttm_utc) ? 'Active' : 'Ended';
    }
  }

  getCurrentObligation(event:EPSEvent) {
    if(event.requested_obligations && event.requested_obligations.length)
    {
      switch (event.event_progress_status) {
        case 'BEFORE':
          return event.requested_obligations[0].obligation_value;
        case 'DURING':
          const now = moment().utc();
          const index = _.findIndex(event.requested_obligations, function(o) {
            return moment(o.start_dttm).isSameOrBefore(now) && (!o.end_dttm || moment(o.end_dttm).isSameOrAfter(now)) });
          if(index > -1 ) return event.requested_obligations[index].obligation_value;
          break;
        case 'AFTER':
          return event.requested_obligations[event.requested_obligations.length - 1].obligation_value;
      }
    }
  }

  setCurrentObligation(event:EPSEvent) {
    if(event.requested_obligations && event.requested_obligations.length)
    {
      switch (event.event_progress_status) {
        case 'BEFORE':
          event.obligation = event.requested_obligations[0].obligation_value;
          break;
        case 'DURING':
          const now = moment().utc();
          const index = _.findIndex(event.requested_obligations, function(o) {
            return moment(o.start_dttm).isSameOrBefore(now) && (!o.end_dttm || moment(o.end_dttm).isSameOrAfter(now)) });
          if(index > -1 ) event.obligation = event.requested_obligations[index].obligation_value;
          break;
        case 'AFTER':
          event.obligation = event.requested_obligations[event.requested_obligations.length - 1].obligation_value;
          break;
      }
    }
  }

  setProgressStatus(event:EPSEvent) {
    if(moment().isBefore(moment(event.event_start_dttm_utc))) {
      event.event_progress_status =  'BEFORE';
    }
    else if(!event.event_end_dttm_utc || moment().isBefore(moment(event.event_end_dttm_utc))) {
      event.event_progress_status =  'DURING';
    }
    else {
      event.event_progress_status =  'AFTER';
    }
  }

  getOverallPerformance(event:EPSEvent, product:Product) {
    let perfValue;
    let perfMetric = product && product.key_performance_metric ? product.key_performance_metric : KeyPerformanceMetric.AVERAGE;
    switch (perfMetric) {
      case KeyPerformanceMetric.AVERAGE:
        perfValue = event.average_performance_value;
        break;
      case  KeyPerformanceMetric.MAXIMUM:
        perfValue = event.non_coincident_maximum_performance_value;
        break;
      case KeyPerformanceMetric.MINIMUM:
        perfValue = event.non_coincident_minimum_performance_value;
        break;
    }
    return perfValue;
  }

  getOverallNodePerformance(node: EventNode, product: Product) {
    let perfValue;
    let perfMetric = product.key_performance_metric ||  KeyPerformanceMetric.AVERAGE;
    switch (perfMetric) {
      case KeyPerformanceMetric.AVERAGE:
        perfValue = node.average_performance_value;
        break;
      case  KeyPerformanceMetric.MAXIMUM:
        perfValue = node.maximum_performance_value;
        break;
      case KeyPerformanceMetric.MINIMUM:
        perfValue = node.minimum_performance_value;
        break;
    }
    return perfValue;
  }

  getTimeZoneName(event_time_zone, abbreviated = true): string {
    switch(this.selectedTZ) {
      case 0:

        if(abbreviated)
          return moment().tz(this.getUsersTimeZone()).format('z');
        else
          return this.getUsersTimeZone();

      case 1:

        if(!event_time_zone)
          return 'UTC';

        if(abbreviated)
          return moment().tz(event_time_zone).format('z');
        else
          return event_time_zone;

      default:
        return 'UTC';
    }
  }

  //TODO make this the actual user's timezone

  changeTimeZonePref(tz){
    this.tzPref.next({pref:tz})
  }

  getTimeZonePref(): Observable<any>{
    return this.tzPref.asObservable();
  }

  getUsersTimeZone(): string {
    return this.userService.user.default_time_zone;
  }

  getUserId(): string {
    return this.userService.user.user_id;
  }

  getUsername(): string {
    return this.userService.user.username;
  }

  getUser(): User {
    return this.userService.user;
  }

  selectedTimeZoneToUTC(datePickerDate, program_time_zone) {
    const d = moment(datePickerDate).format(SharedService.dateFormat);

    //Add the timezone to it. We do it this way so adding the timezone doesn't convert the time - we want to just tack a timezone onto the end of whatever the user entered
    let dateWithTimezone;

    //0 = Local, 1 = Program, 2 = UTC
    switch(this.selectedTZ) {
      case 0:
        dateWithTimezone = moment.tz(d, SharedService.dateFormat, this.getUsersTimeZone());
        break;
      case 1:
        //No program time zone supplied, default to UTC
        if(!program_time_zone) {
          program_time_zone = "UTC";
        }

        dateWithTimezone = moment.tz(d, SharedService.dateFormat, program_time_zone);
        break;
      case 2:
        dateWithTimezone = moment.tz(d, SharedService.dateFormat, "UTC");
        break;
    }

    //Now convert it to UTC and return
    return moment.utc(dateWithTimezone);
  }

  fixedDecimals(value, decimals = 3) {
    if(typeof(value) === 'number')
      return value.toFixed(decimals);
    else
      return null;
  }

  static LocalToFormattedSelectedTimeZone(time, programZone) {

    switch(SharedService.me.selectedTZ) {
      case 0:
        return moment.tz(time, SharedService.me.getUsersTimeZone()).format(SharedService.dateFormat);
      case 1:
        if(programZone) {
          return moment.tz(time, SharedService.me.getTimeZoneName(programZone, false)).format(SharedService.dateFormat);
        } else {
          return moment(time).utc().format(SharedService.dateFormat);
        }
      case 2:
        return moment(time).utc().format(SharedService.dateFormat);
    }
  }

  getPrezUOM(product, ignoreHourUnits = false){
    if(product){
      if(product.prez_conf && product.prez_conf['prefered_prez_demand_uom'] && product.prez_conf['prefered_prez_demand_uom'].length){
        if(ignoreHourUnits)
          return product.prez_conf['prefered_prez_demand_uom'].replace('h', '');
        else
          return product.prez_conf['prefered_prez_demand_uom'];
      }
      return 'MW'
    }
    return 'MW'
  }

  convertProductUOM(val, product, asString:boolean = false, fractionDigits = 3, ignoreHourUnits = false, allowNullReturn = false){
    if((val===undefined || val===null) && allowNullReturn) {
      return null;
    }
    let targetUOM = this.getPrezUOM(product);

    let returnVal;
    switch (targetUOM.toLowerCase()) {
      case 'kw':
        fractionDigits = 0;
        returnVal = val;
        break;
      case 'mw':
        returnVal = val/1000;
        break;
      case 'kwh':
        fractionDigits = 0;
        if(ignoreHourUnits) {
          returnVal = val;
          targetUOM = 'kW';
        }
        else
          returnVal = val/60/((product.reporting_interval_ms / 60000) || 1);
        break;
      case 'mwh':
        if(ignoreHourUnits) {
          returnVal = val/1000;
          targetUOM = 'MW';
        }
        else
          returnVal = val/60/((product.reporting_interval_ms / 60000) || 1)/1000;
        break;
    }
    if(asString){
      return (returnVal || 0).toFixed(fractionDigits) + ' ' + targetUOM;
    }
    else{
      return +(returnVal || 0).toFixed(fractionDigits);
    }

  }

  //If we want we can treat kwh as kw, and mwh as mw, by setting ignoreHourUnits to true. We do this for event creation
  convertToKW(val, currentUnit, fractionDigits = null, ignoreHourUnits = true, product = null) {

    let returnVal;
    switch (currentUnit.toLowerCase()) {

      case 'kw':
        returnVal = val;
        break;

      case 'kwh':
        if(ignoreHourUnits)
          returnVal = val;
        else
          returnVal = val/60/((product.reporting_interval_ms / 60000) || 1);
        break;

      case 'mw':
        returnVal = val * 1000;
        break;

      case 'mwh':
        if(ignoreHourUnits)
          returnVal = val * 1000;
        else
          returnVal = val/60/((product.reporting_interval_ms / 60000)|| 1) * 1000;
        break;
    }

    return fractionDigits ? +returnVal.toFixed(fractionDigits) : returnVal;
  }
  //DEPRECATED - use mqtt service
  subscribeToTopic(observable, ids, resource, mqttService, parseFunction, saveObservable:boolean, currentEventSubs, ngUnsubscribe: Subject<any>, ems:EventManagementService) {
    const controller = this;

    if(resource == 'NEW_EVENT' || ids.length) {
      ems.post('/v1/connection', {"ids": ids, "resource": resource}, {}).pipe(takeUntil(ngUnsubscribe)
      ).subscribe(response => {

        let connection = response.data;

        const match = /(.*?):\/\/(.*?)(\/.*)/.exec(connection.endpoint_url);
        if (!match) return;
        const [, protocol, hostname, path] = match;

        if (mqttService.state.value === MqttConnectionState.CLOSED) {
          mqttService.connect({protocol: (protocol as IMqttServiceOptions['protocol']), hostname, path, port: 443});
        }

        let obs = mqttService.observe(connection.topic);
        let sub = obs.pipe(takeUntil(ngUnsubscribe)).subscribe(response => {

          let msg = JSON.parse(new TextDecoder('utf-8').decode(response.payload));
          parseFunction(msg);

        }, error => {
          console.log("Error receiving changes for " + resource + ": " + JSON.stringify(error))
        });

        if(saveObservable) {
          currentEventSubs.push(sub);
        }

        //Renew after 90% of the expire time is up
        setTimeout(() => {
          controller.renewSubscription(connection.client_id, connection.eps, mqttService, ngUnsubscribe, ems);
        }, connection.expires_seconds * .5 * 1000);

      }, error => {
        console.log("Error subscribing to changes for " + resource)
      });
    }
  }

  //DEPRECATED - use mqtt service
  renewSubscription(clientID:string, eps:boolean, mqtt, ngUnsubscribe: Subject<any>, ems:EventManagementService) {
    const controller = this;

    ems.get('/v1/connection/renew/' + clientID + '?eps_connection=' + eps, {}).pipe(takeUntil(ngUnsubscribe)
    ).subscribe(response => {
      let connection = response.data;
      //Call itself again when 90% of the previous time is up to renew
      setTimeout(() => {
        controller.renewSubscription(clientID, eps, mqtt, ngUnsubscribe, ems);
      }, connection.expires_seconds * .5 * 1000)
    }, error => {
      console.log("Error renewing subscription for client ID " + clientID)
    });
  }

  flattenDisplayLabel(displayLabels) {
    const locale = this.userService.user?.default_locale;
    const defaultBackupLocale = 'en_US';
    if (displayLabels == null) {
      return '';
    } else if (typeof displayLabels !== 'object') {
      return displayLabels;
    } else if (Object.keys(displayLabels).length === 0) {
      return '';
    }
    if (locale != null) {
      if (displayLabels[locale] != null) {
        return displayLabels[locale];
      }
    }
    if (!!displayLabels[defaultBackupLocale]) {
      return displayLabels[defaultBackupLocale];
    } else {
      // default isn't even here so return the first label
      return displayLabels[Object.keys(displayLabels)[0]];
    }
  }

  getEventNodeStatus(node){
    switch (node.workflow_status) {
      case 'PENDING':
        return node.opted_out ? 'optedOut' : 'pending';
      case 'WORKFLOW_COMPLETE':
        if (node.cancelled) return 'cancelled';
        if (node.activation_time !== null) return 'completed';
        return 'excluded';
      default:
        return 'active';
    }
  }

}
