import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';
import { IMqttServiceOptions, MqttConnectionState, MqttService } from "ngx-mqtt";
import { forkJoin, from } from 'rxjs';
import { Subject } from "rxjs/internal/Subject";
import { catchError, map, takeUntil } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { EPSEvent } from "../../classes/event";
import { EventNode } from "../../classes/event_node";
import { Product } from "../../classes/product";
import { Registration } from '../../classes/registration';
import { EventManagementService } from "../../services/event-management.service";
import { NodesFormatService } from '../../services/nodes-format.service';
import { RefDataService } from '../../services/ref-data.service';
import { ListTab, SharedService } from "../../services/shared.service";
import { CreateNoteModalComponent } from "../modals/create-note-modal/create-note-modal.component";
import { CustomModalWrapper } from "../modals/enel-modal/enel-modal.component";

@Component({
  selector: 'app-event-node-info',
  templateUrl: './event-node-info.component.pug',
  styleUrls: ['./event-node-info.component.scss']
})
export class EventNodeInfoComponent implements OnInit {

  event: EPSEvent;
  product:Product;
  eventNode : EventNode;
  errorLabel:string;
  eventLoaded:boolean = false;
  workflowStatusTimes = [];
  workflowStates = [];
  caseTypes = [];
  contacts=[];
  controlElements=[];
  notes=[];
  finishedGettingContacts:boolean = false;
  finishedGettingDevices:boolean = false;
  finishedGettingNotes:boolean = false;
  canBeConfirmed:boolean = false;
  exm_url: string = environment.exm_url;
  dmc_url = environment.dmc_url;
  der_url = environment.der_url;
  e_cream_url = environment.e_cream_url;
  reg_admin_url = environment.reg_admin_url;
  diagnosticsUrl = '';
  configUrl = '';
  registration: Registration;
  eventName;
  eventNodeID = null;
  orgName;
  capacitySourcesMap={};
  regTypesMap={};
  eventNodeSubscribe$;
  eventSubscribe$;
  eventDataSubscribe$;
  eCreamLink;
  eCreamText;
  NFS: NodesFormatService;
  isTraining: boolean;
  pageTitle: string;

  constructor(private ems:EventManagementService, private sharedService:SharedService, private route: ActivatedRoute, private mqttService: MqttService,
              private epsMqttService: MqttService, private RDS: RefDataService) {
                this.NFS = new NodesFormatService();
              }

  private ngUnsubscribe: Subject<any> = new Subject();

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {

    const controller = this;

    this.isTraining = Boolean(this.route.snapshot.data['training']) || false;
    this.pageTitle = this.isTraining ? 'Training Event Node Info' : 'Event Node Info';
    const tab = this.isTraining ? ListTab.TRAINING_NODEDETAILS : ListTab.NODEDETAILS;
    this.sharedService.setListTab(tab);

    //Subscribe to any parameter change in the route (i.e the event ID) and if it changes, update the event object
    this.route.paramMap.subscribe(params => {
      controller.eventNodeID = params.get("id");
    });

    this.RDS.getCapacitySourcesMap().subscribe((resp)=>{this.capacitySourcesMap = resp})



    const regTypesMap$ = this.RDS.getRegistrationTypesMap().pipe(map((resp) => {return resp;}), catchError((err)=> {console.log(err); return from(new Promise((resolve) => resolve(true)));}));
    const eventNode$ = this.ems.get('/v1/event_node/' + this.eventNodeID, {}).pipe(map((resp) => {return resp.data;}), catchError((err)=> {console.log(err); return from(new Promise((resolve) => resolve(true)));}));


    //GET EVENT NODE
    forkJoin(eventNode$, regTypesMap$ ).pipe().subscribe(
      ([eventNode, regTypesMap])=>{
        this.eventNode = eventNode;

        this.NFS.updateStatusForSort(this.eventNode);
        this.regTypesMap = regTypesMap;
        this.eventNode.registration_type_display_label = this.sharedService.flattenDisplayLabel(this.regTypesMap[this.eventNode.registration_type].display_labels)
        controller.exm_url = environment.exm_url + '/registrations/' + eventNode.registration_id;

      //GET ORG
      this.ems.get('/v1/hierarchy/spaces/' + this.eventNode.site_id, {direction: 'up'}).pipe().subscribe(
        (resp)=>{
          this.orgName = this.sharedService.flattenDisplayLabel(resp.data.display_labels)
        }
      )

      //GET EVENT
      this.ems.get('/v1/event/' + this.eventNode.event_id, {}).pipe(takeUntil(this.ngUnsubscribe)
      ).subscribe(response => {
        this.event = response.data;

        this.canBeConfirmed = !this.eventNode.confirmed && (!this.event.event_end_dttm_utc || moment().isSameOrBefore(this.event.event_end_dttm_utc));

        if (!controller.eventSubscribe$) {

          controller.subscribeToTopic(controller.eventSubscribe$, [controller.event.event_id], "eps_event", controller.epsMqttService, msg => {
            this.event = Object.assign({}, this.event, msg);
            this.canBeConfirmed = !this.eventNode.confirmed && (!this.event.event_end_dttm_utc || moment().isSameOrBefore(this.event.event_end_dttm_utc));

          });

          controller.subscribeToTopic(controller.eventDataSubscribe$, [controller.event.event_id], "event", controller.mqttService, msg => {
            if(msg.dataType === "event_node_workflow") {
              this.SetWorkflowStatuses();
              if(msg.event_node_id == this.eventNode.event_node_id) this.eventNode = Object.assign({}, this.eventNode, msg);
              this.canBeConfirmed = !this.eventNode.confirmed && (!this.event.event_end_dttm_utc || moment().isSameOrBefore(this.event.event_end_dttm_utc));

            }
            else if(msg.dataType === "ems_event") {
              this.event = Object.assign({}, this.event, msg);
              this.canBeConfirmed = !this.eventNode.confirmed && (!this.event.event_end_dttm_utc || moment().isSameOrBefore(this.event.event_end_dttm_utc));

            }
          });
        }

        //GET PRODUCT
        this.ems.get('/v1/product/' + this.event.product_id, {}).pipe(takeUntil(this.ngUnsubscribe)
        ).subscribe(response => {
          this.product = response.data;
          this.eventLoaded = true;

        }, error => {
          this.finishedGettingNotes = true;
          console.dir("Error getting notes for event node with ID: " + this.eventNode.event_node_id);
          console.dir(error);
        });


      }, error => {
        this.finishedGettingNotes = true;
        console.dir("Error getting notes for event node with ID: " + this.eventNode.event_node_id);
        console.dir(error);
      });

      //Get workflow attributes
        this.RDS.getWorkflowAttributes().subscribe(
          (resp)=>{
            controller.workflowStates = resp;
            this.SetWorkflowStatuses();
          }
        )


      //GET CASE TYPES

        this.RDS.getCaseTypes().subscribe((resp)=>{
          controller.caseTypes = resp;

        //GET NOTES AND STORE THEM
        this.ems.get('/v1/notes/' + this.eventNode.registration_id, {}).pipe(takeUntil(this.ngUnsubscribe)
        ).subscribe(response => {
          this.notes = response.data;
          this.finishedGettingNotes = true;

          this.notes.forEach(n => {

            this.caseTypes.forEach(ct => {
              if(ct.code === n.case_type) {
                n.case_type_display_label = ct.display_label;
              }
            });
          });

        }, error => {
          this.finishedGettingNotes = true;
          console.dir("Error getting notes for event node with ID: " + this.eventNode.event_node_id);
          console.dir(error);
        });


      }, error => {
        this.sharedService.popError("Error getting Case Types");
        console.dir("Error getting case types: ");
        console.dir(error);
      });

      //GET CONTACTS AND STORE THEM
      this.ems.get('/v1/event_node/site/' + this.eventNode.site_id + '/contacts', {}).pipe(takeUntil(this.ngUnsubscribe)
      ).subscribe(response => {
        this.contacts = response.data;
        this.finishedGettingContacts = true;

      }, error => {
        this.finishedGettingContacts = true;
        console.dir("Error getting contacts for event node with ID: " + this.eventNode.event_node_id);
        console.dir(error);
      });

      //GET REGISTRATION
      this.ems.get('/v1/registration/' + this.eventNode.registration_id, {}).pipe(takeUntil(this.ngUnsubscribe)
      ).subscribe(response => {

        this.registration = response;
        if(this.registration?.alternate_ids && this.registration.alternate_ids['ECRM_ID']){
          this.eCreamLink = `${this.e_cream_url}lightning/r/Registration__c/${this.registration?.alternate_ids['ECRM_ID']}/view`;
          this.eCreamText = this.registration.alternate_ids['ECRM_ID'];
        }

        //GET CONTROL ELEMENTS
        this.ems.get('/v1/registration/' + this.eventNode.registration_id + '/control_elements', {}).pipe(takeUntil(this.ngUnsubscribe)
        ).subscribe(response => {
          this.controlElements = response.data;
          this.finishedGettingDevices = true;

          if(this.registration['control_type'] === "STORAGE") {

            this.controlElements.forEach(e => {
              if(e['control_set'] && e['control_set'].address)
                e.diagnosticsUrl = this.der_url + '/#/app/home/sites/' + e['control_set'].address;
              else {
                e.diagnosticsUrl = this.der_url;
                console.error("Error: No site_id found on the registration with ID: " + response.id)
              }

              if(this.registration['program_id'])
                e.configUrl = this.der_url + '/#/program/' + this.registration['program_id'] + '/view';
              else {
                e.configUrl = this.der_url;
                console.error("Error: No program_id found on the registration with ID: " + this.registration['program_id'])
              }

            });
          }

        }, error => {
          this.finishedGettingDevices = true;
          console.dir("Error getting control sets for registration: " + this.eventNode.registration_id);
          console.dir(error);
        });


      }, error => {
        console.dir("Error getting registration: " + this.eventNode.registration_id);
        console.dir(error);
      });

    }, error => {
      this.eventLoaded = true;
      this.errorLabel = "Error getting event node! See console for details.";
      console.dir("Error getting event node with ID: " + this.eventNodeID);
      console.dir(error);
    });
  }

  subscribeToTopic(observable, ids, resource, mqttService, parseFunction) {
    const controller = this;

    if(ids.length) {
      controller.ems.post('/v1/connection', {"ids": ids, "resource": resource}, {}).pipe(takeUntil(controller.ngUnsubscribe)
      ).subscribe(response => {

        let connection = response.data;

        const match = /(.*?):\/\/(.*?)(\/.*)/.exec(connection.endpoint_url);
        if (!match) return;
        const [, protocol, hostname, path] = match;

        if (mqttService.state.value === MqttConnectionState.CLOSED) {
          mqttService.connect({protocol: (protocol as IMqttServiceOptions['protocol']), hostname, path, port: 443});
        }

        observable = mqttService.observe(connection.topic);
        observable.pipe(takeUntil(controller.ngUnsubscribe)).subscribe(response => {

          let msg = JSON.parse(new TextDecoder('utf-8').decode(response.payload));
          parseFunction(msg);

        }, error => {
          console.log("Error receiving changes for " + resource)
        });

        //Renew after 90% of the expire time is up
        setTimeout(() => {
          controller.renewSubscription(connection['client_id'], resource === "EVENT_NODE", mqttService);
        }, connection.expires_seconds * .5 * 1000)

      }, error => {
        console.log("Error subscribing to changes for " + resource)
      });
    }
  }

  renewSubscription(clientID:string, eps:boolean, mqtt) {
    const controller = this;

    controller.ems.get('/v1/connection/renew/' + clientID + '?eps_connection=' + eps, {}).pipe(takeUntil(controller.ngUnsubscribe)
    ).subscribe(response => {
      let connection = response.data;
      //Call itself again when 90% of the previous time is up to renew
      setTimeout(() => {
        controller.renewSubscription(clientID, eps, mqtt);
      }, connection.expires_seconds * .5 * 1000)
    }, error => {
      console.log("Error renewing subscription for client ID " + clientID)
    });
  }

  SetWorkflowStatuses() {
    this.workflowStatusTimes = [];
    let index = 0;
    this.workflowStates.forEach(wfs => {
      //This will handle all of the attributes that have an "included" boolean on them
      if(wfs.included_attribute_name !== null && this.eventNode[wfs.included_attribute_name.toLowerCase()]) {

        this.workflowStatusTimes.push({
          name: wfs.display_label,
          qaa: wfs.display_label.toLowerCase().replace(/\s/g, '-'),
          time: this.eventNode[wfs.code.toLowerCase()]
        });
      }
      //This will handle the rest
      else if(wfs.included_attribute_name === null && this.eventNode[wfs.code.toLowerCase()]) {

        this.workflowStatusTimes.push({
          name: wfs.display_label,
          qaa: wfs.display_label.toLowerCase().replace(/\s/g, '-'),
          time: this.eventNode[wfs.code.toLowerCase()]
        })
      }

      index++;

      if(index == this.workflowStates.length)
        this.sharedService.sort(this.workflowStatusTimes, 'time');

    });
  }

  onClickConfirm(){
    const controller = this;

    const reqObj = [{
      event_nodes: [this.eventNode.event_node_id],
      attributes: { confirmed : moment().toISOString()}
    }];

    controller.ems.put('/v1/event_node_workflows', reqObj, {}).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      response => {
        controller.sharedService.popSuccess("Successfully confirmed Event Node");

        this.canBeConfirmed = !this.eventNode.confirmed && (!this.event.event_end_dttm_utc || moment().isSameOrBefore(this.event.event_end_dttm_utc));

      },
      error => {
        this.sharedService.popError("Failed to confirm Event Node!");
      });
  }

  addNote() {
    const controller = this;

    this.sharedService.activateModal({
      headerText: "Add Note",
      bodyText: "Adding a note to 1 event node.",
      customContent: new CustomModalWrapper(CreateNoteModalComponent, {
        buttonText: 'Add Note',
        caseTypes: controller.caseTypes,
        confirmFunction: function (note) {
          controller.ems.post('/v1/notes', {
            event_nodes: [{event_node_id: controller.eventNode.event_node_id, registration_id: controller.eventNode.registration_id}],
            notes: [note]
          }, {}).pipe(takeUntil(controller.ngUnsubscribe)
          ).subscribe(response => {
              controller.sharedService.popSuccess("Successfully added note. Updates may take up to 1 minute to display on this page.");
            },
            error => {
              controller.sharedService.popError("Failed to add note!");
              console.dir(error)
            });
        }
      })
    });
  }


}
