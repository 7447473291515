import { CustomTask } from './customTask';





export interface MarkerInterface{
  marker_type: string,
  marker_event: string,
  schedule_time: string,
  timeline_time: string,
  event_node_ids: string[]
}

export interface EventNodeMarker extends MarkerInterface{

}

export interface EventMarker extends MarkerInterface{
  event_ids: string[]
}

export interface WorkflowMarker extends MarkerInterface{
  status: string,
  workflow_state_name: string,
  display_label: string,
  offset_from: string,
  step_num: number,
  included: boolean,
  automated: boolean,
  manual_trigger_time: string,
  completed: boolean,
  assumed_completed_time: string,
  event_node_ids: string[],
  confirmed_nodes?: string[],
  preauthorized_nodes?: string[]
  overlapped_nodes?: string[]
}

export interface CustomTaskMarker extends MarkerInterface{
  action_type: string,
  action_parameter: string,
  template_display_label: string,
  filter_type: string,
  filter_display_label: string,
  completed_time: string,
  automated: boolean,
  email_delivery: boolean,
  voice_delivery: boolean,
  sms_delivery: boolean,
  tasks: string[],
  events: string[],
  event_nodes: string[],
  filtered_event_nodes: string[],
  recipient_event_nodes: string[]
}


export const MarkerType = {
  WORKFLOW: "WORKFLOW",
  EVENT: "EVENT",
  CUSTOM_TASK: "CUSTOM_TASK",
  EVENT_NODE: "EVENT_NODE"
} as const;
type MarkerType = typeof MarkerType[keyof typeof MarkerType];

export const MarkerEvent = {
  START: "START",
  END: "END"
} as const;
type MarkerEvent = typeof MarkerEvent[keyof typeof MarkerEvent];

export const MarkerStatus = {
  COMPLETED: "COMPLETED",
  SKIPPED: "SKIPPED",
  CANCELLED: "CANCELLED",
  CURRENT: "CURRENT",
  UPCOMING: "UPCOMING",
  EXCLUDED: "EXCLUDED"
} as const;
type MarkerStatus = typeof MarkerStatus[keyof typeof MarkerStatus];
