import { Component, OnInit, Input } from '@angular/core';
import {SharedService} from "../../../services/shared.service";
import {EndTimeOptions} from "../../event-creation/event-creation.component";
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';

export enum DateSelection
{
  seven, thirty, ninety, year, custom
}

@Component({
  selector: 'app-create-note-modal',
  templateUrl: './create-note-modal.component.pug',
  styleUrls: ['./create-note-modal.component.scss']
})
export class CreateNoteModalComponent implements OnInit {

  dateSelections = DateSelection;

  constructor(private sharedService: SharedService) {
  }

  @Input() data:any;

  noteContent:string;
  caseType:string;
  chosenDateSelection:DateSelection;
  expirationDate;
  customDate;

  ngOnInit() {

    this.chosenDateSelection= DateSelection.thirty;
    this.caseType = "NO_ASSOCIATED_CASE";

    this.onDurationSelect();
  }

  onDurationSelect(): void {
      switch (this.chosenDateSelection) {

        case DateSelection.seven:
          this.expirationDate = moment().add(7, 'd').format('YYYY-MM-DD');
          break;

        case DateSelection.thirty:
          this.expirationDate = moment().add(30, 'd').format('YYYY-MM-DD');
          break;

        case DateSelection.ninety:
          this.expirationDate = moment().add(90, 'd').format('YYYY-MM-DD');
          break;

        case DateSelection.year:
          this.expirationDate = moment().add(1, 'y').format('YYYY-MM-DD');
          break;
      }
  }

  confirm() {
    if(this.chosenDateSelection === DateSelection.custom)
      this.expirationDate = this.customDate;

    let finalTime:string = this.sharedService.selectedTimeZoneToUTC(this.expirationDate, this.data.timezone).toISOString();

    this.data.confirmFunction({note_text: this.noteContent, case_type: this.caseType, expiration_dttm: finalTime});
    this.data.close();
  }

  close() {
    this.data.close();
  }
}
