import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-obligation-modal',
  templateUrl: './obligation-modal.component.pug',
  styleUrls: ['./obligation-modal.component.scss']
})
export class ObligationModalComponent implements OnInit {

  constructor() { }

  @Input() data:any;

  obligation:number;

  ngOnInit() {
  }

  confirm() {
    this.data.confirmFunction(this.obligation);
    this.data.close();
  }

  close() {
    this.data.close();
  }
}
