import { animate, group, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';
import { CookieService } from 'ngx-cookie-service';
import { MqttService } from 'ngx-mqtt';
import { of, Subject, Subscription } from 'rxjs';
import { EPSEvent } from '../../../classes/event';
import { EventNode, RegistrationType } from '../../../classes/event_node';
import { EventNodeIot } from '../../../classes/node-performance';
import { Product } from '../../../classes/product';
import { EventManagementService } from '../../../services/event-management.service';
import { SharedService } from '../../../services/shared.service';


@Component({
  selector: 'app-npc-banner',
  templateUrl: './npc-banner.component.pug',
  styleUrls: ['./npc-banner.component.scss'],
  animations: [
    trigger('toggle', [
      transition(':enter', [
        style({'max-height': 0, 'padding': 0}),
        group([
          animate('200ms ease-out', style({ padding: '20px 30px' })),
          animate('500ms ease-out', style({ 'max-height': 500 })),
        ]),
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ 'height': 0, 'padding-top': 0, 'padding-bottom': 0, 'padding-right': 0, 'padding-left': 0 })),
      ]),
    ]),
    trigger('fade', [
      transition('* => *', [
        style({ opacity: 0 }),
        animate('200ms ease-out', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class NpcBannerComponent implements OnInit, OnDestroy {

  @Input() event: EPSEvent;
  @Input() eventNode: EventNode;
  @Input() product: Product;
  @Input() utility:boolean = false;

  tzSubscription: Subscription;
  performanceStatus: string;
  progressStatus: string;
  isEstimated: boolean;
  isAfter: boolean;
  asOfSuffix: string = '';
  headerPrimary;
  headerSecondary;
  trayPrimaryText;
  traySecondaryTitle;
  traySecondaryText;
  expanded = false;
  capacityValue;
  capacityUOM;
  tz;
  tzShort;
  private ngUnsubscribe: Subject<any> = new Subject();
  private nodeSubscribe$;


  constructor(private SS: SharedService, private cookieService: CookieService, private MQTT: MqttService, private ems: EventManagementService) {

  }

  ngOnInit() {
    this.render();
    this.subscribeNode();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  render(){
    this.SS.setProgressStatus(this.event);
    this.isEstimated = this.eventNode.estimate_performance_ind === true;
    this.isAfter = this.event.event_progress_status == 'AFTER';
    this.capacityValue = this.utility ? this.eventNode.registered_capacity_value : this.eventNode.expected_capacity_value;
    this.capacityUOM = this.utility ? this.eventNode.registered_capacity_uom : this.eventNode.expected_capacity_uom;


    this.tz = this.event.full_time_zone;
    this.tzShort = moment().tz(this.tz).format('z');

    if (this.eventNode.last_current_performance_dttm_utc) {
      this.asOfSuffix = ' as of ' + moment.tz(this.eventNode.last_current_performance_dttm_utc, this.tz).format(SharedService.dateFormat) + ' ' + this.tzShort
    }
    
    this.headerSecondary = this.getHeaderSecondary();

    if (this.isAfter) {
      this.performanceStatus = this.eventNode.average_performance_status;
    } else {
      this.performanceStatus = this.eventNode.last_current_performance_status;
    }

    if (this.eventNode.last_current_performance_dttm_utc) {
      this.asOfSuffix = ' as of ' + SharedService.LocalToFormattedSelectedTimeZone(this.eventNode.last_current_performance_dttm_utc, this.event.full_time_zone) + ' ' + this.cookieService.get('etz')
    }
    this.headerPrimary = this.getHeaderPrimary();
    this.headerSecondary = this.getHeaderSecondary();
    this.trayPrimaryText = this.getTrayPrimaryText();
  }

  subscribeNode() {
    const controller = this;
    controller.SS.subscribeToTopic(controller.nodeSubscribe$, [controller.eventNode.event_node_id], 'NORMALISED_NODE', controller.MQTT, (msg)=> {
      of(msg as EventNodeIot).pipe().subscribe(
        (resp)=> {
          this.event = Object.assign({}, this.eventNode, msg);
          this.render();
        },
        (error)=> {
          console.log(error)
        }
      )
    }, false, [], controller.ngUnsubscribe, controller.ems)
  }

  toggle(): void {
    this.expanded = !this.expanded;
  }

  getPerformancePercent(node:EventNode, product: Product) {
      const performanceVal = this.SS.getOverallNodePerformance(node, product);
      const expectedCapacity = node.expected_capacity_value;
      const performancePerCent = expectedCapacity === 0 ? 0 : Math.round(performanceVal / expectedCapacity * 100);
      return performancePerCent.toFixed(0) + '%'
  }

  isEPSEvent(ev: EPSEvent | EventNode): ev is EPSEvent {
    return (ev as EPSEvent).event_id !== undefined;
  }

  getPerformanceClass() {
    switch (this.performanceStatus) {
      case 'PERFORMING':
        return 'performance-good';
      case 'PERFORMING_NEAR_EXPECTATION':
        return 'performance-meh';
      case 'UNDER_PERFORMING':
        return 'performance-bad';
      default:
        return 'performance-none'
    }
  }

  getHeaderPrimary() {
    const isLoadDropTo = this.eventNode.registration_type === RegistrationType.LOAD_DROP_TO;
    if(!this.performanceStatus || this.performanceStatus === 'ONLY_PERF_AVAILABLE' || this.performanceStatus === 'ONLY_TARGET_AVAILABLE' || this.performanceStatus === 'NO_PERF_NUMBERS') {
      return 'No performance data available at this location.'
    } else if(this.isAfter) {
      switch (this.performanceStatus) {
        case 'PERFORMING':
          return 'Performed as expected.';
        case 'PERFORMING_NEAR_EXPECTATION':
          return 'Nearly performed as expected.';
        case 'UNDER_PERFORMING':
          return 'Did not perform as expected.';
      }
    } else {
      switch (this.performanceStatus) {
        case 'PERFORMING':
          return 'Performing as expected.';
        case 'PERFORMING_NEAR_EXPECTATION':
          return 'Nearly performing as expected.';
        case 'UNDER_PERFORMING':
          if (isLoadDropTo) return 'Not performing as expected.';
          return 'Not yet performing as expected.';
      }
    }
  }

getHeaderSecondary() {
  if (!this.performanceStatus || this.performanceStatus === 'NO_PERF_NUMBERS') {
    return '';
  }

  const isLoadDropTo = this.eventNode.registration_type === RegistrationType.LOAD_DROP_TO;

  if (isLoadDropTo) {
    return this.getLoadDropToHeaderSecondary();
  }

  const statusActions = {
    ONLY_TARGET_AVAILABLE: () => `Expected reduction: ${this.capacityValue.toLocaleString()} kW${this.asOfSuffix}`,
    PERFORMING_NEAR_EXPECTATION: () => this.defaultHeaderSecondary(),
    ONLY_PERF_AVAILABLE: () => this.performanceHeaderSecondaryNoExpectation()
  };

  return statusActions[this.performanceStatus] ? statusActions[this.performanceStatus]() : this.defaultHeaderSecondary();
}

defaultHeaderSecondary(): string {
  const performanceText = this.isAfter ? 'Overall Performance of the event is' : 'Current Performance of the last interval is';
  const expectedValue = this.isAfter
    ? this.SS.getOverallNodePerformance(this.eventNode, this.product).toLocaleString()
    : this.eventNode.last_current_performance_value.toLocaleString();
  const suffix = this.isAfter
    ? `of Expected ${this.capacityValue.toLocaleString()} kW ${this.getPerformancePercent(this.eventNode, this.product)}`
    : `of Expected ${this.capacityValue.toLocaleString()} kW ${this.eventNode.last_current_performance_percentage.toLocaleString()}%`;

  return `${performanceText} ${expectedValue} ${suffix}${this.asOfSuffix}`;
}

performanceHeaderSecondaryNoExpectation(): string {
  const performanceText = this.isAfter ? 'Overall Performance of the event is' : 'Current Performance of the last interval is';
  const performanceValue = this.isAfter
    ? this.SS.getOverallNodePerformance(this.eventNode, this.product).toLocaleString()
    : this.eventNode.last_current_performance_value.toLocaleString();

  return `${performanceText} ${performanceValue} kW${this.asOfSuffix}`;
}

getLoadDropToHeaderSecondary(): string {
  const productUOM = this.product.prez_conf.prefered_prez_demand_uom ?? this.eventNode.last_current_performance_uom;
  const target = Math.round(this.eventNode.last_current_target_value).toLocaleString();
  const averagePerformance = this.eventNode.average_performance_percentage;
  const demand = Math.round(this.eventNode.last_current_metered_value).toLocaleString();
  const dispatchPercentage = (100 - averagePerformance).toFixed(0);
  const performanceValueKW = Math.round(Math.abs(this.eventNode.last_current_performance_value))
  const performanceValue = (productUOM.toLocaleLowerCase() === "kw" ? performanceValueKW : (performanceValueKW/1000)).toLocaleString();
  const capacityValue = (productUOM.toLocaleLowerCase() === "kw" ? this.capacityValue : (this.capacityValue/1000)).toLocaleString()
  const loadDropConditions = {
    UNDER_PERFORMING: () => this.isAfter
      ? `Demand exceeded target (${target} ${productUOM}) for ${dispatchPercentage}% of dispatch${this.asOfSuffix}`
      : `Demand (${demand} ${productUOM}) is ${performanceValue} ${productUOM} above target (${target} ${productUOM})${this.asOfSuffix}`,
    PERFORMING_NEAR_EXPECTATION: () => this.isAfter
      ? `Demand exceeded target (${target} ${productUOM}) for ${dispatchPercentage}% of dispatch${this.asOfSuffix}`
      : `Demand (${demand} ${productUOM}) is ${performanceValue} ${productUOM} above target (${target} ${productUOM})${this.asOfSuffix}`,
    PERFORMING: () => this.isAfter
      ? `Demand was below target (${target} ${productUOM}) for ${averagePerformance}% of dispatch${this.asOfSuffix}`
      : `Demand (${demand} ${productUOM}) is below target (${target} ${productUOM})${this.asOfSuffix}`,
    ONLY_PERF_AVAILABLE: () => `Current Performance of the last interval is ${performanceValue} ${productUOM}${this.asOfSuffix}`,
    ONLY_TARGET_AVAILABLE: () => `Expected reduction: ${capacityValue} ${productUOM}${this.asOfSuffix}`
  };

  return loadDropConditions[this.performanceStatus] ? loadDropConditions[this.performanceStatus]() : '';
}

  getTrayPrimaryText() {
    switch (this.performanceStatus) {
      case 'NO_PERF_NUMBERS':
      case 'ONLY_PERF_AVAILABLE':
      case 'ONLY_TARGET_AVAILABLE':
      case null:
        if(this.isEstimated) {
          return 'Real-time readings are not showing up as this is a non-metered site. We will inform you once the readings are obtained from the utility.'
        } else {
          return 'We are not detecting any performance data for this location.';
        }
    }
  }

}
