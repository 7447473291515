import {Component, Input, OnInit, SimpleChange, SimpleChanges} from '@angular/core';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';
import {SharedService} from "../../services/shared.service";


@Component({
  selector: 'app-timestamp',
  templateUrl: './timestamp.component.pug',
  styleUrls: ['./timestamp.component.scss']
})
export class TimestampComponent implements OnInit {

  //Base time should always be UTC when it comes in - that's how we save everything to the DB
  @Input() baseTime;
  @Input() programTZ;
  @Input() timeOnly:boolean = false;
  @Input() showTooltip: boolean = true;
  @Input() showSeconds: boolean = false;


  utcTime;
  formattedProgramTime;
  formattedLocalTime;

  constructor(private sharedService: SharedService) { }

  ngOnInit() {
    this.updateTimeStrings();
  }

  ngOnChanges(changes: SimpleChanges) {
    const newTime: SimpleChange = changes.baseTime;

    if(newTime) {
      this.baseTime = newTime.currentValue;
      this.updateTimeStrings();
    }
  }

  updateTimeStrings() {



    if(this.baseTime && this.baseTime !== 'null' ) {
      let format = SharedService.dateFormat;
      if(this.timeOnly){
        if(this.showSeconds){
          format = SharedService.preciseTimeFormat;
        }else{
          format = SharedService.timeFormat;
        }
      }else{
        if(this.showSeconds){
          format = SharedService.preciseDateFormat;
        }else{
          format = SharedService.dateFormat;
        }
      }

      //Base time should already be UTC when it comes in, but we format it here to look better
      this.utcTime = moment(this.baseTime).utc().format(format);

      if(this.programTZ && this.programTZ !== 'Coordinated Universal Time') {
        this.formattedProgramTime = moment.tz(this.baseTime, this.programTZ).format(format);
      } else {
        this.formattedProgramTime = this.utcTime;
      }

      this.formattedLocalTime = this.sharedService.getUser() == null ? 'Could not resolve local time' : moment.tz(this.baseTime, this.sharedService.getUsersTimeZone()).format(format);
    }
    else {
      this.formattedProgramTime = 'None';
      this.formattedLocalTime = 'None';
      this.utcTime = 'None';
    }
  }

  getDefaultTimeZone() {
    switch(this.sharedService.selectedTZ) {
      case 0:
        return this.formattedLocalTime;
      case 1:
        return this.formattedProgramTime;
      case 2:
        return this.utcTime;
    }

  }

}
