import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-enel-tabs',
  templateUrl: './enel-tabs.component.pug',
  styleUrls: ['./enel-tabs.component.scss']
})
export class EnelTabsComponent implements OnInit {

  @Input() tabs = [];
  @Input() disabled;

  currentTab = null;

  constructor() { }

  ngOnInit() {
    this.currentTab =  this.tabs[0].value;
  }

  changeTab(value) {
    if(!this.disabled)
      this.currentTab = value;
  }

}
