import {Injectable} from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, ReplaySubject } from 'rxjs';
import {environment} from "../../environments/environment";
import { User } from "../classes/user";

@Injectable({
  providedIn: 'root'
})

export class UserService {

  constructor(private http: HttpClient) { }
  private _user:User;

  public get user(): User {
    return this._user;
  }

  public set user(val:User) {
    this._user = val;
    this.user$.next(val)
  }

  user$ = new ReplaySubject<any>();


  public formatUser(user_details):void {

    let usr = new User();
    usr.brand = user_details.brand;
    usr.first_name = user_details.profile.names.first_name;
    usr.last_name = user_details.profile.names.last_system_name;
    usr.default_locale = user_details.preferences.default_locale;
    usr.default_unit_system = user_details.preferences.default_unit_system;
    usr.default_time_zone = user_details.preferences.default_time_zone;
    usr.username = user_details.username;
    usr.user_id = user_details.user_id;
    usr.resources = user_details.resources;

    this.user = usr;
}

}
