import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TimelineBulkActionModalComponent } from "../modals/timeline-bulk-action-modal/timeline-bulk-action-modal.component";
import { SharedService } from "../../services/shared.service";
import { CustomModalWrapper } from "../modals/enel-modal/enel-modal.component";
import * as Highcharts from "highcharts";
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';
import * as Moment from "moment";
import { CustomTask } from "../../classes/customTask";
import { EPSEvent } from "../../classes/event";
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';


@Component({
  selector: 'app-event-timeline',
  templateUrl: './event-timeline.component.pug',
  styleUrls: ['./event-timeline.component.scss']
})
export class EventTimelineComponent implements OnInit {
  Highcharts = Highcharts;

  @Input() events:Array<EPSEvent> = null;
  @Input() workflowStateInfo = null;
  @Input() eventNodeMap;
  @Input() allCustomTasks: Array<CustomTask> = [];

  @Input() nodesLoaded;

  @Output() bulkDoNowStep : EventEmitter<any> = new EventEmitter();
  @Output() bulkExclude : EventEmitter<any> = new EventEmitter();
  @Output() bulkRescheduleStep : EventEmitter<any> = new EventEmitter();

  @Output() bulkDoNow : EventEmitter<any> = new EventEmitter();
  @Output() bulkCancel : EventEmitter<any> = new EventEmitter();
  @Output() bulkUpdate : EventEmitter<any> = new EventEmitter();

  timeline = null;
  chart = null;
  updateTimeline = true;
  curtailIndex:number;
  restoreCompleteIndex:number;
  pauseTimelineRefresh: boolean = false;

  //region chart options
  timelineOptions = {
    chart: {
      borderWidth: 1,
      borderColor: '#d8d8d8',
      spacingBottom: 15,
      spacingTop: 10,
      spacingLeft: 10,
      spacingRight: 10,
      style: {
        color: '#461e7d',
        fontFamily: 'RoobertENEL-Light, sans-serif'
      },
    },
    xAxis: {
      type: 'datetime',
      visible: false
    },
    yAxis: {
      gridLineWidth: 1,
      title: null,
      labels: {
        enabled: false
      }
    },

    title: undefined,
    legend: {
      enabled: false
    },

    tooltip:
      {
        distance: 5,
        useHTML: true,
        borderWidth: 0,
        borderRadius: 0,
        shadow: false,
        backgroundColor: "rgba(255,255,255,1)",
        formatter: function () {
          if(!this.point.options.empty ) {

            let typeString = this.point.options.label === 'Custom Task' ? ' Events' : ' Nodes';
            let tooltip = "<div style='z-index: 1000'><b>" + this.point.options.label + ": " + this.point.options.name + "</b>";

            if(this.point.options.count)
              tooltip += '<div style="float:left">'
                + this.point.options.count
                + typeString + '</div> ' +
                '<div style="float:right; color: #461e7d;"><b>'
                + (this.point.options.control_type ? this.point.options.control_type : '')
                + '</b></div></br></br>';

            if(!this.point.options.hasOwnProperty("hideTime") || this.point.options.hideTime === false) {
              tooltip += '<div style="display: block">';
              tooltip += this.point.options.estimated_time == true ? 'TBD' : this.point.options.time;
              tooltip += '</div>'
            }

            tooltip+= "</div>";

            return tooltip;
          }
        },
        style: {
          fontFamily: "RoobertENEL-Light, sans-serif",
          zIndex: 1000
        }
      },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        cursor: 'pointer',
        point: {
        },
        states: {
          inactive: {
            opacity: 1
          }
        }
      }
    },
    series: [{
      title: 'Future Steps',
      zoneAxis: 'x',
      type: 'timeline',
      dataLabels: {
        allowOverlap: false,
        useHTML: true,
        distance: 135,
        formatter: function () {
          let now = moment.tz(moment(), this.point.options.timezone)
          let excludedColor = "rgba(211,211,211,0.75)";

          if (this.point.options.labelStaggerIndex % 3 === 0 || this.point.options.labelStaggerIndex % 4 === 0) {
            let numToAdd = this.point.options.dataLabels.y > 0 ? 50 : -65;
            this.point.options.dataLabels.y = numToAdd;
          }

          if (!this.point.options.empty) {

            let typeString = this.point.options.label === 'Custom Task' || this.point.options.label === 'Event Info' ? ' Events' : ' Nodes';
            let formatString = '<div style="min-width: 130px;"><i class="' + this.point.options.icon + '" style="margin-right: 5px; color:' + this.point.options.color + '"></i>' +
              '<b style="color:' + this.point.options.color + '">' + this.point.options.name + '</b></div>';

            if (this.point.options.count)
              formatString += '<span style="float:left">' + this.point.options.count + typeString + '</span> <span style="float:right; ' +
                'color:' + (this.point.options.included ? this.point.options.color : excludedColor) + ' ;"><b>' +
                (this.point.options.control_type ? this.point.options.control_type : '') + '</b></span></br>';

            if (this.point.options.hideTime === undefined || this.point.options.hideTime === null || this.point.options.hideTime === false) {
              formatString += this.point.options.estimated_time == true ? 'TBD' : this.point.options.time;

              let relativeString = now.isBefore(moment(this.point.options.unformatted_time)) ? "m from now" : "m ago";
              let duration = moment.duration(now.diff(moment(this.point.options.unformatted_time)));
              let days = Math.abs(duration.get("days")) > 0 ? Math.abs(duration.get("days")) + "d, " : "";
              let hours = Math.abs(duration.get("hours")) > 0 ? Math.abs(duration.get("hours")) + "h, " : "";
              if (!this.point.options.estimated_time) {
                formatString += '<hr style="margin-top: 3px; margin-bottom: 3px;">' +
                  '<span style="font-style: italic">' + days + hours + Math.abs(duration.get("minutes")) + relativeString + '</span>';
              }
            }

            return '<div style="color:' + (this.point.options.included ? '' : excludedColor) + ' ">' + formatString + '</div>';
          }
        }
      },
      marker: {
        symbol: 'circle'
      }
    }, {
      title: 'Completed Steps',
      zoneAxis: 'x',
      type: 'timeline',
      dataLabels: {
        allowOverlap: false,
        useHTML: true,
        distance: 135,
        formatter: function () {
          let now = moment.tz(moment(), this.point.options.timezone)
          let excludedColor = "rgba(211,211,211,0.75)";

          if (this.point.options.labelStaggerIndex % 3 === 0 || this.point.options.labelStaggerIndex % 4 === 0) {
            let numToAdd = this.point.options.dataLabels.y > 0 ? 50 : -65;
            this.point.options.dataLabels.y = numToAdd;
          }

          if (!this.point.options.empty) {

            let typeString = this.point.options.label === 'Custom Task' || this.point.options.label === 'Event Info' ? ' Events' : ' Nodes';
            let formatString = '<div style="min-width: 130px;"><i class="' + this.point.options.icon + '" style="margin-right: 5px; color:' + this.point.options.color + '"></i>' +
              '<b style="color:' + this.point.options.color + '">' + this.point.options.name + '</b></div>';

            if (this.point.options.count)
              formatString += '<span style="float:left">' + this.point.options.count + typeString + '</span> <span style="float:right; ' +
                'color:' + (this.point.options.included ? this.point.options.color : excludedColor) + ' ;"><b>' +
                (this.point.options.control_type ? this.point.options.control_type : '') + '</b></span></br>';
            formatString += this.point.options.estimated_time == true ? 'TBD' : this.point.options.time;

            let relativeString = now.isBefore(moment(this.point.options.unformatted_time)) ? "m from now" : "m ago";
            let duration = moment.duration(now.diff(moment(this.point.options.unformatted_time)));
            let days = duration.get("days") > 0 ? duration.get("days") + "d, " : "";
            let hours = duration.get("hours") > 0 ? duration.get("hours") + "h, " : "";

            if (!this.point.options.estimated_time) {
              formatString += '<hr style="margin-top: 3px; margin-bottom: 3px;">' +
                '<span style="font-style: italic">' + days + hours + duration.get("minutes") + relativeString + '</span>';
            }

            return '<div style="color:' + (this.point.options.included ? '' : excludedColor) + ' ">' + formatString + '</div>';
          }
        }
      },
      marker: {
        symbol: 'circle'
      }
    }]
  };
  //endregion

  constructor(private sharedService: SharedService) { }

  ngOnInit() {
    //Set moment on the window so highcharts can find it
    window['moment'] = Moment
  }

  setTimeline(chart: Highcharts.Chart) {

    this.timeline = chart;
    const controller = this;

    this.setTimelineData();

    //Update the timeline every 5 seconds
    setInterval(() => {
      controller.setTimelineData();
    }, 5000);
  };
  ngAfterViewInit() {
    window.dispatchEvent(new Event('resize'));
  }

  setTimelineData(){
    //Don't update the timeline under certain circumstances, like the timeline action modal being open
    if(this.pauseTimelineRefresh) {
      return;
    }

    let completedData = [];
    let futureData = [];

    const controller = this;
    let workflowStatusTimes = [];
    let unSortedStatusTimes = [];


    //Create the objects for the workflow_steps on the timeline and order them by sequence. If they don't have scheduled times, set them.
    let setTimelineSteps = function() {
      let addToStart = 0;
      controller.workflowStateInfo.forEach(wfs => {

        controller.events.forEach(event => {

          if(controller.eventNodeMap && controller.eventNodeMap[event.event_id]) {

            let allActiveNodes = controller.eventNodeMap[event.event_id].activeNodes.concat(controller.eventNodeMap[event.event_id].completedNodes)

            allActiveNodes.forEach(eventNode => {
              //This will handle all of the attributes that have an "included" boolean on them

              const last_completed_sequence = () => {
                if(eventNode.workflow_status == 'PENDING' || eventNode.workflow_status == 'READY') {
                  return 0;
                } else if(eventNode.workflow_status == 'WORKFLOW_COMPLETE'){
                  return 100;
                } else {
                  let status;
                  switch (eventNode.workflow_status){
                    case 'CURTAIL_COMPLETE':
                    case 'CURTAIL_EXCEPTION_DETECTED':
                      status = 'CURTAILING';
                      break;
                    case 'RESTORE_COMPLETE':
                    case 'RESTORE_EXCEPTION_DETECTED':
                      status = 'RESTORING';
                      break;
                    case 'TRIP_COMPLETE':
                    case 'TRIP_EXCEPTION_DETECTED':
                      status = 'MANUALLY_TRIPPING';
                      break;
                    default:
                      status = eventNode.workflow_status;
                      break;
                  }
                  return  _.find(controller.workflowStateInfo, ['workflow_state_name', status])['sequence'];
                }
              }

              if (wfs.included_variable_name !== null) {

                unSortedStatusTimes.push({
                  node: eventNode.event_node_id,
                  start_time: eventNode.event_node_start_dttm_utc,
                  end_time: eventNode.event_node_end_dttm_utc,
                  code: wfs.schedule_variable_name,
                  name: wfs.display_label,
                  sequence: wfs.sequence,
                  state_name: wfs.workflow_state_name,
                  time: eventNode[wfs.schedule_variable_name.toLowerCase()],
                  included: eventNode[wfs.included_variable_name.toLowerCase()],
                  automated: eventNode[wfs.automated_variable_name],
                  included_name: wfs.included_variable_name,
                  automated_name: wfs.automated_variable_name,
                  trigger_time: eventNode[wfs.trigger_variable_name.toLowerCase()],
                  offset_from: wfs.offset_from,
                  mox_offset: wfs.max_offset,
                  completed: wfs.sequence <= last_completed_sequence()
                });
              }
              //This will handle the rest
              else if(wfs.included_variable_name === null && eventNode[wfs.schedule_variable_name.toLowerCase()]) {

                unSortedStatusTimes.push({
                  node: eventNode.event_node_id,
                  start_time: eventNode.event_node_start_dttm_utc,
                  end_time: eventNode.event_node_end_dttm_utc,
                  code: wfs.schedule_variable_name,
                  name: wfs.display_label,
                  state_name: wfs.workflow_state_name,
                  sequence: wfs.sequence,
                  time: eventNode[wfs.schedule_variable_name.toLowerCase()],
                  included: true,
                  automated: eventNode[wfs.automated_variable_name],
                  included_name: wfs.included_variable_name,
                  automated_name: wfs.automated_variable_name,
                  trigger_time: eventNode[wfs.trigger_variable_name.toLowerCase()],
                  offset_from: wfs.offset_from,
                  mox_offset: wfs.max_offset,
                  completed: wfs.sequence <= last_completed_sequence()
                })
              }
            });
          }
        });
      });

      unSortedStatusTimes = _.sortBy(unSortedStatusTimes, 'sequence');

      //BEGIN Fix Timestamps
      let itemsBeforeEvent = _.filter(unSortedStatusTimes, (o) => {
        return o.offset_from === 'Start'
      })
      let itemsAfterEvent = _.filter(unSortedStatusTimes, (o) => {
        return o.offset_from === 'End'
      })

      let lastItemBefore = itemsBeforeEvent[itemsBeforeEvent.length - 1];
      let firstItemAfter = itemsAfterEvent[0];


      //ensure the last item in the array has a time
      if(lastItemBefore && lastItemBefore.time == null) {
        lastItemBefore.time = moment(lastItemBefore.start_time).subtract(1, 'seconds').toISOString();
        lastItemBefore.estimated_time = true;
      }
      //then iterate backwards
      for(let b = itemsBeforeEvent.length - 1; b >= 0; b--) {
        //if time is null, subtract 1 second from the last time
        if(itemsBeforeEvent[b].time == null){
          if(itemsBeforeEvent[b].sequence == itemsBeforeEvent[b + 1].sequence) {
            itemsBeforeEvent[b].time = itemsBeforeEvent[b + 1].time;
          } else {
            itemsBeforeEvent[b].time = moment(itemsBeforeEvent[b + 1].time).subtract(1, 'seconds').toISOString();
          }

          itemsBeforeEvent[b].estimated_time = true;
        }
      }

      //ensure the first item in the array has a time
      if(firstItemAfter && firstItemAfter.time == null) {
        firstItemAfter.time = firstItemAfter.end_time ? moment(firstItemAfter.end_time).add(1, 'seconds').toISOString() : moment(firstItemAfter.start_time).add(2, 'seconds').toISOString();
        firstItemAfter.estimated_time = true;
      }

      for(let a = 0; a < itemsAfterEvent.length; a++) {
        if(itemsAfterEvent[a].time == null) {
          if(itemsAfterEvent[a].sequence == itemsAfterEvent[a - 1].sequence) {
            itemsAfterEvent[a].time = itemsAfterEvent[a - 1].time;
          } else {
            itemsAfterEvent[a].time = moment(itemsAfterEvent[a - 1].time).add(1, 'seconds').toISOString();
          }

          itemsAfterEvent[a].estimated_time = true;
        } else {
          //if it's the same as the start time, back it off by 1 second
          if(moment(itemsAfterEvent[a].time).isSame(moment(itemsAfterEvent[a].end_time), 'second')){
            itemsAfterEvent[a].time = moment(itemsAfterEvent[a].time).add(1, 'seconds').toISOString();
          }
        }
      }
      //END Fix Timestamps

      for(let i =0; i < unSortedStatusTimes.length; i++) {
        if (!unSortedStatusTimes[i].included) {
          unSortedStatusTimes[i].control_type = "Excluded";
        } else {
          unSortedStatusTimes[i].control_type = unSortedStatusTimes[i].automated === true ? "Automated" : "Manual";
        }
      }
    };

    //We loop through every different node's workflow steps and make sure that their scheduled times match their sequence, for timeline plotting purposes.
    let setOutOfSequenceStepTimes = function() {
      let groupedStatuses = _.groupBy(unSortedStatusTimes, 'node');

      //There is probably a more sophisticated way to do this, but it's taking in a starting index and a time and setting all workflow steps from that index onward to the time + (1 second ahead of the previous step).
      let shiftTimes = function(index, time, group) {
        let multiplier = 1;
        for(let i = index; i < groupedStatuses[group].length; i++) {

          //Only update the time if this time is BEFORE the next sequence's time. Otherwise we want to leave it the same.
          if(moment(groupedStatuses[group][i].time).isBefore(time)) {
            groupedStatuses[group][i].time = moment(time).add(1 * multiplier, 'seconds').toISOString();
          }
          multiplier++;
        }
      };

      _.forEach(groupedStatuses, (group, groupIndex) => {
        for(let i = group.length - 1; i > 0; i--) {
          if(moment(group[i].time).isBefore(moment(group[i - 1].time))) {
            //uh oh, shift them all ahead
            shiftTimes(i, group[i - 1].time, groupIndex);
          }
        }

        workflowStatusTimes = workflowStatusTimes.concat(group);
      });

      workflowStatusTimes = _.sortBy(workflowStatusTimes, 'sequence');
    };

    setTimelineSteps();
    setOutOfSequenceStepTimes();

    let sortedSteps = _.chain(workflowStatusTimes)
      .groupBy("time")
      .map((groups, time) => ({
        time: time,
        groups: _.chain(groups)
          .groupBy((item)=>`${item.name}${item.estimated_time}${item.completed}`)
          .map((nodes) => ({
            sequence: nodes[0].sequence,
            time: time,
            estimated_time: nodes[0].estimated_time,
            name: nodes[0].name,
            type: 'Workflow Step',
            entities: nodes,
            completed: nodes[0].completed
          })).value()
      })).value();
//task.completed_time != null
    if(this.allCustomTasks) {
      let sortedTasks = _.chain(this.allCustomTasks)
        .filter(['cancelled', null])
        .groupBy("scheduled_time")
        .map((groups, time) => ({
          time: time,
          groups: _.chain(groups)
            .groupBy("template_display_label")
            .map((events, name) => ({
              time: time,
              estimated_time: false,
              name: name,
              type: 'Custom Task',
              entities: events,
              completed: groups[0].completed_time != null
            })).value()
        })).value();

      sortedSteps = sortedSteps.concat(sortedTasks);
    }

    //If the start time is the same as one of the steps that is supposed to preceded it, add 1 second to the start time to keep the timeline in order
    let addToStart = _.find(sortedSteps, (o)=> {moment(o.time).isSame(moment(this.events[0].event_start_dttm_utc), 'second')}) ? 1 : 0;
    sortedSteps.push({
      time: moment(this.events[0].event_start_dttm_utc).add(addToStart, 'seconds').toISOString(),
      groups: [{
        type: 'schedule',
        time: this.events[0].event_start_dttm_utc,
        name: 'Event Start',
        completed: moment(this.events[0].event_start_dttm_utc).isSameOrBefore(moment())
      }]
    });


    let endTimes = {};
    //Add the end times. This is the end times
    _.forEach(this.events, e => {

      let time = e.event_end_dttm_utc;

      if(Object.keys(endTimes).indexOf(time) === -1) {
        endTimes[time] = 1;
      }
      else {
        endTimes[time] ++;
      }
    });

    let showCount = Object.keys(endTimes).length > 1;

    for (const [key, value] of Object.entries(endTimes)) {
      if(key !== "null") {
        const comp = moment(key).isSameOrBefore(moment())
        sortedSteps.push({
          time: key,
          groups: [{type: 'schedule', time: key, name: 'Event End', count: showCount ? value : null, completed: comp}]
        });
      }
    }

    sortedSteps = _.sortBy(sortedSteps, 'time');

    ///////////////////////////
    ///// SET CHART DATA /////
    /////////////////////////
    let index = 0;
    let labelStaggerIndex = 1;
    sortedSteps.forEach(step => {
      var indexSmall = 0;
     // debugger;
      step.groups.forEach(group => {
        let thisDataPoint;
        let ifcTime
        if (group.type === 'Custom Task') {

          let currentStep = group.entities[0];

          thisDataPoint = {
            empty: false,
            tasks: group.entities,
            x: index + indexSmall,
            labelStaggerIndex: labelStaggerIndex,
            count: group.entities.length,
            color: '#461e7d',
            included: true,
            name: group.name,
            control_type: group.entities[0].automated ? "Automated" : "Manual",
            label: "Custom Task",
            icon: "fa fa-clipboard",
            time: SharedService.LocalToFormattedSelectedTimeZone(currentStep.scheduled_time, this.events[0].full_time_zone),
            timezone: this.events[0].full_time_zone,
            unformatted_time: moment.tz(currentStep.scheduled_time, this.events[0].full_time_zone).toISOString(),
            estimated_time: group.estimated_time
          };
        }
        else if (group.type === 'schedule') {
          thisDataPoint = {
            empty: false,
            x: index + indexSmall,
            labelStaggerIndex: labelStaggerIndex,
            color: '#461e7d',
            count: group.count,
            label: "Event Info",
            control_type: null,
            included: true,
            name: group.name,
            time: SharedService.LocalToFormattedSelectedTimeZone(group.time, this.events[0].full_time_zone),
            timezone: this.events[0].full_time_zone,
            unformatted_time: moment.tz(group.time, this.events[0].full_time_zone).toISOString(),
            estimated_time: false
          }
        }
        else {
          //WORKFLOW
          thisDataPoint = {
            empty: false,
            x: index + indexSmall,
            labelStaggerIndex: labelStaggerIndex,
            count: group.entities.length,
            color: '#461e7d',
            nodes: group.entities,
            state_name: group.entities[0].state_name,
            included: group.entities[0].included,
            automated: group.entities[0].automated,
            included_name: group.entities[0].included_name,
            automated_name: group.entities[0].automated_name,
            name: group.name.replace('Scheduled Time', ''),
            control_type: group.entities[0].control_type,
            hideTime: group.time === 'null',
            label: "Workflow Step",
            icon: "fa fa-step-forward",
            time: group.entities[0].trigger_time ? SharedService.LocalToFormattedSelectedTimeZone(group.entities[0].trigger_time, this.events[0].full_time_zone) : SharedService.LocalToFormattedSelectedTimeZone(group.time, this.events[0].full_time_zone),
            timezone: this.events[0].full_time_zone,
            unformatted_time: group.entities[0].trigger_time ?
              moment.tz(group.entities[0].trigger_time, this.events[0].full_time_zone).toISOString() :
              moment.tz(group.time, this.events[0].full_time_zone).toISOString(),
            estimated_time: group.estimated_time
          };

          if (group.entities[0].code === "curtail_time") {
            controller.curtailIndex = index;
          } else if (group.entities[0].code === "restore_time") {
            controller.restoreCompleteIndex = index;
          }
        }

        if (group.completed) {
          completedData.push(thisDataPoint);
        } else {
          futureData.push(thisDataPoint);
        }

        //indexSmall += .07;
        labelStaggerIndex++;

        if (labelStaggerIndex > 4)
          labelStaggerIndex = 1;
      });

      index++;
    });

    //idk do this twice because it doesn't render correctly the first time someone help????
    this.updateTimelineData(completedData, futureData);

  }

  updateTimelineData(completedData, futureData) {

    let controller = this;

    if(controller.timeline && controller.timeline.series) {

      let d2;

      if(completedData.length === 0)
        d2 = futureData;
      else
        d2 = [{x: completedData.length - 1, empty: true}].concat(futureData);

      controller.timeline.series[1].setData(completedData, false, null, false);
      controller.timeline.series[0].setData(d2, false, null, false);

      if(d2.length) {
        controller.timeline.series[0].update({
          zones: [ {
            value: controller.curtailIndex,
            color: 'rgba(110, 70, 165, 0.51)',
            dashStyle: 'Dash'
          },{
            value: controller.restoreCompleteIndex,
            color: 'rgba(110, 70, 165, 0.51)',
            dashStyle: 'Solid'
          },{
            color: 'rgba(110, 70, 165, 0.51)',
            dashStyle: 'Dash'
          }]
        }, false);
      }

      if(completedData.length) {

        controller.timeline.series[1].update({
          zones: [
            {
              value: controller.curtailIndex,
              color: '#59BC5F',
              dashStyle: 'Dash'
            }, {
              value: controller.restoreCompleteIndex,
              color: '#59BC5F',
              dashStyle: 'Solid'
            }, {
              color: '#59BC5F',
              dashStyle: 'Dash'
            }]
        }, false);
      }

      this.timeline.series[0].points.forEach(function(point, index) {
        if (point.options.included === false) {
          point.update({
            color: "#707070"
          }, false);
        }
        else if (point.options.control_type === 'Manual') {
          point.update({
            color: "#FC4B88",
          }, false);
        }

        if(point.options.nodes && controller.isNextWorkflowStep(point.options.nodes[0].node, point.options.state_name)[0]) {
          point.update({
            marker: {symbol: "url(assets/images/button_action_go_up.png)", width: 30, height: 30}
          }, false);
        } else {
          point.update({
            marker: {radius: 6, lineColor: "#ffffff", lineWidth: 0, symbol: "circle"}
          }, false);
        }
      });

      this.timeline.series[1].points.forEach(function(point, index) {
        if (point.options.included === false) {
          point.update({
            color: "#707070"
          }, false);
        }
        else {
          point.update({
            color: "#59BC5F",
          }, false);
        }

        if(point.options.nodes && controller.isNextWorkflowStep(point.options.nodes[0].node, point.options.state_name)[0]) {
          point.update({
            marker: {symbol: "url(assets/images/button_action_go_up.png)", width: 30, height: 30}
          }, false);
        } else {
          point.update({
            marker: {radius: 6, lineColor: "#ffffff", lineWidth: 0, symbol: "circle"}
          }, false);
        }
      });

      controller.timeline.redraw(true);
    }
  }

  //Returns an array of two booleans: First is if this workflow step is the next one for the node passed in,
  // second is checking it the FOLLOWING included step would immediately fire if this step was excluded
  isNextWorkflowStep(id, step) {

    let node = null;

    //Get node by ID
    _.forEach(this.events, ev => {
      _.forEach(this.eventNodeMap[ev.event_id].activeNodes, n => {
        if(n.event_node_id === id)
          node = n;
      });
    });

    if(node) {

      let workflowStateCopy = JSON.parse(JSON.stringify(this.workflowStateInfo));

      workflowStateCopy.push({workflow_state_name: 'READY', sequence: -1})

      workflowStateCopy = _.sortBy(workflowStateCopy, 'sequence');

      //Get sequence # of what we're currently on, and sequence # of the next state that is included.
      // If that next state is the step passed in, return true
      let currentSequenceIndex = _.findIndex(workflowStateCopy, wfs => {
        return wfs.workflow_state_name === node.workflow_status || (node.workflow_status === 'CURTAIL_COMPLETE' && wfs.workflow_state_name === 'CURTAILING');

      });

      if(currentSequenceIndex > -1 && currentSequenceIndex + 1 < workflowStateCopy.length) {

        for(let i = currentSequenceIndex + 1; i < workflowStateCopy.length; i++){

          if(node[workflowStateCopy[i].included_variable_name]) {

            if(step === workflowStateCopy[i].workflow_state_name) {

              if(i + 1 < workflowStateCopy.length && node[workflowStateCopy[i + 1].automated_variable_name] && node[workflowStateCopy[i + 1].included_variable_name]
                && moment(node[workflowStateCopy[i + 1].schedule_variable_name]).isBefore(moment.utc())) {
                return [true, true];
              }

              return [true, false];
            }

            break;
          }
        }
      }
    }

    return [false, false];
  }

  onClickPoint(event): void {
    const controller = this;
    let num = 0;
    if (event.point && event.point.options.label === "Custom Task" && (moment(event.point.options.time).isAfter(moment()) || event.point.options.control_type === "Manual")) {

      this.pauseTimelineRefresh = true;

      event.point.options.tasks.forEach(t => {
        this.allCustomTasks.forEach(task => {
          if (task.custom_task_id === t.custom_task_id) {
            num++;
            task.selectedInGrid = true;
          }
        });
      });

      controller.sharedService.activateModal({
        headerText: "Custom Task Bulk Action",
        bodyText: num + " Custom Tasks selected. Enter password and choose an action:",
        allowCancel: true,

        customContent: new CustomModalWrapper(TimelineBulkActionModalComponent, {
          closeFn: function() {
            controller.pauseTimelineRefresh = false;
          },
          style: {
            'width': '50%',
            'max-width': '450px'
          },
          buttons: [
            {
              buttonText: "Do Now", action: function () {
                controller.bulkDoNow.emit();
              }
            },
            {
              buttonText: "Cancel", action: function () {
                controller.bulkCancel.emit();
              }
            },
            {
              buttonText: "Update", action: function () {
                controller.bulkUpdate.emit();
              }
            }
          ]
        })
      });
    } else if(event.point && event.point.options.label === "Workflow Step") {

      this.pauseTimelineRefresh = true;

      let buttons = [
        {
          buttonText: "Update", action: function () {
            controller.bulkRescheduleStep.emit({
              eventNodes: _.map(event.point.options.nodes, 'node'),
              attribute: event.point.options.state_name,
              showInclude: event.point.options.included === false,
              workflowStepInfo: event.point.options
            });
          }
        },
      ];

      let addButton = false;
      let excludeWarning = false;
      _.forEach(event.point.options.nodes, n => {
        let results = controller.isNextWorkflowStep(n.node, event.point.options.state_name);
        addButton = results[0];
        excludeWarning = results[1];
      });

      if(addButton){
        buttons.push({
          buttonText: "Do Now", action: function () {
            controller.bulkDoNowStep.emit({
              eventNodes: _.map(event.point.options.nodes, 'node'),
              attribute: event.point.options.state_name,
              workflowStepName: event.point.options.name
            });
          }
        })
      }

      if(event.point.options.included) {
        buttons.push({
          buttonText: "Exclude", action: function () {
            controller.bulkExclude.emit({
              eventNodes: _.map(event.point.options.nodes, 'node'),
              attribute: event.point.options.state_name,
              warnNextStepWillFire: excludeWarning,
              workflowStepName: event.point.options.name
            });
          }
        })
      }

      controller.sharedService.activateModal({
        headerText: "Workflow Step Bulk Action",
        bodyText: "Enter password and choose an action:",
        allowCancel: true,

        customContent: new CustomModalWrapper(TimelineBulkActionModalComponent, {
          closeFn: function() {
            controller.pauseTimelineRefresh = false;
          },
          style: {
            'width': '50%',
            'max-width': '450px'
          },
          buttons: buttons
        })
      });
    }
  }

}
