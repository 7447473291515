import { Component, Input, OnInit } from '@angular/core';
import { EventListService } from '../../services/event-list.service';
import { ColumnFilter, FieldType } from '../../classes/coulmns';
import { SharedService } from '../../services/shared.service';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';

@Component({
  selector: 'app-filter-pill',
  templateUrl: './filter-pill.component.pug',
  styleUrls: ['./filter-pill.component.scss']
})
export class FilterPillComponent implements OnInit {

  @Input() filter:ColumnFilter;
  viewValue;
  eventsLoaded = true;
  constructor(private filterService: EventListService, private sharedService:SharedService) {
    const {loadingEvents$} = this.filterService;
    loadingEvents$.pipe().subscribe(
      resp => {
        this.eventsLoaded = !resp;
      }
    );
  }

  ngOnInit() {
    if(this.filter.value != null){
      if(this.filter.column.type == FieldType.DATE) {
        this.viewValue = ' '+ moment.tz(this.filter.value, this.sharedService.getUsersTimeZone()).format(SharedService.dateFormat);
      } else {
        this.viewValue = ' '+ this.filter.value;
        if(this.filter.column.field == 'obligation'){this.viewValue += ' kW'};
        if(this.filter.column.field == 'event_performance_percent'){this.viewValue += ' %'};
      }
    }
  }

  onClickRemove() {
    this.filterService.removeFilter(this.filter);
  }
}
