// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environment: 'dev',
  api_url: 'https://event-management-api.dev-apps.enelx.net',
  utility_api_url: 'https://utility-portal-api.dev-apps.enelx.net',
  exm_url: 'https://exception-management.dev-apps.enelx.net',
  dmc_url: 'https://dmc-iot-dev.enel.com',
  der_url: 'https://cloud.soak.der.enelx.com',
  login_url: 'https://openam.dev-apps.enelx.net/openam/XUI/',
  openAmCookieDomain: 'enelx.net',
  globalNavUrl: 'https://global-nav-v2.dev-svcs.enernoc.net/api/v2',
  aunt_url: 'https://exception-management.dev-apps.enelx.net',
  reg_admin_url: 'https://registrations.dev-apps.enelx.net/',
  e_cream_url: 'https://enernoc--test2.sandbox.lightning.force.com/',
  cdn: 'https://cdn.dev-apps.enelx.net',
  defaults: {
    brand: 'enelx',
    locale: 'en-us',
    cookie_domain: '.enelx.net'
  },

};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
