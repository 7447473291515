import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: '[app-contact-info]',
  templateUrl: './contact-info.component.pug',
  styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent implements OnInit {

  @Input() contacts;
  @Input() finishedGettingContacts:boolean = false;
  contactsScrollHeight;
  constructor() { }

  ngOnInit() {
  }
  ngOnChanges(changes:SimpleChanges){
    if(changes.contacts?.currentValue?.length){
      if(changes.contacts?.currentValue?.length > 10){
        this.contactsScrollHeight = '200px'
      } else {
        this.contactsScrollHeight = null;
      }
    }
  }

}
