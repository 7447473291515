import { EventEmitter, Injectable, OnDestroy, Output } from '@angular/core';
import { DateTime } from 'luxon';
import { IMqttServiceOptions, MqttConnectionState, MqttService } from 'ngx-mqtt';
import { fromEvent, Observable } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { AppInjector } from '../app.module';
import { EventManagementService } from './event-management.service';





@Injectable()
//NOTE: This service is not a singleton
export class MqttSubscriptionService implements OnDestroy {
  @Output() connectionLost = new EventEmitter<any>();

  public id;
  private ngUnsubscribe: Subject<any> = new Subject();
  currentSubscription;
  currentConnection;
  currentIds;
  currentResource;
  currentNext;
  mqttTimeout;
  connected = false;
  EMS: EventManagementService;
  MQTT: MqttService
  visible: Observable<any> = fromEvent(document, 'visibilitychange');
  hiddenDTTM;
  visibleDTTM;

  visibility$: Observable<any> = fromEvent(document, 'visibilitychange');

  disconnectedAt;

  constructor( ) {
    this.EMS = AppInjector.get(EventManagementService);
    this.MQTT = AppInjector.get(MqttService);
    this.id = uuidv4();
    this.visibility$.pipe().subscribe((e)=>{this.onVisibilityChange()})
  }

  ngOnDestroy(){
    this.clearSubscription();
  }

  clearSubscription(){
    this.ngUnsubscribe.next(null);
    clearTimeout(this.mqttTimeout);
    this.currentSubscription?.unsubscribe();
    this.currentConnection = null;
    this.currentIds = null;
    this.currentResource = null;
    this.currentNext = null;
  }

  subscribeToTopic(ids, resource, next){
    if(!ids.length) return;
    const controller = this;

    this.clearSubscription();

    controller.currentIds = ids;
    controller.currentResource = resource;
    controller.currentNext = next;

    controller.currentSubscription?.unsubscribe();


    this.EMS.post('/v1/connection', {"ids": ids, "resource": resource}, {}).pipe(takeUntil(controller.ngUnsubscribe)).subscribe(
      (resp)=> {

        controller.currentConnection = resp.data;

        const match = /(.*?):\/\/(.*?)(\/.*)/.exec(controller.currentConnection.endpoint_url);
        if (!match) return;
        const [, protocol, hostname, path] = match;

        if (this.MQTT.state['value'] === MqttConnectionState.CLOSED) { // TODO REVIEW
          this.MQTT.connect({protocol: (protocol as IMqttServiceOptions['protocol']), hostname, path, port: 443});
        }
        this.MQTT.onConnect.subscribe(()=> this.onConnect());
        this.MQTT.onOffline.subscribe(()=> this.onOffline());
        this.MQTT.onError.subscribe((e) => { console.error('mqtt error', e); this.renewSubscription(this.currentConnection) });
        controller.currentSubscription = this.MQTT.observe(controller.currentConnection.topic).subscribe(
          (resp)=>{
            let msg = JSON.parse(new TextDecoder('utf-8').decode(resp.payload));
            next(msg)

          }, (err)=>{
            console.log(err)
          }
        );

          setTimeout(() => {
            controller.renewSubscription(controller.currentConnection);
          }, controller.currentConnection.expires_seconds * .5 * 1000);


      }, (error) => {
        console.log("Error subscribing to changes.")
      }
    )
  }

  renewSubscription(connection){
    const controller = this;
    if(!!connection && connection === controller.currentConnection) {
      this.EMS.get('/v1/connection/renew/' + connection.client_id + '?eps_connection=' + connection.eps, {}).pipe(takeUntil(this.ngUnsubscribe)
      ).subscribe(response => {
        let connection = response.data;
        //Call itself again when 90% of the previous time is up to renew
        controller.mqttTimeout = setTimeout(() => {
          controller.renewSubscription(connection);
        }, connection.expires_seconds * .5 * 1000)

      }, error => {
        console.log("Error renewing subscription for client ID " + connection.client_id)
      });
    }
  }

  onConnect(){
    this.connected = true;
    this.disconnectedAt = null;
  }

  onOffline(){
    this.connected = false;
    this.disconnectedAt = DateTime.now();
    this.clearSubscription();
  }

  onVisibilityChange(){
    let state = document.visibilityState;
    if(!!this.disconnectedAt && state === 'visible'){
      if(DateTime.now() > this.disconnectedAt){
        this.connectionLost.next(null);
        this.disconnectedAt = null;
      }
    }
  }
}
