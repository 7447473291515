
class LoaderActivator {

  currentJob: Promise<any>;
  onSuccess: Function;
  onError: Function;
  chainLoad: boolean;
  loaderText: string;
  endInstantly: boolean;
  isPromise: boolean;

  constructor(currentJob: Promise<any>, onSuccess: Function, onError: Function, chainLoad: boolean, loaderText: string, endInstantly: boolean, isPromise:boolean) {
    this.currentJob = currentJob;
    this.onSuccess = onSuccess;
    this.onError = onError;
    this.chainLoad = chainLoad;
    this.loaderText = loaderText;
    this.endInstantly = endInstantly;
    this.isPromise = isPromise;
  }
}

export { LoaderActivator }
