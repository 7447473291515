import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: '[app-collapse-panel]',
  templateUrl: './collapse-panel.component.pug',
  styleUrls: ['./collapse-panel.component.scss']
})
export class CollapsePanelComponent implements OnInit {

  @Input() startCollapsed = false;
  collapsed: boolean = false;
  dirty: boolean = false;

  constructor() { }

  ngOnInit() {
      this.collapsed = this.startCollapsed;
  }

  toggle(): void {
    this.collapsed = !this.collapsed;
  }
}
