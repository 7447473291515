import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import {
    ACTION,
    UnderfrequencyServiceError,
    UnderfrequencyEventService,
    UnderfrequencyPayload
} from '../../../services/underfrequency-event.service';

@Component({
    selector: 'app-underfrequency-action-modal',
    templateUrl: './underfrequency-action-modal.component.pug',
    styleUrls: ['./underfrequency-action-modal.component.scss'],
})
export class UnderfrequencyActionModal {

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: UnderfrequencyPayload,
        private underfrequencyService: UnderfrequencyEventService,
        private toastr: ToastrService,
    ) {}

    passwordEntered: string = '';
    loading: boolean = false;

    closeModal(): void {
        this.dialogRef.close();
    }

    checkPassword(ev) {
        // Not sure why [(ngModel)] isn't working
        this.passwordEntered = ev.target.value;
    }

    clickAction() {
        let promise;
        this.loading = true;
        switch(this.data.action) {
            case ACTION.ARM:
                promise = this.underfrequencyService.arm(this.data);
                break;
            case ACTION.DISARM:
                promise = this.underfrequencyService.disarm(this.data);
                break;
            case ACTION.EMERGENCY_DISARM:
                promise = this.underfrequencyService.emergencyDisarm(this.data);
                break;
            case ACTION.OPT_IN:
                promise = this.underfrequencyService.optIn(this.data);
                break
            default:
                this.toastr.error(`Unknown action: "${this.data.action}"`);
        }
        if (!promise) return;

        promise
            .then(() => {
                this.toastr.success(`"${this.data.action}" successfully sent to registration set`);
                this.closeModal();
            })
            .catch(err => {
                let message = `"${this.data.action}" command to EMS failed`;
                if (err instanceof UnderfrequencyServiceError) {
                    message = err.message;
                    console.log(err);
                } else {
                    console.log(err);
                }
                this.toastr.error(`Error encountered: ${message}`);
            })
            .finally(() => this.loading = false);
    }
}
