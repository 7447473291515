import {Component, Input, OnInit} from '@angular/core';
import {SharedService} from "../../../services/shared.service";
import {ModalDatePickerData} from "../../../classes/modalDatePickerData";
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';
import {CustomTask} from "../../../classes/customTask";

@Component({
  selector: 'app-custom-task-modal',
  templateUrl: './custom-task-modal.component.pug',
  styleUrls: ['./custom-task-modal.component.scss']
})
export class CustomTaskModalComponent implements OnInit {

  constructor(private shared:SharedService) { }

  @Input() data:any;
  task:CustomTask;

  ngOnInit() {

    this.task = new CustomTask(null, null, null, null, null, true);

    if(!this.data.program_time_zone)
      this.data.program_time_zone = "UTC";

    if(this.data.task) {
      this.task.automated = this.data.task.automated;
      this.task.action_parameter = this.data.task.action_parameter;
      this.task.filter_type = this.data.task.filter_type;
    }
  }

  confirm() {

    let hasErrors: boolean = false;
    let datesToSend = [];

    let tpd = this.data.timePickerData;
    const date = moment(tpd.entered_time);

    tpd.errors = [];

    if (date.isValid()) {
      let chosenDateUTC = this.shared.selectedTimeZoneToUTC(tpd.entered_time, this.data.program_time_zone);

      if (chosenDateUTC.isBefore(moment.utc(tpd.min_date))) {

        tpd.errors.push('Entered date must be the same as or after ' + moment.tz(tpd.min_date, this.shared.getTimeZoneName(this.data.program_time_zone, false)).format(SharedService.dateFormat));
      }

      if (chosenDateUTC.isAfter(moment.utc(tpd.max_date))) {

        tpd.errors.push('Entered date must be the same as or before ' + moment.tz(tpd.max_date, this.shared.getTimeZoneName(this.data.program_time_zone, false)).format(SharedService.dateFormat));
      }


      if (tpd.errors.length > 0) {
        hasErrors = true;
      } else {
        datesToSend.push(chosenDateUTC.toISOString())
      }
    }
    else {
      if (!tpd.entered_time && tpd.allow_null) {
        datesToSend.push(null);
      } else {
        hasErrors = true;
        tpd.errors.push('Invalid date entered.');
      }
    }


    if (hasErrors === false) {

      let taskBody;

      //If updating, only send scheduled_time
      if (this.data.updateTask) {
        taskBody = {
          scheduled_time: datesToSend[0],
          automated: this.task.automated
        };
      } else {
        taskBody = {
          action_type: "NOTIFICATION",
          action_parameter: this.task.action_parameter,
          filter_type: this.task.filter_type,
          scheduled_time: datesToSend[0],
          automated: this.task.automated
        };
      }

      this.data.confirmFunction(taskBody);
      this.data.close();
    }
  }

  close() {
    this.data.close();
  }

  getTimezone() {
    return this.shared.getTimeZoneName(this.data.program_time_zone);
  }

  getTimeZoneConversion(timePickerData:ModalDatePickerData, zone)
  {
    let localTZ = this.shared.getUsersTimeZone();
    let tz;

    switch (zone) {
      case 'local':
        tz = localTZ;
        break;
      case 'program':
        tz = this.data.program_time_zone;
        break;
      case 'utc':
        tz = 'UTC';
        break;
    }

    let convertedTime: string = this.shared.selectedTimeZoneToUTC(timePickerData.entered_time, this.data.program_time_zone).toISOString();
    return timePickerData.entered_time ? moment.tz(convertedTime, tz).format(SharedService.dateFormat) : 'None';
  }

  //Gets the date from the child datepicker and sets it in our corresponding timepicker data object
  getTimeFromChild(time, tpd) {
    tpd.entered_time = time.date;
  }
}
