import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EventManagementService } from '../../services/event-management.service';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { RegistrationSet, RegistrationSetReading } from '../../classes/registration_set';
import * as _ from 'lodash';

@Component({
  selector: 'app-underfrequency-details',
  templateUrl: './underfrequency-details.component.pug',
  styleUrls: ['./underfrequency-details.component.scss']
})
export class UnderfrequencyDetailsComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;

  regSetId;
  eventId;
  siteId;
  regSetLoaded = true;
  finishedGettingContacts = false;
  errorLabel;
  contacts = [];
  regSetReading: RegistrationSetReading;
  regSet: RegistrationSet;
  registrationName;
  displayedColumns: string[] = ['action_dttm', 'action_display_label', 'user_display_label'];
  logDataSource: MatTableDataSource<{action_dttm, action_display_label, user_display_label}>;

  constructor(private EMS: EventManagementService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.regSetId = params.get("regsetid");
      this.getData()
    });
  }

  getData() {
    this.EMS.post('/v1/registration_set_readings', {ids:[this.regSetId]}, null).pipe().subscribe(
      (resp) => {
        this.regSetReading = resp.data[0];
        this.getContacts(this.regSetReading.site_id)
      },
      (err) => {
        console.dir("Error getting contacts for registration set readings with ID: " + this.regSetId);
        console.dir(err);
      }
    );

    this.EMS.get('/v1/underfrequency_registration_set/' + this.regSetId, {}).pipe().subscribe(
      (resp) => {
        this.regSet = resp.data;
      },
      (err) => {
        console.dir("Error getting contacts for registration set with ID: " + this.regSetId);
        console.dir(err);
      }
    );

    Promise.all([
        this.EMS.get(`/v1/reg_set_history/${this.regSetId}?by=registration_set`, {}).toPromise(),
        this.EMS.get('/v1/event_action_types', {}).toPromise(),
    ]).then(([historyResp, actionTypeResp]) => {
        const eventsLog = historyResp.data;
        const types = _.keyBy(actionTypeResp.data, 'code');
        eventsLog.forEach((log) => {
            // Merge in the reference data display label
            log.action_display_label = _.get(types, `${log.action}.display_label`, '');
            // Display system users as "Platform"
            log.user_display_label = log.user_name.endsWith('_system') ? 'Platform' : log.user_name;
        });
        this.logDataSource = new MatTableDataSource(eventsLog);
        setTimeout(() => this.logDataSource.sort = this.sort, 50);
    });
  }

  getContacts(siteId) {
    //GET CONTACTS AND STORE THEM
    this.EMS.get('/v1/event_node/site/' + siteId + '/contacts', {}).pipe(
    ).subscribe(
      (response) => {
        this.contacts = response.data;
        this.finishedGettingContacts = true;
      }, (err) => {
        this.finishedGettingContacts = true;
        console.dir("Error getting contacts for site with ID: " + siteId);
        console.dir(err);
      });
  }
}
