import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-event-create-summary',
  templateUrl: './event-create-summary.component.pug',
  styleUrls: ['./event-create-summary.component.scss']
})
export class EventCreateSummaryComponent implements OnInit {
  @Input() data:any;
  constructor() { }

  ngOnInit(): void {
    console.log(this.data)
  }


}
