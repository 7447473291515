import { Component, Directive, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NgForm, Validator, ValidatorFn, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import * as _ from 'lodash';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Subject } from "rxjs/internal/Subject";
import { takeUntil } from "rxjs/operators";
import { EPSEvent } from "../../classes/event";
import { LoaderActivator } from "../../classes/loader_activator";
import { Operator } from "../../classes/operator";
import { Portfolio } from "../../classes/portfolio";
import { Product } from "../../classes/product";
import { Program } from "../../classes/program";
import { EventManagementService } from "../../services/event-management.service";
import { FdrService } from "../../services/fdr.service";
import { RefDataService } from '../../services/ref-data.service';
import { ListTab, SharedService } from "../../services/shared.service";
import { CreateErrorModalComponent } from '../create-error-modal/create-error-modal.component';
import { CustomModalWrapper } from '../modals/enel-modal/enel-modal.component';
import { EventCreateSummaryComponent } from '../modals/event-create-summary/event-create-summary.component';





export enum EndTimeOptions {
  None, Fifteen, Thirty, Sixty, Ninety, OneTwenty, Custom, Default
}

@Directive({
  selector: "[minNum][formControlName],[minNum][formControl],[minNum][ngModel]",
  providers: [
    { provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MinDirective),
      multi: true }
  ]
})
export class MinDirective implements Validator {
  private _validator: ValidatorFn;

  @Input() public set minNum(value: string) {
    this._validator = Validators.min(parseFloat(value));
  }

  public validate(control: AbstractControl): { [key: string]: any } {
    return this._validator(control);
  }
}

@Directive({
  selector: "[maxNum][formControlName],[maxNum][formControl],[maxNum][ngModel]",
  providers: [
    { provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MaxDirective),
      multi: true }
  ]
})
export class MaxDirective implements Validator {
  private _validator: ValidatorFn;
  @Input() public set maxNum(value: string) {
    this._validator = Validators.max(parseInt(value, 10));
    //updateOn: 'blur'
  }

  public validate(control: AbstractControl): { [key: string]: any } {
    const temp = this._validator(control);
    return temp;
  }
}




@Component({
  selector: 'app-event-creation',
  templateUrl: './event-creation.component.pug',
  styleUrls: ['./event-creation.component.scss']
})
export class EventCreationComponent implements OnInit {

  @ViewChild('portfoliosCheckGroup', {static:false}) portfoliosCheckGroup: any;
  @ViewChild('form', {static:false}) public form: NgForm;
  tzSubscription: Subscription;

  isTrainingEnv: boolean;
  pageTitle: string;

  constructor(
      private fdrService: FdrService,
      private sharedService: SharedService,
      private eventManagementService: EventManagementService,
      private toastr: ToastrService,
      private RDS: RefDataService,
      private route: ActivatedRoute
  ) {
    this.tzSubscription = this.sharedService.getTimeZonePref().subscribe(pref => {
      this.resetDateTimes();
    })
  }

  CreateSteps = Object.freeze({
    one: 1,
    two: 2
  });

  currentStep;

  eventObj:EPSEvent = null;

  enteredStartTime = null;
  enteredEndTime = null;
  enteredNotificationTime = null;
  enteredAdjTime = null;
  minStartTime;
  minEndTime;
  maxEndTime;
  startAtTime;
  endAtTime;
  bulkObligationPercent = null;
  durationErrorMsg:string = '';
  durationWarningMsg:string = '';
  notifTimeRequired:boolean = false;
  notifTimeMax:string = '';
  portfoliosResponse;

  allOperators: Array<Operator> = null;
  allPrograms: Array<Program> = null;
  allProducts: Array<Product> = null;
  portfolios: Array<Portfolio>= null;
  hidePortfolioObligation:boolean = false;
  allowPercentObligationEntry:boolean = false;
  eventTypes = null;
  eventTypeGroup:string;
  registrations = null;
  gettingRegistrations:boolean = false;
  loadedPrograms:boolean = false;
  loadedProducts:boolean = false;
  loadedPortfolios:boolean = false;
  loadingPortfolios:boolean = false;
  loadedClearedOffers:boolean = false;
  startTimeAfterEnd:boolean = false;

  selectedOperator: Operator = null;
  selectedProgram: Program = null;
  selectedProduct: Product = null;

  ignoringRegistrationLimits:boolean = false;

  //Workflows and event types
  allowedWorkflowMatrix = null;
  cannedWorkflows = null;
  defaultWorkflow = null;
  filteredCannedWorkflows = null;
  allEventTypes = null;
  selectedEventType:string = null;
  selectedWorkflow:string = null;
  gettingWorkflow:boolean = false;

  nowBoxChecked:boolean = false;
  nowBoxNotifChecked:boolean = false;
  nowBoxAdjChecked: boolean = false;
  noPortfoliosSelected:boolean = true;
  noRegistrationsSelected:boolean = true;
  allPortfoliosSelected:boolean = false;

  endDateError:string = null;
  startTimeInputTimeout;
  notifTimeInputTimeout;
  showDurationWarning:boolean = false;
  private endTimeOptions = EndTimeOptions;
  endTimeOption:EndTimeOptions = this.endTimeOptions.Default;

  //populate weather adjustment window
  baselineAdjOverride;
  weatherAdjStart: string;
  weatherAdjEnd:string;
  weatherAdjDuration = 0;
  weatherAdjDurationDefault = 0;
  offersTimeOut;
  customerOffers = [];

  noComProdToastId;
  readyToTestReggies = false;
  showReadyToTestCheck = false;

  private ngUnsubscribe: Subject<any> = new Subject();


  ngOnInit() {

    const controller = this;
    this.eventObj = new EPSEvent();
    controller.currentStep = this.CreateSteps.one;

    this.isTrainingEnv = Boolean(this.route.snapshot.data['training']);
    this.pageTitle = this.isTrainingEnv ? 'Training Event Creation' : 'Event Creation';
    const tabName = this.isTrainingEnv ? 'TRAINING_CREATE' : 'CREATE';
    this.sharedService.setListTab((<any>ListTab[tabName]));

    //GET OPERATORS
    controller.eventManagementService.get('/v1/operator_hierarchy', null).pipe(takeUntil(this.ngUnsubscribe)
    ).subscribe(response => {

      controller.allOperators = response.data;

      this.sharedService.sortAlphabetically(controller.allOperators, "display_label");

    }, error => {
      this.sharedService.popError("Error getting Product Hierarchy");
      console.dir("Error getting operators: ");
      console.dir(error);
    });

    //GET EVENT TYPES
    this.RDS.getEventTypes().subscribe((resp)=>{

      controller.allEventTypes = !this.isTrainingEnv
        ? resp.filter(et => et.display_in_event_creation !== false)
        : resp.filter(et => et.code == 'TRAINING');

      controller.selectedEventType = !this.isTrainingEnv ? 'OFFICIAL' : 'TRAINING';
    })

    //GET CANNED WORKFLOWS
    controller.eventManagementService.get('/v1/workflow?show_default=true&show_configs=false', null).pipe(takeUntil(this.ngUnsubscribe)
    ).subscribe(response => {

      controller.cannedWorkflows = response.data;

    }, error => {
      this.sharedService.popError("Error getting Canned Workflows");
      console.dir("Error getting canned workflows: ");
      console.dir(error);
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
    clearTimeout(this.offersTimeOut)
  }

  filterWorkflows(): void {
    //#1 We add the default workflow and remove any duplicates
    let tempWorkflows = this.cannedWorkflows;
    tempWorkflows.push(this.defaultWorkflow);
    tempWorkflows = _.uniqBy(tempWorkflows, 'workflow_id');

    //#2 We reduce the array into an object that is keyed by the workflow_id.
    // This way we can use the allowedWorkflowMatrix.selectedEventType in the ngFor, and reference this.filteredCannedWorkflows[id] for the data.
    // This also means we don't need to filter based on whether or not it's UFR, because we're only showing the workflows in the allowedWorkflowMatrix
    this.filteredCannedWorkflows = _.reduce(tempWorkflows, function (result, wf) {
      result[wf.workflow_id] = wf;
      return result;
    }, {});
  }

  filterPortfolios() {
    const cont = this;

    this.portfolios = _.filter(this.portfoliosResponse, function (portfolio) {
      if(cont.selectedEventType == 'READINESS_TEST'){
        return portfolio.ready_to_at_registration_count > 0;
      } else {
        return portfolio.active_registration_count > 0;
      }
    })
  }
  onEventTypeSelect(): void {

    this.allPortfoliosSelected = false;
    this.portfolios.forEach((portfolio)=>{
      portfolio.selected = false;
    })
    if(this.selectedEventType === 'READINESS_TEST'){
      this.showReadyToTestCheck = true;
    } else {
      this.showReadyToTestCheck = false;
      this.readyToTestReggies = false;
    }
    this.filterPortfolios();
    if(this.allowedWorkflowMatrix[this.selectedEventType]) {
      this.selectedWorkflow = this.allowedWorkflowMatrix[this.selectedEventType][0].workflow_id;
    }
    else
      this.selectedWorkflow = this.defaultWorkflow.workflow_id;
  }

  onOperatorSelect(event): void {
    const cont = this;
    cont.loadedProducts = false;
    cont.loadedPortfolios = false;

    cont.selectedProgram = null;
    cont.selectedProduct = null;

    cont.allPrograms = cont.selectedOperator.programs;
    cont.loadedPrograms = true;

    cont.resetStepOne();

    this.sharedService.sortAlphabetically(cont.allPrograms, "display_label");

    this.toastr.remove(this.noComProdToastId)
  }

  onProgramSelect(event):void {
    const cont = this;
    cont.loadedPortfolios = false;
    cont.selectedProduct = null;
    cont.allProducts = cont.selectedProgram.products;

    cont.resetStepOne();

    this.sharedService.sortAlphabetically(cont.allProducts, "display_label");

    cont.loadedProducts = true;

    this.toastr.remove(this.noComProdToastId)
  }

  onProductSelect(event):void {

    const cont = this;

    //Reset a bunch of stuff
    cont.loadedPortfolios = false;
    cont.gettingWorkflow = true;
    cont.allowPercentObligationEntry = false;
    cont.enteredStartTime = null;
    cont.enteredAdjTime = null;
    cont.bulkObligationPercent = 0;
    cont.loadedClearedOffers = false;
    cont.nowBoxNotifChecked = false;
    cont.nowBoxChecked = false;
    cont.nowBoxAdjChecked = false;
    cont.showReadyToTestCheck = false;
    cont.allPortfoliosSelected = false;


    cont.resetStepOne();

    const doneLoadingEverything = _.after(2, () => {
      cont.loadingPortfolios = false;
      cont.loadedPortfolios = true;
    });

    //Get the max event duration for this product
    cont.eventManagementService.get('/v1/product/' + cont.selectedProduct.id, {}).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      response => {
        this.toastr.remove(this.noComProdToastId)

        cont.selectedProduct = Object.assign(this.selectedProduct, response.data);

        cont.hidePortfolioObligation = (cont.selectedProduct.portfolio_obligation_source === 'SUM_CUSTOMER_OFFER' || cont.selectedProduct.portfolio_obligation_source === 'SUM_CLEARED_OFFER');
        cont.allowPercentObligationEntry = cont.selectedProduct.portfolio_obligation_source === 'PORTFOLIO_CLEARED_OFFER';

        cont.notifTimeRequired = (cont.selectedProduct.collect_notification_time && cont.selectedProduct.collect_notification_time == true) || (cont.selectedProduct.selection_offer_time && cont.selectedProduct.selection_offer_time == 'NOTIFICATION_TIME') ;

        if(cont.notifTimeRequired) {
          cont.notifTimeMax = moment.tz(moment().add(1, 'm'), this.sharedService.getTimeZoneName(this.selectedProduct.timezone, false)).format('YYYY-MM-DDTHH:mm')
        }
        cont.baselineAdjOverride = cont.selectedProduct.baseline_configuration && cont.selectedProduct.baseline_configuration.baseline_adjustment_allow_override === true;

        if (cont.selectedProduct.baseline_configuration) {
          cont.eventManagementService.get('/v1/baseline_definition?default=true&product_id=' + cont.selectedProduct.id, {}).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
            response => {
              cont.weatherAdjDuration = response.data.adjustment_length || 0;
              cont.weatherAdjDurationDefault = response.data.adjustment_length || 0;
            },
            error => {
              if (error.error.code == 404) {
                console.log("No baseline definition found for productId: " + cont.selectedProduct.id);
                cont.weatherAdjDuration = 0;
                cont.weatherAdjDurationDefault = 0;
              } else {
                this.sharedService.popError("Error getting baseline definition from EMS");
                console.dir("Error getting baseline definition from EMS");
                console.dir(error);
              }
            }
          );
        }

        cont.setMinStart();

        if(cont.selectedProduct.underfrequency_product && cont.selectedProduct.underfrequency_product === true) {
          this.toastr.error('This product is not available for Manual Event Creation. Underfrequency Products can be seen and managed in the Underfrequency Management UI. Select a non-Underfrequency Product for creating an event in "Event Creation"', '', {
            disableTimeOut: true,
            closeButton: true
          });
            return;
        }
        cont.eventTypeGroup = 'STANDARD';
        cont.selectedEventType = this.isTrainingEnv ? 'TRAINING' : 'OFFICIAL';

        //Get the allowed workflows for the product
        cont.eventManagementService.get( '/v1/event_types/' + cont.selectedProduct.id + '/workflows/', {}).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
          response => {
            cont.allowedWorkflowMatrix = response.data.matrix;


            if(response.data.defaultWorkflowID) {
              //Get the default workflow for this product
              cont.eventManagementService.get( '/v1/workflow/' + response.data.defaultWorkflowID, {}).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
                response => {
                  cont.gettingWorkflow = false;
                  cont.defaultWorkflow = response.data;
                  cont.filterWorkflows();

                  cont.selectedWorkflow = cont.defaultWorkflow.workflow_id;
                  doneLoadingEverything();

                },
                error => {
                  console.dir("Error getting default workflow");
                  console.dir(error);
                  doneLoadingEverything();

                }
              );
            } else {

              this.sharedService.popError("This product doesn't have a default workflow assigned. Please navigate to Workflow Admin to save a default workflow for this product.");

              //There isn't a default workflow so just set it to null and show the canned workflows
              cont.gettingWorkflow = false;
              cont.defaultWorkflow = this.cannedWorkflows[1];
              cont.filterWorkflows();

              cont.selectedWorkflow = null;
              doneLoadingEverything();
            }
          },
          error => {
            console.dir("Error getting allowed workflows");
            console.dir(error);
            doneLoadingEverything();

          }
        );

        //Get portfolios
        cont.loadingPortfolios= true;
        cont.eventManagementService.get('/v1/portfolios?product_id=' + cont.selectedProduct.id + '&program_id=' + this.selectedProgram.id, {}).pipe(takeUntil(this.ngUnsubscribe)
        ).subscribe(response => {
          cont.portfoliosResponse = response.data;
          cont.filterPortfolios();

          this.sharedService.sortAlphabetically(cont.portfolios, "display_label");

          doneLoadingEverything();

        }, error => {

          console.dir("Error getting portfolios: ");
          console.dir(error);

          this.sharedService.popError("Failed to retrieve portfolios");
          cont.loadedPortfolios = false;
          cont.loadingPortfolios = false;
        });
      },
      error => {
        if(error.error.code !== 404) {
          this.sharedService.popError("Failed to retrieve product.");
        } else {
          this.toastr.error('This product is not correctly configured under the program in Market Admin. User should work with DR Operations or Energy Markets to update configuration.', '', {
            disableTimeOut: true,
            closeButton: true
          });
          this.noComProdToastId = this.toastr.toasts[this.toastr.currentlyActive].toastId
        }
        console.dir("Error getting product");
        console.dir(error);
        doneLoadingEverything();
      }
    );
  }

  getClearedOffers(time):void {
    const controller = this;

    controller.eventManagementService.get('/v1/cleared_offers?product_id=' + controller.selectedProduct.id + '&start_dttm_utc=' + time, {}).pipe(takeUntil(this.ngUnsubscribe)
    ).subscribe(response => {

        controller.portfolios.forEach(p => {
          p.obligation_percent = 0;
          p.obligation = 0;
          p.cleared_offer = null;

          response.data?.forEach(offer => {
            if(offer.id === p.id)
            {
              p.cleared_offer = parseFloat(this.sharedService.convertProductUOM(offer.cleared.quantities.capacity, this.selectedProduct, false, 3, true).toString());
              p.obligation_percent = controller.bulkObligationPercent;
            }

            if(p.obligation_percent){
              controller.onObligationPercentChange(p);
            }
          });
        });


      controller.loadedClearedOffers = true;

    }, error => {
      controller.loadedClearedOffers = true;

      console.dir("Error getting cleared offers: " + JSON.stringify(error));

      this.sharedService.popError("Failed to retrieve cleared offers");
    });

  }

  getCustomerOffers() {
    const controller = this;
    const convertedStartTime:string = controller.sharedService.selectedTimeZoneToUTC(controller.enteredStartTime, controller.selectedProduct.timezone).toISOString();
    clearTimeout(this.offersTimeOut)
    this.eventManagementService.get('/v1/customer_offers/', {product_id: this.selectedProduct.id, start_dttm: convertedStartTime}).pipe(takeUntil(this.ngUnsubscribe)
    ).subscribe(response => {
      this.customerOffers = response.data;
      if(this.customerOffers.length) {
        const thirtySeconds = 30000;
        const timeToEnd = moment.duration(moment(this.customerOffers[0].offer_end_dttm_utc).diff(moment().utc())).as('milliseconds');
        this.offersTimeOut = setTimeout( () => {
          controller.getCustomerOffers();
        }, timeToEnd + thirtySeconds)
      }

    }, error => {
      console.dir("Error getting Customer Offer for product: " + this.eventObj.product_id);
      console.dir(error);
      this.customerOffers = [];
    });


  }


  onNotificationTimeChange():void {
    const controller = this;

    //Clear the previous timeout that we set below
    clearTimeout(controller.notifTimeInputTimeout);

    if(controller.selectedProduct.selection_offer_time === 'NOTIFICATION_TIME') {
      //Get Cleared Offers for portfolios
      if(controller.allowPercentObligationEntry) {

        let convertedStartTime:string = controller.sharedService.selectedTimeZoneToUTC(controller.enteredNotificationTime, controller.selectedProduct.timezone).toISOString();

        //Wait 1.5 seconds to make sure input has stopped
        controller.notifTimeInputTimeout = setTimeout(() => {
          this.loadedClearedOffers = false;
          controller.getClearedOffers(convertedStartTime);
        }, 1500);

      } else {
        controller.loadedClearedOffers = true;
      }
    }
  }

  onAdjTimeChange(): void {

  }

  onStartTimeChange(): void {
    const controller = this;
    this.onDurationSelect();
    this.minEndTime = moment(this.enteredStartTime).add(1, 'm');
    this.maxEndTime = moment(this.enteredStartTime).add(+this.selectedProduct.max_event_duration + 43200000, 'ms');
    this.setMinStart();
    let totalMinutes = Math.floor(Number(this.selectedProduct.max_event_duration) / 60000);
    let hours = Math.floor(totalMinutes / 60);
    let minutes = totalMinutes % 60;
    let timeString = hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0');

    this.durationErrorMsg = "Event Duration cannot be longer than the Product's Max Event Duration of " + timeString + " plus 12 hours - " + moment(this.maxEndTime).format(SharedService.dateFormat);

    this.checkMaxDuration();

    //Clear the previous timeout that we set below
    clearTimeout(controller.startTimeInputTimeout);

    //Get Cleared Offers for portfolios
    if(controller.selectedProduct.selection_offer_time === 'START_TIME') {
      if(controller.allowPercentObligationEntry) {

        let convertedStartTime:string = controller.sharedService.selectedTimeZoneToUTC(controller.enteredStartTime, controller.selectedProduct.timezone).toISOString();

        //Wait 1.5 seconds to make sure input has stopped
        controller.startTimeInputTimeout = setTimeout(() => {
          this.loadedClearedOffers = false;
          controller.getClearedOffers(convertedStartTime);
        }, 1500);

      } else {
        controller.loadedClearedOffers = true;
      }
    }
  }

  onTotalObligationPercentChange():void {
    this.portfolios.forEach(p => {
      if(p.cleared_offer) {
        p.obligation_percent = this.bulkObligationPercent;
        p.obligation = parseFloat((p.cleared_offer * p.obligation_percent / 100).toFixed(3));
      }
    })
  }

  onObligationChange(portfolio:Portfolio): void {
    if(portfolio.cleared_offer)
      portfolio.obligation_percent = parseFloat((portfolio.obligation / portfolio.cleared_offer * 100).toFixed(2));
  }

  onObligationPercentChange(portfolio:Portfolio): void {
    if(portfolio.cleared_offer)
      portfolio.obligation = parseFloat((portfolio.cleared_offer * portfolio.obligation_percent / 100).toFixed(3));
  }

  onEndTimeChange(): void {
    this.endTimeOption = this.enteredEndTime ? EndTimeOptions.Custom : EndTimeOptions.None;
    this.checkMaxDuration()
  }

  checkMaxDuration(): void {
    this.showDurationWarning = this.enteredStartTime && this.enteredEndTime && moment(this.enteredEndTime).isAfter(moment(this.enteredStartTime).add(+this.selectedProduct.max_event_duration, 'ms')) && moment(this.enteredEndTime).isBefore(moment(this.maxEndTime));
    if(this.showDurationWarning)this.durationWarningMsg = "Warning: Event Duration is longer than the Product's Max Event Duration - " + moment(this.enteredStartTime).add(+this.selectedProduct.max_event_duration, 'ms').format(SharedService.dateFormat);
  }

  onDurationSelect(): void {

    if(this.enteredStartTime)
    {
      switch (this.endTimeOption) {

        case EndTimeOptions.None:
          this.enteredEndTime = null;
          break;

        case EndTimeOptions.Fifteen:
          this.enteredEndTime = moment(this.enteredStartTime).add(15, 'm').format('YYYY-MM-DDTHH:mm');
          break;

        case EndTimeOptions.Thirty:
          this.enteredEndTime = moment(this.enteredStartTime).add(30, 'm').format('YYYY-MM-DDTHH:mm');
          break;

        case EndTimeOptions.Sixty:
          this.enteredEndTime = moment(this.enteredStartTime).add(60, 'm').format('YYYY-MM-DDTHH:mm');
          break;

        case EndTimeOptions.Ninety:
          this.enteredEndTime = moment(this.enteredStartTime).add(90, 'm').format('YYYY-MM-DDTHH:mm');
          break;

        case EndTimeOptions.OneTwenty:
          this.enteredEndTime = moment(this.enteredStartTime).add(120, 'm').format('YYYY-MM-DDTHH:mm');
          break;
        //program max
        case EndTimeOptions.Default:
          this.enteredEndTime = moment(this.enteredStartTime).add(this.selectedProduct.default_dispatch_duration, 'ms').format('YYYY-MM-DDTHH:mm');
          break;
      }
      this.checkMaxDuration();
      this.endAtTime = this.enteredEndTime;
    }
  }

  onAdjDurationChange(): void{
    if(!this.weatherAdjDuration || this.weatherAdjDuration < 0){
      this.resetAdjDuration();
    }
  }

  resetAdjDuration(): void {
    this.weatherAdjDuration = this.weatherAdjDurationDefault;
  }

  toggleAdjNowCheckbox():void {
    this.form.controls.baseline_adjustment_time.markAsTouched();
    let convertedTime = moment.tz(moment(), this.sharedService.getTimeZoneName(this.selectedProduct.timezone, false));
    this.enteredAdjTime = convertedTime.format('YYYY-MM-DDTHH:mm');
    this.onAdjTimeChange();
  }

  toggleNotifNowCheckbox():void {
    this.form.controls.event_notification_time.markAsTouched();
    let convertedTime = moment.tz(moment(), this.sharedService.getTimeZoneName(this.selectedProduct.timezone, false));
    this.enteredNotificationTime = convertedTime.format('YYYY-MM-DDTHH:mm');
    this.onNotificationTimeChange();
  }

  toggleNowCheckbox():void {
    this.form.controls.event_start.markAsTouched();
    let convertedTime = moment.tz(moment(), this.sharedService.getTimeZoneName(this.selectedProduct.timezone, false));
    this.enteredStartTime = convertedTime.format('YYYY-MM-DDTHH:mm');
    this.onStartTimeChange();
  }

  getTimeZoneConversion(which, zone)
  {
    let localTZ = this.sharedService.getUsersTimeZone();
    let tz;

    switch (zone) {
      case 'local':
        tz = localTZ;
        break;

      case 'program':
        tz = this.selectedProduct.timezone;
        break;

      case 'utc':
        tz = 'UTC';
        break;

    }

    if(which == 'start')
    {
      let convertedStartTime:string  = this.sharedService.selectedTimeZoneToUTC(this.enteredStartTime, this.selectedProduct.timezone).toISOString();

      return this.enteredStartTime ? moment.tz(convertedStartTime , tz).format(SharedService.dateFormat) : 'No Start Time';
    }
    else {
      let convertedEndTime:string  = this.sharedService.selectedTimeZoneToUTC(this.enteredEndTime, this.selectedProduct.timezone).toISOString();

      return this.enteredEndTime ? moment.tz(convertedEndTime, tz).format(SharedService.dateFormat) : 'No End Time';
    }

  }

  back(): void {

    const controller = this;

    if(!controller.noPortfoliosSelected) {
      this.sharedService.activateModal({headerText: "Go Back?", bodyText: "Returning to Step 1 with clear all of your Step 2 progress. Continue?", allowCancel: true, confirmFunction: function() {
          controller.currentStep = controller.CreateSteps.one;
        }});
    } else {
      controller.currentStep = controller.CreateSteps.one;
    }
  }

  resetStepOne(): void {
    this.resetDateTimes();
    this.allPortfoliosSelected = false;
    this.eventObj = new EPSEvent();
    clearTimeout(this.offersTimeOut)
  }


  resetDateTimes(preferredTZ = null):void {
    this.enteredNotificationTime = null;
    this.enteredStartTime = null;
    this.enteredEndTime = null;
    this.nowBoxChecked = false;
    this.endTimeOption = EndTimeOptions.Default;
    this.setMinStart();
  }

  setMinStart():void {
    if(this.selectedProduct)
    {
      this.startAtTime = this.enteredStartTime ? this.enteredStartTime : moment.tz(moment(), this.sharedService.getTimeZoneName(this.selectedProduct.timezone, false)).format('YYYY-MM-DDTHH:mm');
      this.minStartTime = moment.tz(moment().subtract(15, 'm'), this.sharedService.getTimeZoneName(this.selectedProduct.timezone, false)).format('YYYY-MM-DDTHH:mm');
    }
  }

  checkSelectedRegistrations()
  {
    const controller = this;
    controller.noRegistrationsSelected = false;

    controller.portfolios.forEach(p => {
      if (p.selected) {
        if (!controller.registrations[p.id].selected.length && !controller.registrations[p.id].opted_out.length) {
          controller.noRegistrationsSelected = true;
        }
      }
    })
  }

  selectRegistration(portfolio_id, reg):void {
    //Get the index of it in the selected array
    let index = this.registrations[portfolio_id].excluded.indexOf(reg);

    //Add it to excluded and remove it from selected
    if(index !== -1) {
      this.registrations[portfolio_id].selected.push(this.registrations[portfolio_id].excluded[index]);
      this.registrations[portfolio_id].excluded.splice(index, 1);
    }
    this.checkSelectedRegistrations();
  }

  deSelectRegistration(portfolio_id, reg):void {
    //Get the index of it in the selected array
    let index = this.registrations[portfolio_id].selected.indexOf(reg);

    //Add it to excluded and remove it from selected
    if(index !== -1) {
      this.registrations[portfolio_id].excluded.push(this.registrations[portfolio_id].selected[index]);
      this.registrations[portfolio_id].selected.splice(index, 1);
    }
    this.checkSelectedRegistrations();
  }

  selectAllPortfolios():void {
    const controller = this;
    this.portfolios.forEach(p => {
      p.selected = !controller.allPortfoliosSelected;
    });

      Object.keys(this.portfoliosCheckGroup.control.controls).forEach(key =>{

        const control = this.portfoliosCheckGroup.control.controls[key];
        control.markAsTouched();
        control.markAsDirty();
      });
  }

  checkIfAllPortfoliosSelected():void {
    this.allPortfoliosSelected = this.portfolios.every(p => {return p.selected;})
  }

  calculateAvailability(registrations, totalObligation, elem, portfolioElem) {

    if (!elem.classList.contains('availabilityIncludedMet')) {
      elem.classList.add('availabilityIncludedMet');
    }

    if (this.ignoringRegistrationLimits || !this.selectedProduct.implement_registration_limits) {
      let total: number = 0;

      registrations.forEach(reg => {
        total += +this.sharedService.convertProductUOM(reg.availability, this.selectedProduct, false, 3, true);
      });

      if (total >= +this.sharedService.convertProductUOM(totalObligation, this.selectedProduct, false, 3, true)) {
        if (!elem.classList.contains('availabilityIncludedMet')) {
          elem.classList.add('availabilityIncludedMet');
        }
        if (portfolioElem.dirty === false) {
          setTimeout(()=> {
            portfolioElem.collapsed = true;
            portfolioElem.dirty = true;
          }, 0);
        }

      } else {

        if (elem.classList.contains('availabilityIncludedMet')) {
          elem.classList.remove('availabilityIncludedMet');
        }
        if (portfolioElem.dirty === false) {
          setTimeout(()=> {
            portfolioElem.collapsed = false;
            portfolioElem.dirty = true;
          }, 0);
        }
      }

      return +this.sharedService.fixedDecimals(total);
    } else {

      let allGood = true;

      _.forEach(registrations, r => {

        let val = +this.sharedService.convertProductUOM(r, this.selectedProduct, false, 3, true)
        let ob = +this.sharedService.convertProductUOM(totalObligation, this.selectedProduct, false, 3, true);

        if (val < ob) {
          allGood = false;
        }
      });

      if (allGood) {
        if (!elem.classList.contains('availabilityIncludedMet')) {
          elem.classList.add('availabilityIncludedMet');
        }
        if (portfolioElem.dirty === false) {
          setTimeout(()=> {
            portfolioElem.collapsed = true;
            portfolioElem.dirty = true;
          }, 0);
        }

      } else {
        if (elem.classList.contains('availabilityIncludedMet')) {
          elem.classList.remove('availabilityIncludedMet');
        }
        if (portfolioElem.dirty === false) {
          setTimeout(()=> {
            portfolioElem.collapsed = false;
            portfolioElem.dirty = true;
          }, 0);
        }
      }

      let returnString = (+this.sharedService.fixedDecimals(registrations[0])) + ' ~ ' + (+this.sharedService.fixedDecimals(registrations[registrations.length - 1]));

      return returnString;

    }
  }

  clearErrors() {
    this.endDateError = null;
  }

  getTimezone() {
    return this.sharedService.getTimeZoneName(this.selectedProduct.timezone);
  }

  setTimes(){
    //Convert the entered times to UTC. We need them in UTC to display them correctly on the next page, and also it makes comparing them easier below
    let convertedStartTime:string = this.sharedService.selectedTimeZoneToUTC(this.enteredStartTime, this.selectedProduct.timezone).toISOString();
    let convertedEndTime:string;
    let convertedNotifTime:string;
    let convertedAdjTime: string;

    if(this.enteredEndTime) {
      convertedEndTime = this.sharedService.selectedTimeZoneToUTC(this.enteredEndTime, this.selectedProduct.timezone).toISOString();
    }

    if(this.notifTimeRequired) {
      convertedNotifTime = this.sharedService.selectedTimeZoneToUTC(this.enteredNotificationTime, this.selectedProduct.timezone).toISOString();
    }

    if(this.enteredAdjTime) {
      convertedAdjTime = this.sharedService.selectedTimeZoneToUTC(this.enteredAdjTime, this.selectedProduct.timezone).toISOString();
    }

    this.eventObj.event_start_dttm_utc = convertedStartTime;
    this.eventObj.event_end_dttm_utc = convertedEndTime;
    this.eventObj.notification_time_utc = convertedNotifTime;


    //Baseline Adjustment
    //By default, set to event start time
    this.weatherAdjStart = this.eventObj.event_start_dttm_utc;
    this.weatherAdjEnd = this.eventObj.event_start_dttm_utc;

    const baselineConfig = this.selectedProduct.baseline_configuration;
    if(baselineConfig
      && baselineConfig.baseline_adjustment_offset
      && baselineConfig.baseline_adjustment_offset_time
      && baselineConfig.baseline_adjustment_offset_time.length) {
      //if product allows baseline override and the user entered a dttm in the field
      if(this.selectedProduct.baseline_configuration.baseline_adjustment_allow_override === true && (convertedAdjTime && convertedAdjTime.length)) {
        this.weatherAdjStart = convertedAdjTime;
      }
      //else calculate for them
      else{
        if(this.selectedProduct.baseline_configuration.baseline_adjustment_offset_time === 'START_TIME') {
          this.weatherAdjStart = moment(this.eventObj.event_start_dttm_utc).subtract(this.selectedProduct.baseline_configuration.baseline_adjustment_offset, 'minutes').toISOString();
        }
        else {
          this.weatherAdjStart = moment(this.eventObj.notification_time_utc).subtract(this.selectedProduct.baseline_configuration.baseline_adjustment_offset, 'minutes').toISOString();
        }
      }

      this.weatherAdjEnd = moment(this.weatherAdjStart).add(this.weatherAdjDuration, 'minutes').toISOString();
    }
    this.eventObj.event_type_display_label = _.find(this.allEventTypes, et => {
      return et.code === this.selectedEventType;
    }).display_label;
  }

  onSubmitOne({value, valid}): void {
    this.getCustomerOffers();
    const controller = this;


    if(this.selectedEventType == "READINESS_TEST" || this.selectedEventType == "NOTIFICATION_TEST") {
      this.ignoringRegistrationLimits = true;
    }else{
      this.ignoringRegistrationLimits = false;
    }

    this.setTimes()

    let portfoliosToSend = [];

    this.portfolios.forEach(p => {

      //We need to send obligation as KW, so convert it
      if(p.selected) {
        portfoliosToSend.push({
          portfolio_id: p.id,
          obligation: this.sharedService.convertToKW(p.obligation, this.sharedService.getPrezUOM(this.selectedProduct)) || 1
        })
      }

    });

    //Activate loader for fetching registrations
    this.sharedService.activateLoader(new LoaderActivator(new Promise(function (resolve, reject) {
      let selectionStartTime = controller.eventObj.event_start_dttm_utc;
      if(controller.selectedProduct.selection_offer_time == 'NOTIFICATION_TIME'){
        selectionStartTime = controller.eventObj.notification_time_utc;
      }
      resolve(controller.eventManagementService.post('/v1/select_registrations', {product_id: controller.selectedProduct.id, "portfolios": portfoliosToSend, event_type:  controller.selectedEventType, start_time: selectionStartTime, end_time: controller.eventObj.event_end_dttm_utc, event_start_time: controller.eventObj.event_start_dttm_utc }, {only_test_registrations: controller.readyToTestReggies}));
    }), function(response) {


      controller.registrations = response.data;

      if(!controller.ignoringRegistrationLimits && controller.selectedProduct.implement_registration_limits) {
        _.forEach(controller.registrations, p => {
          p.orderedSelectedValues = Object.values(p.selected_availabilities).sort(function(a:number, b:number){
            return a - b;
          });

          p.selected = _.orderBy(p.selected, ['event_node_start_time', 'event_node_end_time']);
        });
      }

      controller.gettingRegistrations = false;

      controller.noPortfoliosSelected = true;
      controller.portfolios.forEach(p => {
        if(p.selected) {
          controller.noPortfoliosSelected = false;
        }
      });

      controller.currentStep = controller.CreateSteps.two;
      controller.checkSelectedRegistrations();
    }, function(error) {
      console.dir("Error getting registrations: ");
      console.dir(error);
    }, false, "Selecting Registrations", true, false));

  }

  getRegTimeout(registration): number{
    if(registration.control_timeout_override){
      return registration.control_timeout_override
    }
    else if(this.selectedProduct['control_timeout']){
      return this.selectedProduct['control_timeout'];
    }
    else{
      return 300000;
    }


  }

  getPerfColor(val, obligation) {

    val = +this.sharedService.convertProductUOM(val, this.selectedProduct, false, 3, true);
    obligation = +this.sharedService.convertProductUOM(obligation, this.selectedProduct, false, 3, true);

    if (val < obligation)
      return 'rgb(243, 60, 77)';
    else
      return 'rgb(0, 207, 18)';
  }

  skipStepTwo(){
    const controller = this;
    let portfolios = []

    controller.setTimes();

    controller.portfolios.forEach((p)=>{
      if(p.selected){
        portfolios.push(
          {portfolio_id: p.id,
            obligations: [
              {start_dttm: controller.eventObj.event_start_dttm_utc, end_dttm: controller.eventObj.event_end_dttm_utc, obligation_value: p.obligation || 1}
            ]
          }
        )
      }
    })


    let eventPostObj = {
      operator_id: controller.selectedOperator.id,
      program_id: controller.selectedProgram.id,
      product_id: controller.selectedProduct.id,
      portfolios: portfolios,
      start_time: controller.eventObj.event_start_dttm_utc,
      end_time: controller.eventObj.event_end_dttm_utc,
      notification_time: controller.eventObj.notification_time_utc,
      event_name: controller.eventObj.event_name,
      event_type: controller.selectedEventType,
      source_system_type: 'EM_UI',
      adjustment_window_start: controller.weatherAdjStart,
      adjustment_window_end: controller.weatherAdjEnd,
    };
    if(controller.selectedWorkflow != controller.defaultWorkflow.workflow_id) {
      eventPostObj['workflow_id'] = controller.selectedWorkflow;
    }


    this.sharedService.activatePasswordModal({
      headerText: "Create Event",
      bodyText: "",
      buttonText: 'Continue',
      cancelText: 'Cancel',
      warningText: "This action will create the event immediately and skip the second step \"Review Event Details\". Please note the event name will not match what was input in the Event Name field, and will instead have the following naming convention {Event Start Date} {Operator} {Program} {Product} {Event Type} {Portfolio} display labels",
      allowCancel: true,
      password: 'ennel1',
      customContent: new CustomModalWrapper(EventCreateSummaryComponent, {
        event: controller.eventObj,
        product: controller.selectedProduct,
        weatherAdjStart: controller.weatherAdjStart,
        weatherAdjEnd: controller.weatherAdjEnd,
        style: {
          'width': '50%',
          'mid-width': '450px'
        }
      }),
      confirmFunction: function () {
        controller.sharedService.activateLoader(new LoaderActivator(new Promise(
          function (resolve, reject) {
            resolve(controller.eventManagementService.post('/v1/dispatch/', [eventPostObj], {only_test_registrations: controller.readyToTestReggies}));
          }),
          function(response) {
            if(response.code == 207){
              controller.sharedService.activateModal({
                headerText: "One or more events were not created",
                customContent: new CustomModalWrapper(CreateErrorModalComponent, {
                    style: {
                      'width': '50%',
                      'mid-width': '450px'
                    },
                    postObj: eventPostObj,
                    closeFn: function(){
                      window.location.reload();
                    },
                    response: response
                  }
                )
              })
            } else {
              window.location.reload();
            }
          },
          function(error) {

          }, false, "Creating Event", false, false));
      }

    })
  }

  onSubmitTwo(): void {

    const controller = this;

    this.sharedService.activateModal({
      headerText: "Creating Event",
      bodyText: "",
      buttonText: 'Create',
      cancelText: 'Cancel',
      allowCancel: true,
      password: 'ennel1',
      confirmFunction: function () {

        let portfolioObj = [];

        //Loop through the portfolios and if it's selected, add it to the portfolios array in the event
        controller.portfolios.forEach(p => {
          if(p.selected) {

            let selectedRegistrations = [];
            let pendingRegistrations = [];

            //Need to set the selected- and pending-registration arrays
            //Registrations is a dictionary of <portfolio_id, registration> so we key off of the current portfolio
            controller.registrations[p.id].selected.forEach(r => {

              //Because EMS only takes in the following attributes
              let remappedRegistrationObject = {
                availability: r.availability,
                availability_uom: r.availability_uom,
                site_id: r.site_id,
                site_display_label: r.site_display_label,
                organization_id: r.organization_id,
                organization_name: r.organization_name,
                display_labels: r.display_labels,
                dispatch_conf: r.dispatch_conf,
                id: r.id,
                confirmed: r.confirmed,
                opted_out: r.opted_out,
                is_fsl_indicator: r.is_fsl_indicator,
                firm_service_level_value: r.firm_service_level_value,
                firm_service_level_uom: r.firm_service_level_uom,
                expected_capacity_value: 0,
                expected_capacity_uom: r.expected_capacity_uom,
                registered_capacity_value: 0,
                registered_capacity_uom: r.registered_capacity_uom,
                anticipated_kw: r.anticipated_kw,
                bonus_value: r.bonus_value,
                bonus_uom: r.bonus_uom,
                is_generator_indicator: r.is_generator_indicator,
                control_timeout_override: controller.getRegTimeout(r),
                preauthorized: r.preauthorized || false,
                control_type: r.control_type,
                criteria_objects: r.criteria_objects || null,
                registration_type: r.registration_type,
                estimate_performance_ind: (r.estimate_performance_ind != null ? r.estimate_performance_ind : (r.dispatch_conf?.estimate_performance_ind ? r.dispatch_conf.estimate_performance_ind : false)),
                adjustment_window_start: controller.weatherAdjStart,
                adjustment_window_end: controller.weatherAdjEnd,
                event_node_start_time: r.event_node_start_time || null,
                event_node_end_time: r.event_node_end_time || null,
                flexible_asset_id: r.flexible_asset_id,
                initial_notif_time_override: r.initial_notif_time_override,
                control_time_override: r.control_time_override,
                restore_notif_time_override: r.restore_notif_time_override,
                restore_time_override: r.restore_time_override,
                baseline_def_id: r.baseline_def_id,
              };

              selectedRegistrations.push(remappedRegistrationObject);

            });

            if(controller.selectedEventType !== 'READINESS_TEST') {

              controller.registrations[p.id].excluded.forEach(r => {

                //Because EMS only takes in the following attributes
                let remappedRegistrationObject = {
                  availability: r.availability,
                  availability_uom: r.availability_uom,
                  site_id: r.site_id,
                  site_display_label: r.site_display_label,
                  organization_id: r.organization_id,
                  organization_name: r.organization_name,
                  display_labels: r.display_labels,
                  dispatch_conf: r.dispatch_conf,
                  id: r.id,
                  confirmed: r.confirmed,
                  opted_out: r.opted_out,
                  is_fsl_indicator: r.is_fsl_indicator,
                  firm_service_level_value: r.firm_service_level_value,
                  firm_service_level_uom: r.firm_service_level_uom,
                  expected_capacity_value: 0,
                  expected_capacity_uom: r.expected_capacity_uom,
                  registered_capacity_value: 0,
                  registered_capacity_uom: r.registered_capacity_uom,
                  anticipated_kw: r.anticipated_kw,
                  bonus_value: r.bonus_value,
                  bonus_uom: r.bonus_uom,
                  is_generator_indicator: r.is_generator_indicator,
                  control_timeout_override: controller.getRegTimeout(r),
                  preauthorized: r.preauthorized || false,
                  control_type: r.control_type,
                  criteria_objects: r.criteria_objects || null,
                  registration_type: r.registration_type,
                  estimate_performance_ind: (r.estimate_performance_ind != null ? r.estimate_performance_ind : (r.dispatch_conf?.estimate_performance_ind ? r.dispatch_conf.estimate_performance_ind : false)),
                  adjustment_window_start: controller.weatherAdjStart,
                  adjustment_window_end: controller.weatherAdjEnd,
                  flexible_asset_id: r.flexible_asset_id,
                  initial_notif_time_override: r.initial_notif_time_override,
                  control_time_override: r.control_time_override,
                  restore_notif_time_override: r.restore_notif_time_override,
                  restore_time_override: r.restore_time_override,
                  baseline_def_id: r.baseline_def_id,
                };

                pendingRegistrations.push(remappedRegistrationObject);

              });
            }

            controller.registrations[p.id].opted_out.forEach(r => {

              //Because EMS only takes in the following attributes
              let remappedRegistrationObject = {
                availability_uom: r.availability_uom,
                site_id: r.site_id,
                site_display_label: r.site_display_label,
                organization_id: r.organization_id,
                organization_name: r.organization_name,
                display_labels: r.display_labels,
                dispatch_conf: r.dispatch_conf,
                id: r.id,
                confirmed: r.confirmed,
                opted_out: r.opted_out,
                opted_out_reason: r.opted_out_reason,
                opted_out_user_id: r.opted_out_user_id,
                is_fsl_indicator: r.is_fsl_indicator,
                firm_service_level_value: r.firm_service_level_value,
                firm_service_level_uom: r.firm_service_level_uom,
                expected_capacity_value: 0,
                expected_capacity_uom: r.expected_capacity_uom,
                registered_capacity_value: 0,
                registered_capacity_uom: r.registered_capacity_uom,
                bonus_value: r.bonus_value,
                bonus_uom: r.bonus_uom,
                is_generator_indicator: r.is_generator_indicator,
                control_timeout_override: controller.getRegTimeout(r),
                preauthorized: r.preauthorized || false,
                control_type: r.control_type,
                registration_type: r.registration_type,
                estimate_performance_ind: (r.dispatch_conf?.estimate_performance_ind ? r.dispatch_conf.estimate_performance_ind : false),
                adjustment_window_start: controller.weatherAdjStart,
                adjustment_window_end: controller.weatherAdjEnd,
                flexible_asset_id: r.flexible_asset_id,
                baseline_def_id: r.baseline_def_id,
              };

              pendingRegistrations.push(remappedRegistrationObject);

            });

            portfolioObj.push({
              obligation: controller.sharedService.convertToKW(p.obligation, controller.sharedService.getPrezUOM(controller.selectedProduct)) || 1,
              portfolio_id: p.id,
              portfolio_display_label: p.display_label,
              selected_registrations: selectedRegistrations,
              pending_registrations: pendingRegistrations
            });
          }
        });

        let eventPostObj = {
          event_name_prefix: controller.eventObj.event_name,
          obligation_uom: 'kW',
          operator_id: controller.selectedOperator.id,
          operator_name: controller.selectedOperator.display_labels,
          event_start_dttm: controller.eventObj.event_start_dttm_utc,
          event_end_dttm: controller.eventObj.event_end_dttm_utc,
          notification_time_utc: controller.eventObj.notification_time_utc,
          program_id: controller.selectedProgram.id,
          program_name: controller.selectedProgram.display_labels,
          product_id: controller.selectedProduct.id,
          portfolios: portfolioObj,
          product_name: controller.selectedProduct.display_labels,
          full_time_zone: controller.selectedProduct.timezone,
          event_type: controller.selectedEventType
        };

        if(controller.selectedWorkflow != controller.defaultWorkflow.workflow_id) {
          eventPostObj['workflow_id'] = controller.selectedWorkflow;
        }

        //Save the event and activate our loader
        controller.sharedService.activateLoader(new LoaderActivator(new Promise(
            function (resolve, reject) {
              resolve(controller.eventManagementService.post('/v1/events', eventPostObj, {}));
            }),
          function(response) {
            if(response.code == 207){
              controller.sharedService.activateModal({
                headerText: "One or more events were not created",
                customContent: new CustomModalWrapper(CreateErrorModalComponent, {
                    style: {
                      'width': '50%',
                      'mid-width': '450px'
                    },
                    postObj: eventPostObj,
                    closeFn: function(){
                      window.location.reload();
                    },
                    response: response
                  }
                )
              })
            } else {
              window.location.reload();
            }
          },
          function(error) {

          }, false, "Creating Event", false, false));

      }
    });

  }
}
