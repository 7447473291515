import { Component, OnInit } from '@angular/core';
import { ListTab, SharedService } from "../../services/shared.service";
import { CookieService } from 'ngx-cookie-service';
import { ActivationStart, Router } from '@angular/router';

@Component({
  selector: 'app-timezone-picker',
  templateUrl: './timezone-picker.component.pug',
  styleUrls: ['./timezone-picker.component.scss']
})
export class TimezonePickerComponent implements OnInit {
  appName: string;

  constructor(private sharedService: SharedService, private cookieService: CookieService, private router: Router) {

  }

  ngOnInit() {
    this.router.events
      .subscribe((evt) => {
        if (evt instanceof ActivationStart) {
          if (evt.snapshot.data.training) {
            this.selectedTZ = 1;
            this.appName = "Training Event Management";
          } else if (evt.snapshot.data.utility) {
            this.selectedTZ = 1;
            this.appName = "Utility Portal";
          } else {
            this.appName = "Event Management";
            const cookie = this.cookieService.get('etz');
            if (cookie) {
              this.selectedTZ = Number(cookie);
            }
          }
        }
      }
    );

  }

  get selectedTZ() {
    return this.sharedService.selectedTZ;
  }

  set selectedTZ(val: number) {
    this.sharedService.selectedTZ = val;
    this.cookieService.set('etz', this.sharedService.selectedTZ.toString(), null, '/');
    this.sharedService.changeTimeZonePref(val);
  }

  openCreatePage():void{
    const url = window.location.origin + '/create';
    window.open(url, '_blank');
  }

  openUtilityCreatePage():void{
    const url = window.location.origin + '/utility/create';
    window.open(url, '_blank');
  }

  openTrainingCreatePage():void{
    const url = window.location.origin + '/training/create';
    window.open(url, '_blank');
  }
  openAdminPage():void{
    const url = window.location.origin + '/admin';
    window.open(url, '_blank');
  }

  openUnderfrequencyPage():void{
    const url = window.location.origin + '/underfrequency-events';
    window.open(url, '_blank');
  }

  setTab(tab) {
    SharedService.me.setListTab((<any>ListTab[tab]))
  }

  getTab() {
    return SharedService.me.getListTab();
  }

  isActiveTab(num: number) {
    return (
        window.location.pathname === '/' &&
        num === this.getTab()
    );
  }

  //9 is the regular nodedetails and i'm lazy
  isUtilityTab() {
    return SharedService.me.getListTab() > 4 && SharedService.me.getListTab() < 9;
  }

  isTrainingTab() {
    return SharedService.me.getListTab() >= 10;
  }

}
