import { ApiTime } from 'common-utils/dist/models/time';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';

export interface NodePerformanceAPI extends NodePerformance {
  event_node_id: string;
  site_id: string;
  event_id: string;
  interval_dttm_utc: string;
  interval_dttm_utc_program_locale: string;
  metered_value: number | null;
  metered_uom: string | null;
  baseline_value: number | null;
  baseline_uom: string | null;
  target_value: number | null;
  target_uom: string | null;
  adjusted_baseline_value: number | null;
  performance_value: number;
  estimated: boolean;
}

export interface NodePerformanceIot extends NodePerformanceAPI{
  baseline_uom: string;
  baseline_value: number;
  dispatch_unit_interval_id: string;
  eps_last_processed_ms: number;
  eps_message_id: string;
  eps_process_count: number;
  event_node_id: string;
  interval_dttm_node_timezone_label: string;
  interval_dttm_oper_ms: number;
  interval_dttm_program_locale: string;
  interval_dttm_program_offset_min: number;
  interval_dttm_program_timezone_label: string;
  interval_dttm_utc: string;
  interval_dttm_utc_ms: number;
  interval_dttm_utc_offset_min: number;
  interval_dttm_utc_program_locale: string;
  message_action_type: string;
  message_received_dttm: number;
  message_type: string;
  metered_uom: string;
  metered_value: number;
  operator_name: string;
  original_baseline_value: number;
  sequence_number: number;
  source_dispatch_unit_id: string;
  source_system_type: string;
  target_uom: string;
  target_value: number;
  test_data: boolean;
}

export interface NodePerformance {
  event_node_id: string;
  site_id: string;
  event_id: string;
  interval_dttm_utc: string;
  metered_value: number | null;
  baseline_value: number | null;
  target_value: number | null;
  adjusted_baseline_value: number | null;
  performance_value: number;
  estimated: boolean;
  interval: ApiTime | null;
}

export function parse(model:NodePerformanceAPI, timezone: string): NodePerformance {
  return{
    event_node_id: model.event_node_id,
    site_id: model.site_id,
    event_id: model.event_id,
    interval_dttm_utc: model.interval_dttm_utc,
    metered_value: model.metered_value,
    baseline_value: model.baseline_value,
    target_value: model.target_value,
    adjusted_baseline_value: model.adjusted_baseline_value,
    performance_value: model.performance_value,
    estimated: model.estimated,
    interval: new ApiTime(moment.tz(model.interval_dttm_utc, timezone).format(ApiTime.momentFormat)),
  }
}

export interface EventNodeIot {
  action_unit_id: string;
  average_performance_percentage: number;
  average_performance_status: string;
  average_performance_uom: string;
  average_performance_value: number;
  bdm_email: string;
  bdm_fullname: string;
  bonus_minutes: number;
  bonus_uom: string;
  curtail_attempted_indicator: boolean;
  customer_display_label: { [locale: string]: string; };
  customer_node_id: string;
  display_label: { [locale: string]: string; };
  eps_last_processed_ms: number;
  eps_message_id: string;
  eps_process_count: number;
  event_node_id: string;
  expected_capacity_uom: string;
  expected_capacity_value: number;
  firm_service_level_uom: string;
  is_fsl_indicator: boolean;
  is_generator_indicator: boolean;
  is_inc_generator_indicator: boolean;
  last_current_metered_uom: string;
  last_current_metered_value: number;
  last_current_performance_dttm_program_locale: string;
  last_current_performance_dttm_utc: string;
  last_current_performance_percentage: number;
  last_current_performance_status: string;
  last_current_performance_uom: string;
  last_current_performance_value: number;
  max_end_node_offset_min: number;
  max_end_node_timezone_label: string;
  max_end_program_offset_min: number;
  max_end_program_timezone_label: string;
  max_end_time_local_ms: number;
  max_end_time_utc_ms: number;
  max_last_curr_perf_ms: number;
  max_last_current_perf_ms: number;
  max_last_current_perf_node_offset_min: number;
  max_last_current_perf_node_timezone_label: string;
  max_last_current_perf_program_offset_min: number;
  max_last_current_perf_program_timezone_label: string;
  message_action_type: string;
  message_node_type: string;
  message_received_dttm: number;
  message_type: string;
  min_start_node_offset_min: number;
  min_start_node_timezone_label: string;
  min_start_program_offset_min: number;
  min_start_program_timezone_label: string;
  min_start_time_local_ms: number;
  min_start_time_utc_ms: number;
  operator_name: string;
  opted_out_indicator: boolean;
  parent_registration_node_id: string;
  parent_source_dispatch_id: string;
  participating_indicator: boolean;
  pre_authorised_indicator: boolean;
  project_manager_fullname: string;
  project_mananger_email: string;
  registered_capacity_uom: string;
  registered_capacity_value: number;
  registered_node_member_id: string;
  remote_response_indicator: boolean;
  response_type: string;
  sequence_number: number;
  source_dispatch_id: string;
  source_dispatch_unit_id: string;
  source_site_id: string;
  source_system_type: string;
  test_data: boolean;
  utility_performance_uom: string;
  utility_performance_value: number;
}

