
class PaginationMeta {

  total_items: number;
  total_pages_count: number;
  total_items_in_current_page: number;
  page: number;
  per_page: number;


  constructor() {}
}
export { PaginationMeta }
