import { mark } from '@angular/compiler-cli/src/ngtsc/perf/src/clock';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as Moment from 'moment';
import { forkJoin, Observable, of } from 'rxjs';
import { EPSEvent } from '../../classes/event';
import { Product } from '../../classes/product';
import {
  MarkerEvent,
  MarkerStatus,
  MarkerType,
  EventNodeMarker,
  CustomTaskMarker,
  WorkflowMarker,
  MarkerInterface, EventMarker
} from '../../classes/timeline_step';
import * as Highcharts from "highcharts";
import { BulkService } from '../../services/bulk.service';
import { CustomTasksService } from '../../services/custom-tasks.service';
import { EventManagementService } from '../../services/event-management.service';
import * as _ from 'lodash';
import { MqttSubscriptionService } from '../../services/mqtt-subscription.service';
//import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';
import { RefDataService } from '../../services/ref-data.service';
import { SharedService } from '../../services/shared.service';
import { DateTime, Duration } from 'luxon';
import { CustomModalWrapper } from '../modals/enel-modal/enel-modal.component';
import { TimelineBulkActionModalComponent } from '../modals/timeline-bulk-action-modal/timeline-bulk-action-modal.component';
import { catchError } from 'rxjs/operators';


@Component({
  selector: 'app-event-steps-timeline',
  templateUrl: './event-steps-timeline.component.pug',
  styleUrls: ['./event-steps-timeline.component.scss'],
  providers: [ MqttSubscriptionService ]
})
export class EventStepsTimelineComponent implements OnInit {
  Highcharts = Highcharts;

  @Input() events:Array<EPSEvent> = null;
  @Input() product:Product = null;
  @Output() bulkActionStart : EventEmitter<any> = new EventEmitter();
  @Output() bulkActionEnd : EventEmitter<any> = new EventEmitter();

  nodesLoaded = true;
  timeline = null;
  pauseTimelineRefresh = false;
  markers;
  workflowStates;
  templates;
  fetchingData = false;
  allSteps = [];
  completedSteps = [];
  futureSteps = [];
  curtailIndex = 0;
  restoreCompleteIndex = 0;
  lastCompletedIndex=0;
  updateTimeline = true;
  CTS: CustomTasksService;
  customTasksMap;
  timelineInterval;
  updateDelayTimeout;

  //region chart options
  timelineOptions = {
    chart: {
      borderWidth: 1,
      borderColor: '#d8d8d8',
      spacingBottom: 15,
      spacingTop: 10,
      spacingLeft: 10,
      spacingRight: 10,
      style: {
        color: '#461e7d',
        fontFamily: 'RoobertENEL-Light, sans-serif'
      },
    },
    xAxis: {
      type: 'datetime',
      visible: false
    },
    yAxis: {
      gridLineWidth: 1,
      title: null,
      labels: {
        enabled: false
      }
    },

    title: undefined,
    legend: {
      enabled: false
    },

    tooltip:
      {
        distance: 13,
        useHTML: true,
        borderWidth: 0,
        borderRadius: 0,
        shadow: false,
        backgroundColor: "rgba(255,255,255,1)",
        formatter: function () {
          if(!this.point.options.empty ) {

            let typeString = this.point.options.label === 'Custom Task' ? ' Events' : ' Nodes';
            let tooltip = "<div style='z-index: 1000'><b>" + this.point.options.label + ": " + this.point.options.name + "</b>";

            if(this.point.options.count)
              tooltip += '<div style="float:left">'
                + this.point.options.count
                + typeString + '</div> ' +
                '<div style="float:right; color: #461e7d;"><b>'
                + (this.point.options.control_type ? this.point.options.control_type : '')
                + '</b></div></br></br>';


            tooltip += '<div style="display: block">';
            tooltip += this.point.options.estimated_time == true ? 'TBD' : this.point.options.time;
            tooltip += '</div>'


            tooltip+= "</div>";

            return tooltip;
          }
        },
        style: {
          fontFamily: "RoobertENEL-Light, sans-serif",
          zIndex: 1000
        }
      },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        //cursor: 'pointer',
        //allowPointSelect: true,
        point: {
          states:{

          }
        },
        states: {
          inactive: {
            opacity: 1
          },
          hover: {
            halo: {
              size: 12,
              attributes: {
                fill: Highcharts.getOptions().colors[2],
                'stroke-width': 5,
                stroke: Highcharts.getOptions().colors[1]
              }
            }
          }
        }
      }
    },
    series: [{
      title: 'Timeline Steps',
      zoneAxis: 'x',
      type: 'timeline',
      dataLabels: {
        allowOverlap: false,
        useHTML: true,
        distance: 135,
        formatter: function () {

          const getRelativeDuration = (time: DateTime, now?: DateTime): string => {
            if (!now) {
              now = DateTime.utc();
            }
            now = setUTCZone(now);
            time = setUTCZone(time);
            
            let relativeString = now < time ? "m from now" : "m ago";
        
            let duration =  now.diff(time, ['days', 'hours', 'minutes', 'seconds']);
        
            let days = Math.abs(duration.get("days")) > 0 ? Math.abs(duration.get("days")) + "d, " : "";
            let hours = Math.abs(duration.get("hours")) > 0 ? Math.abs(duration.get("hours")) + "h, " : "";
        
            return days + hours + Math.abs(duration.get("minutes")) + relativeString
          }
        
          const setUTCZone = (time: DateTime): DateTime => {
            if (!time.zoneName || time.zoneName !== 'UTC') {
              return time.setZone('UTC');
            }
            return time;
          }

          let now = DateTime.utc();
          let excludedColor = "rgba(211,211,211,0.75)";

          if (!this.point.options.empty) {
            let typeString = this.point.options.label === 'Custom Task' || this.point.options.label === 'Event Info' ? ' Events' : ' Nodes';
            let formatString = '<div style="min-width: 130px;"><i class="' + this.point.options.icon + '" style="margin-right: 5px; color:' + this.point.options.color + '"></i>' +
              '<b style="color:' + this.point.options.color + '">' + this.point.options.name + '</b></div>';

            if (this.point.options.count)
              formatString += '<span style="float:left">' + this.point.options.count + typeString + '</span> <span style="float:right; ' +
                'color:' + (this.point.options.included ? this.point.options.color : excludedColor) + ' ;"><b>' +
                (this.point.options.control_type ? this.point.options.control_type : '') + '</b></span></br>';

            if (this.point.options.hideTime === undefined || this.point.options.hideTime === null || this.point.options.hideTime === false) {
              formatString += this.point.options.estimated_time == true ? 'TBD' : this.point.options.time;
              
              const relativeDuration = getRelativeDuration(this.point.options.unformatted_utc_time, now);
              if (!this.point.options.estimated_time) {
                formatString += '<hr style="margin-top: 3px; margin-bottom: 3px;">' +
                  '<span style="font-style: italic">' + relativeDuration + '</span>';
              }
            }

            return '<div style="cursor: default; color:' + (this.point.options.included ? '' : excludedColor) + ' ">' + formatString + '</div>';
          }
        }
      },
      marker: {
        symbol: 'circle'
      }
    }]
  };
  //endregion


  constructor(private EMS: EventManagementService, private BS: BulkService, private RDS: RefDataService, private SS: SharedService, private timelineSub: MqttSubscriptionService) { }

  ngOnInit(): void {
    window['DateTime'] = DateTime;
    this.fetchData(true);
    this.timelineSub.connectionLost.subscribe(()=>{
      this.fetchData(true);
    });
  }

  ngOnDestroy() {
    clearInterval(this.timelineInterval)
    this.timelineSub.clearSubscription();
  }

  fetchData(firstPass = false){
    const ctrl = this;
    this.fetchingData = true;
    clearInterval(this.timelineInterval)
    let eventIds = _.map(this.events, 'event_id')

    let getTimelineData$ = this.EMS.post('/v1/events/timeline', {event_ids: eventIds}, null).pipe();
    let getFilterTypes$: Observable<any> = this.RDS.getAllFilterTypesMap();
    let getTemplates$: Observable<any> = this.RDS.getTemplatesMap();

    forkJoin([getTimelineData$, getFilterTypes$, getTemplates$]).pipe(
      catchError((err) => {
        console.error(err);
        return of(null);
      })
    ).subscribe(
      ([markers, filters, templates])=>{
        this.markers = markers.data.timeline_markers;
        this.markers.forEach((marker: MarkerInterface)=>{
          if(marker.marker_type === MarkerType.CUSTOM_TASK){
            let m = marker as CustomTaskMarker;
            m.filter_display_label = filters[m.filter_type].display_label || '';
            m.template_display_label = templates[m.action_parameter].display_label || '';
          }
        })
        this.setTimelineData();

        if(firstPass){
          this.timelineSub.subscribeToTopic(_.map(this.events, 'event_id'), 'event', (msg)=>{
            clearTimeout(ctrl.updateDelayTimeout);

            ctrl.updateDelayTimeout = setTimeout(()=>{
              this.fetchData();
            }, 3000)

          })
        }

        this.timelineInterval = setInterval(() => {
          this.setTimelineData();
        }, 30000);

      }
    )
  }

  setTimeline(chart: Highcharts.Chart){
    this.timeline = chart;
  }

  setTimelineData(){
    if(this.pauseTimelineRefresh) {
      return;
    }
    const controller = this;
    this.allSteps = [];
    this.completedSteps = [];
    this.futureSteps = [];

    let index = 0;
    let sequence = 1;
    this.markers.forEach((iMarker: MarkerInterface)=>{
      let thisDataPoint;
      let marker;
      let timezone = this.events[0].full_time_zone;

      switch (iMarker.marker_type){
        case MarkerType.WORKFLOW:
          marker = iMarker as WorkflowMarker;
          thisDataPoint = {
            name: marker.display_label,
            control_type: marker.included == false ? 'Excluded' : marker.automated == true ? 'Automated' : 'Manual',
            workflow_state_name: marker.workflow_state_name,
            included: marker.included,
            automated: marker.automated,
            completed: marker.completed,
            step_num: marker.step_num,
            count: marker.event_node_ids.length,
            color: '#461e7d',
            label: "Workflow Step",
            icon: "fa fa-step-forward",
            status: marker.status,
          }
          if(marker.marker_event == 'CURTAILING'){
            controller.curtailIndex = index;
          }
          if(marker.marker_event == 'RESTORING'){
            controller.restoreCompleteIndex = index;
          }
          break;
        case MarkerType.CUSTOM_TASK:
          marker = iMarker as CustomTaskMarker;
          thisDataPoint = {
            name: marker.template_display_label,
            control_type: marker.automated == true ? 'Automated' : 'Manual',
            completed: marker.completed_time != null,
            action_type: marker.action_type,
            action_parameter: marker.action_parameter,
            filter_type: marker.filter_type,
            tasks: marker.tasks,
            included: true,
            automated: marker.automated,
            count: Object.keys(marker.tasks).length,
            events: marker.events,
            color: '#461e7d',
            label: "Custom Task",
            icon: "fa fa-clipboard",
          }
          break;
        case MarkerType.EVENT:
          marker = iMarker as EventMarker;
          thisDataPoint = {
            name: marker.marker_event == 'END' ? 'Event End': 'Event Start',
            control_type: null,
            included: true,
            completed: marker.schedule_time && DateTime.fromISO(marker.schedule_time) < DateTime.utc(),
            count: marker.event_ids.length,
            color: '#461e7d',
            label: "Event Info",
          }
          break;
      }
      if(thisDataPoint){
        thisDataPoint.marker_type = marker.marker_type;
        thisDataPoint.marker_event = marker.marker_event;
        thisDataPoint.event_node_ids = marker.event_node_ids;
        thisDataPoint.empty = false;
        thisDataPoint.time = SharedService.LocalToFormattedSelectedTimeZone( marker.timeline_time, timezone);
        thisDataPoint.timeline_time = marker.timeline_time;
        thisDataPoint.schedule_time = marker.schedule_time;
        thisDataPoint.estimated_time = marker.schedule_time == null;
        thisDataPoint.unformatted_time = DateTime.fromISO(marker.timeline_time).setZone(timezone);
        thisDataPoint.unformatted_utc_time = DateTime.fromISO(marker.timeline_time);
        thisDataPoint.x = index;
        thisDataPoint.timezone = timezone;
        thisDataPoint.sequence = sequence;
        controller.allSteps.push(thisDataPoint)
        index += 1;
        sequence += 1;
      }


    })

    controller.allSteps.forEach((dp)=>{
      if(dp.completed == true){
        this.lastCompletedIndex = dp.sequence-1;
      }
    })
    controller.updateTimelineData();
  }

  updateTimelineData() {
    let controller = this;

    if(controller.timeline && controller.timeline.series) {

      controller.timeline.series[0].setData(controller.allSteps, false, null, false);

      if(this.lastCompletedIndex > this.curtailIndex){
        controller.timeline.series[0].update({
          zones: [
            {
              value: controller.curtailIndex,
              color: '#59BC5F',
              dashStyle: 'Dash'
            },{
              value: controller.lastCompletedIndex,
              color: '#59BC5F',
              dashStyle: 'Solid'
            },{
              color: 'rgba(110, 70, 165, 0.51)',
              dashStyle: 'Solid'
            }
          ]
        }, false);
      } else {
        controller.timeline.series[0].update(
          {
            zones: [
              {
                value: controller.lastCompletedIndex,
                color: '#59BC5F',
                dashStyle: 'Dash'
              },
              {
                value: controller.curtailIndex,
                color: 'rgba(110, 70, 165, 0.51)',
                dashStyle: 'Dash'
              },{
                color: 'rgba(110, 70, 165, 0.51)',
                dashStyle: 'Solid'
              }
            ]
          }, false);
      }


      this.timeline.series[0].points.forEach(function(point, index) {
        if (point.options.included === false) {
          point.update({
            color: "#707070"
          }, false);
        }
        else if (point.options.control_type === 'Manual') {
          point.update({
            color: "#FC4B88",
          }, false);
        }
        else if (point.options.completed === true) {
          point.update({
            color: "#59BC5F",
          }, false);
        }

        if(point.options.marker_type == MarkerType.WORKFLOW && controller.isNextWorkflowStep(point.options.sequence)[0]) {
          point.update({
            marker: {symbol: "url(assets/images/button_action_go_up.png)", width: 30, height: 30}
          }, false);
        } else {
          point.update({
            marker: {radius: 6, lineColor: "#ffffff", lineWidth: 0, symbol: "circle"}
          }, false);
        }
      });

      controller.timeline.redraw(true);
      window.dispatchEvent(new Event('resize'));
    }
  }

  //Returns an array of two booleans: First is if this workflow step is the next one for the node passed in,
  // second is checking it the FOLLOWING included step would immediately fire if this step was excluded
  isNextWorkflowStep(seq){
    let isNext = false;
    let wouldFire = false;
    let thisStep;
    let nextIncludedStep;

    thisStep = this.allSteps[seq-1];

    if(this.allSteps.length >= seq){
      let nextStepIndex=seq;
      while(nextStepIndex<this.allSteps.length){
        if(this.allSteps[nextStepIndex].marker_type == MarkerType.WORKFLOW && this.allSteps[nextStepIndex].included){
          nextIncludedStep = this.allSteps[nextStepIndex];
          break;
        }else{
          nextStepIndex++;
        }

      }

    }

    if(thisStep?.status == MarkerStatus.CURRENT){
      isNext = true;
    }



    if(nextIncludedStep?.automated){
      let now = DateTime.utc();
      let nextStepScheduleTime = DateTime.fromISO(nextIncludedStep?.schedule_time);
      wouldFire = isNext && now.diff(nextStepScheduleTime) >= 0;
    }

    return [isNext, wouldFire];
  }

  onClickPoint(e){
    const ctrl = this;
    if(e.point?.dist && e.point?.dist > 10){
      return;
    }
    ctrl.pauseTimelineRefresh = true;
    if(e.point?.options.marker_type == MarkerType.CUSTOM_TASK && (DateTime.fromISO(e.point?.options.timeline_time) > DateTime.utc() || e.point?.options.control_type === "Manual")){
      ctrl.bulkActionStart.emit()
      let tasks = JSON.parse(JSON.stringify(_.values(e.point?.options.tasks)));
      ctrl.SS.activateModal({
        headerText: "Custom Task Bulk Action",
        bodyText: e.point?.options.tasks.length + " Custom Tasks selected. Enter password and choose an action:",
        allowCancel: true,

        customContent: new CustomModalWrapper(TimelineBulkActionModalComponent, {
          closeFn: function() {
            ctrl.pauseTimelineRefresh = false;
          },
          style: {
            'width': '50%',
            'max-width': '450px'
          },
          buttons: [
            {
              buttonText: "Do Now", action: function () {
                ctrl.BS.doNowCustomTasks(tasks).then(
                  (resolved)=>{
                    ctrl.bulkActionEnd.emit();
                  }
                ).catch(
                  (rejected)=>{
                    ctrl.bulkActionEnd.emit();
                  }
                )
              }
            },
            {
              buttonText: "Cancel", action: function () {
                ctrl.BS.cancelCustomTasks(_.map(tasks, 'custom_task_id')).then(
                  (resolved)=>{
                    ctrl.bulkActionEnd.emit();
                  }
                ).catch(
                  (rejected)=>{
                    ctrl.bulkActionEnd.emit();
                  }
                )
              }
            },
            {
              buttonText: "Update", action: function () {
                ctrl.BS.updateCustomTasks(tasks, ctrl.events, ctrl.product).then(
                  (resolved)=>{
                    ctrl.bulkActionEnd.emit();
                  }
                ).catch(
                  (rejected)=>{
                    ctrl.bulkActionEnd.emit();
                  }
                )
              }
            }
          ]
        })
      });

    }else if(e.point?.options.marker_type == MarkerType.WORKFLOW){
      this.bulkActionStart.emit()

      let wf =  e.point?.options;
      let buttons = [
        {
          buttonText: "Update", action: function () {
            ctrl.BS.rescheduleStep(wf.name, wf.time, wf.event_node_ids, wf.automated, wf.included, ctrl.product.timezone,  wf.workflow_state_name)
          }
        },
      ];

      let addButton = false;
      let excludeWarning = false;

      let results = ctrl.isNextWorkflowStep(wf.sequence);
      addButton = results[0];
      excludeWarning = results[1];


      if(addButton){
        buttons.push({
          buttonText: "Do Now", action: function () {
            ctrl.BS.doNowStep(wf.name, wf.event_node_ids,wf.workflow_state_name)
          }
        })
      }

      if(wf.included) {
        buttons.push({
          buttonText: "Exclude", action: function () {
            ctrl.BS.excludeStep(wf.name, wf.event_node_ids,wf.workflow_state_name, excludeWarning)
          }
        })
      }

      ctrl.SS.activateModal({
        headerText: "Workflow Step Bulk Action",
        bodyText: "Enter password and choose an action:",
        allowCancel: true,

        customContent: new CustomModalWrapper(TimelineBulkActionModalComponent, {
          closeFn: function() {
            ctrl.pauseTimelineRefresh = false;
          },
          style: {
            'width': '50%',
            'max-width': '450px'
          },
          buttons: buttons
        })
      });
    }
  }
}
