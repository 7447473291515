import { Injectable, NgModule } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterModule,
  RouterStateSnapshot,
  Routes
} from '@angular/router';
import { EventCreationComponent } from "./components/event-creation/event-creation.component";
import { EventDetailComponent } from "./components/event-detail/event-detail.component";
import { EventListParentComponent } from "./components/event-list-parent/event-list-parent.component";
import { AdminViewComponent } from './components/admin-view/admin-view.component';
import { SharedService } from './services/shared.service';
import { UtilityPortalListComponent } from "./components/utility-portal/list/utility-portal-list.component";
import { EventNodeInfoComponent } from "./components/event-node-info/event-node-info.component";
import { UtilityPortalCreateComponent } from "./components/utility-portal/create/utility-portal-create.component";
import { UtilityPortalEventDetailsComponent } from "./components/utility-portal/utility-portal-event-details/utility-portal-event-details.component";
import { UtilityPortalNodeDetailsComponent } from './components/utility-portal/utility-portal-node-details/utility-portal-node-details.component';
import { UnderfrequencyListComponent } from './components/underfrequency-list/underfrequency-list.component';
import { UnderfrequencyDetailsComponent } from './components/underfrequency-details/underfrequency-details.component';
import { Err403PageComponent } from './components/err403-page/err403-page.component';

@Injectable()
export class ReallyWeakAuthGuardService implements CanActivate{

  authenticated = false;
  constructor(private router:Router, private sharedService:SharedService ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if(!this.authenticated) {
      const controller = this;
      this.sharedService.activateModal({
        headerText: "Enter Password",
        buttonText: 'Enter',
        allowCancel: false,
        password: 'ennel1',
        confirmFunction: function () {
          controller.authenticated = true;
          controller.router.navigate( ['admin']);
        },
        closeFn: function () {
          this.location.back();
        }
      });

      return false;
    }

    return true;
  }
}

const routes: Routes = [
  {path: '', title: 'Event List', component: EventListParentComponent},
  {path: 'training', component: EventListParentComponent, title: 'Training Event List', data: {training: true}},
  {path: 'training/event/:id', component: EventDetailComponent, title: 'Training Event Details', data: {training: true}},
  {path: 'training/eventnode/:id', component: EventNodeInfoComponent, title: 'Training EventNode Details', data: {training: true}},
  {path: 'training/create', component: EventCreationComponent, title: 'Training Event Creation', data: {training: true}},
  {path: 'utility', component: UtilityPortalListComponent, data: {utility: true}},
  {path: 'utility/create', component: UtilityPortalCreateComponent, data: {utility: true}},
  {path: 'utility/event', component: UtilityPortalEventDetailsComponent, data: {utility: true}},
  {path: 'utility/eventnode/:id', component: UtilityPortalNodeDetailsComponent, data: {utility: true}},
  {path: 'event/:id', title: 'Event Details', component: EventDetailComponent},
  {path: 'eventnode/:id', title: 'EventNode Details', component: EventNodeInfoComponent},
  {path: 'underfrequency-management', component: UnderfrequencyListComponent},
  {path: 'underfrequency-management/:regsetid', component: UnderfrequencyDetailsComponent},
  {path: 'create', title: 'Event Creation', component: EventCreationComponent},
  {path: 'admin', component: AdminViewComponent, canActivate: [ReallyWeakAuthGuardService]},
  {path: 'unauthorized', component: Err403PageComponent},
  {path: '**', redirectTo: '/'},
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }


