import { Component, OnInit, ViewChild } from '@angular/core';
import { EventListService } from '../../services/event-list.service';
import {
  Column,
  ColumnFilter,
  columns,
  EnumColumnFilter,
  FieldType,
  iColumnFilter,
  operatorNames
} from '../../classes/coulmns';
import * as _ from 'lodash';

import { MatSelect } from '@angular/material/select';
import { SharedService } from '../../services/shared.service';
import { Subscription } from 'rxjs';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'app-event-list-pill-box',
  templateUrl: './event-list-pill-box.component.pug',
  styleUrls: ['./event-list-pill-box.component.scss']
})

export class EventListPillBoxComponent implements OnInit {
  filters: Array<ColumnFilter>;

  constructor(private listService:EventListService) {
    const {filters$} = this.listService;
    filters$.pipe().subscribe(
      (response)=>{
        let arr = [];
        response.forEach((o:iColumnFilter) => {
          if(o.column.type == FieldType.MULTI){
            arr = arr.concat((o as EnumColumnFilter).enums)
          }
          else {
            arr.push(o)
          }
        });

        this.filters = _.sortBy(arr, ['column.sortOrder', 'column.label']);
      }
    );
  }

  ngOnInit() {
  }

}
