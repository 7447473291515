import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';
import { CustomTask } from '../../classes/customTask';
import { EPSEvent } from '../../classes/event';
import { Product } from '../../classes/product';
import { BulkService } from '../../services/bulk.service';
import { EventNodesService } from '../../services/event-nodes.service';
import { RefDataService } from '../../services/ref-data.service';
import { SharedService } from '../../services/shared.service';
import { BulkActionType } from '../aggregate-view/aggregate-view.component';
import { CustomModalWrapper } from '../modals/enel-modal/enel-modal.component';
import { RegListModalComponent } from '../modals/reg-list-modal/reg-list-modal.component';

@Component({
  selector: 'app-custom-tasks',
  templateUrl: './custom-tasks.component.pug',
  styleUrls: ['./custom-tasks.component.scss']
})
export class CustomTasksComponent implements OnInit, OnChanges {
  @Input() parentView: string;
  @Input() tasks: Array<CustomTask>;

  @Input() events: Array<EPSEvent>;
  @Input() product: Product;
  @Input() selectingEvents: boolean;

  @Output() cancelBulkAction : EventEmitter<any> = new EventEmitter();
  @Output() selectingTasks : EventEmitter<any> = new EventEmitter();

  readonly BulkActionTypes = BulkActionType;
  selectedBulkTemplate = null;
  showBulkAdd;
  showBulkCancel;
  showBulkUpdate;
  showBulkDoNow
  createableTemplates;
  nodesLoaded;
  nodesToEventMap;
  selectAllChecked;
  bulkActionType;

  constructor(private SS: SharedService, private ENS: EventNodesService, private RDS: RefDataService, private BS: BulkService) {
    const {eventNodeUpdate$, nodesToEventMap$} = this.ENS;
    eventNodeUpdate$.pipe().subscribe((resp)=>{this.handleNodeUpdate(resp);})
    nodesToEventMap$.pipe().subscribe((resp)=>{this.handleNewNodesList(resp)});
  }

  ngOnInit(): void {
    //this.getNodes();
    this.RDS.getCreateableTemplates().subscribe((resp)=>{this.createableTemplates = resp})
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.events?.currentValue != changes.events?.previousValue){
      let numEventsAvailable = 0;
      this.events.forEach((event)=>{
        if(moment(event.event_end_dttm_utc).isAfter(moment()) || event.event_end_dttm_utc === null) {
          numEventsAvailable++;
        }
      })

      this.showBulkAdd = numEventsAvailable > 1;
    }
    if(changes.tasks?.currentValue != changes.tasks?.previousValue){
      let numCustomTasksAvailable = 0;
      this.tasks.forEach((task)=>{
        if(!task.completed_time && !task.cancelled) {
          numCustomTasksAvailable++;
        }
      });
      if(numCustomTasksAvailable > 1) {
        this.showBulkCancel = true;
        this.showBulkUpdate = true;
        this.showBulkDoNow = true;
      } else {
        this.showBulkCancel = false;
        this.showBulkUpdate = false;
        this.showBulkDoNow =  false;
      }

    }
  }

  handleNewNodesList(data){
    this.nodesToEventMap = data;
    this.nodesLoaded = true;
  }

   getNodes(){
    let eventIds = _.map(this.events, 'event_id');
    if(eventIds && eventIds.length)this.ENS.getNodes(eventIds)
  }
  handleNodeUpdate(data){
    //this.getNodes();
  }
  customTaskTrackByFunction(index, item) {
    return item ? item.custom_task_id : undefined;
  }

  showRegList(task){
    let arr = Object.values(task.event_nodes);
    this.SS.activateModal({
      headerText: task.portfolio_name + ' - Registrations Included',
      bodyText: '',
      customContent: new CustomModalWrapper(RegListModalComponent, {
        buttonText: 'OK',
        registrationLabels: arr
      })
    })
  }


  addCustomTasks(){
    this.BS.addCustomTasks(this.events, this.product).then(
      (resolved)=>{ this.cancelAction();}
    ).catch((rejected)=>{this.cancelAction();}
    )
  }

  cancelTasks(){

      let arr = []
      this.tasks.forEach((task)=>{
        if(task.selectedInGrid){
          arr.push(task.custom_task_id)
        }
      })

      if(!arr.length){
        this.SS.popError("You must select at least one task!");
        return;
      }

      this.BS.cancelCustomTasks(arr).then(
        (resolved)=>{
          this.cancelAction();
        }
      ).catch(
        (rejected)=>{
          this.cancelAction();
        }
      )

  }

  updateTasks(){
    let arr = []
    this.tasks.forEach((task)=>{
      if(task.selectedInGrid){
        arr.push(task)
      }
    })

    if(!arr.length){
      this.SS.popError("You must select at least one task!");
      return;
    }
    this.BS.updateCustomTasks(arr, this.events, this.product).then(
      (resolved)=>{
        this.cancelAction();
      }
    ).catch(
      (rejected)=>{
        this.cancelAction();
      }
    )
  }

  doNow(){
    let arr = []
    this.tasks.forEach((task)=>{
      if(task.selectedInGrid){
        arr.push(task)
      }
    })

    if(!arr.length){
      this.SS.popError("You must select at least one task!");
      return;
    }
    this.BS.doNowCustomTasks(arr).then(
      (resolved)=>{
        this.cancelAction();
      }
    ).catch(
      (rejected)=>{
        this.cancelAction();
      }
    )
  }


  bulkContinueDisabled():boolean {
    if(this.bulkActionType === this.BulkActionTypes.doNow || this.bulkActionType === this.BulkActionTypes.update) {
      return this.selectedBulkTemplate === null;
    }
    return false;
  }

  resetSelections(){
    this.selectAllChecked = false;
    _.forEach(this.tasks, task => {
      task.selectedInGrid = false;
    });
  }


  clickBulkAction(type: BulkActionType){
    this.bulkActionType = type;
    if(this.bulkActionType === this.BulkActionTypes.add) {
      this.continueAction();
    } else {
      this.selectingEvents = true;
      this.selectingTasks.next(true);
    }
  }


  cancelAction(){
    this.resetSelections();
    this.selectAllChecked = false;
    this.selectingEvents = false;
    this.bulkActionType = this.BulkActionTypes.none;
    this.cancelBulkAction.next(null);
  }


  continueAction() {
    let title;
    let controller = this;
    const type = this.bulkActionType;
    switch (type) {
      case this.BulkActionTypes.add:
        title = 'Bulk Add Custom Tasks';
        break;
      case this.BulkActionTypes.update:
        title = 'Update Custom Tasks';
        break;
      case this.BulkActionTypes.cancel:
        title = 'Cancel Events';
        break;
      case this.BulkActionTypes.doNow:
        title = 'Send Now';
        break;
    }

    this.SS.activateModal({
      headerText: title,
      bodyText: "",
      buttonText: 'Continue',
      cancelText: 'Cancel',
      allowCancel: true,
      password: 'ennel1',
      confirmFunction: function () {
        setTimeout(function () {
          switch (type) {
            case controller.BulkActionTypes.add:
              controller.addCustomTasks();
              break;
            case controller.BulkActionTypes.update:
              controller.updateTasks();
              break;
            case controller.BulkActionTypes.cancel:
              controller.cancelTasks();
              break;
            case controller.BulkActionTypes.doNow:
              controller.doNow();
              break;
          }
        });
      }});

  }


  isTaskSelectable(task:CustomTask):boolean {
    if(!task.cancelled && !task.completed_time && (this.selectedBulkTemplate === task.action_parameter || (this.bulkActionType !== this.BulkActionTypes.doNow && this.bulkActionType !== this.BulkActionTypes.update))) {
      return true;
    }
    return false;
  }

  selectAllTasks(): void {
    if (!this.selectAllChecked) {
      _.forEach(this.tasks, (task) => {
        if (this.isTaskSelectable(task))
          task.selectedInGrid = true;
      });
    } else {
      this.resetSelections();
    }
  }
  checkIfAllTasksSelected():void {
      let allTasksOnEventSelected = [];

      let allTasksSelected = this.tasks.every(t => {
        return (!this.isTaskSelectable(t) || t.selectedInGrid);
      });

      allTasksOnEventSelected.push(allTasksSelected);

      this.selectAllChecked = allTasksOnEventSelected.every(n => {
        return n === true;
      });
    }


  // hideCustomTaskButtons() {
  //   if (this.event.event_end_dttm_utc && moment(this.event.event_end_dttm_utc).add('60', 'minutes') < moment())
  //     return true;
  //   else if(!this.event.event_end_dttm_utc && this.product?.max_event_duration && moment(this.event.event_start_dttm_utc).add('60', 'minutes').add(this.product.max_event_duration, 'ms') < moment())
  //     return true;
  //
  //   return false;
  // }
  protected readonly Object = Object;
}
