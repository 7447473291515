import * as moment from "moment-timezone/builds/moment-timezone-with-data-2012-2022.min";

export interface OwlTimeData {
  date: moment.Moment;
}

class ModalDatePickerData {
  default_date: string;
  action_name: string;
  min_date: string;
  max_date: string;
  max_duration: number;
  min_date_inclusive: boolean;
  max_date_inclusive: boolean;
  has_now_box: boolean;
  errors: Array<string>;
  entered_time: string = null;
  allow_null: boolean;
  min_date_error: string;
  max_date_error: string;

  constructor(
    default_date: string,
    action_name: string,
    min_date: string,
    max_date: string,
    max_duration: number,
    min_date_inclusive: boolean,
    max_date_inclusive: boolean,
    has_now_box: boolean,
    allow_null: boolean
  ) {
    this.default_date = default_date;
    this.action_name = action_name;
    this.min_date = min_date;
    this.max_date = max_date;
    this.max_duration = max_duration;
    this.min_date_inclusive = min_date_inclusive;
    this.max_date_inclusive = max_date_inclusive;
    this.has_now_box = has_now_box;
    this.allow_null = allow_null;
  }
}

export { ModalDatePickerData };
