import { Component, OnInit, Renderer2 } from '@angular/core';
import { Subject } from "rxjs/internal/Subject";
import { takeUntil } from "rxjs/operators";
import { SharedService } from "../../services/shared.service";

export enum State {
  loading, error, success
}

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.pug',
  styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent implements OnInit {

  active:boolean = false;
  loaderText:string = "";
  StateEnum = State;
  loaderState = State.loading;
  errorMessage:string = "";
  successMessage:string = "";
  body = null;

  constructor(private renderer: Renderer2, private sharedService: SharedService) {
    sharedService.loaderActivated$.subscribe(
      loaderDetails => {
        this.activate(loaderDetails.currentJob, loaderDetails.onSuccess, loaderDetails.onError, loaderDetails.chainLoad, loaderDetails.loaderText, loaderDetails.endInstantly, loaderDetails.isPromise)
      });
  }

  private ngUnsubscribe: Subject<any> = new Subject();

  ngOnInit() {
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  //Currently only works for error state. If we allow the user to manually close with the success state we need to think of a way to avoid the success method potentially being called twice (once on click, and once when the $timeOut is done)
  canClose(): boolean {
    return this.active && this.loaderState === this.StateEnum.error;
  }

  closeManually(): void {
    const controller = this;
    if(this.canClose()) {
      controller.close();
    }
  }

   close(): void {
    this.active = false;
    this.renderer.removeClass(document.body, 'ovh');
   }

  //chainLoad allows us to chain multiple loading animations together without displaying a 'success' banner until the last one
  //true if this isn't the final loading call
  //false if we want to show 'success' when this is done
   activate(currentJob: Promise<any>, onSuccess, onError, chainLoad, loaderText, endInstantly, isPromise): void {

    this.renderer.addClass(document.body, 'ovh');

    this.loaderState = this.StateEnum.loading;
    this.active = true;
    this.errorMessage = null;
    this.loaderText = loaderText;


    const controller = this;

     currentJob.then(function(response) {

       //If we passed in a promise instead of an observable, treat it differently
       if(isPromise) {

         if(response.error) {

           onError(response);
           controller.error(response.message);

         } else {
           controller.success(function() { onSuccess(response.message); }, chainLoad, endInstantly, response.message);
         }
       } else {
         response.pipe(takeUntil(controller.ngUnsubscribe))
           .subscribe(
             response => {

               controller.success(function() { onSuccess(response); }, chainLoad, endInstantly);
             },
             error => {

               if(error === null || error.error === null || error.error.message === null) {
                 error = { error: {message: null} };
               }

               let tempMsg = onError(error);

               controller.error(typeof(tempMsg) === 'string' ? tempMsg : error.error.message);
             });
       }
     });
  }

  success (onSuccess, chainLoad, endInstantly, message = null): void {

    const controller = this;

    //Keep going with the next loader
    if(chainLoad) {
      onSuccess();
    }
    else { //Delay and show the success banner
      setTimeout(function () {
        controller.loaderState = controller.StateEnum.success;
        controller.successMessage = message || "Success!";
      }, 1);

      if(!endInstantly) {
        //Auto dismiss after 3 seconds
        setTimeout(function () {
          onSuccess();

          //Don't automatically close if there's a specific message. Gives the user time to read it
          if(message === null)
            controller.close();

        }, 2000);
      } else {
        onSuccess();

        //Don't automatically close if there's a specific message. Gives the user time to read it
        if(message === null)
          controller.close();
      }
    }

  }

  error (errorMsg): void {
    const controller = this;

    setTimeout(function() {
      controller.loaderState = controller.StateEnum.error;
      controller.errorMessage = errorMsg || 'An error appeared!';
    }, 1);
  }

}
