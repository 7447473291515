import {Component, Input, OnInit} from '@angular/core';
import * as Highcharts from "highcharts";
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';
import * as Moment from "moment";
import * as _ from 'lodash';
import {SharedService} from "../../services/shared.service";
import {EPSEvent} from "../../classes/event";
import {Product} from "../../classes/product";

@Component({
  selector: 'app-event-performance-chart',
  templateUrl: './event-performance-chart.component.pug',
  styleUrls: ['./event-performance-chart.component.scss']
})
export class EventPerformanceChartComponent implements OnInit {

  @Input() events:Array<EPSEvent> = null;
  @Input() totalAvailability = null;
  @Input() comProductObj:Product;
  @Input() performanceData = [];

  Highcharts = Highcharts;

  //DROP_BY and RANGE chart. They use a stacked area chart.
  chart = null;
  chartNowLine = null;
  chartOptions = {
    chart: {
      borderWidth: 0,
      borderColor: '#d8d8d8',
      spacingBottom: 15,
      spacingTop: 10,
      spacingLeft: 10,
      spacingRight: 10,
      marginTop: 100,
      style: {
        color: '#461e7d',
        fontFamily: 'RoobertENEL-Light, sans-serif'
      },
      zoomType: 'x',
      scrollablePlotArea: {
        minWidth: 600,
        scrollPositionX: 1
      },
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        second: '<br/>%H:%M:%S',
        minute: '<br/>%H:%M:%S',
        hour: '<br/>%H:%M:%S',
        day: '%Y<br/>%b-%d',
        week: '%Y<br/>%b-%d',
        month: '%Y-%b',
        year: '%Y'
      },
      minTickInterval: 60000*5,
    },
    yAxis: {
      title: {
        label: 'Demand Delivered',
        rotation: -90,
        x: -10,
      },
    },
    series: [
      {
        type: 'arearange',
        name: 'Target',
        color: '#59bc5f',
        fillOpacity: .5,
        lineWidth: 1,
        marker: {
          symbol: 'circle'
        }
      },
      {
        type: 'area',
        name: 'Demand On (Est.)',
        lineWidth: 3,
        stacking: 'normal',
        connectNulls: false,
        color: '#FFF380',
        marker: {
          symbol: 'circle'
        }
      },
      {
        type: 'area',
        name: 'Generator Generation (Est.)',
        lineWidth: 3,
        stacking: 'normal',
        connectNulls: false,
        color: '#CFECFF',
        marker: {
          symbol: 'circle'
        }
      },
      {
        type: 'area',
        name: 'Storage Generation (Est.)',
        lineWidth: 3,
        stacking: 'normal',
        connectNulls: false,
        color: '#ffb59f',
        marker: {
          symbol: 'circle'
        }
      },
      {
        type: 'area',
        name: 'Load Reduction (Est.)',
        lineWidth: 3,
        stacking: 'normal',
        connectNulls: false,
        color: '#A28EBE',
        marker: {
          symbol: 'circle'
        }
      },
      {
        type: 'area',
        name: 'Demand On',
        lineWidth: 3,
        stacking: 'normal',
        connectNulls: false,
        color: '#FFE701',
        marker: {
          symbol: 'circle'
        }
      },
      {
        type: 'area',
        name: 'Generator Generation',
        lineWidth: 3,
        stacking: 'normal',
        connectNulls: false,
        color: '#0099FF',
        marker: {
          symbol: 'circle'
        }
      },
      {
        type: 'area',
        name: 'Storage Generation',
        lineWidth: 3,
        stacking: 'normal',
        connectNulls: false,
        color: '#FF6C40',
        marker: {
          symbol: 'circle'
        }
      },
      {
        type: 'area',
        name: 'Load Reduction',
        lineWidth: 3,
        stacking: 'normal',
        connectNulls: false,
        color: '#461E7D',
        marker: {
          symbol: 'circle'
        }
      },
      {
        type: 'spline',
        name: 'Baseline',
        lineWidth: 3,
        stacking: 'normal',
        connectNulls: false,
        color: '#461E7D',
        marker: {
          symbol: 'circle'
        },
        showInLegend: false
      },
      {
        threshold: Infinity,
        type: 'area',
        name: 'Target',
        color: '#59bc5f',
        fillOpacity: .5,
        lineWidth: 1,
        marker: {
          symbol: 'circle'
        },
        showInLegend: false
      },
    ],
    tooltip: {
      split: true,
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: false
        }
      },
      arearange: {
        marker: {
          enabled: false
        }
      },
      area: {
        marker: {
          enabled: false
        }
      }
    },
    title: undefined,
    credits: {
      enabled: false,
    },
    legend:{
      align: 'right',
      verticalAlign: 'top',
      y: 25,
      x: -30,
    }
  };

  //DROP_TO chart. We plot usage instead of performance so we need a separate chart type
  dropToChart = null;
  dropToChartOptions = {
    chart: {
      borderWidth: 0,
      borderColor: '#d8d8d8',
      spacingBottom: 15,
      spacingTop: 10,
      spacingLeft: 10,
      spacingRight: 10,
      marginTop: 100,
      style: {
        color: '#461e7d',
        fontFamily: 'RoobertENEL-Light, sans-serif'
      },
      zoomType: 'x',
      scrollablePlotArea: {
        minWidth: 600,
        scrollPositionX: 1
      },
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        second: '<br/>%H:%M:%S',
        minute: '<br/>%H:%M:%S',
        hour: '<br/>%H:%M:%S',
        day: '%Y<br/>%b-%d',
        week: '%Y<br/>%b-%d',
        month: '%Y-%b',
        year: '%Y'
      },
      minTickInterval: 60000*5,
    },
    yAxis: {
      title: {
        label: 'Demand',
        rotation: -90,
        x: -10,
      },
      min: 0
    },
    series: [
      {
        type: 'areaspline',
        name: 'Target',
        color: '#59bc5f',
        fillOpacity: .5,
        lineWidth: 1,
        marker: {
          symbol: 'circle'
        }
      },
      {
        type: 'spline',
        name: 'Demand',
        connectNulls: false,
        color: '#461E7D',
        lineWidth: 2,
        marker: {
          symbol: 'circle'
        }
      }
    ],
    tooltip: {
      split: true,
    },
    plotOptions: {
      areaspline: {
        marker: {
          enabled: false
        }
      },
      arearange: {
        marker: {
          enabled: false
        }
      },
      area: {
        marker: {
          enabled: false
        }
      }
    },
    title: {
      text: 'Realtime Event Performance',
      y: 15,
      floating: true,
      align: 'left',
      style: {
        color: '#461e7d',
        fontFamily: 'RoobertENEL-Light, sans-serif'
      }
    },
    credits: {
      enabled: false,
    },
    legend:{
      align: 'right',
      verticalAlign: 'top',
      y: 25,
      x: -30,
    }
  };


  constructor(private sharedService: SharedService) { }

  ngOnInit() {
    //Set moment on the window so highcharts can find it
    window['moment'] = Moment;
  }

  updateChartDataAndStuff(): void {

    const controller = this;

    //We don't have the product back from COM yet or the chart is still being initialized so we wait
    if(!this.comProductObj || (!this.chart && !this.dropToChart))
      return;

    let currentChart;

    Highcharts.setOptions({
      time: {
        timezone: this.sharedService.getTimeZoneName(this.events[0].full_time_zone, false)
      }
    });

    if(this.comProductObj.target_type === "DROP_TO"){
      currentChart = this.dropToChart;

      this.dropToChart.yAxis[0].setTitle({text: 'Usage (' + this.sharedService.getPrezUOM(this.comProductObj) + ')'}, false);
      if (this.dropToChart) {
        let usage = [];
        let targetRange = [];

        //Loop through the performance data and add it to the chart
        this.performanceData.forEach(pd => {
        //  console.log(pd.target_value + "," + pd.metered_value + "," + pd.interval_dttm_utc)

          const time = moment(pd.interval_dttm_utc).valueOf();
          targetRange.push([time,  this.sharedService.convertProductUOM(pd.target_value, this.comProductObj)]);

          if (pd.metered_value !== null && moment(time).isSameOrBefore(moment())) {
            usage.push([time, +this.sharedService.convertProductUOM(pd.metered_value, controller.comProductObj,false,2)]);
          }
        });

        this.dropToChart.series.forEach(function(s) {
          s.update({
            showInLegend: s.points.length
          }, false);
        });

        this.dropToChart.series[0].setData(targetRange, false, null, false);
        this.dropToChart.series[1].setData(usage, false, null, false);
      }
    }
    else {

      currentChart = this.chart;
      if (this.chart?.yAxis) {
        this.chart?.yAxis[0].setTitle({text: 'Demand Delivered (' + this.sharedService.getPrezUOM(this.comProductObj) + ')'}, false);
        let usage = [];
        let estimatedUsage = [];
        let generatorUsage = [];
        let estimatedGeneratorUsage = [];
        let storageUsage = [];
        let estimatedStorageUsage = [];
        let demandOnUsage = [];
        let estimatedDemandOnUsage = [];
        let target = [], targetRange = [], availability = [];

        let index = 0;
        //Loop through the performance data and add it to the chart
        this.performanceData.forEach(pd => {
          const time = moment(pd.interval_dttm_utc).valueOf();

          let totalObligationValue = 0;
          this.events.forEach((e)=>{
            const intervalTime = moment(pd.interval_dttm_utc);
            if(e.requested_obligations?.length) {
              const index = _.findIndex(e.requested_obligations, function(o) {
                return moment(o.start_dttm).isSameOrBefore(intervalTime) && (!o.end_dttm || moment(o.end_dttm).isSameOrAfter(intervalTime)) });
              if(index > -1 ) {
                totalObligationValue +=  e.requested_obligations[index].obligation_value;
              }
            } else {
              totalObligationValue += e.sum_expected_capacity_value;
            }
        })

          if(!pd.baseline_value)
            pd.baseline_value = 0;
          if(!pd.net_performance)
            pd.net_performance = 0;
          if(!pd.original_baseline_value)
            pd.original_baseline_value = 0;
          if(!pd.weather_adjustment_value)
            pd.weather_adjustment_value = 0;

          let valueToUse = pd.net_performance;

          switch(this.comProductObj.target_type) {
            case "DROP_BY":
              pd.target_value = totalObligationValue;
              target.push([time, this.sharedService.convertProductUOM(pd.target_value, this.comProductObj)]);
              break;

            case "RANGE":
              pd.target_value = totalObligationValue * (this.comProductObj.performance_target_min / 100);
              let max_target = totalObligationValue * (this.comProductObj.performance_target_max / 100);
              targetRange.push([time, this.sharedService.convertProductUOM(pd.target_value, this.comProductObj), this.sharedService.convertProductUOM(max_target, this.comProductObj)]);
              break;
          }

          if (valueToUse !== null && moment(time).isSameOrBefore(moment())) {

            switch(pd.registration_type) {
              case "LOAD":
                if(pd.estimated) {
                  estimatedUsage.push([time, +this.sharedService.convertProductUOM(valueToUse, controller.comProductObj,false,2)]);
                } else {
                  usage.push([time, +this.sharedService.convertProductUOM(valueToUse, controller.comProductObj,false,2)]);
                }
                break;
              case "GENERATOR":
              case "LOAD_METERED_GENERATOR":
                let alreadyThere;
                if(pd.estimated) {
                  alreadyThere = _.find(estimatedGeneratorUsage, function(i) { return i[0] == time; });
                  if(alreadyThere) {
                    alreadyThere[1] += +this.sharedService.convertProductUOM(valueToUse, controller.comProductObj,false,2);
                  } else {
                    estimatedGeneratorUsage.push([time, +this.sharedService.convertProductUOM(valueToUse, controller.comProductObj,false,2)]);
                  }
                } else {
                  alreadyThere = _.find(generatorUsage, function(i) { return i[0] == time; });
                  if(alreadyThere) {
                    alreadyThere[1] += +this.sharedService.convertProductUOM(valueToUse, controller.comProductObj,false,2);
                  } else {
                    generatorUsage.push([time, +this.sharedService.convertProductUOM(valueToUse, controller.comProductObj,false,2)]);
                  }
                }
                break;
              case "STORAGE":
                if(pd.estimated) {
                  estimatedStorageUsage.push([time, +this.sharedService.convertProductUOM(valueToUse, controller.comProductObj,false,2)]);
                } else {
                  storageUsage.push([time, +this.sharedService.convertProductUOM(valueToUse, controller.comProductObj,false,2)]);
                }
                break;
              case "DEMAND_ON":
                if(pd.estimated) {
                  estimatedDemandOnUsage.push([time, +this.sharedService.convertProductUOM(valueToUse, controller.comProductObj,false,2)]);
                } else {
                  demandOnUsage.push([time, +this.sharedService.convertProductUOM(valueToUse, controller.comProductObj,false,2)]);
                }
                break;
            }

            //If this is the current data point, show availability
            if(index === this.performanceData.length - 1 && this.totalAvailability) {
              availability.push([time, +this.sharedService.convertProductUOM(valueToUse, controller.comProductObj,false,2) - this.totalAvailability]);
            }
          }

          index++;
        });

        this.chart.series.forEach(function(s) {
          s.update({
            showInLegend: s.points.length
          }, false);
        });

        this.chart.series[0].setData(targetRange, false, null, false);
        this.chart.series[1].setData(estimatedDemandOnUsage, false, null, false);
        this.chart.series[2].setData(estimatedGeneratorUsage, false, null, false);
        this.chart.series[3].setData(estimatedStorageUsage, false, null, false);
        this.chart.series[4].setData(estimatedUsage, false, null, false);
        this.chart.series[5].setData(demandOnUsage, false, null, false);
        this.chart.series[6].setData(generatorUsage, false, null, false);
        this.chart.series[7].setData(storageUsage, false, null, false);
        this.chart.series[8].setData(usage, false, null, false);
        this.chart.series[10].setData(target, false, null, false);
      }
    }

    //Kind of weird it has to be done in two steps, but it does, otherwise we get a datestring instead of a number
    let nowUTC = moment().toISOString();
    this.chartNowLine = moment(nowUTC).valueOf();

    currentChart.xAxis[0].removePlotLine("start-line", false);
    currentChart.xAxis[0].addPlotLine({
      id: "start-line",
      value: moment(this.events[0].event_start_dttm_utc).valueOf(),
      color: 'black',
      dashStyle: 'longdashdot',
      width: 2,
      label: {
        text: "Event Start",
        rotation: 0,
        x: -65
      }
    }, false);

    currentChart.xAxis[0].removePlotLine("now-line", false);
    currentChart.xAxis[0].addPlotLine({
      id: "now-line",
      value: this.chartNowLine,
      color: 'green',
      width: 2,
      label: {
        text: "NOW",
        rotation: 0,
        y: -15
      }
    }, false);

    currentChart.xAxis[0].removePlotLine("end-line", false);
    currentChart.xAxis[0].addPlotLine({
      id: "end-line",
      value: moment(this.events[0].event_end_dttm_utc).valueOf(),
      color: 'black',
      dashStyle: 'longdashdot',
      width: 2,
      label: {
        text: "Event End",
        rotation: 0
      }
    }, false);

    currentChart.redraw(false);
  }

  setDropToChart(chart: Highcharts.Chart): void {
    const controller = this;
    this.dropToChart = chart;
    this.updateChartDataAndStuff();

    //Update the timeline every 5 seconds
    setInterval(() => {
      controller.updateChartDataAndStuff();
    }, 5000);
  };

  setChart(chart: Highcharts.Chart): void {
    const controller = this;
    this.chart = chart;
    this.updateChartDataAndStuff();

    //Update the timeline every 5 seconds
    setInterval(() => {
      controller.updateChartDataAndStuff();
    }, 5000);
  };
}
