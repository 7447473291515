import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { forkJoin, from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EventManagementService } from './event-management.service';
import { SharedService } from './shared.service';





@Injectable({
  providedIn: 'root'
})
export class RefDataService {

  templates
  creatableTemplates
  allTemplates
  caseTypes
  controlTypes
  eventTypes
  createableFilterTypes
  scheduleFilterTypes
  allFilterTypes
  registrationTypes
  workflowStatuses
  workflowStates
  workflowAttributes
  caseTypesMap
  controlTypesMap
  eventTypesMap
  filterTypesMap
  allFilterTypesMap
  registrationTypesMap
  templatesMap
  workflowStatusesMap
  capacitySources
  capacitySourcesMap

  createableTemplatesMap
  createableFilterTypesMap

  constructor(private EMS: EventManagementService, private SS: SharedService) { }

  //region GET EVENT TYPES
  getEventTypes(): Observable<any>{
    return this.eventTypes ? of(this.eventTypes) :
      this.EMS.get('/v1/event_types', null).pipe(
        map( (resp)=>{
          this.eventTypes = resp.data;
          return this.eventTypes;
        }),
        catchError((err)=> {
          this.popError('Error getting Event Types', err);
          return from(new Promise((resolve) => resolve(true))) ;
        })
      );
  }

  getEventTypesMap(): Observable<any>{
    return this.eventTypesMap ? of(this.eventTypesMap) :
      this.getEventTypes().pipe(
        map( (resp)=>{
          this.eventTypesMap = _.keyBy(resp, 'code');
          return this.eventTypesMap;
        })
      );

  }
  //endregion

  //region GET WORKFLOW STATUSES
  getWorkflowStatuses(): Observable<any>{
    return this.workflowStatuses ? of(this.workflowStatuses) :
      this.EMS.get('/v1/workflow_statuses', null).pipe(
        map( (resp)=>{
          this.workflowStatuses = resp.data;
          return this.workflowStatuses;
        }),
        catchError((err)=> {
          this.popError('Error getting Workflow Statuses', err);
          return from(new Promise((resolve) => resolve(true))) ;
        })
      );
  }

  getWorkflowStatusesMap(): Observable<any>{
    return this.workflowStatusesMap ? of(this.workflowStatusesMap) :
      this.getWorkflowStatuses().pipe(
        map( (resp)=>{
          this.workflowStatusesMap = _.keyBy(resp, 'code');
          return this.workflowStatusesMap;
        })
      );
  }
  //endregion

  //region GET WORKFLOW ATTRIBUTES
  getWorkflowAttributes(): Observable<any>{
    return this.workflowAttributes ? of(this.workflowAttributes) :
      this.EMS.get('/v1/workflow_attributes', null).pipe(
        map( (resp)=>{
          this.workflowAttributes = resp.data;
          return this.workflowAttributes;
        }),
        catchError((err)=> {
          this.popError('Error getting Workflow Attributes', err);
          return from(new Promise((resolve) => resolve(true))) ;
        })
      );
  }

  //endregion

  //region GET WORKFLOW STATES
  getWorkflowStates(): Observable<any>{
    return this.workflowStates ? of(this.workflowStates) :
      this.EMS.get('/v1/workflow_state', null).pipe(
        map(
          (resp)=>{
            this.workflowStates = resp.data;
            return this.workflowStates;
          }),
        catchError((err)=> {
          this.popError('Error getting Workflow States', err);
          return from(new Promise((resolve) => resolve(true))) ;
        })
      )
  }

  //endregion

  //region GET CONTROL TYPES
  getControlTypes(): Observable<any>{
    return this.controlTypes ? of(this.controlTypes) :
      this.EMS.get('/v1/control_types', null).pipe(
        map( (resp)=>{
          this.controlTypes = resp.data;
          return this.controlTypes;
        }),
        catchError((err)=> {
          this.popError('Error getting Control Types', err);
          return from(new Promise((resolve) => resolve(true))) ;
        })
      );
  }

  getControlTypesMap(): Observable<any>{
    return this.controlTypesMap ? of(this.controlTypesMap) :
      this.getControlTypes().pipe(
        map((resp)=>{
          this.controlTypesMap =  _.keyBy(resp, 'code');
          return this.controlTypesMap;
        })
      )
  }
  //endregion

  //region GET CASE TYPES
  getCaseTypes(): Observable<any>{
    return this.caseTypes ? of(this.caseTypes) :
      this.EMS.get('/v1/note_case_type', null).pipe(
        map( (resp)=>{
          this.caseTypes = resp.data;
          return this.caseTypes;
        }),
        catchError((err)=> {
          this.popError('Error getting Case Types', err);
          return from(new Promise((resolve) => resolve(true))) ;
        })
      );
  }

  getCaseTypesMap(){

  }
  //endregion

  //region GET REGISTRATION TYPES
  getRegistrationTypes(): Observable<any>{
    return this.registrationTypes ? of(this.registrationTypes) :
      this.EMS.get('/v1/registration_types', null).pipe(
        map( (resp)=>{
          this.registrationTypes = resp.data;
          return this.registrationTypes;
        }),
        catchError((err)=> {
          this.popError('Error getting Registration Types', err);
          return from(new Promise((reject) => reject(true))) ;
        })
      );
  }

  getRegistrationTypesMap(): Observable<any>{
    return this.registrationTypesMap ? of(this.registrationTypesMap) :
      this.getRegistrationTypes().pipe(
        map((resp)=>{
          this.registrationTypesMap =  _.keyBy(resp, 'code');
          return this.registrationTypesMap;
        })
      )
  }
  //endregion

  //region GET TEMPLATES
  getCreateableTemplates(): Observable<any>{
    return this.creatableTemplates ? of(this.creatableTemplates) :
      this.EMS.get('/v1/templates?filter=DEMAND_RESPONSE', null).pipe(
        map( (resp)=>{
          this.creatableTemplates = resp.data;
          return this.creatableTemplates;
        }),
        catchError((err)=> {
          this.popError('Error getting Templates', err);
          return from(new Promise((resolve) => resolve(true))) ;
        })
      );
  }

  getAllTemplates(): Observable<any>{
    return this.allTemplates ? of(this.allTemplates) :
      this.getCreateableTemplates().pipe(
        map((resp)=>{
          this.allTemplates = JSON.parse(JSON.stringify(resp));
          if(Array.isArray(resp)){
            this.allTemplates.push({
              product_line: "DEMAND_RESPONSE",
              display_label: "DER.OS Schedule",
              template_name: "DER_OS_SCHEDULE"
            });
            this.allTemplates.push({
              product_line: "DEMAND_RESPONSE",
              display_label: "OADR Schedule",
              template_name: "OADR_SCHEDULE"
            })
          }
          return this.allTemplates;
    }))
  }

  getTemplatesMap(utility: boolean = false): Observable<any>{
    if (!utility) {
      return this.templatesMap ? of(this.templatesMap) :
      this.getAllTemplates().pipe(
        map( (resp)=>{
          this.templatesMap = _.keyBy(resp, 'template_name');
          return this.templatesMap;
        })
      );
    }
    return of({});
  }

  getCreateableTemplatesMap(utility: boolean = false): Observable<any>{
    if (!utility) {
      return this.createableTemplatesMap ? of(this.createableTemplatesMap) :
      this.getCreateableTemplates().pipe(
        map( (resp)=>{
          this.createableTemplatesMap = _.keyBy(resp, 'template_name');
          return this.createableTemplatesMap;
        })
      );
    }
    return of({});
  }
  //endregion

  //region GET FILTER TYPES
  getAllFilterTypes(): Observable<any>{
    return this.allFilterTypes ? of(this.allFilterTypes) :
      forkJoin(([this.EMS.get('/v1/filter_types', null),  this.EMS.get('/v1/schedule_filter_types', null)])).pipe(
        map(([res1, res2])=>{
          this.createableFilterTypes = res1.data;
          this.scheduleFilterTypes = res2.data;
          this.allFilterTypes = [...this.createableFilterTypes, ...this.scheduleFilterTypes];
          return this.allFilterTypes;
        })
      )
  }

  getAllFilterTypesMap(): Observable<any>{
    return this.allFilterTypesMap ? of(this.allFilterTypesMap) :
      this.getAllFilterTypes().pipe(
        map( (resp)=>{
          this.allFilterTypesMap = _.keyBy(this.allFilterTypes, 'code')
          return this.allFilterTypesMap;
        })
      );
  }

  getCreateableFilterTypes(): Observable<any>{
    return this.createableFilterTypes ? of(this.createableFilterTypes) :
      this.getAllFilterTypes().pipe(
        map(()=> {
          return this.createableFilterTypes
        })
      )
  }

  getCreateableFilterTypesMap(): Observable<any>{
    return this.createableFilterTypesMap ? of(this.createableFilterTypesMap) :
      this.getCreateableFilterTypes().pipe(
        map( (resp)=>{
          this.createableFilterTypesMap = _.keyBy(this.createableFilterTypes, 'code')
          return this.createableFilterTypesMap;
        }),
        catchError((err)=> {
          console.log(err);
          return from(new Promise((resolve) => resolve(true))) ;
        })
      );
  }
  //endregion

  //region GET CAPACITY SOURCES
  getCapacitySources(): Observable<any>{
    return this.capacitySources ? of(this.capacitySources) :
      this.EMS.get('/v1/capacity_sources', null).pipe(
        map(
          (resp)=>{
            this.capacitySources = resp.data;
            return this.capacitySources;
          }),
        catchError((err)=> {
          this.popError('Error getting Capacity Sources', err);
          return from(new Promise((resolve) => resolve(true))) ;
        })
      )
  }

  getCapacitySourcesMap(): Observable<any>{
    return this.capacitySourcesMap ? of(this.capacitySourcesMap) :
      this.getCapacitySources().pipe(
        map( (resp)=>{
          this.capacitySourcesMap = _.keyBy(resp, 'code');
          return this.capacitySourcesMap;
        })
      );
  }

  // endregion


  popError(msg, err){
    this.SS.popError(msg);
    console.dir(msg + ": ");
    console.log(err);
  }
}
