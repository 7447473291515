import { Injectable, Injector } from '@angular/core';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {environment} from "../../environments/environment";
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private cookieService: CookieService;

  constructor(private injector: Injector) {
    this.cookieService = this.injector.get(CookieService);
  }

  logout() {
    this.cookieService.deleteAll('/', environment.openAmCookieDomain);
    let loc = window.location.href;
    let gotoParam = loc.indexOf('?') > -1 ? '&' : '?';
    gotoParam += 'goto=' + encodeURIComponent(loc) + '&realm=enelx';
    window.open(environment.login_url + gotoParam, '_self');
  }
}

import {Observable} from "rxjs/index";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import {ToastrService} from "ngx-toastr";
import { UserService } from './user.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private toastr: ToastrService, private authService: AuthService, private userService: UserService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(request.method === "OPTIONS") {
      return next.handle(request);
    } else {
      request = request.clone({
        withCredentials: true
      });

      // console.log(request);
      return next.handle(request).pipe(tap(
        () => {
          let found = _.find(this.userService.user?.resources, (o)=>{
            return o.resource_name == 'EVENT_MANAGEMENT' || o.resource_name == 'UTILITY_PORTAL_DISPATCH';
          })
          if(this.userService.user && !found){
            this.router.navigate(['unauthorized'])
          }
        },
        (err:any) => {
          // if(environment.environment !== 'local' && err.status === 403){
          //   this.router.navigate(['unauthorized'])
          // }
        }));
    }
  }
}
