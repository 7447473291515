import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { MqttService } from 'ngx-mqtt';
import { ReplaySubject } from 'rxjs';
import { EventNode, GroupedEventNodes } from '../classes/event_node';
import { EventListService } from './event-list.service';
import { EventManagementService } from './event-management.service';
import { MqttSubscriptionService } from './mqtt-subscription.service';
import { NodesFormatService } from './nodes-format.service';
import { SharedService } from './shared.service';





@Injectable({
  providedIn: 'root'
})


export class EventNodesService {

  currentConnections = [];
  collectedNodes = [];
  eventNodes = [];
  groupedNodes$ = new ReplaySubject<GroupedEventNodes[]>();
  eventNodeUpdate$ = new ReplaySubject<EventNode>();
  nodesToEventMap$ = new ReplaySubject<any>();
  groupedNodes
  NFS: NodesFormatService;

  currentEventIds;
  currentSubscription;
  nodesToEventMap;
  spentMessages = [];
  nodesToWatch = []

  constructor(private EMS: EventManagementService, private MQTT: MqttService, private SS: SharedService, private ELS: EventListService) {
    this.currentSubscription = new MqttSubscriptionService();
    this.NFS = new NodesFormatService();
    const {eventWorkflowUpdate$} = this.ELS;
    eventWorkflowUpdate$.pipe().subscribe((resp) => {this.updateNode(resp)});
  }

  getNodes(eventIds, refresh=false){
    if(!eventIds || !eventIds.length){
      return;
    }
    let diff = _.difference(this.currentEventIds, eventIds);
    if(!refresh && this.nodesToEventMap && !diff.length){
      this.nodesToEventMap$.next(this.nodesToEventMap)
    }
    else{
      this.EMS.post('/v1/events/event_nodes',{event_ids: eventIds}, {}).pipe()
        .subscribe(
          (resp)=>{
            this.currentEventIds = eventIds;
            this.nodesToWatch = [];
            const nodesFormatted = _.after(resp.data.length, ()=>{
              this.nodesToEventMap = obj;
              this.currentSubscription?.clearSubscription();
              this.currentSubscription = new MqttSubscriptionService();
              this.currentSubscription.subscribeToTopic(this.nodesToWatch, 'EVENT_NODE', (msg)=>{
                // console.log('++++++++++++++++++++++++++++')
                // console.log('EVENT_NODE')
                // console.log(msg)
                // console.log('++++++++++++++++++++++++++++')
                this.updateNode(msg)
              })
              this.nodesToEventMap$.next(this.nodesToEventMap)
            })
            let obj = {};
            (resp.data).forEach((o)=>{
              let evId = `${o.event_id}`;
              this.NFS.initGroupedNodes(o).then(
                (grp)=>{
                  obj[evId] = grp;
                  this.nodesToWatch.push(_.map(grp.allNodes, 'event_node_id'))
                  nodesFormatted();
                }
              )
            })
          },
          (err)=>{
            this.popError('Error getting Event Nodes', err);
          }
        )
    }

  }

  updateNode(msg){
    if(!this.nodesToEventMap || !this.nodesToEventMap[msg.event_id]){
      return
    }
    this.NFS.updateNode(msg, this.nodesToEventMap[msg.event_id]).then(
      (resp)=>{
         //console.log('updateNode resp');
         //console.log(resp);
        this.nodesToEventMap[resp.event_id] = resp;
        this.eventNodeUpdate$.next(resp);
      }
    )
  }

  popError(msg, err){
    this.SS.popError(msg);
    console.dir(msg + ": ");
    console.log(err);
  }
}
