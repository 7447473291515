import { Component, ComponentFactoryResolver, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ModalContentWrapperDirective } from '../../../directives/modal-content-wrapper/modal-content-wrapper.directive';
import { CustomModalData, CustomModalWrapper } from '../enel-modal/enel-modal.component';
import { SharedService } from '../../../services/shared.service';

import {EventNodeInfoComponent} from "../../event-node-info/event-node-info.component";



@Component({
  selector: 'app-enel-password-modal',
  templateUrl: './enel-password-modal.component.pug',
  styleUrls: ['./enel-password-modal.component.scss']
})
export class EnelPasswordModalComponent implements OnInit {
  private customModalContentDirective: ModalContentWrapperDirective;
  @ViewChild(ModalContentWrapperDirective, {static:false}) set content(content: ModalContentWrapperDirective) {
    this.customModalContentDirective = content;

    const controller = this;
    if(this.customContent) {

      //The Promise is a hacky solution to prevent errors with creating a view during change detection
      //See here: https://github.com/angular/angular/issues/15634
      Promise.resolve().then(() => {
        let componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.customContent.component);
        let viewContainerRef = this.customModalContentDirective.viewContainerRef;
        viewContainerRef.clear();

        let componentRef = viewContainerRef.createComponent(componentFactory);
        (<CustomModalData>componentRef.instance).data = this.customContent.data;

        (<CustomModalData>componentRef.instance).data.close = function() {
          controller.close();
        }
      });
    }
  }

  customContent = null;
  clickedConfirm = null;
  active = false;
  allowCancel = true;
  headerText = null;
  bodyText = null;
  buttonText = null;
  cancelText = null;
  closeFn = null;
  passwordRequired = null;
  passwordEntered = '';
  warningText = '';

  constructor(private sharedService: SharedService, private renderer: Renderer2, private componentFactoryResolver: ComponentFactoryResolver) {
    sharedService.passwordModalActivated$.subscribe(
      modalDetails => {
        this.activate(modalDetails.headerText, modalDetails.bodyText, modalDetails.allowCancel, modalDetails.confirmFunction, modalDetails.customContent, modalDetails.buttonText, modalDetails.cancelText, modalDetails.password, modalDetails.warningText)
      });
  }

  ngOnInit() {
    this.passwordEntered = '';
  }

  close() {
    this.customContent = null;
    this.active = false;
    this.renderer.removeClass(document.body, 'ovh');
    this.passwordEntered = '';

    if(this.closeFn)
      this.closeFn();
  }

  confirm() {
    if(!this.passwordRequired || this.passwordRequired === this.passwordEntered) {
      this.clickedConfirm();
      this.close();
    }
  }

  activate(headerText, bodyText, allowCancel, confirmFunction, customContent: CustomModalWrapper, buttonText, cancelText, password, warningText) {
    this.renderer.addClass(document.body, 'ovh');
    this.clickedConfirm = confirmFunction;
    this.active = true;
    this.headerText = headerText;
    this.bodyText = bodyText;
    this.allowCancel = allowCancel;
    this.buttonText = buttonText;
    this.cancelText = cancelText;
    this.passwordRequired = password;
    this.warningText = warningText;
    this.passwordEntered = '';

    if(customContent) {
      this.customContent = customContent;
      this.closeFn = customContent.data.closeFn;
    }
  }

  getModalWidth():string {
    if(this.customContent && this.customContent.data.modalWidth) {
      return this.customContent.data.modalWidth;
    } else {
      return '30%'
    }
  }

  getModalMinWidth():string {
    if(this.customContent && this.customContent.data.modalMinWidth) {
      return this.customContent.data.modalMinWidth;
    } else {
      return '346px'
    }
  }

  getStyle() {
    if(this.customContent && this.customContent.data.style) {
      return this.customContent.data.style;
    } else {
      return {
        'width': '30%',
        'min-width': '346px',
      }
    }
  }
}



