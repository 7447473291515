import {Component, ViewChild} from '@angular/core';
import {EntitySearchService} from "../../../services/entity-search.service";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {EventManagementService} from "../../../services/event-management.service";
import {SharedService} from "../../../services/shared.service";
import {EntitySearchDropdownComponent} from "../entity-search-dropdown/entity-search-dropdown.component";

@Component({
  selector: 'app-search-registration-filter',
  templateUrl: './search-registration-filter.component.pug',
  styleUrls: ['./search-registration-filter.component.scss'],

})
export class SearchRegistrationFilterComponent  {

  @ViewChild(EntitySearchDropdownComponent) entitySearchDropdown: EntitySearchDropdownComponent;

  registrationForm: FormGroup;
  eventNodeSearchLoading = false;
  eventNodeSearchCalled = false;
  eventNodes: any[] = [];
  entityTypes = ['REGISTRATION'];
  lookback = {value: '1 Day', id: '1'};
  eventTypes = [
    {
      value: "Official",
      id: "OFFICIAL"
    },
    {
      value: "Official - Audit",
      id: "AUDIT"
    },
    {
      value: "Official - Voluntary",
      id: "VOLUNTARY"
    }
  ];

  constructor(
    private fb: FormBuilder,
    private entitySearchService: EntitySearchService,
    private eventManagementService: EventManagementService,
    private sharedService: SharedService
  ) {
    this.registrationForm = this.fb.group({
      entitySelected: [null, Validators.required]
    });
  }

  searchEventNodes() {
    const registrationId = this.registrationForm.get('entitySelected').value;
    const eventTypesParam = this.eventTypes.map(type => type.id).join(',');
    const queryParams = {
      data_since: this.lookback.id,
      ...(this.eventTypes.length > 0 && { event_type_filter: eventTypesParam })
    }
    this.eventNodeSearchCalled = true;
    this.eventNodeSearchLoading = true;
    this.eventManagementService.get(`/v1/registration/${registrationId}/event_nodes`, queryParams).pipe().subscribe(
      resp => {
        this.eventNodeSearchLoading = false;
        resp.data.forEach(node => {
          node.status = this.sharedService.getEventNodeStatus(node);
        })
        this.eventNodes = resp.data
      },
      error => {
        this.eventNodeSearchLoading = false;
        console.log('Error:', error);
        this.sharedService.popError('Error loading eventNodes for registration ' + registrationId);
        this.eventNodes = [];
      }
    );
  }

  onLookbackChanged($event: any) {
    this.lookback = $event;
    this.searchEventNodes();
  }

  onEventTypesChanged($event: any) {
    this.eventTypes = $event;
    this.searchEventNodes();
  }

  handleEntitySearchResultsChange($event: any) {
    console.log('Entity selected:', $event);
    this.registrationForm.get('entitySelected').setValue($event?.id)
  }

  resetFilters() {
    console.log('Resetting filters');
    this.registrationForm.reset();
    this.eventNodes = [];
    this.entitySearchDropdown.reset();
    this.eventNodeSearchCalled = false;
  }
}
