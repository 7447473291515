import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from "@angular/platform-browser";
import * as _ from 'lodash';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';
import { forkJoin, from } from 'rxjs';
import { Subject } from "rxjs/internal/Subject";
import { catchError, debounceTime, map, takeUntil } from "rxjs/operators";
import { EPSEvent } from "../../../classes/event";
import { LoaderActivator } from "../../../classes/loader_activator";
import { Product, ProductSummary } from "../../../classes/product";
import { EventManagementService } from "../../../services/event-management.service";
import { FdrService } from "../../../services/fdr.service";
import { ListTab, SharedService } from "../../../services/shared.service";
import { HierarchySelectorComponent } from "../../hierarchy-selector/hierarchy-selector.component";

enum CreateSteps {
  one = 1,
  two = 2
}

@Component({
  selector: 'app-utility-portal-create',
  templateUrl: './utility-portal-create.component.pug',
  styleUrls: ['./utility-portal-create.component.scss']
})
export class UtilityPortalCreateComponent implements OnInit {

  @ViewChild(HierarchySelectorComponent, {static: false}) hierarchySelectorElement: HierarchySelectorComponent;
  @ViewChild('portfoliosCheckGroup', {static:false}) portfoliosCheckGroup: any;

  hierarchySelectorData = {
    multiProduct: true,
    productOptional: true,
    slim: false,
    disabled: false
  };

  MILLIS_IN_MINUTE = 60 *1000;
  allEventTypes = [];
  eventTypeGroup = "STANDARD";
  selectedEventType;
  loadedPortfolios:boolean = false;
  loadingPortfolios:boolean = false;
  programPortfolioMap = {};
  currentStep = CreateSteps.one;
  eventObj:EPSEvent;
  CreateSteps = CreateSteps

  //Not really the selected product, but the first product we grab. Just so we can get timezone and UOM and shit
  selectedProgramTZ = null;
  selectedOperator;
  selectedProgram;

  enteredStartTime;
  enteredEndTime;
  startAtTime;
  minStartTime;
  hourEntry:number;
  minuteEntry:number;
  noDivisionSelected:boolean;
  nullDuration = true;
  utilityConfig = {};
  selectedProgramsAndDivisions = [];
  lastSelectedProgramsCount = 0;

  dateInputChanged = new Subject();

  constructor(private fdrService: FdrService, private sharedService: SharedService, private eventManagementService: EventManagementService, private titleService:Title) {
    this.titleService.setTitle('Event Creation');
  }

  private ngUnsubscribe: Subject<any> = new Subject();

  ngOnInit() {

    this.sharedService.setListTab((<any>ListTab['UTILITY_CREATE']));

    this.dateInputChanged.pipe(debounceTime(1000)).subscribe(
      () => {
        this.onLengthChange();
      }
    );

    //GET EVENT TYPES
    this.eventManagementService.get('/v1/event_types', null).pipe(takeUntil(this.ngUnsubscribe)
    ).subscribe(response => {

      this.allEventTypes = _.filter(response.data, d => {
        return d.code === "OFFICIAL" || d.code === "VOLUNTARY";
      });
      this.selectedEventType = _.find(this.allEventTypes, et => {
        return et.code === "OFFICIAL";
      }).code;
    }, error => {
      this.sharedService.popError("Error getting Event Types");
      console.dir("Error getting event types: ");
      console.dir(error);
    });

    //Utility Config
    this.eventManagementService.get('/v1/utility_config', {}).pipe(takeUntil(this.ngUnsubscribe)
    ).subscribe(response => {
      this.utilityConfig = response.data;
    }, error => {
      console.dir("Error getting utility config: ");
      console.dir(error);
    });


    if(this.sharedService.getUser() == null){
      setTimeout(() => {  this.logUserAccess(); }, 15000);
    }else{
      this.logUserAccess();
    }


  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  getSelectableProducts(programId) {
    if (!Object.keys(this.programPortfolioMap[programId].products).length || !Object.values(this.programPortfolioMap[programId].products)[0]['status']) {
      return [];
    }
    const activeProducts = _.filter(this.programPortfolioMap[programId].products, p => {
      const portfolios = p.portfolios.filter(port => port.active_registration_count > 0);
      if (p.portfolios.length !== portfolios.length) {
        p.portfolios = _.orderBy(portfolios, 'display_label');
      }
      return !!p.show && p.portfolios.length > 0;
    });
    
    return _.orderBy(activeProducts, 'display_label')
  }

  getTimezone() {
    return this.sharedService.getTimeZoneName(this.selectedProgramTZ);
  }

  getEventType(type) {
    if(type.code === "VOLUNTARY") {
      if(this.utilityConfig[Object.keys(this.programPortfolioMap)[0]] && this.utilityConfig[Object.keys(this.programPortfolioMap)[0]].voluntary_event_type_override)
        return this.utilityConfig[Object.keys(this.programPortfolioMap)[0]].voluntary_event_type_override;
      else
        return "Official - Emergency";
    }

    return type.display_label;
  }

  resetStepOne(): void {
    this.eventObj = new EPSEvent();

    //Clear selections for all portfolios
    _.forEach(this.hierarchySelectorElement.selectedPrograms, prog => {
      _.forEach(this.programPortfolioMap[prog.id].portfolios, port => {
        port.selected = false;
      })
    });

    this.selectedProgramTZ = null;
    this.loadedPortfolios = false;
    this.enteredEndTime = null;
    this.enteredStartTime =  null;
    this.startAtTime = null;
    this.minStartTime = null;
    this.minuteEntry = null;
    this.hourEntry = null;
    this.hierarchySelectorElement.selectedPrograms = [];
    this.nullDuration = true;
  }

  onProgramSelect(event):void {

    const cont = this;
    let deselectedProgramAndMayNeedTZChange = this.lastSelectedProgramsCount > cont.hierarchySelectorElement.selectedPrograms.length;
    this.lastSelectedProgramsCount = cont.hierarchySelectorElement.selectedPrograms.length;

    cont.loadingPortfolios = true;
    cont.loadedPortfolios = false;

    const doneWithProgramMapping = _.after(this.hierarchySelectorElement.selectedPrograms.length, () => {
      cont.loadingPortfolios = false;
      cont.loadedPortfolios = true;
      cont.checkSelectedDivisions();
      cont.checkProductValidations();
    });

    //Clear everything if they've unselected all programs
    if(cont.hierarchySelectorElement.selectedPrograms.length === 0) {
      cont.selectedProgramTZ = null;
      cont.enteredEndTime = null;
      cont.enteredStartTime =  null;
      cont.minuteEntry = null;
      cont.hourEntry = null;
      cont.nullDuration = true;

      cont.loadingPortfolios = false;
      cont.loadedPortfolios = false;
      cont.checkSelectedDivisions();
    }

    _.forEach(this.hierarchySelectorElement.selectedPrograms, prog => {

      if(cont.programPortfolioMap[prog.id] === undefined) {

        //Copying the object over because we're gonna manipulate it a bit and we don't want to change the base object in the hierarchy
        cont.programPortfolioMap[prog.id] = JSON.parse(JSON.stringify(_.find(this.hierarchySelectorElement.allPrograms, p => {return p.id === prog.id;})));

        let prods = cont.hierarchySelectorElement.getProductsUnderProgram(prog.id);

        //Copying the object over because we're gonna manipulate it a bit and we don't want to change the base object in the hierarchy
        if((this.selectedProgramTZ === null || this.selectedProgramTZ === undefined)) {
          this.selectedProgramTZ = this.programPortfolioMap[prog.id].timezone;
          cont.setMinStart();
        }

        const doneWithProducts = _.after(prods.length, () => {
          doneWithProgramMapping();
        });

        if(prods.length === 0)
          doneWithProducts();

          cont.programPortfolioMap[prog.id].products = {};

        _.forEach(prods, product => {

          cont.programPortfolioMap[prog.id].products[product.id] = {display_label: product.display_label, warnings: [], loadedFullProduct: false, show: product.show};
          cont.programPortfolioMap[prog.id].products[product.id].portfolios = [];
          //Get portfolios
          cont.eventManagementService.get('/v1/portfolios?product_id=' + product.id + '&program_id='+prog.id, {}).pipe(takeUntil(this.ngUnsubscribe)
          ).subscribe(response => {

            cont.programPortfolioMap[prog.id].products[product.id].portfolios = cont.programPortfolioMap[prog.id].products[product.id].portfolios.concat(response.data);
            cont.programPortfolioMap[prog.id].products[product.id].portfolios = _.uniqBy(cont.programPortfolioMap[prog.id].products[product.id].portfolios, function (e) {
              return e.id;
            });

            doneWithProducts();
          }, error => {

            console.dir("Error getting portfolios: ");
            console.dir(error);
            doneWithProducts();
            this.sharedService.popError("Failed to retrieve Divisions");
          });
        });
      } else {

        let prods = cont.hierarchySelectorElement.getProductsUnderProgram(prog.id);

        if(this.selectedProgramTZ === null || this.selectedProgramTZ === undefined) {
          //Copying the object over because we're gonna manipulate it a bit and we don't want to change the base object in the hierarchy
          this.selectedProgramTZ = this.programPortfolioMap[prog.id].timezone;
        }else {
          if (deselectedProgramAndMayNeedTZChange && this.selectedProgramTZ == prog.timezone) {
            deselectedProgramAndMayNeedTZChange = false;
          }else if(deselectedProgramAndMayNeedTZChange && this.selectedProgramTZ != prog.timezone){
            this.selectedProgramTZ = this.programPortfolioMap[prog.id].timezone;
            deselectedProgramAndMayNeedTZChange = false;
            cont.setMinStart();
          }
        }

        doneWithProgramMapping();
      }
    })
  }

  //Called on program select and when the start time or length is changed
  checkProductValidations(skipGettingSummaries:boolean = false)
  {
    const controller = this;

    let start = controller.sharedService.selectedTimeZoneToUTC(controller.enteredStartTime, this.selectedProgramTZ);
    let end = controller.sharedService.selectedTimeZoneToUTC(controller.enteredEndTime, this.selectedProgramTZ);
    let eventDuration = moment(end).diff(moment(start)).valueOf();
    let advanceTime = moment(start).diff(moment().utc()).valueOf();

    controller.hierarchySelectorElement.selectedPrograms.forEach(p => {
      Object.keys(controller.programPortfolioMap[p.id].products).forEach(prod_id => {

        let currentProduct:Product = controller.programPortfolioMap[p.id].products[prod_id];


        const setWarnings = function() {
          currentProduct['product_event_start_time'] = controller.enteredStartTime == null ? null : moment.tz(start, currentProduct.timezone).format(SharedService.dateFormat);
          currentProduct['product_event_end_time'] = controller.enteredStartTime == null ? null : moment.tz(end, currentProduct.timezone).format(SharedService.dateFormat);

          if(!!controller.enteredEndTime) {

            //Clear warnings first
            currentProduct['warnings'] = [];

            //Event is too short
            if(currentProduct.min_event_duration !== 0 && eventDuration < currentProduct.min_event_duration) {
              currentProduct['warnings'].push("Warning: Event duration is shorter than the program minimum event duration.");
            }
            //Event is too long
            else if(currentProduct.max_event_duration !== 0 && eventDuration > currentProduct.max_event_duration) {
              currentProduct['warnings'].push("Warning: Event duration is longer than the program maximum event duration.");
            }
            //Not enough advanced warning
            if(currentProduct.advance_notice !== 0 && advanceTime < (currentProduct.advance_notice * controller.MILLIS_IN_MINUTE)) {
              currentProduct['warnings'].push("Warning: Lead time is shorter than the program minimum advanced notice period.");
            }

            if(controller.selectedEventType === 'OFFICIAL' && !!controller.enteredStartTime) {
              let remainingEventsMonth = null,
                remainingEventsSeason = null,
                remainingEventsWeek = null,
                remainingHoursSeason = null;
              if(currentProduct.dispatch_conf.max_events_per_month > 0) remainingEventsMonth = currentProduct.dispatch_conf.max_events_per_month - currentProduct.product_summary.event_count_this_month;
              if(currentProduct.max_events_per_season > 0) remainingEventsSeason = currentProduct.max_events_per_season - currentProduct.product_summary.event_count_this_season;
              if(currentProduct.dispatch_conf.max_events_per_week > 0) remainingEventsWeek = currentProduct.dispatch_conf.max_events_per_week - currentProduct.product_summary.event_count_this_week;
              if(currentProduct.max_event_hours_per_season > 0) remainingHoursSeason = currentProduct.max_event_hours_per_season - currentProduct.product_summary.event_hours_this_season;

              if(remainingEventsSeason !== null){
                if(remainingEventsSeason == 1 || remainingEventsSeason == 2) {
                  currentProduct['warnings'].push("Warning: There are only " + remainingEventsSeason + " dispatches left for the remainder of the dispatch season.");
                }
                else if(remainingEventsSeason < 1) {
                  currentProduct['warnings'].push("Warning: There are no dispatches left for the remainder of the dispatch season.");
                }
              }

              if(remainingEventsMonth !== null) {
                if(remainingEventsMonth == 1 || remainingEventsMonth == 2) {
                  currentProduct['warnings'].push("Warning: There are only " + remainingEventsMonth + " dispatches left for the remainder of the month.");
                }
                else if(remainingEventsMonth < 1) {
                  currentProduct['warnings'].push("Warning: There are no dispatch hours left for the remainder of the month.");
                }
              }

              if(remainingEventsWeek !== null) {
                if(remainingEventsWeek == 1) {
                  currentProduct['warnings'].push("Warning: There is only 1 dispatch left for the remainder of this week.");
                }
                else if(remainingEventsWeek < 1) {
                  currentProduct['warnings'].push("Warning: There are no dispatch hours left for the remainder of the week.");
                }
              }

              if(remainingHoursSeason !== null) {
                if(remainingHoursSeason > 0 && remainingHoursSeason < 11) {
                  currentProduct['warnings'].push("Warning: There are only " + remainingHoursSeason  + " dispatch hours left for the remainder of the dispatch season.");
                }
                else if(remainingHoursSeason < 1) {
                  currentProduct['warnings'].push("Warning: There are no dispatch hours left for the remainder of the dispatch season.");
                }
              }
            }
          }
        };

        const product$ = controller.eventManagementService.get('/v1/product/' + prod_id, {})
          .pipe(
            takeUntil(controller.ngUnsubscribe),
            map(({data}) => data),
            map((product) => this.formatMessage(product)),
            catchError((err) => {
              console.dir("Error getting product with ID: " + prod_id);
              console.dir(err);
              return from(new Promise((resolve) => resolve(true))) ;
            })
          );

        const timeToGet =  this.sharedService.selectedTimeZoneToUTC(!!controller.enteredStartTime ? controller.enteredStartTime : moment(), this.selectedProgramTZ).toISOString();
        const productSummary$ = !!this.enteredStartTime ? this.eventManagementService.get('/v1/product_summary/' + prod_id, {start_time: this.sharedService.selectedTimeZoneToUTC(this.enteredStartTime, this.selectedProgramTZ).toISOString()}) : from(new Promise((resolve) => resolve({data: new ProductSummary()})))
          .pipe(
            catchError((err) => {
              console.dir("Error getting product summary with ID: " + prod_id);
              console.dir(err);
              return from(new Promise((resolve) => resolve(true))) ;
            })
          );

        //We need to get the product from COM for the min/max duration and advanced_notice fields
        if(!currentProduct['loadedFullProduct']) {
          forkJoin([product$, productSummary$]).subscribe(
            ([productResp, summaryResp]) => {
              currentProduct = Object.assign(currentProduct, productResp);
              currentProduct['loadedFullProduct'] = true;
              currentProduct.product_summary = _.get(summaryResp, 'data');
              setWarnings();
            }
          );

        } else if(!skipGettingSummaries){
          productSummary$.pipe().subscribe(
            (resp) => {
              currentProduct.product_summary = _.get(resp, 'data');
              setWarnings();
            }
          )
        } else {
          setWarnings();
        }

      })
    })
  }

  getProductEventTime(product, dttm: string){
    return moment.tz(dttm, product.timezone).format(SharedService.dateFormat)
  }


  formatMessage(product: Product) {
    if(product.dispatch_conf && product.dispatch_conf.dispatch_instructions) {
      product.dispatch_conf.dispatch_instructions_text = this.sharedService.flattenDisplayLabel(product.dispatch_conf.dispatch_instructions).replace(/\n/g, '<br />').replace(/\t/g, '&nbsp;&nbsp;');
    }

    return  product;
  }

  onDurationChange(){

    if(this.hourEntry < 0 || this.hourEntry == null) {
      this.hourEntry = 0;
    }

    if(this.minuteEntry < 0 || this.minuteEntry == null) {
      this.minuteEntry = 0;
    }
    this.hourEntry = Math.floor(this.hourEntry);
    this.minuteEntry = Math.floor(this.minuteEntry);
    this.nullDuration = false;
    this.onLengthChange(true)
  }

  onDateInputChange(evt) {
    this.dateInputChanged.next(null);
    this.setMinStart();
  }

  onLengthChange(skipGettingSummaries:boolean = false) {
    if(this.hourEntry < 0 || this.hourEntry == null) {
      this.hourEntry = 0;
    }

    if(this.minuteEntry < 0 || this.minuteEntry == null) {
      this.minuteEntry = 0;
    }
    this.enteredEndTime = moment(this.enteredStartTime).add(this.hourEntry, 'h').add(this.minuteEntry, 'm').format(SharedService.dateFormat);

    this.checkProductValidations(skipGettingSummaries);
  }

  back(): void {
    const controller = this;
    controller.currentStep = CreateSteps.one;
    controller.hierarchySelectorData.disabled = false;
  }

  checkSelectedDivisions()
  {
    const controller = this;
    controller.selectedProgramsAndDivisions = [];
    controller.noDivisionSelected = true;

    controller.hierarchySelectorElement.selectedPrograms.forEach(p => {

      let productsSelected = [];
      const afterProducts = _.after(Object.keys(controller.programPortfolioMap[p.id].products).length, () => {
        if(productsSelected.length){
          controller.selectedProgramsAndDivisions.push({
            program_id: p.id,
            products:productsSelected
          });
        }
      });

      if(Object.keys(controller.programPortfolioMap[p.id].products).length == 0){
        afterProducts();
      }else {
        Object.keys(controller.programPortfolioMap[p.id].products).forEach(prod_id => {
          let portfoliosSelected = [];
          const afterPortfolios = _.after(controller.programPortfolioMap[p.id].products[prod_id].portfolios.length, () => {
            if (portfoliosSelected.length) {
              productsSelected.push({
                product_id: prod_id,
                timezone: controller.programPortfolioMap[p.id].products[prod_id].timezone,
                portfolios: portfoliosSelected,
                display_label: controller.programPortfolioMap[p.id].products[prod_id].display_label
              })
            }
            afterProducts();
          });

          if (controller.programPortfolioMap[p.id].products[prod_id].portfolios.length == 0) {
            afterPortfolios();
          } else {

            controller.programPortfolioMap[p.id].products[prod_id].portfolios.forEach(port => {
              if (port.selected) {
                controller.noDivisionSelected = false;
                portfoliosSelected.push(port);
              }

              afterPortfolios();
            });
          }
        })
      }
    })
  }

  setMinStart():void {
    if(this.selectedProgramTZ)
    {
      this.startAtTime = this.enteredStartTime ? this.enteredStartTime : moment.tz(moment(), this.sharedService.getTimeZoneName(this.selectedProgramTZ, false)).format('YYYY-MM-DDTHH:mm');
      this.minStartTime = moment.tz(moment().subtract(15, 'm'), this.sharedService.getTimeZoneName(this.selectedProgramTZ, false)).format('YYYY-MM-DDTHH:mm');
    }
  }

  logUserAccess():void {
    let serverLog;
    if(this.sharedService.getUser() == null){
      serverLog = {
        message: "Utility Portal Event Creation just opened by unknown user: " + this.sharedService.getCookie('enoc_session'),
        data: {}
      };
    }else {
      serverLog = {
        message: "Utility Portal Event Creation just opened by user: " + this.sharedService.getUsername() + " : " + this.sharedService.getUserId(),
        data: this.sharedService.getUser()
      };
    }
    this.eventManagementService.post('/v1/log', serverLog, {}).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        response => {
          console.dir("Successfully logged to server: " + serverLog.message);
        },
        err => {
          console.dir("Error logging user Utility Portal Event Creation Access to server.");
          console.dir(err);
        });
  }

  onSubmitOne({value, valid}): void {
    const controller = this;
    controller.currentStep = CreateSteps.two;

    controller.hierarchySelectorData.disabled = true;

    //Convert the entered times to UTC. We need them in UTC to display them correctly on the next page, and also it makes comparing them easier below
    let convertedStartTime:string = this.sharedService.selectedTimeZoneToUTC(this.enteredStartTime, this.selectedProgramTZ).toISOString();
    let convertedEndTime:string = this.sharedService.selectedTimeZoneToUTC(this.enteredEndTime, this.selectedProgramTZ).toISOString();

    this.eventObj.event_start_dttm_utc = convertedStartTime;
    this.eventObj.event_end_dttm_utc = convertedEndTime;

    controller.eventObj.event_type_display_label = controller.getEventType(_.find(controller.allEventTypes, et => {return et.code === controller.selectedEventType; }))
  }

  onSubmitTwo(): void {

    const controller = this;

    this.sharedService.activateModal({
      headerText: "Creating Event",
      bodyText: "Make sure all of the information is correct. Continue?",
      buttonText: 'Create',
      cancelText: 'Cancel',
      allowCancel: true,
      confirmFunction: function () {

        let eventPostObj = {
          "event_start_dttm": controller.eventObj.event_start_dttm_utc,
          "event_end_dttm": controller.eventObj.event_end_dttm_utc,
          "event_type": controller.selectedEventType,
          "operator_id": controller.hierarchySelectorElement.selectedOperator.id,
          "programs" : []
        };


        //Map child portfolio objects to just list of portfolio ids
        let selectedPrograms = JSON.parse(JSON.stringify(controller.selectedProgramsAndDivisions));
        _.forEach(selectedPrograms, prog => {
          _.forEach(prog.products, prod => {
            prod.portfolios = _.map(prod.portfolios, p => {
              return p.id;
            });
          });
        });
        eventPostObj.programs = JSON.parse(JSON.stringify(selectedPrograms));

        //Save the event and activate our loader
        controller.sharedService.activateLoader(new LoaderActivator(new Promise(
          function (resolve, reject) {
            controller.eventManagementService.post('/v1/utility_portal_event', eventPostObj, {}).pipe(takeUntil(controller.ngUnsubscribe))
              .subscribe(
                response => {
                  resolve({message: "Success! Your event was sent and may take time to see on the Event List page. If you don’t see the dispatch within 5 minutes please call Enel X at 617-692-2003."})
                },
                error => {
                    resolve({message: "Warning! Your event has not been successfully sent. Please try again or please call Enel X at 617-692-2003.", error: error});
                });
            }),
          function() {},
          function(error) {}, false, "Creating Event", false, true));
      }
    });

  }
}
