import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subject } from "rxjs/internal/Subject";
import { AuthService } from '../../services/auth.service';
import { EventListService } from "../../services/event-list.service";
import { ListTab, SharedService } from "../../services/shared.service";

@Component({
  selector: 'app-event-list-parent',
  templateUrl: './event-list-parent.component.pug',
  styleUrls: ['./event-list-parent.component.scss']
})
export class EventListParentComponent implements OnInit {
  private ngUnsubscribe: Subject<any> = new Subject();
  isTraining: boolean = false;
  params: Params;

  constructor(private router: Router, private sharedService: SharedService, private authService: AuthService,
              private route: ActivatedRoute, private listService: EventListService) {

    const {newEvent$} = this.listService;
    newEvent$.pipe().subscribe((resp) => {
      this.handleNewEvent(resp)
    });
  }

  ngOnInit(): void {

    this.isTraining = Boolean(this.route.snapshot.data['training']) || false;
    this.listService.training = this.isTraining;

    this.params = this.route.snapshot.queryParams;
    const tabSnippet = this.isTraining ? 'TRAINING_' : '';

    if (this.params['view']) {
      SharedService.me.setListTab(ListTab[tabSnippet + this.params['view'].toUpperCase()]);
    } else {
      SharedService.me.setListTab(ListTab[tabSnippet + 'LIST']);
    }
    this.listService.getEvents(true);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  currentListTab() {
    return SharedService.me.getListTab();
  }

  handleNewEvent(data) {
    this.listService.getEvents(false);
  }
}
