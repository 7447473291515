import { Injectable } from '@angular/core';
import { MqttService } from 'ngx-mqtt';
import { forkJoin, from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppInjector } from '../app.module';
import { EventManagementService } from './event-management.service';
import { MqttSubscriptionService } from './mqtt-subscription.service';
import { RefDataService } from './ref-data.service';
import { SharedService } from './shared.service';
import * as _ from 'lodash';

/*@Injectable({
  providedIn: 'root'
})*/
export class CustomTasksService {
  EMS;
  MQTT;
  SS;
  RDS: RefDataService;
  currentSubscription: MqttSubscriptionService;
  customTasksMap;
  currentEventIds;

  constructor() {
    this.EMS = AppInjector.get(EventManagementService);
    this.MQTT = AppInjector.get(MqttService);
    this.SS = AppInjector.get(SharedService);
    this.RDS = AppInjector.get(RefDataService)
  }

  getCustomTasks(eventIds, refresh = false): Observable<any> {
    let diff = _.difference(this.currentEventIds, eventIds);
    return (this.customTasksMap && !refresh  && !diff.length) ? of(this.customTasksMap) :
      this.EMS.post('/v1/events/custom_tasks', {event_ids: eventIds}, {}).pipe(
        map(
          (resp)=>{
            this.currentEventIds = eventIds;
            this.customTasksMap = resp['data'];
            return this.customTasksMap;
          }
        ),
        catchError((err)=> {
          this.popError('Error getting Custom Tasks', err);
          return from(new Promise((resolve) => resolve(true))) ;
        })
      )
  }

  updateCustomTaskProp(event):Promise<any> {
    const filterTypes$: Observable<any> = this.RDS.getAllFilterTypesMap();
    const templates$: Observable<any> = this.RDS.getTemplatesMap();
    return new Promise<any>((resolve, reject) => {
      forkJoin([filterTypes$, templates$]).pipe().subscribe(
        ([filterTypes, templates])=>{
          event.custom_tasks.forEach((task)=>{
            task.filter_display_label = filterTypes[task.filter_type] ? filterTypes[task.filter_type].display_label : '';
            task.template_display_label = templates[task.action_parameter] ? templates[task.action_parameter].display_label : '';
            task.full_time_zone = event.full_time_zone;
            task['portfolio_name'] = event.portfolio_display_label;
          })
          resolve(event.custom_tasks)
        }
      )
    })
  }

  //TODO: Clean this up
  addTasksToEvent(event, msg):Promise<any> {
    return new Promise<any>((resolve, reject) => {
      msg.portfolio_name = event.portfolio_display_label;
      msg.full_time_zone = event.full_time_zone;
      msg.cancelled = msg.cancelled === undefined ? null : msg.cancelled;
      let index =_.findIndex(event.custom_tasks, task => {
        return task.custom_task_id === msg.custom_task_id;
      });

      if(index === -1) {
        if(!event.custom_tasks) event.custom_tasks = [];
        event.custom_tasks.push(msg);
      } else {
        event.custom_tasks[index] = msg;
      }
      resolve(event.custom_tasks);
    })
  }



  popError(msg, err){
    this.SS.popError(msg);
    console.dir(msg + ": ");
    console.log(err);
  }
}
