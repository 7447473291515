import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';
import { MqttService } from 'ngx-mqtt';
import { ToastrService } from 'ngx-toastr';
import { Table } from 'primeng/table';
import { Observable, Subscription, forkJoin, from } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { EPSEvent } from '../../classes/event';
import { EventNode, EventNodeBulkOperation, GroupedEventNodes } from '../../classes/event_node';
import { ModalDatePickerData } from '../../classes/modalDatePickerData';
import { PaginationMeta } from '../../classes/paginationMeta';
import { Product } from '../../classes/product';
import { PaginatorComponent } from '../../directives/paginator/paginator.component';
import { BulkService } from '../../services/bulk.service';
import { EventListService } from '../../services/event-list.service';
import { EventManagementService } from '../../services/event-management.service';
import { NodesFormatService } from '../../services/nodes-format.service';
import { PaginatedNodeService } from '../../services/paginated-node.service';
import { RefDataService } from '../../services/ref-data.service';
import { SharedService } from '../../services/shared.service';
import { UserService } from '../../services/user.service';
import { AssignModalComponent } from '../modals/assign-modal/assign-modal.component';
import { CreateNoteModalComponent } from '../modals/create-note-modal/create-note-modal.component';
import { CustomModalWrapper } from '../modals/enel-modal/enel-modal.component';
import { EventNodeBulkActivateModalComponent } from '../modals/event-node-bulk-activate-modal/event-node-bulk-activate-modal.component';
import { EventNodeBulkEndModalComponent } from '../modals/event-node-bulk-end-modal/event-node-bulk-end-modal.component';
import { NotifyModalComponent } from '../modals/notify-modal/notify-modal/notify-modal.component';
import { TimestampModalComponent } from '../modals/timestamp-modal/timestamp-modal.component';
import { NodeFilterModel, NodeListFilterComponent } from '../node-list-filter/node-list-filter.component';

@Component({
  selector: 'app-node-list',
  templateUrl: './node-list.component.pug',
  styleUrls: ['./node-list.component.scss'],
})
export class NodeListComponent implements OnInit, OnChanges {
  @Input() product: Product;
  @Input() events: EPSEvent[];
  @Input() parentView: string;
  @Input() isTraining: boolean = false;

  @Input() eventStart: string;
  @Input() eventEnd: string;

  @ViewChild('dt', { static: false }) activeTable: Table;
  @ViewChild('dt2', { static: false }) optedOutTable: Table;
  @ViewChild(PaginatorComponent, { static: false })
  paginator: PaginatorComponent;
  @ViewChild(NodeListFilterComponent, { static: false })
  nodeListFilter: NodeListFilterComponent;

  groupedNodes: GroupedEventNodes = new GroupedEventNodes();

  paginationMeta:PaginationMeta = new PaginationMeta();
  customerOffers: any;
  nodesToWatch;
  offersTimeOut;
  gotInitialOffers = false;
  workflowStatuses;
  templates = [];
  regTypes;
  controlTypes;
  fetchingNodes;
  cachedEventNodeData = {};
  eventSubscription: Subscription;
  nodesSubscription: Subscription;
  currentConnection;
  eventConnection;
  nodesConnection;
  mqttTimeout;
  eventsMap;
  portfolios = [];
  portfolioLabelById = {};

  private memoized = new Map<string, boolean>();

  //Bulk activate and end
  BulkActionType = Object.freeze({
    none: 0,
    activate: 1,
    deactivate: 2,
    recurtail: 3,
    optIn: 4,
    addNote: 5,
    addOptedOutNote: 6,
    assign: 7,
    assignOptedOut: 8,
    notify: 9,
    overrideControl: 10,
    optOut: 11,
    cancel: 12,
    confirmation: 13,
  });

  bulkActionType;
  selectingNodes:boolean = false;
  bulkActivateNodes: Array<string> = [];
  bulkOptInNodes: Array<string> = [];
  bulkRecurtailNodes: Array<string> = [];
  bulkConfirmationNodes: Array<string> = [];
  bulkEndNodes: Array<string> = [];
  bulkNoteNodes = [];
  bulkAssignNodes = [];
  bulkNotifyNodes = [];
  bulkOverrideNodes = [];
  bulkCancelNodes = [];
  bulkOptOutNodes = [];
  showBulkActivate: boolean;
  showBulkEnd: boolean;
  showBulkRecurtail: boolean;
  showBulkConfirmation: boolean;
  showBulkOptIn: boolean;
  showNotify: boolean;
  showBulkOverrideControl: boolean;
  showBulkCancel: boolean;
  showBulkOptOut: boolean;
  optedOutNodes;
  notifyButtonLabel = 'Notify';
  notifyActionButtonLabel = 'Notify';


  selectedActiveNodes = [];
  selectedOptedOutNodes;

  notificationPaths = [{ delivery_path: 'SMS' }, { delivery_path: 'EMAIL' }, { delivery_path: 'VOICE' }];

  nodeListColumns = [];
  optedOutColumns = [];
  _selectedColumns = [];
  _selectedOptedOutColumns = [];


  possibleAssignees = [];
  productUOM;
  caseTypes = null;
  messageQueue = [];
  spentMessages = [];
  eventDatesMap: any = {};

  private ngUnsubscribe: Subject<any> = new Subject();
  NFS: NodesFormatService;
  PNS: PaginatedNodeService;

  //region column selection
  get selectedOptedOutColumns(): any[] {
    return this._selectedOptedOutColumns;
  }

  set selectedOptedOutColumns(val: any[]) {
    //restore original order
    this._selectedOptedOutColumns = this.optedOutColumns.filter(col => val.includes(col));
  }

  get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    this._selectedColumns = this.nodeListColumns.filter(col => val.includes(col));
  }
  //endregion

  constructor(
    private EMS: EventManagementService,
    private SS: SharedService,
    private BS: BulkService,
    private MQTT: MqttService,
    private US: UserService,
    private toastr: ToastrService,
    private RDS: RefDataService,
    private ELS: EventListService,
  ) {
    this.NFS = new NodesFormatService();
    this.PNS = new PaginatedNodeService();

    const { fetchingNodes$, groupedNodes$, eventNodeUpdate$ } = this.PNS;
    fetchingNodes$.pipe().subscribe(resp => {
      this.fetchingNodes = resp;
    });
    groupedNodes$.pipe().subscribe(resp => {
      this.handleNewNodesList(resp);
    });
    eventNodeUpdate$.pipe().subscribe(resp => {
      this.setNodes();
    });
    this.isRowSelectable = this.isRowSelectable.bind(this);
  }


  ngOnInit(): void {
    this.bulkActionType = this.BulkActionType.none;
    this.paginationMeta.page = 1;
    this.paginationMeta.per_page = 50;
    this.paginationMeta.total_items = 0;

    this.productUOM = this.SS.getPrezUOM(this.product);

    this.initializeTables();

    this.getRefData$().subscribe(([workflowStatuses, templates, regTypes, controlTypes]) => {
      this.workflowStatuses = workflowStatuses;
      this.templates = templates;
      this.regTypes = regTypes;
      this.controlTypes = controlTypes;
      //this.fetchNodes();
    });

    this.EMS.get('/v1/event/organization/' + 'ef5d5287-4200-4f8a-bdbf-b6691bbbe7ac' + '/assignee_list', {})
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        resp => {
          this.possibleAssignees = resp.data;
        },
        err => {
          console.dir(err);
        },
      );

    this.RDS.getCaseTypes().subscribe(resp => {
      this.caseTypes = resp;
    });

    const eventMissingPortfolioInfo = [];
    this.events.forEach((event: EPSEvent) => {
      const portfolioId = event.portfolio_id;
      if (!event.portfolio_display_label && !this.portfolioLabelById[portfolioId]) {
        eventMissingPortfolioInfo.push(portfolioId);
      } else {
        this.updatePortfolio(portfolioId, event.portfolio_display_label);
      }
      this.updateEventDatesMap(event);
    });

    const uniqueMissingPortfolioIds = eventMissingPortfolioInfo.filter((i, idx, arr) => arr.indexOf(i) === idx);
    if (uniqueMissingPortfolioIds.length > 0) {
      uniqueMissingPortfolioIds.forEach(portfolioId => {
        this.EMS.get('/v1/portfolio?portfolio_id=' + portfolioId, {})
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: resp => {
              const portfolio = resp.data;
              const displayLabel = this.SS.flattenDisplayLabel(portfolio.display_labels);
              this.updatePortfolio(portfolio.id, displayLabel);
            },
            error: err => {
              console.dir(err);
            },
          });
      });
    }

    this.eventEnd = this.getLatestEventEnd();
    this.getNotifyButtonLabel();
    this.showNotify = this.shouldShowNotifyButton(this.eventEnd);

    //moving offer fetch here to reduce to only once per page and having it fetch regardless of if there are any opted out nodes or when the event ended
    this.getCustomerOffers();
  }

  updatePortfolio(id, displayLabel) {
    if (!this.portfolioLabelById[id] && !!displayLabel) {
      this.portfolioLabelById[id] = displayLabel;
    }
    this.portfolios.push({
      id: id,
      portfolio_display_label: this.portfolioLabelById[id],
    });
  }

  updateEventDatesMap(event: EPSEvent) {
    if (!this.eventDatesMap[event.event_id]) {
      this.eventDatesMap[event.event_id] = {
        event_start_dttm_utc: event.event_start_dttm_utc,
        event_end_dttm_utc: event.event_end_dttm_utc,
      };
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
    clearTimeout(this.offersTimeOut)
  }

  ngOnChanges(changes: SimpleChanges) {
    const evts: SimpleChange = changes.events;
    if (evts?.currentValue && evts.currentValue !== evts.previousValue) {
      this.eventsMap = _.keyBy(evts.currentValue, 'event_id');
      this.eventStart = this.events[0].event_start_dttm_utc;
      // this.eventEnd = this.events[0].event_end_dttm_utc;
      this.eventEnd = this.getLatestEventEnd();
      this.showNotify = this.shouldShowNotifyButton(this.eventEnd);
      this.getNotifyButtonLabel();

      if(this.parentView !== 'agg') {
        const ev = evts.currentValue[0];
        this.SS.setEventStatus(ev);
        this.showNotify = ev.status.toLowerCase() === 'active';
      }
    }
    if (!this.gotInitialOffers) {
      this.getCustomerOffers();
    }
  }

  getNotifyButtonLabel(): void {
    if (this.parentView === 'agg') {
      this.notifyButtonLabel = 'Bulk Notify';
      this.notifyActionButtonLabel = 'Notify Nodes';
      return;
    }
    this.notifyButtonLabel = 'Notify';
    this.notifyActionButtonLabel = 'Notify';
  }

  getLatestEventEnd(): string {
    if (!this.events.length) {
      return null;
    }

    const firstEventEnd = this.events[0].event_end_dttm_utc;
    if (this.events.length === 1) {
      return firstEventEnd;
    }
    if (firstEventEnd === null) {
      return firstEventEnd;
    }

    let lastEventEnd = firstEventEnd;
    for (const event of this.events.slice(1)) {
      const currentDate = event.event_end_dttm_utc;
      if (currentDate === null) {
        lastEventEnd = null;
        break;
      }
      if (moment(currentDate).isAfter(moment(lastEventEnd))) {
        lastEventEnd = currentDate;
      }
    };
    return lastEventEnd;
  }

  shouldShowNotifyButton(eventEnd): boolean {
    const selectableNodesCount = this.groupedNodes.activeNodes.length + this.groupedNodes.pendingNodes.length;
    if (selectableNodesCount === 0) return false;
    if (eventEnd === null) return true;
    const eventEndPlus1Hour = moment(eventEnd).add(60, 'minutes');
    return moment().isBefore(eventEndPlus1Hour);
  }

  initializeTables(){
    const controlTypeOptions = [
      { label: 'Manual', value: 'MANUAL' },
      { label: 'Full Control - Binary', value: 'BINARY' },
      { label: 'Full Control - Graded', value: 'GRADED' },
      { label: 'Storage', value: 'STORAGE' },
    ];

    this.nodeListColumns = [
      { field: 'eventNodeStatusForSort', header: 'Status' },
      { field: 'registration_display_label', header: 'Name' },
      { field: null, header: 'Portfolio' },
      { field: 'event_node_start_dttm_utc', header: 'Start/End Times' },
      { field: 'expected_capacity_value', header: 'Performance' },
      { field: 'shortfall', header: 'Shortfall' },
      { field: 'workflow_status_display_label', header: 'Workflow State' },
      { field: null, header: 'Last Notification Sent' },
      { field: 'registration_type_display_label', header: 'Registration Type' },
      { field: 'organization_display_label', header: 'Organization' },
      { field: 'site_name', header: 'Site' },
      { field: 'assignee', header: 'Assignee' },
      { field: 'control_type', header: 'Control Type' },
      { field: 'confStatus', header: 'Confirmation Status' },
      { field: null, header: 'Actions' },
      { field: null, header: 'Info' },
    ];

    this.optedOutColumns = [
      { field: 'event_node_id', header: 'Status' },
      { field: 'registration_display_label', header: 'Name' },
      { field: null, header: 'Reason' },
      { field: 'opted_out_user_id', header: 'Username' },
      { field: 'locked_out', header: 'Locked Out' },
      { field: null, header: 'Customer Offer' },
      { field: null, header: 'Cleared Offer' },
      { field: null, header: 'Offer Interval Start Time' },
      { field: 'registration_type_display_label', header: 'Registration Type' },
      { field: 'organization_display_label', header: 'Organization' },
      { field: 'site_name', header: 'Site' },
      { field: 'assignee', header: 'Assignee' },
      {
        field: 'control_type',
        header: 'Control Type',
        options: controlTypeOptions,
      },
      { field: null, header: 'Confirmation Status' },
      { field: null, header: 'Actions' },
    ];
    if (this.parentView !== 'agg') {
      _.remove(this.nodeListColumns, o => {
        return o.header === 'Portfolio';
      });
      _.remove(this.optedOutColumns, o => {
        return o.header === 'Portfolio';
      });
    }
    this._selectedColumns = this.nodeListColumns;
    this._selectedOptedOutColumns = this.optedOutColumns;
  }

  //region Component interactions
  onPaginate(){
    this.fetchNodes();
  }

  onFilter() {
    this.fetchNodes();
  }
  //endregion

  //region Data fetchin'
  fetchNodes() {
    let separateOptedOut = false;
    let model = this.nodeListFilter?.modelToSend ? this.nodeListFilter.modelToSend : new NodeFilterModel();

    if (this.parentView == 'agg') {
      _.pull(model.event_node_status, 'opted_out');
    }
    if (this.parentView == 'detail') {
      separateOptedOut = true;
    }
    let currentPage = this.paginator?.currentPage || 0;
    let pageSize = this.paginator?.pageSize || 50;

    this.PNS.getNodes(model, currentPage, pageSize, false, separateOptedOut)
  }

  getOptedInNodes(){
    return this.NFS.getOptedInNodes(this.groupedNodes);
  }

  handleNewNodesList(gn){
    if(!!gn){
      //console.log(gn)
      this.groupedNodes = gn;
      if (this.PNS.paginationMeta) this.paginationMeta = this.PNS.paginationMeta;
      this.setNodes();
      this.showNotify = this.shouldShowNotifyButton(this.eventEnd);
    }
  }


  setNodes(){
    this.setButtons()
    this.setPerformanceColor()

    //WE ABSOLUTELY CANNOT NEED TO REFRESH THE OFFERS FOR THE WHOLE PRODUCT EACH TIME A NODE HAS AN UPDATE
    //this.getCustomerOffers();

    this.updateInSelected(this.groupedNodes.activeNodes);
  }

  setButtons(){
    const controller = this;

    let numCanActivate: number = 0;
    let numCanEnd: number = 0;
    let numCanOptIn: number = 0;
    let numCanOverride: number = 0;
    let numCanOptOut: number = 0;
    let numCanCancel: number = 0;
    let numCanConfirm: number = 0;

    this.showBulkEnd = false;
    this.showBulkActivate  = false;
    this.showBulkRecurtail = false;
    this.showBulkConfirmation = false;
    this.showBulkOptIn = false;
    this.showBulkOverrideControl = false;
    this.showBulkCancel = false;
    this.showBulkOptOut = false;

    this.groupedNodes.optedOutNodes.forEach(node => {
      if (!this.cachedEventNodeData[node.event_node_id]) {
        this.EMS.get('/v1/registration/' + node.registration_id, {})
          .pipe()
          .subscribe(
            resp => {
              controller.cachedEventNodeData[node.event_node_id] = {
                registration: resp,
              };
              node.locked_out = resp.locked_out;
              if (!node.locked_out) {
                numCanOptIn++;
                if (numCanOptIn > 1) this.showBulkOptIn = true;
              }
            },
            err => {
              console.log(err);
            },
          );
      } else {
        node.locked_out = controller.cachedEventNodeData[node.event_node_id]['registration'].locked_out;
        if (!node.locked_out) {
          numCanOptIn++;
          if(numCanOptIn > 1)
            this.showBulkOptIn = true;
        }
      }
    })

    this.groupedNodes.activeNodes.forEach(node => {
      //Don't go negative - that means we're performing well and it might be confusing to see a negative number in the table
      if(node.shortfall < 0)
        node.shortfall = 0;

      //Add up our nodes that can be ended or activated
      if (
        moment(node.event_node_start_dttm_utc).isBefore(moment()) &&
        (!node.event_node_end_dttm_utc || moment(node.event_node_end_dttm_utc).isAfter(moment()))
      )
        numCanEnd++;
      if (node.workflow_status === 'RESTORE_COMPLETE') numCanActivate++;

      if (
        node.workflow_status !== 'WORKFLOW_COMPLETE' &&
        (node.control_type === 'BINARY' || node.control_type === 'GRADED') &&
        moment(node.curtail_time).isAfter(moment())
      ) {
        numCanOverride++;
      }

      if (node.workflow_status !== 'WORKFLOW_COMPLETE' && moment(node.event_node_end_dttm_utc).isBefore(moment())) {
        numCanOptOut++;
      }

      if (node.workflow_status !== 'WORKFLOW_COMPLETE' && moment(node.event_node_start_dttm_utc).isAfter(moment())) {
        numCanCancel++;
      }
      if (
        (!node.confirmed || node.confStatus === 'Unconfirmed') &&
        (!node.event_node_end_dttm_utc || moment(node.event_node_end_dttm_utc).isAfter(moment()))
      ) {
        numCanConfirm++;
      }
    });

    this.groupedNodes.pendingNodes.forEach(node => {
      if (this.isNodeSelectableForConfirmation(node)) {
        numCanConfirm++;
      }
    });

    //If we can end more than 2 nodes, enable the bulk end button
    this.showBulkEnd = numCanEnd > 1;
    //Add the total number of pending nodes since those can all be activated. We only counted "RESTORE_COMPLETE" nodes above
    this.showBulkActivate = this.groupedNodes.pendingNodes.length > 1;

    this.showBulkRecurtail = numCanActivate > 1;
    this.showBulkOverrideControl = numCanOverride > 1;
    this.showBulkOptOut = numCanOptOut > 1;
    this.showBulkCancel = numCanCancel > 1;
    this.showBulkConfirmation = numCanConfirm > 1;
  }


  updateNodes(msg){
    this.NFS.updateNode(msg);
    this.setButtons();
    this.setPerformanceColor();

  }

  getRefData$(): Observable<any> {
    const workFlowStatuses$ = this.RDS.getWorkflowStatuses().pipe(
      map(resp => {
        return resp;
      }),
      catchError(err => {
        console.log(err);
        return from(new Promise(resolve => resolve(true)));
      }),
    );
    const templates$ = this.RDS.getAllTemplates().pipe(
      map(resp => {
        return resp;
      }),
      catchError(err => {
        console.log(err);
        return from(new Promise(resolve => resolve(true)));
      }),
    );
    const regTypes$ = this.RDS.getRegistrationTypes().pipe(
      map(resp => {
        return resp;
      }),
      catchError(err => {
        console.log(err);
        return from(new Promise(resolve => resolve(true)));
      }),
    );
    const controlTypes$ = this.RDS.getControlTypes().pipe(
      map(resp => {
        return resp;
      }),
      catchError(err => {
        console.log(err);
        return from(new Promise(resolve => resolve(true)));
      }),
    );

    return forkJoin([workFlowStatuses$, templates$, regTypes$, controlTypes$]);
  }

  getCustomerOffers() {
    let startTime;
    const now = moment().utc();
    //TODO: Redo start time based on product tz and product.schedule.offer_frequency + frequency_unit, to calculate offer start+end for current now
    startTime = now.toISOString();
    const controller = this;
    let productID = null;

    if (controller.product != null) {
      productID = controller.product.id;
    } else if (controller.events != null && controller.events.length > 0) {
      productID = controller.events[0].product_id;
    }

    if (productID != null) {
      this.EMS.get('/v1/customer_offers/', {
        product_id: productID,
        start_dttm: startTime,
      })
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          response => {
            this.customerOffers = response.data;
            if (this.customerOffers.length) {
              const thirtySeconds = 30000;
              //TODO: scheduled refresh should be based off of above calculated current offer end
              const timeToEnd = moment.duration(moment(this.customerOffers[0].offer_end_dttm_utc).diff(moment().utc())).as('milliseconds');
              this.gotInitialOffers = true;
              this.offersTimeOut = setTimeout(() => {
                controller.getCustomerOffers();
              }, timeToEnd + thirtySeconds);
            }
          },
          error => {
            console.dir('Error getting Customer Offer for product: ' + controller.product.id);
            console.dir(error);
            this.customerOffers = [];
          },
        );
    } else {
      console.dir('Unable to get customer offers while waiting for product_id');
      this.customerOffers = [];
    }
  }

  //endregion

  setPerformanceColor() {
    if (this.groupedNodes.activeNodes && this.groupedNodes.activeNodes.length && this.product) {
      const prod = this.product;
      const errorThreshold = prod.performance_error_threshold || 100;
      const warningThreshold = prod.performance_warning_threshold || 105;

      this.groupedNodes.activeNodes.forEach(node => {
        const value = node.last_current_performance_percentage;
        if (!value) {
          node.perf_color = 'rgb(158, 158, 158)';
        } else if (value <= errorThreshold)
          //red
          node.perf_color = 'rgb(243, 60, 77)';
        else if (value > errorThreshold && value < warningThreshold)
          //yellow
          node.perf_color = 'rgb(255, 231, 1)';
        else if (value >= warningThreshold)
          //green
          node.perf_color = 'rgb(0, 207, 18)';
        else node.perf_color = 'rgb(89,188,95)';
      });
    }
  }

  //region Node selections
  resetNodeSelections():void {
    this.resetActiveSelections();
    this.resetOptedOutSelections();
  }

  resetOptedOutSelections() {
    this.selectedOptedOutNodes = null;
  }

  resetActiveSelections() {
    this.selectedActiveNodes = [];
  }

  isRowSelectable(row: any): boolean {
    return this.isNodeSelectable(row.data);
  }

  isNodeSelectable(node: EventNode): boolean {
    const cacheKey = `${this.bulkActionType}_${node.event_node_id}`; // Use a unique property of the node for memoization

    if (this.memoized.has(cacheKey)) {
      return this.memoized.get(cacheKey)!;
    }

    const now = moment(); // Cache the current moment instance for reuse
    const bulkAction = this.bulkActionType;

    const { optIn, addOptedOutNote, assignOptedOut, assign, addNote, notify, overrideControl, optOut, cancel, confirmation, deactivate, activate, recurtail } = this.BulkActionType;

    let isSelectable = false;

    if (bulkAction === assignOptedOut || bulkAction === addOptedOutNote || bulkAction === assign || bulkAction === addNote) {
      isSelectable = true;
    } else if (bulkAction === optIn) {
      isSelectable = !node.locked_out || bulkAction === addOptedOutNote || bulkAction === assignOptedOut;
    } else if (bulkAction === notify) {
      isSelectable = this.isNodeSelectableForNotify(node);
    } else if (bulkAction === overrideControl) {
      isSelectable = (node.control_type === 'BINARY' || node.control_type === 'GRADED') &&
                     now.isBefore(moment(node.curtail_time)) &&
                     node.workflow_status !== 'WORKFLOW_COMPLETE';
    } else if (bulkAction === optOut) {
      isSelectable = now.isAfter(moment(node.event_node_end_dttm_utc)) &&
                     node.workflow_status !== 'WORKFLOW_COMPLETE';
    } else if (bulkAction === cancel) {
      isSelectable = node.workflow_status !== 'WORKFLOW_COMPLETE' &&
                     node.eventNodeStatusForSort.toLowerCase() === 'active';
    } else if (bulkAction === confirmation) {
      isSelectable = this.isNodeSelectableForConfirmation(node);
    } else if (bulkAction === deactivate) {
      isSelectable = now.isAfter(moment(node.event_node_start_dttm_utc)) &&
                     (!node.event_node_end_dttm_utc || now.isBefore(moment(node.event_node_end_dttm_utc))) &&
                     node.eventNodeStatusForSort.toLowerCase() === 'active';
    } else if (bulkAction === activate && node.workflow_status === 'PENDING') {
      isSelectable = true;
    } else if (bulkAction === recurtail && node.workflow_status === 'RESTORE_COMPLETE') {
      isSelectable = true;
    }

    // Cache the result for this node and bulk action type
    this.memoized.set(cacheKey, isSelectable);

    return isSelectable;
  }

  isNodeSelectableForNotify(node: EventNode) {
    // 2336 - Current logic to consider a node active: since event creation until event node end time + 1 hr
    const eventStatus = node.eventNodeStatusForSort.toLowerCase();
    if (eventStatus === 'pending') {
      return true;
    }
    const eventNodeEnd = node.event_node_end_dttm_utc;
    if (eventStatus === 'active') {
      if (eventNodeEnd === null) {
        return true;
      }
      const eventNodePlusOneHour = moment(eventNodeEnd).add(1, 'hour');
      return moment().isBefore(eventNodePlusOneHour);
    }
    return false;
  }

  isNodeSelectableForConfirmation(node: EventNode): boolean {
    const eventNodeEnd = node.event_node_end_dttm_utc;
    const eventEnd = this.eventDatesMap[node.event_id]?.event_end_dttm_utc;
    if (!node.confirmed || node.confStatus === 'Unconfirmed') {
      if (!eventNodeEnd) {
        return !eventEnd || moment(eventEnd).isAfter(moment());
      }
      return moment(eventNodeEnd).isAfter(moment());
    }
    return false;
  }

  isSelectedInGrid(node: EventNode): boolean {
    if (!this.isNodeSelectable(node)) {
      return false;
    }
    const filteredActive = this.activeTable ? this.activeTable.filteredValue || this.activeTable.value : [];
    const filteredPassive = this.optedOutTable ? this.optedOutTable.filteredValue || this.optedOutTable.value : [];
    const selectedActive = this.selectedActiveNodes;
    const selectedPassive = this.optedOutTable?.selection || [];
    const selectedIds = _.map([...selectedActive, ...selectedPassive], 'event_node_id');
    const filteredIds = _.map([...filteredActive, ...filteredPassive], 'event_node_id');
    return selectedIds.includes(node.event_node_id) && filteredIds.includes(node.event_node_id);
  }

  onRemoveSelected(selected) {
    const arr = [...this.selectedActiveNodes];
    const arrWithoutSelected = _.without(arr, selected);
    this.selectedActiveNodes = arrWithoutSelected;
  }

  updateInSelected(nodes){
    let arr = JSON.parse(JSON.stringify(this.selectedActiveNodes));
    nodes.forEach(node => {
      let found = _.find(arr, ['event_node_id', node.event_node_id]);
      if (found) {
        found = Object.assign({}, found, node);
      }
    })
    this.selectedActiveNodes = arr;
  }

  //endregion

  //region click handlers
  clickAddNote(): void {
    this.selectingNodes = true;
    this.bulkActionType = this.BulkActionType.addNote;
  }

  clickAssign(): void {
    this.selectingNodes = true;
    this.bulkActionType = this.BulkActionType.assign;
  }

  clickOptedOutAssign(): void {
    this.selectingNodes = true;
    this.bulkActionType = this.BulkActionType.assignOptedOut;
  }

  clickOptedOutAddNote(): void {
    this.selectingNodes = true;
    this.bulkActionType = this.BulkActionType.addOptedOutNote;
  }

  clickBulkActivate(): void {
    this.selectingNodes = true;
    this.bulkActionType = this.BulkActionType.activate;
  }

  clickBulkRecurtail(): void {
    this.selectingNodes = true;
    this.bulkActionType = this.BulkActionType.recurtail;
  }

  clickBulkConfirmation(): void {
    this.selectingNodes = true;
    this.bulkActionType = this.BulkActionType.confirmation;
  }

  clickBulkEnd(): void {
    this.selectingNodes = true;
    this.bulkActionType = this.BulkActionType.deactivate;
  }

  clickNotify(): void {
    this.selectingNodes = true;
    this.bulkActionType = this.BulkActionType.notify;

  }

  clickBulkOptIn(): void {
    this.selectingNodes = true;
    this.bulkActionType = this.BulkActionType.optIn;
  }

  clickBulkOverrideControl(): void {
    this.selectingNodes = true;
    this.bulkActionType = this.BulkActionType.overrideControl;
  }

  clickBulkOptOut(): void {
    this.selectingNodes = true;
    this.bulkActionType = this.BulkActionType.optOut;
  }

  clickBulkCancel(): void {
    this.selectingNodes = true;
    this.bulkActionType = this.BulkActionType.cancel;
  }

  cancelBulkAction():void {
    this.resetNodeSelections();
    this.selectingNodes = false;
    this.bulkActionType = this.BulkActionType.none;
  }
  //endregion

  //region Bulk methods

  bulkOverrideControl(){
    this.bulkOverrideNodes = [];
    this.groupedNodes.activeNodes.forEach(node => {
      if (this.isSelectedInGrid(node)) this.bulkOverrideNodes.push(node.event_node_id);
    });

    if (!this.bulkOverrideNodes.length) {
      this.SS.popError('You must select at least one event node!');
    } else {
      this.BS.overrideControl(this.bulkOverrideNodes)
        .then(resolved => {
          this.cancelBulkAction();
        })
        .catch(rejected => {
          this.cancelBulkAction();
        }
      )
    }
  }

  bulkOptOut(){

  }

  bulkCancel(){
    this.bulkCancelNodes = [];
    this.groupedNodes.activeNodes.forEach(node => {
      if (this.isSelectedInGrid(node)) this.bulkCancelNodes.push(node.event_node_id);
    });

    if (!this.bulkCancelNodes.length) {
      this.SS.popError('You must select at least one event node!');
    } else {
      this.BS.cancelNodes(this.bulkCancelNodes)
        .then(resolved => {
          this.cancelBulkAction();
        })
        .catch(rejected => {
          this.cancelBulkAction();
        }
      )
    }
  }

  bulkOptIn(): void {
    const controller = this;

    this.bulkOptInNodes = [];

    this.groupedNodes.optedOutNodes.forEach(node => {
      if (this.isSelectedInGrid(node)) {
        controller.bulkOptInNodes.push(node.event_node_id);
      }
    });

    if (this.bulkOptInNodes.length) {

      this.SS.activateModal({
        headerText: 'Bulk Opt In Nodes',
        bodyText: 'Opting In ' + this.bulkOptInNodes.length + ' event nodes.',
        allowCancel: true,
        confirmFunction: function () {
          controller.EMS.put('/v1/opt_in', controller.bulkOptInNodes, {})
            .pipe(takeUntil(controller.ngUnsubscribe))
            .subscribe(
              response => {
                controller.cancelBulkAction();
                controller.SS.popSuccess('Successfully opted in Event Nodes. Updates may take up to 1 minute to display on this page.');
              },
              error => {
                controller.SS.popError('Failed to opt in Event Nodes!');
                console.dir(error);
                controller.cancelBulkAction();
              },
            );
        },
      });
    } else {
      controller.SS.popError('Must select at least one event node!');
    }
  }

  bulkActivate(): void {
    if (!this.groupedNodes.pendingNodes.length) {
      this.SS.popError('There are no pending nodes!');
      return;
    }

    const { eventNodes, hasDifferentEndTimes, maximumAllowedEndTime } = this.getBulkOperationParameters('activate');

    if (!eventNodes.length) {
      this.SS.popError('Must select at least one event node!');
      return;
    }
    let timePickerDataStart = new ModalDatePickerData(null, 'Start', this.eventStart, maximumAllowedEndTime, 0, true, false, true, false);
    let timePickerDataEnd = new ModalDatePickerData(
      maximumAllowedEndTime,
      'End',
      this.eventStart,
      maximumAllowedEndTime,
      0,
      false,
      true,
      false,
      !this.eventEnd,
    );

    this.SS.activateModal({
      headerText: 'Bulk Activate Nodes',
      bodyText: 'Activating ' + eventNodes.length + ' event nodes.',
      customContent: new CustomModalWrapper(EventNodeBulkActivateModalComponent, {
        program_time_zone: this.product.timezone,
        timePickerData: [timePickerDataStart, timePickerDataEnd],
        buttonText: 'Bulk Start',
        operation: 'activate',
        isAggregate: this.parentView === 'agg',
        event_nodes: eventNodes,
        hasDifferentEndTimes: hasDifferentEndTimes,
        eventEnd: maximumAllowedEndTime,
        confirmFunction: times => {
          this.EMS.put(
            '/v1/activate',
            {
              event_nodes: eventNodes.map(n => n.event_node_id),
              start_time: times[0],
              end_time: times[1],
            },
            {},
          )
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
              response => {
                this.cancelBulkAction();
                this.SS.popSuccess('Successfully activated Event Nodes. Updates may take up to 1 minute to display on this page.');
              },
              error => {
                console.dir(error);
                this.SS.popError('Error activating event nodes');
                this.cancelBulkAction();
              },
            );
        },
      }),
    });
  }

  private getMaximumEventEndTime(maximumAllowedEndTime: string, currEventEndTime: string): string {
    if (currEventEndTime === null) {
      return maximumAllowedEndTime;
    }
    if (maximumAllowedEndTime === null) {
      return currEventEndTime;
    }
    if (moment(currEventEndTime).isBefore(moment(maximumAllowedEndTime))) {
      return currEventEndTime;
    }
    return maximumAllowedEndTime;
  }

  bulkRecurtail(): void {
    const controller = this;

    this.bulkRecurtailNodes = [];

    this.groupedNodes.activeNodes.forEach(node => {
      if (this.isSelectedInGrid(node)) {
        controller.bulkRecurtailNodes.push(node.event_node_id);
      }
    });

    //Only show the modal if we have nodes selected
    if (this.bulkRecurtailNodes.length) {
      let timePickerDataStart = new ModalDatePickerData(null, 'Start', this.eventStart, this.eventEnd, 0, true, false, true, false);

      this.SS.activateModal({
        headerText: 'Bulk Re-Activate Nodes',
        bodyText: 'Re-Activating ' + this.bulkRecurtailNodes.length + ' event nodes.',
        customContent: new CustomModalWrapper(TimestampModalComponent, {
          program_time_zone: controller.product.timezone,
          timePickerData: [timePickerDataStart],
          buttonText: 'Bulk Re-Activate',
          confirmFunction: function (times) {
            //Recurtail
            let recurtailBody = [];

            //Set up the request body
            controller.bulkRecurtailNodes.forEach(node => {
              recurtailBody.push({
                event_node_id: node,
                curtail_time: times[0]
              })
            });

            controller.EMS.put('/v1/recurtail', recurtailBody, {})
              .pipe(takeUntil(controller.ngUnsubscribe))
              .subscribe(
                response => {
                  controller.cancelBulkAction();
                  controller.SS.popSuccess('Successfully activated Event Nodes. Updates may take up to 1 minute to display on this page.');
                },
                error => {
                  console.dir(error);
                  controller.SS.popError('Error re-activating event nodes');
                  controller.cancelBulkAction();
                },
              );
          },
        }),
      });
    } else {
      controller.SS.popError('Must select at least one event node!');
    }
  }

  bulkConfirmation(): void {
    let controller = this;
    const bulkConfirmationNodes = this.groupedNodes.activeNodes
      .concat(this.groupedNodes.pendingNodes)
      .filter(node => this.isSelectedInGrid(node))
      .map(node => node.event_node_id);

    if (!bulkConfirmationNodes.length) {
      return;
    }
    this.SS.activatePasswordModal({
      headerText: 'Confirm',
      bodyText: 'This action will confirm the participation of the event nodes selected. Proceed?',
      buttonText: 'Confirm',
      allowCancel: true,
      password: 'ennel1',
      confirmFunction: function () {
        const serviceURL = '/v1/event_node_workflows';
        const payload = [
          {
            event_nodes: bulkConfirmationNodes,
            attributes: {
              confirmed: moment().toISOString(),
            },
          },
        ];

        controller.EMS.put(serviceURL, payload, {})
          .pipe(takeUntil(controller.ngUnsubscribe))
          .subscribe({
            next: () => {
              controller.cancelBulkAction();
              controller.SS.popSuccess('Successfully confirmed events. Updates may take up to 1 minute to display on this page.');
            },
            error: error => {
              controller.SS.popError('Failed to confirm event.');
              console.dir(error);
              controller.cancelBulkAction();
            },
          });
      },
    });
  }

  assign() {
    const controller = this;

    this.bulkAssignNodes = [];

    if (this.bulkActionType === this.BulkActionType.assignOptedOut) {
      this.groupedNodes.optedOutNodes.forEach(node => {
        if (this.isSelectedInGrid(node)) {
          controller.bulkAssignNodes.push(node.event_node_id);
        }
      });

    } else {
      this.groupedNodes.activeNodes.forEach(node => {
        if (this.isSelectedInGrid(node)) {
          controller.bulkAssignNodes.push(node.event_node_id);
        }
      });

      this.groupedNodes.pendingNodes.forEach(node => {
        if (this.isSelectedInGrid(node)) {
          controller.bulkAssignNodes.push(node.event_node_id);
        }
      });

      this.groupedNodes.completedNodes.forEach(node => {
        if (this.isSelectedInGrid(node)) {
          controller.bulkAssignNodes.push(node.event_node_id);
        }
      });

      this.groupedNodes.excludedNodes.forEach(node => {
        if (this.isSelectedInGrid(node)) {
          controller.bulkAssignNodes.push(node.event_node_id);
        }
      });

      this.groupedNodes.cancelledNodes.forEach(node => {
        if (this.isSelectedInGrid(node)) {
          controller.bulkAssignNodes.push(node.event_node_id);
        }
      });
    }


    if(this.bulkAssignNodes.length) {
      this.SS.activateModal({
        headerText: 'Assign',
        bodyText: 'Changing assignee for ' + this.bulkAssignNodes.length + ' event nodes.',
        customContent: new CustomModalWrapper(AssignModalComponent, {
          allUsers: this.possibleAssignees,
          buttonText: 'Assign',
          confirmFunction: function (user_id) {
            controller.EMS.put(
              '/v1/event_nodes/assignee',
              {
                event_nodes: controller.bulkAssignNodes,
                assignee: user_id,
              },
              {},
            )
              .pipe(takeUntil(controller.ngUnsubscribe))
              .subscribe(
                response => {
                  controller.cancelBulkAction();
                  controller.SS.popSuccess('Successfully updated assignee. Updates may take up to 1 minute to display on this page.');
                },
                error => {
                  controller.SS.popError('Failed to update assignee!');
                  console.dir(error);
                  controller.cancelBulkAction();
                },
              );
          },
        }),
      });
    } else {
      controller.SS.popError('Must select at least one event node!');
    }
  }

  autoAssign(){
    const controller = this;
    const user = this.US.user;
    const body = {
      username: user.username,
      event_start_time: this.events[0].event_start_dttm_utc,
      event_ids: _.map(this.events, 'event_id'),
    };
    this.EMS.post('/v1/event_nodes/auto_assign', body, {})
      .pipe()
      .subscribe(
        response => {
          const num = response.data.length;
          let msg = num > 0 ? num + ' Registration(s) have been assigned to your user' : 'There are no additional registrations to assign';
          controller.toastr.success(msg);
        },
        error => {
          controller.toastr.error('Error assigning registrations');
          console.log(error);
        },
      );
  }

  addNote(){
    const controller = this;

    this.bulkNoteNodes = [];

    if (this.bulkActionType === this.BulkActionType.addOptedOutNote) {
      this.groupedNodes.optedOutNodes.forEach(node => {
        if (this.isSelectedInGrid(node)) {
          controller.bulkNoteNodes.push({
            event_node_id: node.event_node_id,
            registration_id: node.registration_id,
          });
        }
      });

    } else {
      this.groupedNodes.activeNodes.forEach(node => {
        if (this.isSelectedInGrid(node)) {
          controller.bulkNoteNodes.push({
            event_node_id: node.event_node_id,
            registration_id: node.registration_id,
          });
        }
      });

      this.groupedNodes.pendingNodes.forEach(node => {
        if (this.isSelectedInGrid(node)) {
          controller.bulkNoteNodes.push({
            event_node_id: node.event_node_id,
            registration_id: node.registration_id,
          });
        }
      });

      this.groupedNodes.completedNodes.forEach(node => {
        if (this.isSelectedInGrid(node)) {
          controller.bulkNoteNodes.push({
            event_node_id: node.event_node_id,
            registration_id: node.registration_id,
          });
        }
      });

      this.groupedNodes.excludedNodes.forEach(node => {
        if (this.isSelectedInGrid(node)) {
          controller.bulkNoteNodes.push({
            event_node_id: node.event_node_id,
            registration_id: node.registration_id,
          });
        }
      });

      this.groupedNodes.cancelledNodes.forEach(node => {
        if (this.isSelectedInGrid(node)) {
          controller.bulkNoteNodes.push({
            event_node_id: node.event_node_id,
            registration_id: node.registration_id,
          });
        }
      });
    }


    if(this.bulkNoteNodes.length) {
      this.SS.activateModal({
        headerText: 'Add Note',
        bodyText: 'Adding a note to ' + this.bulkNoteNodes.length + ' event nodes.',
        customContent: new CustomModalWrapper(CreateNoteModalComponent, {
          buttonText: 'Add Note',
          caseTypes: controller.caseTypes,
          confirmFunction: function (note) {
            controller.EMS.post(
              '/v1/notes',
              {
                event_nodes: controller.bulkNoteNodes,
                notes: [note],
              },
              {},
            )
              .pipe(takeUntil(controller.ngUnsubscribe))
              .subscribe(
                response => {
                  controller.cancelBulkAction();
                  controller.SS.popSuccess('Successfully added note. Updates may take up to 1 minute to display on this page.');
                },
                error => {
                  controller.SS.popError('Failed to add note!');
                  console.dir(error);
                  controller.cancelBulkAction();
                },
              );
          },
        }),
      });
    } else {
      controller.SS.popError('Must select at least one event node!');
    }
  }


  getBulkOperationParameters(operation) {
    const groupedNodeCategory = operation === 'end' ? 'activeNodes' : 'pendingNodes';
    const eventNodes = [];
    let hasDifferentEndTimes = false;
    let firstNode = null;
    let lastCheckedParent = {} as any;
    let maximumAllowedEndTime = null;
    _.get(this.groupedNodes, groupedNodeCategory, [])
      .forEach(node => {
        if (this.isSelectedInGrid(node)) {
          if (!firstNode) {
            firstNode = node;
            lastCheckedParent = this.eventsMap[firstNode.event_id];
          }
          const eventNodeParent: EPSEvent = this.eventsMap[node.event_id];
          if (lastCheckedParent.event_end_dttm_utc === undefined) {
            lastCheckedParent.event_end_dttm_utc = null;
          }
          if (lastCheckedParent.event_end_dttm_utc !== eventNodeParent.event_end_dttm_utc) {
            hasDifferentEndTimes = true;
          }
          maximumAllowedEndTime = this.getMaximumEventEndTime(maximumAllowedEndTime, eventNodeParent.event_end_dttm_utc);
          const eventNodeBulkOperation: EventNodeBulkOperation = {
            event_node_id: node.event_node_id,
            event_start_time: eventNodeParent.event_start_dttm_utc,
            event_end_time: eventNodeParent.event_end_dttm_utc,
            operation: operation,
          };
          eventNodes.push(eventNodeBulkOperation);
        }
      });
    if (eventNodes.length < 2) {
      hasDifferentEndTimes = false;
      maximumAllowedEndTime = eventNodes[0].event_end_time;
    }
    return {
      eventNodes: eventNodes,
      hasDifferentEndTimes: hasDifferentEndTimes,
      maximumAllowedEndTime: maximumAllowedEndTime,
    };
  }

  bulkEnd(): void {
    const controller = this;

    this.bulkEndNodes = [];

    if (!this.groupedNodes.activeNodes.length) {
      controller.SS.popError('There are no active nodes!');
      return;
    }

    const { eventNodes, hasDifferentEndTimes, maximumAllowedEndTime } = this.getBulkOperationParameters('end');

    if (!eventNodes.length) {
      controller.SS.popError('Must select at least one event node!');
      return;
    }

    let timePickerDataEnd = new ModalDatePickerData(maximumAllowedEndTime, 'End', controller.eventStart, maximumAllowedEndTime, 0, false, true, true, false);

    this.SS.activateModal({
      headerText: 'Bulk End Nodes',
      bodyText: 'Ending ' + eventNodes.length + ' event nodes.',
      customContent: new CustomModalWrapper(EventNodeBulkEndModalComponent, {
        program_time_zone: controller.product.timezone,
        timePickerData: [timePickerDataEnd],
        event_nodes: eventNodes,
        buttonText: 'Bulk End',
        operation: 'end',
        hasDifferentEndTimes: hasDifferentEndTimes,
        eventEnd: maximumAllowedEndTime,
        isAggregate: this.parentView === 'agg',
        confirmFunction: function (times, node_ids) {
          controller.EMS.put(
            '/v1/schedule_end',
            {
              event_nodes: node_ids,
              end_time: times[0],
              product_id: controller.product.id,
            },
            {},
          )
            .pipe(takeUntil(controller.ngUnsubscribe))
            .subscribe(
              () => {
                controller.cancelBulkAction();
                controller.SS.popSuccess('Successfully ended Event Nodes. Updates may take up to 1 minute to display on this page.');
              },
              error => {
                controller.SS.popError('Failed to end Event Node!');
                console.dir(error);
                controller.cancelBulkAction();
              },
            );
        },
      }),
    });
  }

  notify() {
    let notificationsToSend;
    const controller = this;
    const isAggregate = this.parentView === 'agg';
    const timePickerData = this.createNotifyDatePickerData(isAggregate);

    if (!controller.selectedActiveNodes.length) {
      this.SS.activateModal({
        headerText: 'Send Notifications',
        bodyText: 'You must select at least one event node to continue.',
        buttonText: 'Continue',
        allowCancel: false,
        confirmFunction: function () {
        }
      });
      return;
    }

    this.SS.activateModal({
      headerText: 'Send Notifications',
      customContent: new CustomModalWrapper(NotifyModalComponent, {
        style: {
          width: '50%',
          'mid-width': '450px',
          'min-height': '1000px;',
        },
        program_time_zone: controller.product.timezone,
        timePickerData: timePickerData,
        event_start: controller.events[0].event_start_dttm_utc,
        event_end: controller.eventEnd,
        notificationPaths: controller.notificationPaths,
        notificationTemplates: controller.templates,
        selectedNodes: controller.selectedActiveNodes,
        allNodesLength: controller.paginationMeta.total_items,
        buttonText: 'Save',
        cancelText: 'Cancel',
        allowCancel: true,
        isAggregate: isAggregate,
        confirmFunction: function (notificationBody) {
          setTimeout(() => {
            notificationsToSend = notificationBody;
            controller.SS.activateModal({
              headerText: 'Send Notifications',
              bodyText: '',
              buttonText: 'Continue',
              cancelText: 'Cancel',
              allowCancel: true,
              password: 'ennel1',
              confirmFunction: function () {
                controller.EMS.post('/v1/custom_task', notificationsToSend, {})
                  .pipe(takeUntil(controller.ngUnsubscribe))
                  .subscribe(
                    response => {
                      controller.cancelBulkAction();
                      controller.SS.popSuccess('Successfully added Notifications.');
                    },
                    error => {
                      controller.cancelBulkAction();
                      controller.SS.popError('Failed to add Notifications!');
                      console.dir(error);
                    },
                  );
              },
            });
          });
        },
      }),
    });
  }
//endregion


  private createNotifyDatePickerData(isAggregate: boolean) {
    const defaultDate = null;
    const actionName = 'Notify';
    let minDate = isAggregate ? moment().utc() : moment().utc().subtract('15', 'minutes');
    minDate = minDate.clone().seconds(0).milliseconds(0);
    const maxDate = moment(this.eventEnd).utc().add('60', 'minutes');
    const maxDuration = 0;
    const minDateInclusive = false;
    const maxDateInclusive = true;
    const hasNowBox = true;
    const allowNull = false;
    if (this.eventEnd) {
      return new ModalDatePickerData(
        defaultDate,
        actionName,
        minDate.toISOString(),
        maxDate.toISOString(),
        maxDuration,
        minDateInclusive,
        maxDateInclusive,
        hasNowBox,
        allowNull,
      );
    }
    return new ModalDatePickerData(
      defaultDate,
      actionName,
      minDate.toISOString(),
      maxDate.add(this.product.max_event_duration, 'ms').toISOString(),
      maxDuration,
      minDateInclusive,
      maxDateInclusive,
      hasNowBox,
      allowNull,
    );
  }
  //endregion

  isAcceptableBulkAction(): boolean {
    return this.bulkActionType !== this.BulkActionType.optIn && this.bulkActionType !== this.BulkActionType.addOptedOutNote && this.bulkActionType !== this.BulkActionType.assignOptedOut;
  }

  isSelectingAndAcceptableBulkAction(): boolean {
    return this.selectingNodes && this.isAcceptableBulkAction();
  }
}
