import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-reg-list-modal',
  templateUrl: './reg-list-modal.component.pug',
  styleUrls: ['./reg-list-modal.component.scss']
})
export class RegListModalComponent implements OnInit {

  @Input() data:any;

  registrations;

  constructor() { }

  ngOnInit(): void {
  }


}
