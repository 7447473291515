import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-send-templates-modal',
  templateUrl: './send-templates-modal.component.pug',
  styleUrls: ['./send-templates-modal.component.scss']
})
export class SendTemplatesModalComponent implements OnInit {
  @Input() data:any;

  sendCancellationTemplate;
  willReceive = 0;
  notifyNodes = {};

  clickedConfirm = null;

  allowCancel = true;
  headerText = null;
  bodyText = null;
  buttonText = null;
  cancelText = null;
  closeFn = null;
  passwordRequired = 'ennel1';
  passwordEntered = '';
  warningText = '';

  constructor() { }

  ngOnInit(): void {
    this.bodyText = this.data.bodyText;
    this.buttonText = this.data.buttonText;
    this.willReceive = this.data.willReceive;
  }

  close() {
    this.data.close()
  }
  confirm() {
    if(!this.passwordRequired || this.passwordRequired === this.passwordEntered) {
      this.data.confirmFunction(this.sendCancellationTemplate);
      this.data.close()
    }
  }

}
