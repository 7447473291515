import {Injectable} from "@angular/core";
import {EventManagementService} from "./event-management.service";
import {SharedService} from "./shared.service";
import {from, Observable} from 'rxjs';
import {catchError, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class EntitySearchService {

  entities: any[];

  constructor(private EMS: EventManagementService, private sharedService: SharedService) {
  }

  searchEntites(query: string, types: string[]): Observable<any> {
    return this.EMS.get('/v1/entity_search', {
      'query': query,
      'types': types.join(',')
    }).pipe(
      map((resp) => {
        this.entities = resp.data;
        return this.entities;
      }),
      catchError((err) => {
        this.popError('Error getting Event Types', err);
        return from(new Promise((resolve) => resolve(true)));
      })
    );
  }


  popError(msg: string, err: any) {
    this.sharedService.popError(msg);
    console.dir(msg + ": ");
    console.log(err);
  }
}
