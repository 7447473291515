import { Component, Input, OnInit } from '@angular/core';
import { Subject } from "rxjs/internal/Subject";
import { takeUntil } from 'rxjs/operators';
import { EPSEvent } from '../../classes/event';
import { Notification } from '../../classes/notification';
import { EventManagementService } from '../../services/event-management.service';

@Component({
  selector: 'app-event-notifications',
  templateUrl: './event-notifications.component.pug',
  styleUrls: ['./event-notifications.component.scss']
})
export class EventNotificationsComponent implements OnInit {

  @Input() events: EPSEvent[] = [];
  @Input() timezone: string;
  
  eventIDs: string[];
  notifications: Notification[] = [];
  notifsLoading:boolean = true;

  constructor(private EMS: EventManagementService) {}
  private ngUnsubscribe: Subject<any> = new Subject();

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.eventIDs = this.events.flatMap(event => event.event_id);
    this.timezone = this.events[0].full_time_zone;
    this.EMS.post('/v1/notification_summary/events/', {event_ids: this.eventIDs}, {})
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
        this.notifications = response.data;
        this.notifsLoading = false;
      }, error => {
        if(error.status !== 404) {
          console.dir("Error getting event notifications: ");
          console.dir(error);
        }
        this.notifsLoading = false;
      });
  }

}
