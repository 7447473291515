import { Component, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { EventCreationComponent } from "../../components/event-creation/event-creation.component";
import {SharedService} from "../../services/shared.service";
import {Product} from "../../classes/product";
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';
import { Registration } from '../../classes/registration';
import * as _ from 'lodash';

enum RegistrationStatus {
  selected = 'selected',
  excluded = 'excluded',
  optedOut = 'optedOut'
}
export { RegistrationStatus }


@Component({
  selector: 'tr[registration-row]',
  templateUrl: './registration-row.component.pug',
  styleUrls: ['./registration-row.component.scss']
})
export class RegistrationRowComponent implements OnInit, OnChanges {

  @Input() registration: Registration;
  @Input() portfolio_id: string;
  @Input() infoRow = true;
  @Input() selected = false;
  @Input() optedOut = false;
  @Input() ignoringRegistrationLimits = false;
  @Input() product: Product;
  @Input() registrationStatus: string = '';
  @Input() customerOffers;

  customerOffer;
  clearedOffer;
  startTime:string;

  constructor(public eventsCreationPage: EventCreationComponent, private sharedService:SharedService) { }

  ngOnInit() {
    console.log(this.registration.id)
    console.log('ignoring reg limits: ' + this.ignoringRegistrationLimits);
    this.setCustomerOffers();
    this.registration['duration'] = moment(this.registration['event_node_end_time'])
      .diff(moment(this.registration['event_node_start_time']), 'minutes');
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.customerOffers) this.setCustomerOffers();
  }

  getRemainingMinutes(r) {
    if(r.max_event_duration === null)
      r.max_event_duration = 0;

    if(r.remaining_dispatch_minutes === null)
      r.remaining_dispatch_minutes = 0;

    if(r.remaining_dispatch_minutes >= r.max_event_duration)
      return r.max_event_duration;
    else
      return r.remaining_dispatch_minutes;
  }

  clearCustomerOffers() {
    this.customerOffer = null;
    this.clearedOffer = null;
    this.startTime = null;
  }

  setCustomerOffers() {
    this.clearCustomerOffers();
    let arr = [];
    const co = this.customerOffers ?  _.find(this.customerOffers, ['registration_id', this.registration.id]) : null;
    if(co) {
      this.startTime = co.offer_start_dttm_utc;
      if(co.offer_value && co.offer_value.kW) {
        //customer offer
        this.customerOffer = this.sharedService.convertProductUOM(co.offer_value.kW, this.product, true, 3, true);
      }
      if(co.cleared) {
        if(co.cleared && co.cleared.prices) {
          if(co.cleared.prices.capacity) {
            arr.push('Capacity Price: ' + this.sharedService.convertProductUOM(co.cleared.prices.capacity, this.product, true, 3, true))
          }
          if(co.cleared.prices.energy) {
            arr.push('Energy Price: ' + this.sharedService.convertProductUOM(co.cleared.prices.energy, this.product, true, 3, false) + 'h')
          }
        }

        if(co.cleared && co.cleared.quantities) {
          if(co.cleared.quantities.capacity) {
            arr.push('Capacity Quantity: ' + this.sharedService.convertProductUOM(co.cleared.quantities.capacity, this.product, true, 3, true));
          }
          if(co.cleared.quantities.energy) {
            arr.push('Energy Quantity: ' + this.sharedService.convertProductUOM(co.cleared.quantities.energy, this.product, true, 3, false) + 'h');
          }
        }
      }
      this.clearedOffer = arr;
    }
  }
}
