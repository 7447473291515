
class BaselineConfiguration {
  baseline_adjustment_offset_time: string;
  baseline_adjustment_offset: number;
  baseline_adjustment_duration: number;
  baseline_adjustment_allow_override: boolean;
  allow_current_interval_edit: boolean;

  constructor(){}
}
export { BaselineConfiguration }

class DispatchConfiguration {
  ramping_period: number;
  availability_channel: number;
  performance_channel: number;
  post_bonus_minutes: number;
  include_bonus_minutes:boolean;
  dispatch_instructions: object;
  management_email: string;
  max_events_per_day: number;
  max_events_per_week: number;
  max_events_per_month: number;
  dispatch_instructions_text: string;

  constructor(){}
}
export { DispatchConfiguration }

class ProductSummary {
  event_count_this_month: number = 0;
  event_count_this_season: number = 0;
  event_count_this_week: number = 0;
  event_hours_this_season: number = 0;
  event_minutes_this_season: number = 0;
  constructor(){}
}
export { ProductSummary }

class UiPrez {
  prefered_prez_demand_uom: string;
  event_management_hex_code: string;
  ramp_up: number;
  ramp_down: number;
  hide_cleared_tab: boolean;
  hide_baselines_tab: boolean;
  constructor(){}
}
export { UiPrez }

class Product {
  create_dttm: string;
  default_locale: string;
  display_labels;
  display_label:string;
  dr_type:string;
  id: string;
  program_id: string;
  show: boolean;
  status: string;
  timezone: string;
  update_dttm: string;
  updated_by: string;

  //From COM
  collect_notification_time:boolean;
  max_event_duration: number;
  prez_conf:UiPrez;
  underfrequency_product: boolean;
  selection_type: string;
  default_dispatch_duration:number;
  performance_error_threshold:number;
  performance_warning_threshold:number;
  selection_offer_time:string;
  portfolio_obligation_source:string;
  equipment_interval_frequency: number;
  baseline_configuration: BaselineConfiguration;
  implement_registration_limits:boolean;
  reporting_interval_ms:number;
  performance_target_min;
  performance_target_max;
  target_type;
  bonus_minutes: number;
  key_performance_metric: string;
  dispatch_conf: DispatchConfiguration;
  max_event_hours_per_season: number;
  max_events_per_season: number;
  min_event_duration: number;
  advance_notice: number;
  product_summary: ProductSummary;
  expected_capacity_source: string;

  constructor() {

  }

}

export { Product }
export enum TargetType {DROP_TO = 'DROP_TO', RANGE = 'RANGE', DROP_BY = 'DROP_BY'}
export enum KeyPerformanceMetric {AVERAGE = 'AVERAGE', MINIMUM = 'MINIMUM', MAXIMUM = 'MAXIMUM'}
