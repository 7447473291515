import { Component, OnInit, Input } from '@angular/core';
import {SharedService} from "../../../services/shared.service";
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';
import * as _ from 'lodash';
import {UserService} from "../../../services/user.service";

@Component({
  selector: 'app-create-note-modal',
  templateUrl: './assign-modal.component.pug',
  styleUrls: ['./assign-modal.component.scss']
})
export class AssignModalComponent implements OnInit {

  constructor(private sharedService: SharedService, private userService: UserService) {
  }

  @Input() data:any;

  selectedUser:string;
  enteredUsername: string;
  chosenRadio = 0;

  ngOnInit() {
  }

  public setUser(e): void {
    this.selectedUser = "invalid";
    let user = this.data.allUsers.filter(x => x.username === e.target.value)[0];
    if(user)this.selectedUser = user.username;
  }

  confirm() {
    switch(this.chosenRadio) {
      case 0:
        this.selectedUser = this.userService.user.username;
        break;
      case 2:
        this.selectedUser = null;
        break;

    }

    if(this.selectedUser !== "invalid") {
      this.data.confirmFunction(this.selectedUser);
      this.data.close();
    }
  }

  close() {
    this.data.close();
  }
}
