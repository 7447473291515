import {Directive, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[custom-modal-content]'
})
export class ModalContentWrapperDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}
