import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-err403-page',
  templateUrl: './err403-page.component.pug',
  styleUrls: ['./err403-page.component.scss']
})
export class Err403PageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
