import { Pipe, PipeTransform } from '@angular/core';
import {SharedService} from "../services/shared.service";

@Pipe({
  name: 'uomConversion'
})
export class UOMConversionPipe implements PipeTransform {

  constructor(private sharedService:SharedService){}

  transform(val, product, factionDigits = 3, ignoreHourUnits = false, allowNullReturn = false): any {
    let poo = this.sharedService.convertProductUOM(val, product, true, factionDigits, ignoreHourUnits, allowNullReturn);
    return this.sharedService.convertProductUOM(val, product, true, factionDigits, ignoreHourUnits, allowNullReturn);
  }

}
