import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { MqttService } from "ngx-mqtt";
import {
    UnderfrequencyPayload,
    UnderfrequencyEventService
} from '../../services/underfrequency-event.service';
import { EventManagementService } from "../../services/event-management.service";
import { SharedService } from "../../services/shared.service";
import * as _ from 'lodash';

@Component({
    selector: 'app-underfrequency-list',
    templateUrl: './underfrequency-list.component.pug',
    styleUrls: ['./underfrequency-list.component.scss']
})
export class UnderfrequencyListComponent implements OnInit {
    constructor(
        private listService: UnderfrequencyEventService,
        private toastr: ToastrService,
        private emService: EventManagementService,
        private enutsMqttService: MqttService,
        private sharedService: SharedService,
    ) {}

    loading = false;
    interval: any;
    setPointTimeout;
    unsubscribe: Subject<boolean> = new Subject<boolean>();
    eventNodeIds: string[] = [];
    activeEvents: UnderfrequencyPayload[];
    excludeActiveFields = ['disarm_reason'];

    pendingRegistrations: UnderfrequencyPayload[];
    excludePendingFields = ['last_updated_dttm', 'event_details_link'];
    thingIdToRegIdMap = {};
    cachedSetpoints = {};

    ngOnInit() {
        this.fetchWithRetries();
        this.interval = setInterval(() => this.fetchWithRetries(), 60 * 1000);
    }

    fetchWithRetries(retries=0) {
        // Don't re-initiate if we're already loading
        if (this.loading) return;

        // EMS returns some intermittent 4xx/5xxs, so keep trying
        this.loading = true;
        this.fetchData()
            .catch((err) => {
                if (err && err.statusCode > 400 && retries < 3) {
                    const incremented = retries + 1;
                    setTimeout(() => this.fetchWithRetries(incremented), 1000 * incremented);
                } else {
                    console.log(err);
                    this.toastr.error('Encountered an error fetching registration sets from EMS');
                    this.loading = false;
                }
            });
    }

    fetchData() {
        const active = this.listService.getList().then((data) => this.activeEvents = data);

        return this.listService.getPending().then((data) => {
            // Gotta wait for the active call to finish if it hasn't already
            active.then(() => {
                // Now that we have event nodes
                this.subscribeToMQTT();

                // Then filter out any pending registration sets that are already active
                this.pendingRegistrations = data.filter((row) => (
                    !this.activeEvents.find((activeRow) => activeRow.registration_set_id === row.registration_set_id)
                ));
              const all = [...this.activeEvents, ...this.pendingRegistrations]
              all.forEach((payload) => {
                if(this.cachedSetpoints[payload.registration_id]) {
                  payload.frequency_setpoint = this.cachedSetpoints[payload.registration_id];
                }
              })
                this.getQueryId();
                this.loading = false;
            });
        });
    }

    subscribeToMQTT() {
        const allIds = this.activeEvents
            .map(e => e.event_id)
            .filter(Boolean);
        allIds.push('new_event');

        // Don't re-subscribe to the same event list
        if (allIds.join(',') === this.eventNodeIds.join(',')) {
            return;
        }

        // Unsubscribe from any existing subscriptions.
        this.unsubscribe.next(true);

        setTimeout(() => {
            // Set the event node ids for next time, and subscribe to the next topic
            this.eventNodeIds = allIds;
            this.sharedService.subscribeToTopic(
                null,
                allIds,
                "event",
                this.enutsMqttService,
                msg => this.fetchWithRetries(),
                false,
                null,
                this.unsubscribe,
                this.emService,
            );
        }, 100);
    }

    getQueryId() {
      const regSetIds = [];
      const all = [...this.activeEvents, ...this.pendingRegistrations]
      all.forEach((payload) => {
        if(payload.registration_id) regSetIds.push(payload.registration_id)
      })
      if(regSetIds.length) {
        this.emService.post('/v1/frequency_setpoint/get_query_id', {ids: regSetIds}, {}).pipe().subscribe(
          (resp)=>{
            if(resp.data.map)
              this.thingIdToRegIdMap = {...this.thingIdToRegIdMap, ...resp.data.map}
            this.getFrequencySetPoints(resp.data.queryId);
          },
          (err)=>{
            console.log(err)
          }
        )
      }
    }

    getFrequencySetPoints(queryId) {
      this.emService.get('/v1/frequency_setpoint/' + queryId, {}).pipe().subscribe(
        (resp)=>{
          if(resp.data.status =='SUCCEEDED'){
            this.populateFrequencySetPoints(resp.data);
          } else if(resp.status !== 'FAILED') {
            this.setPointTimeout = setTimeout(()=>{
              this.getFrequencySetPoints(queryId)
            }, 2000)
          }
        },
        (err)=>{
          clearTimeout(this.setPointTimeout);
          console.log(err)
        }
      )
    }

    populateFrequencySetPoints(data) {
      const controller = this;
      const all = [...this.activeEvents, ...this.pendingRegistrations];

      data.resp.forEach((d)=>{
        d.registration_id = this.thingIdToRegIdMap[d.id]
      })
      const fspMap =  _.keyBy(data.resp, 'registration_id');

      all.forEach((o)=>{
        if(fspMap[o.registration_id]) {
          o.frequency_setpoint = fspMap[o.registration_id].value + " Hz"
          controller.cachedSetpoints[o.registration_id] = o.frequency_setpoint;
        }
        }
      )
    }

    ngOnDestroy() {
        this.unsubscribe.next(true);
        clearInterval(this.interval);
        clearTimeout(this.setPointTimeout);
    }
}
