import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, } from "@angular/common/http";
import { Injector, NgModule } from "@angular/core";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE, OwlDateTimeModule } from "@danielmoncada/angular-datetime-picker";
import { OwlMomentDateTimeModule } from "@danielmoncada/angular-datetime-picker-moment-adapter";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HighchartsChartModule } from "highcharts-angular";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { CookieService } from "ngx-cookie-service";
import { NgxGlobalContactFooterModule } from "ngx-global-contact-footer";
import { GlobalNavModule } from "ngx-global-nav";
import { MqttModule, MqttService } from "ngx-mqtt";
import { NOC_ANALYTICS } from "ngx-shared-services";
import { ToastrModule } from "ngx-toastr";
import { analytics } from "noc-analytics";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { ContextMenuModule } from "primeng/contextmenu";
import { DialogModule } from "primeng/dialog";
import { DropdownModule } from "primeng/dropdown";
import { InputTextModule } from "primeng/inputtext";
import { MultiSelectModule } from "primeng/multiselect";
import { PaginatorModule } from "primeng/paginator";
import { PanelModule } from "primeng/panel";
import { ProgressBarModule } from "primeng/progressbar";
import { RippleModule } from "primeng/ripple";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { SliderModule } from "primeng/slider";
import { SplitterModule } from "primeng/splitter";
import { TableModule } from "primeng/table";
import { ToastModule } from "primeng/toast";
import { AccordionModule } from "primeng/accordion";

import { AppRoutingModule, ReallyWeakAuthGuardService, } from ".//app-routing.module";
import { AppComponent } from "./app.component";
import { AdminViewComponent } from "./components/admin-view/admin-view.component";
import { AggregateViewComponent } from "./components/aggregate-view/aggregate-view.component";
import { HeatmapComponent } from "./components/aggregate-view/heatmap/heatmap.component";
import { ObligationsMapComponent } from "./components/aggregate-view/obligations-map/obligations-map.component";
import { CancelEventModalComponent } from "./components/cancel-event-modal/cancel-event-modal.component";
import { ContactInfoComponent } from "./components/contact-info/contact-info.component";
import { CreateErrorModalComponent } from "./components/create-error-modal/create-error-modal.component";
import { CustomTasksComponent } from "./components/custom-tasks/custom-tasks.component";
import { Err403PageComponent } from "./components/err403-page/err403-page.component";
import {
  EventCreationComponent,
  MaxDirective,
  MinDirective,
} from "./components/event-creation/event-creation.component";
import { EventDetailComponent } from "./components/event-detail/event-detail.component";
import { EventListParentComponent } from "./components/event-list-parent/event-list-parent.component";
import { EventListPillBoxComponent } from "./components/event-list-pill-box/event-list-pill-box.component";
import { EventListTableComponent } from "./components/event-list-table/event-list-table.component";
import { EventListComponent } from "./components/event-list/event-list.component";
import { EventNodeInfoComponent } from "./components/event-node-info/event-node-info.component";
import { EventNotificationsComponent } from "./components/event-notifications/event-notifications.component";
import { EventPerformanceChartComponent } from "./components/event-performance-chart/event-performance-chart.component";
import { EventStepsTimelineComponent } from './components/event-steps-timeline/event-steps-timeline.component';
import { EventTimelineComponent } from "./components/event-timeline/event-timeline.component";
import { FilterControlComponent } from "./components/filter-control/filter-control.component";
import { HierarchySelectorComponent } from "./components/hierarchy-selector/hierarchy-selector.component";
import { LoadingScreenComponent } from "./components/loading-screen/loading-screen.component";
import { MainComponent } from "./components/main/main.component";
import { AssignModalComponent } from "./components/modals/assign-modal/assign-modal.component";
import { CreateNoteModalComponent } from "./components/modals/create-note-modal/create-note-modal.component";
import { CustomTaskModalComponent } from "./components/modals/custom-task-modal/custom-task-modal.component";
import { EnelModalComponent } from "./components/modals/enel-modal/enel-modal.component";
import { EnelPasswordModalComponent } from "./components/modals/enel-password-modal/enel-password-modal.component";
import { EventCreateSummaryComponent } from "./components/modals/event-create-summary/event-create-summary.component";
import { EventExtendModalComponent } from "./components/modals/event-extend-modal/event-extend-modal.component";
import {
  EventNodeBulkActivateModalComponent
} from "./components/modals/event-node-bulk-activate-modal/event-node-bulk-activate-modal.component";
import { EventNodeBulkEndModalComponent } from "./components/modals/event-node-bulk-end-modal/event-node-bulk-end-modal.component";
import { NotifyModalComponent } from "./components/modals/notify-modal/notify-modal/notify-modal.component";
import { ObligationModalComponent } from "./components/modals/obligation-modal/obligation-modal.component";
import {
  RefreshBaselinesModalComponent
} from "./components/modals/refresh-baselines-modal/refresh-baselines-modal.component";
import { RegListModalComponent } from "./components/modals/reg-list-modal/reg-list-modal.component";
import { SendTemplatesModalComponent } from "./components/modals/send-templates-modal/send-templates-modal.component";
import {
  TimelineBulkActionModalComponent
} from "./components/modals/timeline-bulk-action-modal/timeline-bulk-action-modal.component";
import { TimestampModalComponent } from "./components/modals/timestamp-modal/timestamp-modal.component";
import {
  WorkflowStepEditModalComponent
} from "./components/modals/workflow-step-edit-modal/workflow-step-edit-modal.component";
import { NodeListFilterComponent } from './components/node-list-filter/node-list-filter.component';
import { NodeListComponent } from './components/node-list/node-list.component';
import { NodePerformanceComponent } from './components/node-performance/node-performance.component';
import { NpcBannerComponent } from './components/node-performance/npc-banner/npc-banner.component';
import { NotificationResultsComponent } from './components/notification-results/notification-results.component';
import { OwlDatePickerComponent } from './components/owl-date-picker/owl-date-picker.component';
import { SummaryCardComponent } from './components/summary-card/summary-card.component';
import { TimestampComponent } from './components/timestamp/timestamp.component';
import { TimezonePickerComponent } from './components/timezone-picker/timezone-picker.component';
import { UnderfrequencyDetailsComponent } from './components/underfrequency-details/underfrequency-details.component';
import { UnderfrequencyListComponent } from './components/underfrequency-list/underfrequency-list.component';
import { UnderfrequencyTableComponent } from './components/underfrequency-table/underfrequency-table.component';
import { UtilityPortalCreateComponent } from './components/utility-portal/create/utility-portal-create.component';
import { UtilityPortalListComponent } from './components/utility-portal/list/utility-portal-list.component';
import {
  UtilityPortalEventDetailsComponent
} from './components/utility-portal/utility-portal-event-details/utility-portal-event-details.component';
import {
  UtilityPortalNodeDetailsComponent
} from './components/utility-portal/utility-portal-node-details/utility-portal-node-details.component';
import { CollapsePanelComponent } from './directives/collapse-panel/collapse-panel.component';
import { EnelTabsComponent } from './directives/enel-tabs/enel-tabs.component';
import { EventNodeRowComponent } from './directives/event-node-row/event-node-row.component';
import { OptedOutNodeRowComponent } from './directives/event-node-row/opted-out-node-row.component';
import { EventRowComponent } from './directives/event-row/event-row.component';
import { FilterPillComponent } from './directives/filter-pill/filter-pill.component';
import { MinCheckedValidator } from './directives/min-checked-validator/min-checked-validator.directive';
import { ModalContentWrapperDirective } from './directives/modal-content-wrapper/modal-content-wrapper.directive';
import { PaginatorComponent } from './directives/paginator/paginator.component';
import { RegistrationRowComponent } from './directives/registration-row/registration-row.component';
import { L10nconversionPipe } from './pipes/l10nconversion.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { UOMConversionPipe } from './pipes/uomconversion.pipe';
import { AuthInterceptor } from './services/auth.service';
import { UnderfrequencyEventService } from './services/underfrequency-event.service';
import { SearchParentComponent } from './components/search/search-parent/search-parent.component';
import { SearchContactFilterComponent } from './components/search/search-contact-filter/search-contact-filter.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { EventNodeSearchTableComponent } from './components/search/event-node-search-table/event-node-search-table.component';
import { ContactSearchTableComponent } from './components/search/contact-search-table/contact-search-table.component';
import { MatRadioModule } from "@angular/material/radio";
import { NgxDropdownModule } from 'ngx-dropdown';
import { EntitySearchDropdownComponent } from './components/search/entity-search-dropdown/entity-search-dropdown.component';
import { SearchRegistrationFilterComponent } from './components/search/search-registration-filter/search-registration-filter.component';
import {MatDivider, MatDividerModule} from "@angular/material/divider";
import {MatButton, MatButtonModule} from "@angular/material/button";

export let AppInjector: Injector;
export const MY_MOMENT_FORMATS = {
  parseInput: "YYYY/MM/DD, HH:mm",
  fullPickerInput: "YYYY/MM/DD, HH:mm",
  datePickerInput: "YYYY/MM/DD",
  timePickerInput: "HH:mm z",
  monthYearLabel: "YYYY/MM",
  dateA11yLabel: "LL",
  monthYearA11yLabel: "MMMM YYYY",
};

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    EventCreationComponent,
    RegistrationRowComponent,
    EnelModalComponent,
    EnelPasswordModalComponent,
    RefreshBaselinesModalComponent,
    EventCreateSummaryComponent,
    CollapsePanelComponent,
    EventRowComponent,
    EventListComponent,
    EnelTabsComponent,
    EventDetailComponent,
    EventNodeRowComponent,
    MaxDirective,
    MinDirective,
    EventNodeInfoComponent,
    EventNotificationsComponent,
    ModalContentWrapperDirective,
    SummaryCardComponent,
    LoadingScreenComponent,
    TimestampComponent,
    MinCheckedValidator,
    TimestampModalComponent,
    MinCheckedValidator,
    ContactInfoComponent,
    TimezonePickerComponent,
    OwlDatePickerComponent,
    CustomTaskModalComponent,
    UOMConversionPipe,
    AggregateViewComponent,
    HierarchySelectorComponent,
    EventListParentComponent,
    OptedOutNodeRowComponent,
    ObligationModalComponent,
    EventListPillBoxComponent,
    FilterPillComponent,
    EventListTableComponent,
    FilterControlComponent,
    CreateErrorModalComponent,
    CancelEventModalComponent,
    TimelineBulkActionModalComponent,
    EventNodeBulkEndModalComponent,
    EventNodeBulkActivateModalComponent,
    CreateNoteModalComponent,
    NodePerformanceComponent,
    EventExtendModalComponent,
    WorkflowStepEditModalComponent,
    AssignModalComponent,
    AdminViewComponent,
    NotificationResultsComponent,
    UtilityPortalListComponent,
    EventTimelineComponent,
    EventPerformanceChartComponent,
    UtilityPortalCreateComponent,
    UtilityPortalEventDetailsComponent,
    NpcBannerComponent,
    UtilityPortalNodeDetailsComponent,
    UnderfrequencyListComponent,
    UnderfrequencyTableComponent,
    UnderfrequencyDetailsComponent,
    L10nconversionPipe,
    Err403PageComponent,
    HeatmapComponent,
    NotifyModalComponent,
    RegListModalComponent,
    NodeListFilterComponent,
    PaginatorComponent,
    NodeListComponent,
    SendTemplatesModalComponent,
    CustomTasksComponent,
    ObligationsMapComponent,
    EventStepsTimelineComponent,
    OrderByPipe,
    SearchParentComponent,
    SearchContactFilterComponent,
    EventNodeSearchTableComponent,
    ContactSearchTableComponent,
    EntitySearchDropdownComponent,
    SearchRegistrationFilterComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: "toast-top-full-width",
      closeButton: true,
    }),
    MqttModule.forRoot({ connectOnCreate: false }),
    BrowserModule,
    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    HighchartsChartModule,
    NgbTooltipModule,
    MatTableModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatChipsModule,
    MatPaginatorModule,
    MatRadioModule,
    MatDividerModule,
    MatButtonModule,
    NgxDropdownModule,
    GlobalNavModule.forRoot({ namespace: "ngx-global-nav." }),
    NgxGlobalContactFooterModule.forRoot({ namespace: "ngx-global-contact-footer." }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    TableModule,
    CalendarModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    DropdownModule,
    ButtonModule,
    ToastModule,
    InputTextModule,
    ProgressBarModule,
    HttpClientModule,
    FormsModule,
    SplitterModule,
    PaginatorModule,
    ScrollPanelModule,
    PanelModule,
    RippleModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgSelectModule,
    MatIconModule,
    ReactiveFormsModule
  ],
  providers: [
    CookieService,
    MqttService,
    ReallyWeakAuthGuardService,
    UnderfrequencyEventService,
    { provide: NOC_ANALYTICS, useValue: analytics },
    { provide: OWL_DATE_TIME_LOCALE, useValue: "en" },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  // entryComponents: [
  //   EventNodeInfoComponent,
  //   TimestampModalComponent,
  //   CustomTaskModalComponent,
  //   CreateErrorModalComponent,
  //   CancelEventModalComponent,
  //   ObligationModalComponent,
  //   TimelineBulkActionModalComponent,
  //   WorkflowStepEditModalComponent,
  //   CreateNoteModalComponent,
  //   EventExtendModalComponent,
  //   AssignModalComponent,
  //   NodePerformanceComponent,
  // ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
