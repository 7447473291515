import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { EventManagementService } from '../../services/event-management.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-cancel-event-modal',
  templateUrl: './cancel-event-modal.component.pug',
  styleUrls: ['./cancel-event-modal.component.scss']
})
export class CancelEventModalComponent implements OnInit {

  @Input() data:any;
  passwordEntered: string = '';

  sendCancellationTemplate;
  willReceive = 0;
  notifyNodes = {};
  nodesLoaded;
  nodesToEventMap;

  constructor(private EMS: EventManagementService) { }

  ngOnInit() {
   if(!!this.data.nodes){
     this.nodesLoaded = true;
     this.getNodeIds();
   } else {
     this.EMS.post('/v1/events/event_nodes',{event_ids: this.data.events}, {}).pipe().subscribe(
       (resp)=>{
         this.data.nodes = _.keyBy(resp.data, 'event_id');
         this.nodesLoaded = true;
         this.getNodeIds();
       },
       (err)=>{

       }
     )
   }
  }

  getNodeIds(){
    const now = moment().utc();
    let amountNotifying = 0;


    this.data.events.forEach(id => {

      this.notifyNodes[id] = [];

      if(this.data.nodes[id].activeNodes) {
        this.data.nodes[id].activeNodes.forEach((node) => {
          if(node.last_notification_sent_dttm != null && (node.event_node_end_dttm_utc == null || moment(node.event_node_end_dttm_utc).isAfter(now))) {
            this.notifyNodes[id].push(node.event_node_id);
            amountNotifying++;
          }
        });
      }
    });

    this.willReceive = amountNotifying;
  }

  onClickCancel(){
    this.data.onCancel(this.sendCancellationTemplate);
    this.data.close();
  }

}
