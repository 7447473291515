import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ModalContentWrapperDirective} from "../../directives/modal-content-wrapper/modal-content-wrapper.directive";

@Component({
  selector: 'app-summary-card',
  templateUrl: './summary-card.component.pug',
  styleUrls: ['./summary-card.component.scss']
})
export class SummaryCardComponent implements OnInit, AfterViewInit {

  @Input() cardTitle:string;
  @Input() mainInfo:string;
  @Input() secondaryInfo:string;
  @Input() secondaryInfoTimestamp:string;
  @Input() timezone:string;
  @Input() cardType:string;
  @Input() selected:boolean;
  @Input() eventGroup:boolean = false;

  @ViewChild('card',{read: ElementRef, static:false}) cardElement: ElementRef;

  constructor() { }

  ngAfterViewInit(){
    switch(this.cardType) {
      case 'green':
        this.cardElement.nativeElement.classList.add('sc-green');
        break;
      case 'exception':
        this.cardElement.nativeElement.classList.add('sc-exception');
        break;
      case 'red':
        this.cardElement.nativeElement.classList.add('sc-red');
        break;
      case 'gray':
        this.cardElement.nativeElement.classList.add('sc-gray');
        break;
      case 'yellow':
        this.cardElement.nativeElement.classList.add('sc-yellow');
        break;
      case 'purple':
        this.cardElement.nativeElement.classList.add('sc-purple');
        break;
    }
  }

  ngOnInit() {

  }
}
