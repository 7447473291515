import {Component, Input, Output, OnInit, EventEmitter} from '@angular/core';
import {EPSEvent} from "../../classes/event";
import {EventManagementService} from "../../services/event-management.service";
import {SharedService} from "../../services/shared.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {Product} from "../../classes/product";

@Component({
  selector: 'tr[app-event-row]',
  templateUrl: './event-row.component.pug',
  styleUrls: ['./event-row.component.scss']
})
export class EventRowComponent implements OnInit {

  @Input() event: EPSEvent;
  @Input() aggregateView: boolean;
  @Input() selectingEvents:boolean;
  @Input() selectable:boolean;
  @Input() nodeTypes;
  @Output() selectEvent : EventEmitter<any> = new EventEmitter();

  comProductObj;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private eventManagementService:EventManagementService, private sharedService:SharedService) { }

  ngOnInit() {
    this.comProductObj = this.event.product;
  }

  clickSelect(): void {
    //Calls the function we pass in from the Event Detail Service
    this.selectEvent.emit();
  }

  fixedDecimals(value, numDecimals = 3) {
    if(typeof(value) === 'number')
      return value.toFixed(numDecimals);
    else
      return null;
  }
}
