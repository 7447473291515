import { Injectable } from '@angular/core';
import { MqttService } from 'ngx-mqtt';
import { forkJoin, from, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { EPSEvent } from '../classes/event';
import { EventManagementService } from './event-management.service';
import { catchError, map, mergeMap, share, takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';
import {
  ColumnFilter,
  columns,
  EnumColumnFilter,
  FieldType,
  iColumnFilter,
  operatorNames,
  enumDefaults
} from '../classes/coulmns';
import { HttpHeaders } from '@angular/common/http';
import { MqttSubscriptionService } from './mqtt-subscription.service';
import { SharedService } from './shared.service';
import {EventNode, EventNodeWrapper} from "../classes/event_node";
import { RefDataService } from './ref-data.service';

@Injectable({
  providedIn: 'root'
})
export class EventListService {

  //TODO this is just temporary until we are fully using subscriptions. It's to prevent us calling to get event nodes every minute
  private gotEventNodes:boolean = false;

  private _events: EPSEvent[];
  private _eventNodes: EventNodeWrapper[];
  private _training: boolean = false;

  get events(){
    return this._events;
  }
  set events(val){
    this._events = val;
  }
  get eventNodes(){
    return this._eventNodes;
  }
  set eventNodes(val){
    this._eventNodes = val;
  }

  get training(): boolean {
    return this._training;
  }

  set training(value: boolean) {
    this._training = value;
  }

  filters$ = new ReplaySubject<iColumnFilter[]>();
  filteredEvents$ = new ReplaySubject<EPSEvent[]>();
  eventNodes$ = new ReplaySubject<EventNodeWrapper[]>();
  unfilteredEvents$ = new ReplaySubject<EPSEvent[]>();
  unfilteredActiveEvents$ = new ReplaySubject<EPSEvent[]>();
  loadingEvents$ = new ReplaySubject<boolean>();
  loadingEventNodes$ = new ReplaySubject<boolean>();
  workflowStatuses$ = new ReplaySubject<any>();
  cutomTasksUpdate$ = new ReplaySubject<any>();
  eventWorkflowUpdate$ = new ReplaySubject<any>();
  newEvent$ = new ReplaySubject<any>();
  users$ = new ReplaySubject<any>();
  refreshEvent$ = new ReplaySubject<any>();

  productsMap = {};
  usersMap = {};
  portfoliosMap = {};
  eventTypesMap = {};
  filterTypesMap = {};
  templatesMap = {};
  registrationTypesMap = {};

  createableFilterTypesMap = {};
  createableTemplatesMap = {};

  workflowStatusesMap = {};

  operator_display_labelEnum:Array<ColumnFilter>;
  ems_program_display_labelEnum:Array<ColumnFilter>;
  product_display_labelEnum:Array<ColumnFilter>;
  portfolio_display_labelEnum:Array<ColumnFilter>;
  event_action_type_display_labelEnum:Array<ColumnFilter>;
  operator_nameEnum:Array<ColumnFilter>;
  created_by_nameEnum:Array<ColumnFilter>;
  statusEnum:Array<ColumnFilter>;

  filters:Array<iColumnFilter>;

  eventSubscription: MqttSubscriptionService;
  epsEventSubscription: MqttSubscriptionService;
  newEventSubscription: MqttSubscriptionService;
  DayRanges = Object.freeze({
    one: 1,
    seven: 7,
    thirty: 30
  });
  dayRange = this.DayRanges.one;

  constructor(private EMS: EventManagementService, private sharedService: SharedService,
              private RDS: RefDataService, private MQTT: MqttService) { }

  //region get events
  getEventsFromDate(firstLoad = true, utility = false, date_since, program_id, event_type) {
    //If this is the initial data load, set the loading bools to true so we show the spinner. Otherwise we're just silently updating data
    if(firstLoad) {
      this.loadingEvents$.next(true);
      this.loadingEventNodes$.next(true);
    }

    this.EMS.get('/v1/events?date_since=' + date_since + '&program_id=' + program_id + '&hierarchy=false&event_type=' + event_type + '&utility=' + utility, {headers: new HttpHeaders({ 'Content-Type': 'text/plain' })}).pipe().subscribe(
      response => {
        this.sharedService.sort(response.data, 'event_start_dttm_utc');

        this.events = response.data;
        this.getSupportingData(utility);
        this.subscribeEvents();
      },
      error => {
        console.dir("Error getting events: ");
        console.dir(error);
      });
  }


  getEvents(firstLoad = true, utility = false): void {
    //If this is the initial data load, set the loading bools to true so we show the spinner. Otherwise we're just silently updating data
    if(firstLoad) {
      this.loadingEvents$.next(true);
      this.loadingEventNodes$.next(true);
    }

    const eventTypeSnippet = this.training ? '&event_type=TRAINING' : '&exclude_event_types=OFFICIAL_UFR,TRAINING';
    this.EMS.get('/v1/events?data_since=' + this.dayRange + '&hierarchy=false&utility=' + utility + eventTypeSnippet, {headers: new HttpHeaders({ 'Content-Type': 'text/plain' })}).pipe().subscribe(
      response => {
        this.sharedService.sort(response.data, 'event_start_dttm_utc');
        this.events = response.data;
        this.getSupportingData(utility);
        this.subscribeEvents();
      },
      error => {
        console.dir("Error getting events: ");
        console.dir(error);
    });
  }


  //Gets an event and adds it to our list of events
  getEvent(id, next, utility = false): void {
    this.EMS.get('/v1/event/' + id, {headers: new HttpHeaders({ 'Content-Type': 'text/plain' })}).pipe().subscribe(
      response => {

        //Set and update the event
        let event = response.data;

        if(_.findIndex(this.events, e => {
          return e.event_id === response.data.event_id;
        }) === -1) {
          //We only care about this event if it's XCORE or it's Utility with the correct source type
          if((!utility && event.event_action_type !== 'OFFICIAL_UFR') || (utility && event.source_system_type === "CLASSIC_DR")) {
            this.updateEventProp(event);
            this.events.push(event);
            //get custom
            this.sharedService.sort(this.events, 'event_start_dttm_utc');
            this.unfilteredEvents$.next(this.events);

            this.filterOutCancelledEvents();
            this.populateEnums();
            this.applyFilters();
            this.subscribeEvents();
          } else {
            return;
          }
        }
        else {
          console.log("Incoming new event is already in our event list.")
          this.updateEvent(event);
        }
      },
      error => {
        console.dir("Error getting events: ");
        console.dir(error);
      });
  }
  //endregion

  getSupportingData(utility: boolean = false): void {
    const instance = this;

    let arr = [];
    this.events.forEach((o)=>{
      arr.push({"program_id": o.program_id, "product_id": o.product_id})
    })

    const uniqueProgramProductIds: Observable<any[]> = of(_.uniqWith(arr, (arrVal, othVal)=>{return arrVal.program_id == othVal.program_id && arrVal.product_id == othVal.product_id})) as Observable<any[]>;
    const uniqueProdIds:Observable<any[]> = of(_.map(_.uniqBy(this.events, 'product_id'), 'product_id').filter((i)=>{return i && !instance.productsMap[i]})) as Observable<any[]>;
    const uniquePortfolioIds:Observable<any[]> = of(_.map(_.uniqBy(this.events, 'portfolio_id'), 'portfolio_id').filter((i)=>{return i && !instance.portfoliosMap[i]})) as Observable<any[]>;

    const uniqueUserIds:String[] = _.map(_.uniqBy(this.events, 'created_by'), 'created_by').filter((i)=>{return i && !instance.usersMap[i]});
    const users$ = uniqueUserIds.length > 0 ? this.EMS.post('/v1/users/',{ ids: uniqueUserIds}, {headers: new HttpHeaders({ 'Content-Type': 'text/plain' })}).pipe(map((resp)=>{this.usersMap = Object.assign(this.usersMap, resp.data); this.users$.next(this.usersMap);})) : of<any[]>([]);

    const registrationTypes$ = this.RDS.getRegistrationTypesMap().pipe(map((resp)=>{this.registrationTypesMap = resp}))
    const filterTypes$ = this.RDS.getAllFilterTypesMap().pipe(map((resp)=>{this.filterTypesMap = resp}))
    const createableFilterTypes$ = this.RDS.getCreateableFilterTypesMap().pipe(map((resp)=>{this.createableFilterTypesMap = resp}))
    const templates$ = this.RDS.getTemplatesMap(utility).pipe(map((resp)=>{this.templatesMap = resp}));
    const creatableTemplates$ = this.RDS.getCreateableTemplatesMap(utility).pipe(map((resp)=>{this.createableTemplatesMap = resp}));
    const workflowStatuses$ = this.RDS.getWorkflowStatusesMap().pipe(map((resp)=>{this.workflowStatusesMap = resp; this.workflowStatuses$.next(this.workflowStatusesMap);}))
    const eventType$ = this.RDS.getEventTypesMap().pipe(map((resp)=>{this.eventTypesMap = resp}))

    const products$ = uniqueProdIds.pipe(
      mergeMap((ids) => {
        if(!ids.length) {
          return of<any[]>([]);
        }forkJoin
        return forkJoin(
          ids.map((id) => {
              return this.EMS.get('/v1/product/' + id, {}).pipe(
                map((resp) => {
                  this.productsMap[id] = resp.data;
                  return resp;
                }),
                catchError((err)=> {
                  console.log(err);
                  return from(new Promise((resolve) => resolve(true))) ;
                })
              );
            }
          ))
      }),
    );

    const portfolios$ = uniqueProgramProductIds.pipe(
      mergeMap((ids) => {
        if(!ids.length) {
          return of<any[]>([]);
        }
        return forkJoin(
          ids.map((o) => {
              return this.EMS.get('/v1/portfolios?product_id=' + o.product_id + '&program_id=' + o.program_id + '&dont_care_about_dispatchability=true', {}).pipe(
                map((resp) => {
                  resp.data.forEach(
                    (portfolio)=>{
                      this.portfoliosMap[portfolio.id] = portfolio;
                    }
                  )

                  return resp;
                }),
                catchError((err)=> {
                  console.log(err);
                  return from(new Promise((resolve) => resolve(true))) ;
                })
              );
            }
          ))
      })
    );

    forkJoin([products$,  portfolios$, users$, eventType$, filterTypes$, templates$, workflowStatuses$, registrationTypes$, creatableTemplates$, createableFilterTypes$]).subscribe(
      (res) => {
        this.updateEventProps()
      }
    );
  }

  //region MQTT
  subscribeEvents(){
    let eventsToWatch = _.map(this.events, 'event_id');

    this.epsEventSubscription?.clearSubscription();
    this.epsEventSubscription = new MqttSubscriptionService();
    this.epsEventSubscription.subscribeToTopic(eventsToWatch, 'eps_event', (msg)=>{
      this.handleEPSEventUpdate(msg)
    });

    this.eventSubscription?.clearSubscription();
    this.eventSubscription = new MqttSubscriptionService();
    this.eventSubscription.subscribeToTopic(eventsToWatch, 'event', (msg)=>{
      this.handleEventUpdate(msg)
    });

    this.newEventSubscription?.clearSubscription();
    this.newEventSubscription = new MqttSubscriptionService();
    this.newEventSubscription.subscribeToTopic(['new_event'], 'event', (msg)=>{
      this.handleNewEvent(msg)
    });
  }

  handleEPSEventUpdate(msg){
    // console.log('---------------------------')
    // console.log('handleEPSEventUpdate')
    // console.log(msg)
    // console.log('---------------------------')
    this.updateEvent(msg);
  }

  handleEventUpdate(msg){
    // console.log('---------------------------')
    // console.log('handleEventUpdate')
    // console.log(msg.dataType)
    // console.log(msg)
    // console.log('---------------------------')
    switch (msg.dataType) {
      case 'event_node_workflow':
        this.eventWorkflowUpdate$.next(msg);
        break;
      case 'ems_event':
        this.updateEvent(msg);
        break;
      case 'custom_task':
        this.cutomTasksUpdate$.next(msg);
        break;
    }
  }

  handleNewEvent(msg){
    this.newEvent$.next(msg);
  }

  //endregion

  //region custom tasks


  //endregion

  //region update events
  updateEvent(data) {
    let index = _.findIndex(this.events, e => {return e.event_id === data.event_id;});
    this.events[index] = Object.assign({}, this.events[index], data);
    this.updateEventProps();
    //this.unfilteredEvents$.next(this.events);
    this.filterOutCancelledEvents();

    this.applyFilters();
  }

  updateEventProp(event) {
    this.sharedService.setProgressStatus(event);
    event.event_action_type_display_label = this.eventTypesMap[event.event_action_type] ? this.eventTypesMap[event.event_action_type].display_label : '';
    event.product = this.productsMap[event.product_id];
    event.created_by_name = this.usersMap[event.created_by] ? this.usersMap[event.created_by].username : event.created_by + ' (username not found)';
    event.portfolio_display_label = this.portfoliosMap[event.portfolio_id] ? this.sharedService.flattenDisplayLabel(this.portfoliosMap[event.portfolio_id].display_labels) : '';
    this.sharedService.setEventStatus(event);
    this.sharedService.setCurrentObligation(event);
  }

  filterOutCancelledEvents(): void {
    let activeEvents = [];
    activeEvents = _.filter(this.events, e => {
      return e.cancelled === false;
    });
    this.unfilteredActiveEvents$.next(activeEvents);
  }

  refreshEvent(eventId){
    this.EMS.get('/v1/event/' + eventId, {headers: new HttpHeaders({ 'Content-Type': 'text/plain' })}).pipe().subscribe(
      (resp)=>{
        let found = _.find(this.events, ['event_id', eventId]);
        if(found){
          Object.assign(found, resp.data)
        }

      },
      (err) => {

      }
    )
  }

  updateEventProps(): void {
    this.eventNodes = [];
    const doneGettingEventNodes = _.after(this.events.length, () => {
      this.unfilteredEvents$.next(this.events);
      this.eventNodes$.next(this.eventNodes);
      this.loadingEventNodes$.next(false);
      this.filterOutCancelledEvents();
      this.populateEnums();
      this.applyFilters();
    });

    this.events.forEach((event) => {

      this.updateEventProp(event);

      //Haven't gotten the initial nodes yet, so lets do that now
      if(!this.gotEventNodes) {

        let enw = new EventNodeWrapper();
        enw.setEventNodes(event, this.workflowStatusesMap, this.templatesMap, this.registrationTypesMap);
        this.eventNodes[event.event_id] = enw;
        doneGettingEventNodes();

      } else {
        doneGettingEventNodes();
      }
    });

    if(this.events.length === 0)
      doneGettingEventNodes();
  }
  //endregion

  //region enums
  createEnum(field) {
    const uniqueVals = _.map(_.uniqBy(this.events, field), field).filter((i)=>{return i}).sort();
    const column = _.find(columns, { 'field': field});
    const arr = [{column: column,  operator: operatorNames.contains, value: 'All'} as ColumnFilter];
    uniqueVals.forEach((val)=>{
      arr.push({ column: column, operator: operatorNames.contains, value: val} as ColumnFilter)
    });
    return arr;
  }

  populateEnums() {
    this.operator_display_labelEnum = this.createEnum('operator_display_label');
    this.ems_program_display_labelEnum = this.createEnum('ems_program_display_label');
    this.product_display_labelEnum = this.createEnum('product_display_label');
    this.portfolio_display_labelEnum = this.createEnum('portfolio_display_label');
    this.event_action_type_display_labelEnum = this.createEnum('event_action_type_display_label');
    this.operator_nameEnum = this.createEnum('operator_name');
    this.created_by_nameEnum = this.createEnum('created_by_name');
    this.statusEnum = this.createEnum('status');
  }
  //endregion

  //region filters
  applyFilters():void {
    const filters = this.filters;
    if(this.events && this.events.length && filters){
        const filteredEvents = this.events.slice().filter((event):boolean => {
          let bool = true;
          for (let i = 0; i < filters.length; i++) {
            const el = <iColumnFilter>filters[i];
              switch (el.column.type) {
                case FieldType.NUMBER:
                  const num = el as ColumnFilter;
                  let numComparitor = event[el.column.field];
                  if(numComparitor && el.column.field == 'event_performance_percent'){
                    numComparitor = +numComparitor.toFixed(1)
                  }
                  if(numComparitor && el.column.field == 'obligation'){
                    numComparitor = +numComparitor.toFixed(3)
                  }
                  switch (num.operator) {
                    case operatorNames.eq:
                      if(!(numComparitor == num.value)){bool = false;}
                      break;
                    case operatorNames.gt:
                      if(!(numComparitor > num.value)){bool = false;}
                      break;
                    case operatorNames.gte:
                      if(!(numComparitor >= num.value)){bool = false;}
                      break;
                    case operatorNames.lt:
                      if(!(numComparitor < num.value)){bool = false;}
                      break;
                    case operatorNames.lte:
                      if(!(numComparitor <= num.value)){bool = false;}
                      break;
                  }

                  break;
                case FieldType.DATE:
                  const date = el as ColumnFilter;
                    const val = date.value ? moment.utc(date.value) : null;
                    const comparator = event[el.column.field] ? moment(event[el.column.field]) : null;
                    switch (date.operator) {
                      case operatorNames.eq:
                        if(!(comparator.isSame(val, 'minute'))){bool = false;}
                        break;
                      case operatorNames.gt:
                        if(!(comparator.isAfter(val, 'minute'))){bool = false;}
                        break;
                      case operatorNames.gte:
                        if(!(comparator.isSameOrAfter(val, 'minute'))){bool = false;}
                        break;
                      case operatorNames.lt:
                        if(!(comparator.isBefore(val, 'minute'))){bool = false;}
                        break;
                      case operatorNames.lte:
                        if(!(comparator.isSameOrBefore(val, 'minute'))){bool = false;}
                        break;
                      case operatorNames.is_null:
                        if(comparator){bool = false}
                        break;
                      case operatorNames.is_not_null:
                        if(!comparator){bool = false}
                        break;
                    }

                  break;
                case FieldType.MULTI:
                  const multi = el as EnumColumnFilter;
                  if(!_.find(multi.enums, {'value': 'All'}) && !_.find(multi.enums, {'value': event[el.column.field]})) {bool = false;}
                  break;
              }
            }
          return bool;
        });
        this.loadingEvents$.next(false);
        this.filteredEvents$.next(filteredEvents);
    } else if (this.events && (!this.events.length || !filters)){
      this.loadingEvents$.next(false);
      this.filteredEvents$.next([]);
    }
  }

  addFilter(filter: iColumnFilter):void{
    //get ridda the old gal
    const found = _.find(this.filters, (o) => {
      if(o.hasOwnProperty('enums'))
      {
        return o.column.field == filter.column.field
      } else {
        return o.column.field == filter.column.field && (o as ColumnFilter).operator == (filter as ColumnFilter).operator;
      }

    });
    _.pull(this.filters, found);

    this.filters.push(filter);
    this.filters$.next(this.filters);
    this.applyFilters();
  }

  removeFilter(filter: iColumnFilter):void{
    if(filter.column.type == FieldType.MULTI) {
      const found:EnumColumnFilter = _.find(this.filters, (o) => {return o.column.field == filter.column.field});
      if(found) {
        _.pull(found.enums, filter);
        if(!found.enums.length) {
          found.enums.push({column:found.column, operator:operatorNames.contains, value: 'All'})
        }
      }
    } else {
      _.pull(this.filters, filter);
    }

    this.filters$.next(this.filters);
    this.applyFilters();
  }

  removeAllFilters():void {
    this.filters = [];
    this.filters$.next(this.filters);
    this.applyFilters();
  }

  resetAllFilters():void{
    this.filters = JSON.parse(JSON.stringify(enumDefaults));
    this.filters$.next(this.filters);
    this.applyFilters();
  }
  //endregion

  resetDayRange(range, utility = false):void {
    this.dayRange = range;
    this.getEvents(false, utility);
  }

  filterEvents(e: EPSEvent, training: boolean) {
    const trainingEventActionType = 'TRAINING';
    if(!training){
      return e.event_action_type !== 'OFFICIAL_UFR' && e.event_action_type !== trainingEventActionType
    } else {
      return e.event_action_type === trainingEventActionType
    }
  }
}
