import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';
import { ModalDatePickerData } from '../../../classes/modalDatePickerData';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-event-node-bulk-activate-modal',
  templateUrl: './event-node-bulk-activate-modal.component.pug',
  styleUrls: ['./event-node-bulk-activate-modal.component.scss'],
})
export class EventNodeBulkActivateModalComponent implements OnInit {
  @Input() data: any;

  defaultDateWarning = false;
  defaultEndTimeConsideration: string = '';
  hasErrors = false;
  passwordRequired = 'ennel1';
  passwordEntered = '';
  showTimezoneTable = true;

  constructor(private shared: SharedService) {}

  ngOnInit() {
    //Set the default dates if there are any
    this.data.timePickerData.forEach(tpd => {
      if (tpd.default_date) {
        let convertedTime = moment.tz(moment(tpd.default_date), this.shared.getTimeZoneName(this.data.program_time_zone, false));
        tpd.entered_time = convertedTime.format('YYYY-MM-DDTHH:mm');
      }
    });
    this.showTimezoneTable = this.data.showTimezoneTable ?? true;

    this.checkDifferentEndTimes();
  }

  cannotConfirm() {
    return !this.isPasswordValid() || this.hasErrors || this.isMissingDates();
  }

  checkDifferentEndTimes() {
    if (this.data.hasDifferentEndTimes) {
      let endDate = '2050/01/01, 00:00 UTC';
      if (!!this.data.eventEnd) {
        endDate = moment.tz(moment(this.data.eventEnd), this.data.program_time_zone).format('YYYY/MM/DD, HH:mm z');
      }
      this.defaultDateWarning = true;
      this.defaultEndTimeConsideration =
        'Default end time corresponds to the earliest event end time of the group of selected nodes. Entered date must be before ' +
        endDate;
    }
  }

  close() {
    this.data.close();
  }

  confirm() {
    const validationHasError = this.validateDates();
    if (!!validationHasError) {
      return;
    }
    const times = this.getTimepickerDataInCorrectTZ();
    this.data.confirmFunction(times, this.data.event_nodes);
    this.data.close();
  }

  getTimepickerDataInCorrectTZ(): string[] {
    return this.data.timePickerData.map(tpd => {
      if (tpd.entered_time) {
        if (moment.isMoment(tpd.entered_time)) {
          return tpd.entered_time.clone().tz(this.data.program_time_zone, true).toISOString();
        }
        return moment.tz(tpd.entered_time, this.data.program_time_zone).toISOString();
      }
      return null;
    });
  }

  getTimezone() {
    return this.shared.getTimeZoneName(this.data.program_time_zone);
  }

  getTimeZoneConversion(timePickerData: ModalDatePickerData, zone: string) {
    let localTZ = this.shared.getUsersTimeZone();
    let tz: string;

    switch (zone) {
      case 'local':
        tz = localTZ;
        break;
      case 'program':
        tz = this.data.program_time_zone;
        break;
      case 'utc':
        tz = 'UTC';
        break;
    }

    let convertedTime: string = this.shared.selectedTimeZoneToUTC(timePickerData.entered_time, this.data.program_time_zone).toISOString();
    return timePickerData.entered_time ? moment.tz(convertedTime, tz).format(SharedService.dateFormat) : 'None';
  }

  isMissingDates(): boolean {
    return this.data.timePickerData.some((tpd: ModalDatePickerData) => this.isDateFieldEmptyOrInvalid(tpd));
  }

  isDateFieldEmptyOrInvalid(tpd: ModalDatePickerData): boolean {
    const isEmptyOrInvalid = !tpd.entered_time && !tpd.allow_null;
    if (!moment(tpd.entered_time).isValid() && !tpd.allow_null) {
      tpd.errors = ['Invalid date entered.'];
    }
    return isEmptyOrInvalid;
  }

  onChangeDate(time, tpd) {
    tpd.entered_time = time.date;
    tpd.errors = [];

    this.hasErrors = this.validateDates();
  }

  validateDates(): boolean {
    let hasError = false;
    this.data.timePickerData.forEach(tpd => {
      const dateInPickerTZ = moment.tz(moment(tpd.entered_time).format('YYYY-MM-DDTHH:mm'), this.data.program_time_zone);
      hasError = hasError || this.isDateFieldEmptyOrInvalid(tpd) || this.validateDateRange(tpd, dateInPickerTZ.clone());
    });
    return hasError;
  }

  private isPasswordValid(): boolean {
    return this.passwordEntered === this.passwordRequired;
  }

  private validateDateRange(tpd: ModalDatePickerData, chosenDateUTC: moment.Moment): boolean {
    let hasError = false;
    if (tpd.min_date) {
      const minDate = moment(tpd.min_date);
      if (tpd.min_date_inclusive) {
        if (chosenDateUTC.isBefore(minDate)) {
          tpd.errors.push('Entered date must be the same as or after the event start time.');
          hasError = true;
        }
      } else {
        if (chosenDateUTC.isSameOrBefore(minDate)) {
          tpd.errors.push('Entered date must be after the event start time.');
          hasError = true;
        }
      }
    }
    if (tpd.max_date) {
      const maxDate = moment(tpd.max_date);
      if (tpd.max_date_inclusive) {
        if (chosenDateUTC.isAfter(maxDate)) {
          tpd.errors.push('Entered date must be the same as or before the event end time.');
          hasError = true;
        }
      } else {
        if (chosenDateUTC.isSameOrAfter(maxDate)) {
          tpd.errors.push('Entered date must be before the event end time.');
          hasError = true;
        }
      }
    }
    return hasError;
  }
}
