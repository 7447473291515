import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import * as _ from 'lodash';


@Component({
  selector: 'app-create-error-modal',
  templateUrl: './create-error-modal.component.pug',
  styleUrls: ['./create-error-modal.component.scss']
})
export class CreateErrorModalComponent implements OnInit {

  @Input() data:any;
  failures:Array<any>=[];
  successes:Array<any>=[];

  constructor() { }

  ngOnInit() {

    const allTheShittyThings = [...this.data.response.err.event_errors, ...this.data.response.err.event_node_errors, ...this.data.response.err.event_node_workflow_errors];
    const whatLittleWeGotRight = this.data.response.data;

    whatLittleWeGotRight.forEach((o) => {
      this.successes.push(o.event.event_name)
    });

    allTheShittyThings.forEach((o) => {

      var obj = {label: this.data.postObj.event_name_prefix + ' ' + _.find(this.data.postObj.portfolios, {portfolio_id: o.portfolio_id}).portfolio_display_label, description: o.detail + ' and event_id=' + o.ems_rollback_event_id, overlap_id: o.ems_rollback_event_id};
      this.failures.push(obj)
    });

  }

  goToOverlapEvent(overlapId){
    const url = '/event/' + overlapId;
    window.open(url, '_blank');
  }

  onClose(){
    this.data.close.apply();
  }

}
