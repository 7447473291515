import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {concat, Observable, of, Subject} from "rxjs";
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from "rxjs/operators";
import {EntitySearchService} from "../../../services/entity-search.service";

@Component({
  selector: 'app-entity-search-dropdown',
  templateUrl: './entity-search-dropdown.component.pug',
  styleUrls: ['./entity-search-dropdown.component.scss']
})
export class EntitySearchDropdownComponent implements OnInit {

  @Input()
  entityTypes = ['SITE', 'REGISTRATION', 'ORGANIZATION'];

  @Input()
  resetTrigger: boolean;

  @Output()
  entitySelected = new EventEmitter<any>();

  entitySearchResults$: Observable<any[]>;
  entitySearchQuery$ = new Subject<string>();
  entitySearchLoading = false;
  debounce = 2000;

  typeToSearchText = "Search Site/Org/Reg"
  placeHolderText = "* Search Site/Org/Reg"
  selectedEntity: any;

  constructor(private entitySearchService: EntitySearchService) {
  }

  ngOnInit(): void {
    if(this.entityTypes.includes('REGISTRATION') && this.entityTypes.length === 1){
      this.typeToSearchText = "Search Registration"
      this.placeHolderText = "* Search Registration"
    }
    this.initEntitySearch();
  }

  trackByFn(item: any) {
    return item.id;
  }

  initEntitySearch() {
    this.entitySearchResults$ = concat(
      of([]),
      this.entitySearchQuery$.pipe(
        debounceTime(this.debounce),
        distinctUntilChanged(),
        tap(() => (this.entitySearchLoading = true)),
        switchMap((term) => {
          if (!term) {
            this.entitySearchLoading = false;
            return of([]);
          }
          return this.entitySearchService.searchEntites(term, this.entityTypes).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => (this.entitySearchLoading = false))
          );
        })
      )
    );
  }

  onEntitySelected($event: any){
    this.entitySelected.emit($event)
  }

  reset() {
    this.selectedEntity = null;
    this.entitySearchQuery$.next('');
    this.initEntitySearch();
  }

}
