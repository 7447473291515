import { Component, OnInit } from '@angular/core';
import { ListTab, SharedService } from '../../services/shared.service';
import { EventManagementService } from '../../services/event-management.service';
import { Title } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LoaderActivator } from '../../classes/loader_activator';





@Component({
  selector: 'app-admin-view',
  templateUrl: './admin-view.component.pug',
  styleUrls: ['./admin-view.component.scss']
})
export class AdminViewComponent implements OnInit {

  private ngUnsubscribe: Subject<any> = new Subject();

  autoAccept:boolean;
  adamsToggleDisabled:boolean = true;

  constructor(private sharedService: SharedService, private EMS: EventManagementService, private titleService:Title) {
    this.titleService.setTitle('Administration');
    this.sharedService.setListTab((<any>ListTab['ADMIN']));
  }

  ngOnInit() {
    this.EMS.get('/v1/adams_toggle', null).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (resp) => {
        this.autoAccept = resp.data.auto_accept;
        this.adamsToggleDisabled = false;
      },
      (err) => {

      }
    )
  }

  onChangeAdamsToggle() {
    const controller = this;
    controller.adamsToggleDisabled = true;
    this.sharedService.activateLoader(new LoaderActivator(new Promise(
      function (resolve, reject) {
        resolve(controller.EMS.post('/v1/adams_toggle', {auto_accept: controller.autoAccept}, {}));
      }),
      function(response) {
        controller.adamsToggleDisabled = false;
      },
      function(error) {
        controller.adamsToggleDisabled = false;
      }, false, "Updating AdamsWorker", false, false));

  }

}
