import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { EventManagementService } from '../../services/event-management.service';
import { EventListService } from '../../services/event-list.service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import {
  Column,
  ColumnFilter,
  columns,
  EnumColumnFilter,
  FieldType,
  iColumnFilter,
  operatorNames
} from '../../classes/coulmns';
import * as _ from 'lodash';
import { SharedService } from '../../services/shared.service';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';

@Component({
  selector: 'app-filter-control',
  templateUrl: './filter-control.component.pug',
  styleUrls: ['./filter-control.component.scss']
})
export class FilterControlComponent implements OnInit {

  @ViewChild('enumsFilter', {static:false}) enumsFilter: MatSelect;
  @ViewChild('allEnumsOption', {static:false}) allEnumsOption: MatOption;
  readonly FieldType = FieldType;

  @Input() hideFilters:boolean = false;
  @Input() utility:boolean = false;

  operators: Array<string> = [];
  columns: Array<Column>;

  value: any;
  enums: Array<ColumnFilter>;
  selectedOperator: string = null;
  selectedColumn: Column = null;
  allEnum: ColumnFilter;
  startAtTime;
  enumList;
  DayRanges;
  dayRange;
  eventsLoaded;
  filtersDisabled = true;
  noValueNeeded = false;
  userTzAbbr;

  constructor(private EMS: EventManagementService, private listService: EventListService, private sharedService: SharedService) {
    this.DayRanges = listService.DayRanges;
    this.dayRange = this.DayRanges.one;

    const {loadingEvents$} = this.listService;
    loadingEvents$.pipe().subscribe(
      resp => {
        this.eventsLoaded = !resp;
        this.filtersDisabled = resp;
      }
    );
    if(this.selectedColumn){this.onSelectColumn();}
  }

  ngOnInit() {
    this.columns = columns;
    this.listService.resetAllFilters();
  }

  onSelectColumn(e?):void {
    this.value = null;
    switch (this.selectedColumn.type) {
      case FieldType.DATE:
        this.startAtTime = moment.tz(moment(), this.sharedService.getUsersTimeZone());
        this.userTzAbbr = moment().tz(this.sharedService.getUsersTimeZone()).format('z');
        this.operators = [operatorNames.eq, operatorNames.gt, operatorNames.gte, operatorNames.lt, operatorNames.lte];
        if(this.selectedColumn.field == 'event_end_dttm_utc') {
          this.operators = [...this.operators, operatorNames.is_null, operatorNames.is_not_null ];
        }
        break;
      case FieldType.MULTI:
        this.operators = [operatorNames.contains];
        this.selectedOperator = this.operators[0];
        this.enums = this.listService[this.selectedColumn.field + 'Enum'].map((x) => x.value);

        const cf = _.find(this.listService.filters, {column: this.selectedColumn}) as EnumColumnFilter;
        if (cf) {
          if(_.find(cf.enums, {value: "All"}))
          {
            this.value = this.enums;
          } else {
            this.value = cf.enums.map((x) => x.value)
          }
        }
        this.allEnum = this.enums[0];
        this.enumList = this.enums.slice(1);
        break;
      case FieldType.NUMBER:
        this.operators = [operatorNames.eq, operatorNames.gt, operatorNames.gte, operatorNames.lt, operatorNames.lte];
        break;
    }
    this.selectedOperator = this.operators[0];
    this.onSelectOperator();
  }

  onSelectOperator(){
    this.noValueNeeded = (this.selectedOperator == operatorNames.is_not_null || this.selectedOperator == operatorNames.is_null);
  }

  setDate(newVal){
    this.value = newVal;
  }

  onSelectEnum(){
    this.allEnumsOption.deselect();
    if(this.enumsFilter.value.length == this.enumList.length){
      this.allEnumsOption.select();
    }
  }

  onSelectAllEnum(){
    if(this.allEnumsOption.selected)
    {
      this.enumsFilter.options.forEach( (item: MatOption) => item.select())
    }
    else {
      this.enumsFilter.options.forEach( (item: MatOption) => item.deselect())
    }
  }

  addFilter(){
    switch (this.selectedColumn.type) {
      case FieldType.NUMBER:
        this.listService.addFilter({column: this.selectedColumn, operator: this.selectedOperator, value: this.value} as ColumnFilter);
        break;
      case FieldType.DATE:
        switch (this.selectedOperator) {
          case operatorNames.is_null:
          case operatorNames.is_not_null:
            this.listService.addFilter({column: this.selectedColumn, operator: this.selectedOperator, value:null} as ColumnFilter);
            break;
          default:
            const offset = moment.tz(this.sharedService.getUsersTimeZone()).format('Z');
            const enteredDate = moment(this.value).format();
            const userLocalTime = enteredDate.slice(0, enteredDate.length - 6) + offset;
            this.listService.addFilter({column: this.selectedColumn, operator: this.selectedOperator, value:userLocalTime} as ColumnFilter);
            break;
        }
        break;
      case FieldType.MULTI:
        if(!this.value || !this.value.length){
          this.value = ['All']
        }
        const allSelected = this.value.indexOf('All') > -1;
        if(allSelected) this.value = ['All'];

        this.value = this.value.map((x) => {return {column: this.selectedColumn, operator: operatorNames.contains, value: x} as ColumnFilter});

        this.listService.addFilter({column: this.selectedColumn, enums: this.value} as EnumColumnFilter);
        break;
    }

    this.onClearFilter();
  }

  onClearFilter():void {
    this.selectedColumn = null;
    this.selectedOperator = null;
    this.operators = [];
    this.value = null;
  }

  resetFilters(){
    this.dayRange = this.DayRanges.one;
    this.listService.resetAllFilters();
    this.refreshEvents(true)
  }

  refreshEvents(disableFilters:boolean = false):void {
    this.filtersDisabled = disableFilters;
    this.eventsLoaded = false;
    this.listService.resetDayRange(this.dayRange, this.utility);
  }

}
