import {Component, Input, OnInit} from '@angular/core';
import {SharedService} from "../../../services/shared.service";
import {ModalDatePickerData} from "../../../classes/modalDatePickerData";
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';

@Component({
  selector: 'app-workflow-step-edit-modal',
  templateUrl: './workflow-step-edit-modal.component.pug',
  styleUrls: ['./workflow-step-edit-modal.component.scss']
})
export class WorkflowStepEditModalComponent implements OnInit {

  constructor(private shared:SharedService) { }

  @Input() data:any;
  automated:boolean = false;
  included:boolean = false;

  ngOnInit() {

    //Set some basic data based on what the step currently has
    if(this.data.automated !== undefined && this.data.automated !== null)
      this.automated = this.data.automated;
    if(this.data.defaultTime !== undefined && this.data.defaultTime !== null) {
      var iso = moment(this.data.defaultTime).toISOString();
      let convertedTime = moment.tz(iso, this.shared.getTimeZoneName(this.data.program_time_zone, false));
      this.data.timePickerData[0].entered_time = convertedTime.format('YYYY-MM-DDTHH:mm');
    }
  }

  confirm() {

    let hasErrors: boolean = false;
    let datesToSend = [];

    let tpd = this.data.timePickerData;
    const date = moment(tpd[0].entered_time);

    tpd.errors = [];

    if (date.isValid()) {
      let chosenDateUTC = this.shared.selectedTimeZoneToUTC(date, this.data.program_time_zone);

      if (chosenDateUTC.isBefore(moment.utc(tpd[0].min_date))) {

        tpd.errors.push('Entered date must be in the future');
      }

      if (chosenDateUTC.isAfter(moment.utc(tpd[0].max_date))) {

        tpd.errors.push('Entered date must be in the past');
      }


      if (tpd.errors.length > 0) {
        hasErrors = true;
      } else {
        datesToSend.push(chosenDateUTC.toISOString())
      }
    }
    else {
      if (!tpd.entered_time && tpd.allow_null) {
        datesToSend.push(null);
      } else {
        hasErrors = true;
        tpd.errors.push('Invalid date entered.');
      }
    }


    if (hasErrors === false) {

      let rescheduleBody;

      rescheduleBody = {
          time: datesToSend[0],
          automated: this.automated
        };

      if(this.data.showInclude && this.included)
        rescheduleBody['include'] = true;

      this.data.confirmFunction(rescheduleBody);
      this.data.close();
    }
  }

  close() {
    this.data.close();
  }

  getTimezone() {
    return this.shared.getTimeZoneName(this.data.program_time_zone);
  }

  getTimeZoneConversion(timePickerData:ModalDatePickerData, zone)
  {
    let localTZ = this.shared.getUsersTimeZone();
    let tz;

    switch (zone) {
      case 'local':
        tz = localTZ;
        break;
      case 'program':
        tz = this.data.program_time_zone;
        break;
      case 'utc':
        tz = 'UTC';
        break;
    }

    let convertedTime: string = this.shared.selectedTimeZoneToUTC(timePickerData.entered_time, this.data.program_time_zone).toISOString();
    return timePickerData.entered_time ? moment.tz(convertedTime, tz).format(SharedService.dateFormat) : 'None';
  }

  //Gets the date from the child datepicker and sets it in our corresponding timepicker data object
  getTimeFromChild(time, tpd) {
    tpd.entered_time = time.date;
  }
}
