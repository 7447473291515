import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { EPSEvent } from '../../classes/event';
import {Sort} from '@angular/material/sort';
import { MatSort } from '@angular/material/sort';
import { EventListService } from '../../services/event-list.service';
import * as moment from 'moment';
import { SharedService } from '../../services/shared.service';


@Component({
  selector: 'app-event-list-table',
  templateUrl: './event-list-table.component.pug',
  styleUrls: ['./event-list-table.component.scss']
})


export class EventListTableComponent implements OnInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  @Input()
  private isTraining: Boolean = false;

  filteredEvents: EPSEvent[];
  displayedColumns: string[] = ['operator_display_label', 'ems_program_display_label', 'product_display_label', 'event_action_type_display_label', 'portfolio_display_label', 'obligation', 'event_performance_percent', 'total_availability', 'event_start_dttm_utc', 'event_end_dttm_utc', 'num_participating_units', 'created_by_name', 'created_dttm', 'status'];
  sortedData: EPSEvent[];
  currentSort:Sort = {active: null, direction: null};

  constructor(private listService: EventListService, private SS: SharedService) {
    const {filteredEvents$} = this.listService;
    filteredEvents$.pipe().subscribe(
      (response) => {
        this.filteredEvents = response;
        this.sortData(this.currentSort)
      }
    )
  }

  ngOnInit() {

  }

  getPerformance(event:EPSEvent) {
    if(event.event_progress_status == 'AFTER'){
      const performanceVal = this.SS.getOverallPerformance(event, event.product);
      const expectedCapacity = event.sum_expected_capacity_value;
      const performancePerCent = expectedCapacity === 0 ? 0 : Math.round(performanceVal / expectedCapacity * 100);
      return performancePerCent.toFixed(1) + ' %'
    } else {
      return event.event_performance_percent !=null ? (event.event_performance_percent).toFixed(1) + ' %' : ''
    }
  }

  goToEventDetail(event): void {
    const eventUrlSnippet = this.isTraining ? '/training/event/' : '/event/';
    const url = window.location.origin + eventUrlSnippet + event.event_id;
    window.open(url, '_blank');
  }

  sortData(sort:Sort): void {
    this.currentSort = sort;
    const data = this.filteredEvents.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'operator_display_label': return compare(a.operator_display_label, b.operator_display_label, isAsc);
        case 'ems_program_display_label': return compare(a.ems_program_display_label, b.ems_program_display_label, isAsc);
        case 'product_display_label': return compare(a.product_display_label, b.product_display_label, isAsc);
        case 'event_action_type_display_label': return compare(a.event_action_type_display_label, b.event_action_type_display_label, isAsc);
        case 'portfolio_display_label': return compare(a.portfolio_display_label, b.portfolio_display_label, isAsc);
        case 'obligation': return compare(a.obligation, b.obligation, isAsc);
        case 'event_performance_percent': return compare(a.event_performance_percent, b.event_performance_percent, isAsc);
        case 'total_availability': return compare(a.total_availability, b.total_availability, isAsc);
        case 'event_start_dttm_utc': return dateCompare(a.event_start_dttm_utc, b.event_start_dttm_utc, isAsc);
        case 'event_end_dttm_utc': return dateCompare(a.event_end_dttm_utc, b.event_end_dttm_utc, isAsc);
        case 'num_participating_units': return compare(a.num_participating_units, b.num_participating_units, isAsc);
        case 'created_by_name': return compare(a.created_by_name, b.created_by_name, isAsc);
        case 'created_dttm': return dateCompare(a.created_dttm, b.created_dttm, isAsc);
        case 'status': return compare(a.status, b.status, isAsc);
        default: return 0;
      }
    });
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
function dateCompare(a: string, b: string, isAsc: boolean) {
  let num = 0;
  if(!a){
    num = 1;
  }else if(!b) {
    num = -1;
  }
  else {
    const aDate = moment(a);
    const bDate = moment(b);
    num = aDate.isBefore(bDate) ? -1 : 1;
  }

  return num * (isAsc ? 1 : -1);
}
