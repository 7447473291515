import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatTableDataSource} from "@angular/material/table";
import {EventNodeRow, EventNodeTableData} from "./event-node-search-table-model";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {SharedService} from "../../../services/shared.service";
import {NgxDropdownOption} from "ngx-dropdown";
import {RefDataService} from "../../../services/ref-data.service";
import {Subject} from "rxjs/internal/Subject";

@Component({
  selector: 'app-event-node-search-table',
  templateUrl: './event-node-search-table.component.pug',
  styleUrls: ['./event-node-search-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', visibility: 'hidden'})),
      state('expanded', style({height: '*', visibility: 'visible'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class EventNodeSearchTableComponent implements AfterViewInit, OnInit {

  @Input() set gridValue(g: EventNodeTableData) {
    this.rowCount = g.rows.length;
    this.dataSource = new MatTableDataSource<EventNodeRow>(g.rows);
  }

  @Input() set selectedLookbackOption(value: NgxDropdownOption) {
    this.selectedLookbackOptionValue = this.lookbackOptions.find(option => option.id === value.id);
  };

  @Input() set selectedEventTypeOptions(selectedValues: NgxDropdownOption[]) {
    if (this.eventTypeOptions.length > 0) {
      this.selectedEventTypeOptionValues = this.eventTypeOptions.filter(option =>
        selectedValues.some(selectedValue => selectedValue.id === option.id)
      );
    }

    this.eventTypesSubject$.subscribe((options) => {
      this.selectedEventTypeOptionValues = options.filter(option =>
        selectedValues.some(selectedValue => selectedValue.id === option.id)
      );
    });
  };

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  @Output() lookbackChanged: EventEmitter<any> = new EventEmitter();
  @Output() eventTypeChanged: EventEmitter<any> = new EventEmitter();

  selectedLookbackOptionValue: NgxDropdownOption;
  selectedEventTypeOptionValues: NgxDropdownOption[] = [];

  lookbackOptions: NgxDropdownOption[] = [
    {value: '1 Day', id: '1'},
    {value: '7 Days', id: '7'},
    {value: '30 Days', id: '30'},
  ];

  eventTypesSubject$ = new Subject<NgxDropdownOption[]>();
  eventTypeOptions: NgxDropdownOption[] = [];
  dateFormat = 'yyyy/MM/dd HH:mm z';
  dataSource!: MatTableDataSource<EventNodeRow>;
  columnsToDisplay = ['status', 'registration_display_labels', 'operator_name', 'program_name', 'product_name', 'portfolio_display_label', 'event_type'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement!: EventNodeRow | null;
  rowCount = 0;
  showFilters = false;

  constructor(
    private sharedService: SharedService,
    private RDS: RefDataService
  ) {
    console.log('EventNodeSearchTableComponent constructor');
    this.RDS.getEventTypes().subscribe((res) => {
      this.eventTypeOptions = res
        .filter(eventType => eventType.code !== 'TRAINING' && eventType.code !== 'OFFICIAL_UFR')
        .map((eventType) => {
        return {value: this.sharedService.flattenDisplayLabel(eventType.display_label), id: eventType.code};
      })
      this.eventTypesSubject$.next(this.eventTypeOptions)
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item, property) => {
      const data = item[property];
      return typeof data === "object" ? this.sharedService.flattenDisplayLabel(data) : item[property];
    }
  }

  ngOnInit() {
    this.showFilters = true;
  }

  getEventNodeStatusLabel(status: string) {
    const statusMap = {
      'active': 'ACTIVE',
      'pending': 'PENDING',
      'completed': 'COMPLETED',
      'excluded': 'EXCLUDED',
      'cancelled': 'CANCELLED',
      'optedOut': 'OPTED OUT',
    };

    return statusMap[status] || '';
  }

  // Just to keep nice syntax for css class names
  getEventNodeStatusClassName(status) {
    return (status === 'optedOut' ? 'opted-out' : status)
  }


  getConfirmationStatusLabel(status: string) {
    const statusMap = {
      'CONFIRMED': 'Confirmed',
      'UNCONFIRMED': 'Unconfirmed',
      'PREAUTHORIZED': 'Preauthorized',
      'CURTAIL_APPROVED': 'Curtail Approved',
    };
    return statusMap[status] || '';
  }

  openEventNodeInfoPage(event_node_id: string | undefined) {
    const url = window.location.origin + '/eventnode/' + event_node_id;
    window.open(url, '_blank');
  }

  openEventPage(event_id: string | undefined) {
    const url = window.location.origin + '/event/' + event_id;
    window.open(url, '_blank');
  }

  onLookbackChange(event: any) {
    if (this.selectedLookbackOptionValue !== event) {
      this.lookbackChanged.emit(event);
    }
  }

  onEventTypeChange(events: any[]) {
    if (this.selectedEventTypeOptionValues.length === 0 && events.length === 0) {
      return;
    }
    if (this.selectedEventTypeOptionValues != events) {
      this.eventTypeChanged.emit(events);
    }
  }

  getEventTypeLabel(eventType: string) {
    return this.eventTypeOptions.find(option => option.id === eventType)?.value || '';
  }
}
