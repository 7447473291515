import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ApiTime } from 'common-utils/dist/models/time';
import * as Highcharts from 'highcharts';
import { PositionObject, TooltipPositionerPointObject } from 'highcharts';
import More from 'highcharts/highcharts-more';
import * as _ from 'lodash';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';
import { MqttService } from "ngx-mqtt";
import { of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { EPSEvent } from '../../classes/event';
import { EventNode, RegistrationType } from '../../classes/event_node';
import { NodePerformance, NodePerformanceIot, parse } from '../../classes/node-performance';
import { Product, TargetType } from '../../classes/product';
import { Registration } from '../../classes/registration';
import { EventManagementService } from '../../services/event-management.service';
import { MqttSubscriptionService } from '../../services/mqtt-subscription.service';
import { SharedService } from '../../services/shared.service';
import { baselineAdjustmentWhitelist as productsWithBaselineAdjustment } from "./baselineAdjustmentWhitelist";

More(Highcharts);

@Component({
  selector: 'app-node-performance',
  templateUrl: './node-performance.component.pug',
  styleUrls: ['./node-performance.component.scss']
})
export class NodePerformanceComponent implements OnInit, OnChanges  {
  Highcharts = Highcharts;
  chart: Highcharts.Chart;
  viewModel: DispatchGraphViewModel = new DispatchGraphViewModel();
  updateChart: true;
  loadingData = false;
  noDataToShow = false;
  curtailmentPlan = '';
  curtailmentPlanReceived = false;
  private ngUnsubscribe: Subject<any> = new Subject();
  private nodePerformanceSubscribe$;
  private perfValues: NodePerformance[] = [];
  private tz: string;
  private tzShort:string;
  private isFirstTimeLoad: boolean;
  perfSub;

  chartOptions: Highcharts.Options = {
    title : { text : '' },
    chart: {
      style: { fontFamily: 'ProximaNova' },
    },
    plotOptions: {
      series: {
        connectNulls: true,
      },
    },
    legend: {reversed: true},
    credits: { enabled: false },
  };

  options = this.chartOptions;

  @Input() event: EPSEvent;
  @Input() eventNode: EventNode;
  @Input() product: Product;
  @Input() utility:boolean = false;
  @Input() registration:Registration;


  constructor(private EMS: EventManagementService, private MQTT: MqttService, private SS: SharedService) {
    this.perfSub = new MqttSubscriptionService();
    this.perfSub.connectionLost.subscribe(()=>{
      this.getNodePerformanceData(this.eventNode, this.event, this.product);
    })
  }

  ngOnInit() {
    if(this.eventNode.event_node_start_dttm_utc === null || this.eventNode.event_node_start_dttm_utc === '') {
      this.noDataToShow = true;
      this.loadingData = false;
    } else {
      this.eventNode.chart_end_time = this.eventNode.event_node_end_dttm_utc ? this.eventNode.event_node_end_dttm_utc : moment(this.event.event_start_dttm_utc).add(this.product.max_event_duration, 'milliseconds').format(ApiTime.momentFormat);
      this.getNodePerformanceData(this.eventNode, this.event, this.product);
    }

    this.tz = this.event.full_time_zone;
    this.tzShort = moment().tz(this.tz).format('z');
  }

  ngOnChanges():void {}

  getNodePerformanceData(node: EventNode, event: EPSEvent, product:Product) {
    const controller = this;

    //A little fuckery here.  We can't have nodes with null end times or the chart won't work.  So, if it ain't there, add the product max duration to the event start time and senak it in.
    if(node.event_node_start_dttm_utc === null){
      node.event_node_start_dttm_utc = moment(event.event_start_dttm_utc).add(event.product.max_event_duration, 'milliseconds').format(ApiTime.momentFormat);
    }


    node.pre_event_buffer = event.source_system_type === 'CLASSIC_DR' ? 7200000 : 1200000;
    node.post_event_buffer = event.source_system_type === 'CLASSIC_DR' ? 7200000 : 1200000;
    // From here, we have to adjust the pre_event_buffer to account for the addition of a baseline adjustment window or bonus minutes.
    // see if there's a difference between the actual event start time and the start time we're displaying in the graph.
    const startTimesDiff = moment.duration(moment(node.event_node_start_dttm_utc).diff(moment(event.event_start_dttm_utc))).as('milliseconds');


    // Baseline Adjustment Window.
    // don't bother if the offsets are the same
    /*if(node.adjustment_window_start !== node.adjustment_window_end) {
      const offsetStart = moment.duration(moment(event.event_start_dttm_utc).diff(moment(node.adjustment_window_start))).as('milliseconds');
      node.pre_event_buffer = Math.max(node.pre_event_buffer, (offsetStart));
    }*/

    // Bonus minutes
    if(this.product != null) {
      let bonus_minutes = this.product.bonus_minutes || 0;
      let ramp_period = this.product.dispatch_conf?.ramping_period || 0;
      node.pre_event_buffer = Math.max(node.pre_event_buffer, (startTimesDiff + bonus_minutes * 60000), (startTimesDiff + ramp_period));
    }

    const startDateTime = moment.tz(node.event_node_start_dttm_utc, this.tz).subtract(node.pre_event_buffer, 'milliseconds');
    const endDateTime = moment.tz(node.chart_end_time, this.tz).add(node.post_event_buffer, 'milliseconds');


    let frequency = 300000;
    if (product && product.reporting_interval_ms != null && product.reporting_interval_ms > 0) {
      frequency = Math.max(product.reporting_interval_ms, 60000);
    }

    const frequencyInMinutes = frequency/60000;
    startDateTime.startOf('minute').minute(frequencyInMinutes * Math.round(startDateTime.minute() / frequencyInMinutes)); // TODO think of a better way for this. We should not need to round to the nearest X minute to show intervals
    endDateTime.startOf('minute').minute(frequencyInMinutes * Math.round(endDateTime.minute() / frequencyInMinutes));     // TODO think of a better way for this. We should not need to round to the nearest X minute to show intervals

    const intervals = this.determineIntervals(startDateTime, endDateTime, frequency);

    this.EMS.get('/v1/event_node_performance/' + node.site_id + '/' + node.event_node_id, {}).pipe(
      map(({data}) => data),
      map((intervalData) => controller.massageTheIntervals(intervalData, controller.product)),
      map((nodes) => nodes.map((evn) => parse(evn, this.tz))),
      map((perf) => this.gapFilling(perf, intervals, node.event_id, node.site_id, node.event_node_id)),
    ).subscribe(
      (resp) => {
        this.perfValues = resp;
        controller.viewModel = controller.populateViewModel(controller.eventNode, controller.event, resp);
        this.setChartOptions();
        this.loadingData = false;

        if(product.reporting_interval_ms >= 60000) {
          controller.perfSub.subscribeToTopic([node.event_node_id], 'NODE_PERFORMANCE', (msg) => {
            of(msg as NodePerformanceIot)
              .pipe(
                map((iotUpdate) => controller.updateNodes(controller.perfValues, iotUpdate)),
                map((perf) => controller.gapFilling(perf, intervals, node.event_id, node.site_id, node.event_node_id)),
              ).subscribe(
                (resp) => {
                  this.perfValues = resp;
                  controller.viewModel = controller.populateViewModel(controller.eventNode, controller.event, resp);
                  this.setChartOptions();
                },
                () =>{
                  console.log("Error getting node performance data.")
                }
              )
          })
        }
      },
      (error) => {
        console.log("Error getting node performance data.")
      }
    );

    this.EMS.get('/v1/node_curtailment_plan/' + node.site_id + '/' + node.event_node_id, {}).pipe(
      map(({ data }) => data ? data[0] : [])
    ).subscribe(
      (resp) => {
        this.curtailmentPlan = resp.curtailment_plan;
        this.curtailmentPlanReceived = true;
      },
      (err) => {
        this.curtailmentPlanReceived = true;
      }
    )
  }

  massageTheIntervals(intervalData, product) {
    if(product.reporting_interval_ms < 60000) {
      intervalData.forEach((i)=>{
        i.interval_dttm_utc = moment(i.interval_dttm_utc).startOf('minute').toISOString();
      })
      console.log(_.uniqBy(intervalData, 'interval_dttm_utc'))
      return _.uniqBy(intervalData, 'interval_dttm_utc')
    }
    return intervalData;
  }

  updateNodes(performances: NodePerformance[], update: NodePerformanceIot): NodePerformance[] {
    const node = performances.find(({interval_dttm_utc}) => update.interval_dttm_utc === interval_dttm_utc);
    return node && performances.map((perf) => (perf === node) ? update : perf) || performances;
  }

  gapFilling(performances: NodePerformance[], intervals: string[], eventId: string, siteId: string, nodeId: string): NodePerformance[] {
    return intervals.map((interval) => {
      const predicate = performances.find((a) => moment(a.interval.fullDate).toISOString() === interval);
      if (predicate) {
        return predicate;
      } else {
        return {
          event_node_id: nodeId,
          site_id: siteId,
          event_id: eventId,
          interval_dttm_utc: interval,
          metered_value: null,
          baseline_value: null,
          target_value: null,
          adjusted_baseline_value: null,
          interval: new ApiTime(moment(interval).format(ApiTime.momentFormat))
        } as NodePerformance;
      }
    });
  }

  determineIntervals(startTime: moment.Moment, endTime: moment.Moment, granularity: number): string[] {
    const range: string[] = [];

    while (startTime < endTime) {
      range.push(startTime.toISOString());
      startTime.add(granularity, 'milliseconds');
    }

    return range;
  }

  /*=================================================================================================================================================*/

  setChartOptions() {
    let chartWidth:any = null ;
    const x = moment(this.viewModel.dataRange[0]);
    const y = moment(this.viewModel.dataRange[1]);
    const units = moment.duration(y.diff(x)).as('minutes');
    const controller = this;

    if(this.viewModel.frequency === 60000 && units > 59) {
      chartWidth = 582 + 87 * (units/5);
    }
    if(this.viewModel.frequency === 300000 && units > 295) {
      chartWidth = 582 + 18 * (units/5);
    }

    // Calculate post ramping time
    let postRampingPeriod = 1200000; // default
    const dispatchConf = this.viewModel.product.dispatch_conf;
    if (dispatchConf.post_bonus_minutes > 0 || dispatchConf.post_ramping_period > 0) {
      const post_bonus_ms = dispatchConf.post_bonus_minutes * 60 * 1000;
      postRampingPeriod = post_bonus_ms > dispatchConf.post_ramping_period
                            ? post_bonus_ms
                            : dispatchConf.post_ramping_period;
    }

    this.options.xAxis = {
      type: 'datetime',
      minTickInterval: 6000 * 5,
      labels: {
        formatter: (() => {
          return function() {
            const time = moment.tz(this.value, controller.tz).format('HH:mm:ss');
            return `${time} ${controller.tzShort}`;
          };
        })(),
      },
      tickWidth: 1,
      crosshair: { width: 0 },
    };
    this.options.yAxis = {
      title: {text: this.viewModel.showGenLoad ? 'Power' : 'Electricity Demand (kW)'},
      maxPadding: .1
    };

    this.chart.update({
      chart: {
        scrollablePlotArea: {
          minWidth: chartWidth
        },
        style: {
          fontFamily: 'RoobertENEL-Light, sans-serif'}
      },
      series: [
        { // bogus series to get the label into the legend
          type: 'area',
          name: 'Baseline Adjustment',
          color: '#D4F87F',
          showInLegend: this.viewModel.show_baseline_adjustment,
        },
        { // bogus series to get the label into the legend
          type: 'area',
          name: 'Ramp Period',
          color: '#BFDBFB',
          showInLegend: this.viewModel.show_bonus_minutes,
        },
        {
          data: this.viewModel.target,
          name: 'Target',
          type: this.viewModel.showTargetRange ? 'arearange' : 'area',
          color: '#59bc5f',
          fillOpacity: .25,
          lineWidth: 1,
          threshold: this.viewModel.showGenLoad ? Infinity : -Infinity,
          marker: {
            enabled: false,
            symbol: 'circle',
          },
        },
        {
          data: this.viewModel.demand,
          name: 'Demand',
          type: 'spline',
          color: '#461E7D',
          lineWidth: 2,
          visible: this.viewModel.showDemand,
          marker: {
            enabled: false,
            symbol: 'circle',
            radius: 2,
            states: {
              hover: {
                fillColor: 'white',
                lineColor: '#461E7D',
              },
            },
          },
          showInLegend: this.viewModel.showDemand,
        },
        {
          data: this.viewModel.baseline,
          name: 'Baseline',
          type: 'spline',
          color: '#717171',
          lineWidth: 2,
          zIndex: -1,
          marker: { enabled: false,
            symbol: 'circle', },
          states: { hover: { enabled: false } },
          visible: this.viewModel.showBaseline,
          showInLegend: this.viewModel.showBaseline,
        },
        {
          data: this.viewModel.performance,
          name: 'Performance',
          type: 'spline',
          color: '#461E7D',
          lineWidth: 2,
          visible: this.viewModel.showGenLoad,
          marker: {
            enabled: false,
            symbol: 'circle',
            radius: 2,
            states: {
              hover: {
                fillColor: 'white',
                lineColor: '#461E7D',
              },
            },
          },
          showInLegend: this.viewModel.showGenLoad,
        },
      ],
      plotOptions: {
        series: {
          turboThreshold: 0,
          states: {
            inactive: {
              opacity: 1
            }
          }
        }
      },
      tooltip: {
        shared: true,
        useHTML: true,
        borderWidth: 1,
        borderColor: '#EEEEEE',
        borderRadius: 2,
        backgroundColor: 'white',
        shape: 'rect',
        positioner: ((labelWidth: number, labelHeight: number, point: TooltipPositionerPointObject): PositionObject => {
          return {x: point.plotX, y: point.plotY};
        }),
        formatter: (() => {
          const instance = this;
          return function() {
            const expectedCapacity: number = controller.utility ? controller.eventNode.registered_capacity_value : controller.eventNode.expected_capacity_value;
            const regType: RegistrationType = controller.eventNode.registration_type as RegistrationType;
            const timeValue: string = (instance.options.xAxis as Highcharts.AxisOptions).labels!.formatter!.call({ value: this.x });
            let baselinePoint, meteredPoint, targetPoint, performancePoint;
            this.points.forEach(point => {
              const field = point.point['field'];
              baselinePoint = field === 'baseline' ? point : baselinePoint;
              meteredPoint = field === 'metered' ? point : meteredPoint;
              targetPoint = field === 'target' ? point : targetPoint;
              performancePoint = field === 'performance' ? point : performancePoint;
            });
            let performanceVal, performancePerCent;

            function getTargetValue(point): string {
              if (instance.viewModel.showTargetRange) {
                return point.point.low.toFixed(2) + ' - ' + point.point.high.toFixed(2);
              }
              return Math.round(point.y).toString();
            }

            function shouldShowShortfall(meteredPoint, targetPoint): boolean {
              return controller.viewModel.registrationType === RegistrationType.LOAD_DROP_TO
                && !!meteredPoint && !!targetPoint && (meteredPoint.y > targetPoint.y);
            }

            let rows = '';
            if(controller.viewModel.showGenLoad) {
              // GENERATOR, STORAGE
              if(!!performancePoint && !!performancePoint.point['metered']) {
                rows += '<tr><td></td><td>Generation</td><td>' + Math.round(performancePoint.point['metered']).toString() + ' kW</td></tr>'
              }
              if(!!targetPoint) {
                rows += '<tr><td style="color:#59bc5f">&#9679</td><td>Target</td><td>' + getTargetValue(targetPoint) + ' kW</td></tr>'
              }
              if(!!performancePoint) {
                performanceVal = performancePoint.y;
                rows += '<tr><td style="color:#461E7D">&mdash;</td><td>Performance</td><td>' + Math.round(performanceVal).toString() + ' kW (' + Math.round(performanceVal / expectedCapacity * 100) +')%</td></tr>'
              }
            } else {
              // LOAD, DEMAND_ON
              if(!!baselinePoint) {
                rows += '<tr><td style="color:#717171">&mdash;</td><td>Baseline</td><td>' + Math.round(baselinePoint.y).toString() + ' kW</td></tr>'
              }
              if(!!meteredPoint) {
                rows += '<tr><td style="color:#461E7D">&mdash;</td><td>Demand</td><td>' + Math.round(meteredPoint.y).toString() + ' kW</td></tr>'
              }
              if(!!targetPoint) {
                rows += '<tr><td style="color:#59bc5f">&#9679</td><td>Target</td><td>' + getTargetValue(targetPoint) + ' kW</td></tr>'
              }
              if (shouldShowShortfall(meteredPoint, targetPoint)) {
                const shortfall = Math.round(meteredPoint.y - targetPoint.y);
                rows += '<tr><td></td><td>Shortfall</td><td>' + shortfall + ' kW</td></tr>'
              }
              if(baselinePoint &&  meteredPoint && targetPoint) {
                performanceVal = baselinePoint.y - meteredPoint.y;
                performancePerCent = expectedCapacity === 0 ? 0 : Math.round(performanceVal / expectedCapacity * 100);
                rows += '<tr><td></td><td>Performance</td><td>' + Math.round(performanceVal).toString() + ' kW (' + Math.round(performancePerCent).toString() +'%)</td></tr>'
              }
            }

            return '<span>' + timeValue  + '</span><table>' + rows + '</table>'
          };
        })(),
      },
      legend: {reversed: true},
      yAxis: {
          title: {
            text: this.viewModel.showGenLoad ? 'Power' : 'Electricity Demand (kW)'
          },
            maxPadding: .1
        },
        xAxis: {
          type: 'datetime',
          max: this.viewModel.to + postRampingPeriod,
          minTickInterval: 6000 * 5,
          labels: {
            formatter: (() => {
              return function() {
                const time = moment.tz(this.value, controller.tz).format('HH:mm:ss');
                return `${time} ${controller.tzShort}`;
              };
            })(),
        },
        tickWidth: 1,
        crosshair: { width: 0 },
        plotBands: [
          {
            color: '#F1F1F2',
            from: 0,
            to: this.viewModel.from,
          },
          {
            color: '#F1F1F2',
            from: this.viewModel.to,
            to: Infinity,
          },
          {
            color: '#BFDBFB',
            from: this.viewModel.bonusMinutesFrom,
            to: this.viewModel.bonusMinutesTo,
          },
          this.viewModel.show_baseline_adjustment ? {
            color: '#D4F87F',
            from: this.viewModel.baselineAdjFrom,
            to: this.viewModel.baselineAdjTo,
          } : {},
        ],
      },
      credits: { enabled: false },
    }, true, true);
  }

  setChart(chart: Highcharts.Chart) {
    this.chart = chart;
  };

  filterByRange<T>([from, to]: [number, number], accessor: (d: T) => number): (d: T) => boolean {
    return (d: T) => {
      const t = accessor(d);
      return from <= t && t <= to;
    };
  }

  /*=================================================================================================================================================*/
  getTargetValue(node: EventNode, event:EPSEvent, t, b): any {
    if (b.baseline_value !== null) {
      const isGen = node.registration_type === RegistrationType.GENERATOR || node.registration_type === RegistrationType.STORAGE;
      const prod = this.product;
      const targetType = prod.target_type || TargetType.DROP_BY;
      const perfTargetMin = prod.performance_target_min || 100;
      const perfTargetMax = prod.performance_target_max || (perfTargetMin * 1.5);
      let dropTarget;
      let targetLow;
      let targetHigh;

      let capValue = this.utility ? node.registered_capacity_value : node.expected_capacity_value;

      if(targetType == TargetType.RANGE){
        targetLow = b.baseline_value - (capValue  * perfTargetMin / 100);
        targetHigh = b.baseline_value - (capValue  * perfTargetMax / 100);
        return { x:t, low:targetLow, high:targetHigh, targetVal:b.target_value};
      } else {
        return { x:t, y:b.target_value};
      }
    } else {
      return {x:t, low:null, high:null, targetVal:b.target_value};
    }
  }
  populateViewModel(node: EventNode, event:EPSEvent, intervals: NodePerformance[]){
    const es = new ApiTime(moment.tz(event.event_start_dttm_utc, this.tz).format(ApiTime.momentFormat));
    const ens = new ApiTime(moment.tz(node.event_node_start_dttm_utc, this.tz).format(ApiTime.momentFormat));
    const ene = new ApiTime(moment.tz(node.chart_end_time, this.tz).format(ApiTime.momentFormat));
    const eventRange = [ens.time, ene.time] as [number, number];
    const dataRange = [ens.time - node.pre_event_buffer, ene.time + node.post_event_buffer] as [number, number];
    const viewModel: DispatchGraphViewModel = intervals
      .filter(this.filterByRange(dataRange, (d) => d.interval.time))
      .reduce((a, b) => {
        const t = b.interval.time;
        a.baseline.push({x:t, y:b.baseline_value, field: 'baseline'});
        a.demand.push({x:t, y:b.metered_value, field: 'metered'});
        a.target.push({...this.getTargetValue(node, event, t, b), field: 'target', metered: b.metered_value });
        a.performance.push({x:t, y:b.performance_value, field: 'performance', metered: b.metered_value});
        return a;
      }, {
        from: ens.time,
        to: ene.time,
        dataRange: dataRange,
        demand: [],
        baseline: [],
        target: [],
        performance: [],
        product: this.product,
        registrationType: node.registration_type,
        frequency: this.product ? this.product.reporting_interval_ms : 300000,
        baselineAdjFrom: new Date(node.adjustment_window_start).getTime(),
        baselineAdjTo: new Date(node.adjustment_window_end).getTime(),
        bonusMinutesFrom: es.time - this.product.bonus_minutes * 60000,
        bonusMinutesTo: es.time,
        show_baseline_adjustment: productsWithBaselineAdjustment[environment.environment].includes(this.product.id),
        show_bonus_minutes: this.product.bonus_minutes != null &&  this.product.bonus_minutes > 0,
        showBaseline: node.registration_type === RegistrationType.LOAD || node.registration_type === RegistrationType.DEMAND_ON || node.registration_type === RegistrationType.LOAD_METERED_GENERATOR,
        showDemand: node.registration_type === RegistrationType.LOAD || node.registration_type === RegistrationType.DEMAND_ON || node.registration_type === RegistrationType.LOAD_METERED_GENERATOR || node.registration_type === RegistrationType.LOAD_DROP_TO,
        showGenLoad: node.registration_type === RegistrationType.GENERATOR || node.registration_type === RegistrationType.STORAGE,
        showTargetRange: this.product.target_type === TargetType.RANGE,
      } as DispatchGraphViewModel);

    return {
      ...viewModel,
      target: viewModel.target.filter(this.filterByRange(eventRange, (d) => d.x)),
    };
  }

}

export class DispatchGraphViewModel {
  readonly from: number = 0;
  readonly to: number = 0;
  readonly dataRange: Array<number> = [0,0];
  readonly baseline: any[] = [[0,0]];
  readonly demand: any[] = [[0,0]];
  readonly target: any[] = [[0,0]];
  readonly performance: any[] = [[0,0]];
  readonly product: Product = null;
  readonly registrationType: string = '';
  readonly frequency: number = 0;
  readonly baselineAdjFrom: number = 0;
  readonly baselineAdjTo: number = 0;
  readonly bonusMinutesFrom: number = 0;
  readonly bonusMinutesTo: number = 0;
  readonly show_baseline_adjustment: boolean = false;
  readonly show_bonus_minutes: boolean = false;
  readonly showBaseline: boolean = false;
  readonly showDemand: boolean = false;
  readonly showGenLoad: boolean = false;
  readonly showTargetRange: boolean = false;
}
