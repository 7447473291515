import { Pipe, PipeTransform } from '@angular/core';
import { SharedService } from '../services/shared.service';

@Pipe({
  name: 'l10nconversion'
})
export class L10nconversionPipe implements PipeTransform {

  constructor(private sharedService:SharedService){}

  transform(value: unknown, ...args: unknown[]): unknown {
    return this.sharedService.flattenDisplayLabel(value);
  }

}
