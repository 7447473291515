import { EventNode, EventNodeWrapper } from './event_node';


class CustomTask {

  custom_task_id: string;
  action_parameter: string;
  filter_type: string;
  completed_time: string;
  cancelled: string;
  scheduled_time: string;
  automated: boolean;
  full_time_zone: string;
  delivery_methods: string[];
  event_nodes: string[];
  action_type: string;

  //Added for our UI logic
  filter_display_label: string;
  template_display_label:string;
  selectedInGrid:boolean;
  wrapper: EventNodeWrapper;
  event_node_count?: number;
  has_event_nodes?: boolean;

  constructor(action_parameter, filter_type, completed_time, cancelled, scheduled_time, automated) {
    this.action_parameter = action_parameter;
    this.filter_type = filter_type;
    this.completed_time = completed_time;
    this.cancelled = cancelled;
    this.scheduled_time = scheduled_time;
    this.automated = automated;

  }

}

export { CustomTask }
