import { Component, Input, Output, EventEmitter, OnChanges, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { UnderfrequencyActionModal } from '../modals/underfrequency-action-modal/underfrequency-action-modal.component';
import { UnderfrequencyPayload } from '../../services/underfrequency-event.service';
import { environment } from '../../../environments/environment';

interface FieldMeta {
    display: string;
    value: string;
}

@Component({
    selector: 'app-underfrequency-table',
    templateUrl: './underfrequency-table.component.pug',
    styleUrls: ['./underfrequency-table.component.scss']
})
export class UnderfrequencyTableComponent implements OnChanges {

    constructor(
        public dialog: MatDialog,
    ) {}

    @Input() header: string;
    @Input() placeholder: string;
    @Input() rows: UnderfrequencyPayload[];
    @Input() excludeFields: string[];
    @Output() refresh: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild(MatSort, {static: false}) sort: MatSort;
    dataSource: MatTableDataSource<UnderfrequencyPayload>;
    filter: string = '';
    aunt_url = environment.aunt_url;

    allFields: FieldMeta[] = [
        {display: 'Program', value: 'program_display_label'},
        {display: 'Registration Set', value: 'registration_set_display_label'},
        {display: 'Last Updated', value: 'last_updated_dttm'},
        {display: 'Disarm Reason', value: 'disarm_reason'},
        {display: 'Offer', value: 'offer'}, // Needs source
        {display: 'Availability', value: 'availability'}, // Needs source
        {display: 'Load', value: 'load'}, // Needs source
        {display: 'Device Status', value: 'device_status'},
        {display: 'Frequency', value: 'actual_frequency'}, // Needs source
        {display: 'Frequency Setpoint', value: 'frequency_setpoint'}, // Needs source
        {display: 'Event Details', value: 'event_details_link'},
        {display: 'Action', value: 'action'}, // Needs clarity
        {display: 'Info', value: 'info'},
    ];
    fields: FieldMeta[];
    displayedFields: string[];

    ngOnInit() {
        this.fields = this.allFields.filter(f => !this.excludeFields.includes(f.value));
        this.displayedFields = this.fields.map(f => f.value);
    }

    ngOnChanges() {
        this.dataSource = new MatTableDataSource(this.rows);
        this.filterData();
        setTimeout(() => this.dataSource.sort = this.sort, 50);
    }

    filterData() {
        this.dataSource.filter = this.filter.toLowerCase().trim();
    }

    clickAction(row: UnderfrequencyPayload) {
        const dialogRef = this.dialog.open(UnderfrequencyActionModal, {
            data: row,
        });

        dialogRef.afterClosed().subscribe(result => {
            setTimeout(() => this.refresh.emit(true), 400);
        });
    }

    showMoreInfo(row: UnderfrequencyPayload) {
      const url = window.location.origin + '/underfrequency-management/' + row.registration_set_id;
      window.open(url, '_blank');
    }
}
