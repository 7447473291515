import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Paginator } from 'primeng/paginator';

import { PaginationMeta } from '../../classes/paginationMeta';
import { PageEvent } from '@angular/material/paginator';



@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.pug',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, OnChanges {
  @Input() metaData: PaginationMeta;
  @Output() fetchPage = new EventEmitter<any>();
  @Input() fetchingData;
  @Input() pageSizeOptions;

  @ViewChild('paginator', { static: true }) paginator: Paginator

  pageEvent: PageEvent;
  public currentPage = 0;
  public pageSize = 0;
  first = 0;

  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    const md: SimpleChange = changes.metaData;
    if(md?.currentValue && (md?.currentValue != md?.previousValue)) {
      this.pageSize = md.currentValue.per_page;
      let cv: PaginationMeta = md.currentValue
      console.log(cv)
      if(cv.page > 1 ){
        this.first = (cv.page - 1) * cv.per_page;
      } else {
        this.first = 0;
      }

    }
  }

  onPageChange(e){
    this.currentPage = e.page + 1;
    this.pageSize = e.rows;
    this.fetchPage.emit();

  }

}
