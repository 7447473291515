import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { forkJoin, from, ObservableInput } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppInjector } from '../app.module';
import { EventNodeStatus, GroupedEventNodes } from '../classes/event_node';
import { RefDataService } from './ref-data.service';



@Injectable()

//NOTE: This service is not a singleton
export class NodesFormatService {

  groupedNodes: GroupedEventNodes;

  workFlowStatusesMap;
  templatesMap;
  registrationTypesMap;
  controlTypesMap;
  RDS;

  constructor() {
    this.RDS = AppInjector.get(RefDataService);
  }

  initGroupedNodes(grp:GroupedEventNodes): Promise<any>{
    grp.activeNodes     = grp.activeNodes || [];
    grp.pendingNodes    = grp.pendingNodes || [];
    grp.optedOutNodes   = grp.optedOutNodes || [];
    grp.completedNodes  = grp.completedNodes || [];
    grp.excludedNodes   = grp.excludedNodes || [];
    grp.cancelledNodes  = grp.cancelledNodes || [];
    grp.allNodes        = this.getAllNodes(grp) || [];
    this.groupedNodes = grp;

    const workFlowStatuses$: ObservableInput<any> = this.RDS.getWorkflowStatusesMap().pipe(map((resp) => {return resp;}), catchError((err)=> {console.log(err); return from(new Promise((resolve) => resolve(true)));}));
    const templates$: ObservableInput<any> = this.RDS.getTemplatesMap().pipe(map((resp) => {return resp;}), catchError((err)=> {console.log(err); return from(new Promise((resolve) => resolve(true)));}));
    const regTypes$: ObservableInput<any> = this.RDS.getRegistrationTypesMap().pipe(map((resp) => {return resp;}), catchError((err)=> {console.log(err); return from(new Promise((resolve) => resolve(true)));}));
    const controlTypes$: ObservableInput<any> = this.RDS.getControlTypesMap().pipe(map((resp) => {return resp;}), catchError((err)=> {console.log(err); return from(new Promise((resolve) => resolve(true)));}));

    return new Promise<any>((resolve, reject) => {
      forkJoin([workFlowStatuses$, templates$, regTypes$, controlTypes$]).pipe().subscribe(
        ([workflowStatusesMap, templatesMap, regTypesMap, controlTypesMap])=>{
          this.workFlowStatusesMap = workflowStatusesMap;
          this.templatesMap = templatesMap;
          this.registrationTypesMap = regTypesMap;
          this.controlTypesMap = controlTypesMap;

          this.setStatus(grp.activeNodes, EventNodeStatus.active);
          this.setStatus(grp.pendingNodes, EventNodeStatus.pending);
          this.setStatus(grp.optedOutNodes, EventNodeStatus.optedOut);
          this.setStatus(grp.completedNodes, EventNodeStatus.completed);
          this.setStatus(grp.excludedNodes, EventNodeStatus.excluded);
          this.setStatus(grp.cancelledNodes, EventNodeStatus.cancelled);

          let allNodes = this.getAllNodes();
          allNodes.forEach((node)=>{
            this.setLabels(node);
          })
        }
      )


      resolve(this.groupedNodes);
    })
  }

  setLabels(node){
      node.confStatus = node.confirmed ? 'Confirmed' : node.preauthorized ? 'Preauthorized' : 'Unconfirmed';
      if(this.workFlowStatusesMap[node.workflow_status])node.workflow_status_display_label = this.workFlowStatusesMap[node.workflow_status]['display_label'];
      if(this.workFlowStatusesMap[node.last_notification_sent]) node.last_notification_sent_display_label = this.workFlowStatusesMap[node.last_notification_sent]['display_label'];

      if(this.templatesMap[node.last_notification_sent]) node.last_notification_sent_display_label = this.templatesMap[node.last_notification_sent]['display_label'];

      if(this.registrationTypesMap[node.registration_type]) node.registration_type_display_label = this.registrationTypesMap[node.registration_type]['display_label'];
      if(this.controlTypesMap[node.control_type]) node.control_type_display_label = this.controlTypesMap[node.control_type]['display_label'];
  }

  updateNode(node, gn = this.groupedNodes){
    return new Promise<any>((resolve, reject) => {
        let found = _.find(this.getAllNodes(gn), ['event_node_id', node.event_node_id]);
        if(!found){
          return;
        }
        found = Object.assign(found, node);

        switch(found.eventNodeStatusForSort){
          case EventNodeStatus.active:
            if(found.workflow_status === "PENDING"){
              _.pull(gn.activeNodes, found);
              gn.pendingNodes.push(found);
            }
            else if (found.opted_out !== null) {
              _.pull(gn.activeNodes, found);
              gn.optedOutNodes.push(found);
            }
            else if(found.cancelled){
              _.pull(gn.activeNodes, found);
              gn.cancelledNodes.push(found);
            }
            break;
          case EventNodeStatus.pending:
            if(found.workflow_status === "WORKFLOW_COMPLETE"){
              _.pull(gn.pendingNodes, found);
              gn.excludedNodes.push(found)
            }
            else if(found.workflow_status !== "PENDING"){
              _.pull(gn.pendingNodes, found);
              gn.activeNodes.push(found);
            }
            else if(found.opted_out !== null){
              _.pull(gn.pendingNodes, found);
              gn.optedOutNodes.push(found);
            }
            else if(found.cancelled){
              _.pull(gn.pendingNodes, found);
              gn.cancelledNodes.push(found);
            }
            break;
          case EventNodeStatus.optedOut:
            if(found.opted_out === null){
              _.pull(gn.optedOutNodes, found);
              if(found.workflow_status == "PENDING"){
                gn.pendingNodes.push(found)
              } else {
                gn.activeNodes.push(found)
              }
            }
            break;
        }
        this.updateStatusForSort(found);
        this.setLabels(found);
        resolve(gn)
      }
    )}

  updateStatusForSort(node){
    if(!!node.cancelled || !!node.cancelled_no_restore || !!node.cancelled_with_restore){
      node.workflow_status = "WORKFLOW_COMPLETE";
    }
    switch(node.workflow_status){
      case "PENDING":
        if(node.opted_out)
          node.eventNodeStatusForSort = 'optedOut';
        else
          node.eventNodeStatusForSort = 'pending';
        break;
      case "WORKFLOW_COMPLETE":
        if(node.cancelled)
          node.eventNodeStatusForSort = 'cancelled'
        else if(node.activation_time !== null)
          node.eventNodeStatusForSort = 'completed';
        else if(node.opted_out) {
          node.eventNodeStatusForSort = 'optedOut';
        }
        else
          node.eventNodeStatusForSort = 'excluded';
        break;
      default:
        node.eventNodeStatusForSort = 'active';
        break;
    }
    node.confStatus = node.confirmed ? 'Confirmed' : node.preauthorized ? 'Preauthorized' : 'Unconfirmed';
  }

  getAllNodes(gn = this.groupedNodes){
    return [...gn.activeNodes, ...gn.completedNodes, ...gn.excludedNodes, ...gn.cancelledNodes, ...gn.pendingNodes, ...gn.optedOutNodes]
  }

  getOptedInNodes(gn = this.groupedNodes){
    return [...gn.activeNodes, ...gn.completedNodes, ...gn.excludedNodes, ...gn.cancelledNodes, ...gn.pendingNodes]
  }

  setStatus(nodes, status){
    nodes?.forEach((node)=>{
      node.eventNodeStatusForSort = status;
    })
  }
}
