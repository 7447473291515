import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatTableDataSource} from "@angular/material/table";
import {ContactRow, ContactTableData} from "./contact-search-table-model";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-contact-search-table',
  templateUrl: './contact-search-table.component.pug',
  styleUrls: ['./contact-search-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden'})),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ContactSearchTableComponent implements AfterViewInit {

  @Input() set gridValue(g: ContactTableData) {
    this.rowCount = g.rows.length;
    this.dataSource = new MatTableDataSource<ContactRow>(g.rows);
  }

  @Output() contactSelectedEvent = new EventEmitter<ContactRow>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  private subs = new Subscription();
  dataSource!: MatTableDataSource<ContactRow>;
  columnsToDisplay = ['contact_select', 'full_name', 'email', 'phone', 'user_id'];
  rowCount = 0;
  selectedContact = null;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item, property) => {
      console.log(item, item[property]);
      return (item[property] + '').toLowerCase();
    }
  }

  openUserAdmin(userId: string) {
    const url = environment.user_admin_url + '/details/' + userId + '/view';
    window.open(url, '_blank');
  }

}
