import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(value, field, direction='asc'): unknown {
    return _.orderBy(value, [field], [direction]);
  }

}
