import {Component, HostListener} from '@angular/core';
import {Event, NavigationEnd, NavigationStart, Params, PRIMARY_OUTLET, Router, UrlSegmentGroup} from '@angular/router';
import {AuthService, GlobalNavService} from 'ngx-global-nav';
import {AnalyticsService} from 'ngx-shared-services';
import {ApiEndpointsService} from "./services/api-endpoints.service";
import {UserService} from "./services/user.service";
import {CookieService} from 'ngx-cookie-service';
import {OverlayContainer} from '@angular/cdk/overlay';
import {environment} from "../environments/environment";
import * as _ from 'lodash';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.pug',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  navUrl = '';
  currentUser = null;
  userAllowed = false;
  isSticky: boolean = false;

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.scrollY > 0;
  }

  constructor(private userService: UserService,
              private authService: AuthService,
              private apiService: ApiEndpointsService,
              private analyticsService: AnalyticsService,
              private globalNavService: GlobalNavService,
              private router: Router,
              private cookieService: CookieService,
              private overlayContainer: OverlayContainer) {

    // globalnav
    const {globalNavUrl} = this.apiService;
    this.navUrl = globalNavUrl;

    this.globalNavService.userData$.subscribe((user) => {
      if (user !== null) {
        this.userService.formatUser(user);
        this.currentUser = this.userService.user;
        let found = _.find(this.userService.user?.resources, (o) => {
          return o.resource_name == 'EVENT_MANAGEMENT' || o.resource_name == 'UTILITY_PORTAL_DISPATCH';
        })
        this.userAllowed = found;
        if (!this.userAllowed) {
          this.router.navigate(['unauthorized'])
        }

        this.analyticsService.init(user.user_id, 'Event Management');
        this.analyticsService.setPage('Event Management');
      }
    });


    // We want to change the branding whenever we are in the training env
    this.router.events.subscribe((event: Event) => {

      const cookieKey = 'default_brand';
      const trainingUrlPath = 'training';
      const trainingBrandName = 'training';
      const defaultBrandName = 'enelx';

      if (event instanceof NavigationEnd) {
        const brand = (this.cookieService.getAll()[cookieKey] || '').toLowerCase();
        const urlTree = router.parseUrl(event.url);
        const urlSegments = urlTree.root.children[PRIMARY_OUTLET];
        const isTrainingPath = urlSegments && urlSegments.segments.length > 0 && !urlSegments.segments[0].path.localeCompare(trainingUrlPath)
        const changeCookie = (isTrainingPath && brand != trainingBrandName) || (!isTrainingPath && brand == trainingBrandName)

        if(changeCookie){
          const newBrandValue = isTrainingPath ? trainingBrandName : defaultBrandName;
          this.cookieService.set(cookieKey, newBrandValue, null, '/', environment.defaults.cookie_domain, false);
          router.navigate([event.url], { queryParams: urlTree.queryParams }).then(() => {
            window.location.reload();
          });
        }
      }
    });
  }
}
