import {Directive, forwardRef, Attribute, OnChanges} from '@angular/core';
import { AbstractControl, FormGroup, Validator,Validators, ValidatorFn, NG_VALIDATORS } from '@angular/forms';


export function validateMinChecked(minRequired = 1): ValidatorFn
{
  return function validate (formGroup: FormGroup) {
    let checked = 0;

    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key];

      if (control.value === true) {
        checked ++;
      }
    });

    if (checked < minRequired) {
      return {
        requireCheckboxesToBeChecked: true,
      };
    }

    return null;
  };

}
@Directive({
  selector: '[minChecked]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MinCheckedValidator, multi: true }]
})
export class MinCheckedValidator implements Validator{

  private valFn = Validators.nullValidator;

  constructor(@Attribute('minChecked') val: number) {
    this.valFn = validateMinChecked(val || 1);
  }

  validate(control: AbstractControl): {[key: string]: any} {
    return this.valFn(control);
  }

}


