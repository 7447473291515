import {Component, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {EventManagementService} from "../../../services/event-management.service";
import {SharedService} from "../../../services/shared.service";
import {forkJoin, Observable, of} from 'rxjs';
import {catchError, tap} from "rxjs/operators";
import {ContactRow} from "../contact-search-table/contact-search-table-model";
import {EntitySearchDropdownComponent} from "../entity-search-dropdown/entity-search-dropdown.component";

@Component({
  selector: 'app-search-contact-filter',
  templateUrl: './search-contact-filter.component.pug',
  styleUrls: ['./search-contact-filter.component.scss'],
})
export class SearchContactFilterComponent {

  @ViewChild(EntitySearchDropdownComponent) entitySearchDropdown: EntitySearchDropdownComponent;

  contactForm: FormGroup;

  contactSearchLoading = false;
  eventNodeSearchLoading = false;
  contactSearchCalled = false;
  eventNodeSearchCalled = false;
  contacts: ContactRow[] = [];
  eventNodes = [];
  portfolioMap = new Map();
  totalContacts: number = 0;
  rows: number = 5;

  showOptionalFilters: boolean = false;
  entityTypes = ['ORGANIZATION'];
  lookback = {value: '1 Day', id: '1'};
  eventTypes = [
    {
      value: "Official",
      id: "OFFICIAL"
    },
    {
      value: "Official - Audit",
      id: "AUDIT"
    },
    {
      value: "Official - Voluntary",
      id: "VOLUNTARY"
    }
  ];
  selectedContact = null;

  constructor(
    private fb: FormBuilder,
    private eventManagementService: EventManagementService,
    private sharedService: SharedService
  ) {
    this.contactForm = this.fb.group({
      first_name: [''],
      last_name: [''],
      phone_number: ['', Validators.pattern('^[- +()0-9]+$')],
      email_address: ['', Validators.email],
      entitySelected: [null]
    });
  }

  toggleOptionalFilters() {
    this.showOptionalFilters = !this.showOptionalFilters;
    if (this.showOptionalFilters) {
      this.contactForm.get('entitySelected')?.setValue(null);
    }
  }

  isFormValid(): boolean {
    if (this.contactForm.invalid) {
      return false;
    }
    const {first_name, last_name, phone_number, email_address} = this.contactForm.value;
    return first_name || last_name || phone_number || email_address;
  }

  getQueryParams() {
    return {
      first_name: this.contactForm.get('first_name')?.value || null,
      last_name: this.contactForm.get('last_name')?.value || null,
      phone: this.contactForm.get('phone_number')?.value || null,
      email: this.contactForm.get('email_address')?.value || null,
      primary_organization: this.contactForm.get('entitySelected')?.value || null,
    }
  }

  submitContactSearch() {
    console.log(this.contactForm.value)
    if (this.isFormValid()) {
      const queryParams = this.getQueryParams();
      this.eventNodeSearchCalled = false;
      this.contactSearchCalled = true;
      this.contactSearchLoading = true
      this.eventManagementService.get('/v1/contacts/search', queryParams).pipe().subscribe(
        resp => {
          this.contactSearchLoading = false;
          const transformedContacts: ContactRow[] = resp.data.map(contact => {
            return {
              full_name: contact?.full_name,
              email: contact.email?.email_address || 'N/A',
              phone: contact.phone?.phone_number || 'N/A',
              user_id: contact?.user_id,
            }
          })

          this.contacts = transformedContacts
          this.totalContacts = this.contacts.length;
        },
        error => {
          this.contactSearchLoading = false;
          console.log('Error:', error);
          this.sharedService.popError('Error searching contacts');
          this.contacts = [];
          this.totalContacts = 0;
        }
      );
    } else {
      console.log('Form is invalid');
    }
  }

  onContactSelect($event: any) {
    this.selectedContact = $event
    const userId = $event.user_id;
    const eventTypesParam = this.eventTypes.map(type => type.id).join(',');
    const queryParams = {
      data_since: this.lookback.id,
      ...(this.eventTypes.length > 0 && {event_type_filter: eventTypesParam})
    }
    this.eventNodeSearchCalled = true;
    this.eventNodeSearchLoading = true;
    this.eventManagementService.get(`/v1/user/${userId}/event_nodes`, queryParams).pipe().subscribe(
      resp => {
        console.log('Total event nodes loaded:', resp.data.length);
        this.getAdditionalEventNodeData(resp.data).subscribe(() => {
          resp.data.map(eventNode => {
            eventNode.status = this.sharedService.getEventNodeStatus(eventNode);
            eventNode.portfolio_display_label = this.portfolioMap.get(eventNode.portfolio_id)?.portfolio_display_label;
          })
          this.eventNodes = resp.data.sort((a, b) => a.status.localeCompare(b.status));
          this.eventNodeSearchLoading = false;
        });
      },
      error => {
        this.eventNodeSearchLoading = false;
        console.log(`Error calling /v1/user/${userId}/event_nodes:`, error);
        this.sharedService.popError('Error loading eventNodes for user ' + userId);
        this.eventNodes = [];
      }
    );
  }

  onLookbackChanged($event: any) {
    this.lookback = $event;
    if (this.selectedContact) {
      this.onContactSelect(this.selectedContact);
    }
  }

  onEventTypesChanged($event: any) {
    this.eventTypes = $event;
    if (this.selectedContact) {
      this.onContactSelect(this.selectedContact);
    }
  }

  handleEntitySearchResultsChange($event: any) {
    console.log('Entity selected:', $event);
    this.contactForm.get('entitySelected').setValue($event?.id)
  }

  getAdditionalEventNodeData(eventNodes: any[]): Observable<any> {
    if (eventNodes.length === 0) {
      return of([]);
    }
    const portfolioIds = new Set(eventNodes.map(eventNode => eventNode.portfolio_id));
    if (portfolioIds.size === 0) {
      return of([]);
    }
    const portfolioObservables = Array.from(portfolioIds).map(portfolioId =>
      this.eventManagementService.get('/v1/portfolio', {'portfolio_id': portfolioId}).pipe(
        tap(resp => {
          this.portfolioMap.set(portfolioId, resp.data);
        }),
        catchError(error => {
          console.log(`Error calling /v1/portfolio: `, error);
          return of(null);
        })
      )
    );
    return forkJoin(portfolioObservables);
  }
  
  resetFilters() {
    this.contactForm.reset()
    if(this.entitySearchDropdown){
      this.entitySearchDropdown.reset();
    }
    this.contacts = [];
    this.eventNodes = [];
    this.contactSearchCalled = false;
    this.eventNodeSearchCalled = false;
  }

}
