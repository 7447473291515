import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';
import {SharedService} from "../../services/shared.service";

@Component({
  selector: 'app-owl-date-picker',
  templateUrl: './owl-date-picker.component.pug',
  styleUrls: ['./owl-date-picker.component.scss']
})
export class OwlDatePickerComponent implements OnInit {

  @Input() actionName:string;
  @Input() isDisabled:boolean;
  @Input() timezone:string;
  @Input() enteredTime:string;
  @Input() hasNowBox:boolean;
  @Input() programTimeZone:string;
  nowBoxChecked:boolean = false;

  @Output() onChangeDate:EventEmitter<{date: string}> = new EventEmitter();

  constructor(private shared:SharedService) { }
  startAtTime;

  ngOnInit() {
    this.setStartAt();
  }

  toggleNowCheckbox():void {
    if(this.nowBoxChecked)
    {
      let convertedTime = moment.tz(moment(), this.shared.getTimeZoneName(this.programTimeZone, false));
      this.enteredTime = convertedTime.format('YYYY-MM-DDTHH:mm');
      this.onChange();
    }
  }
  setStartAt():void {
    this.startAtTime = this.enteredTime ? this.enteredTime : moment.tz(moment(), this.shared.getTimeZoneName(this.programTimeZone, false)).format('YYYY-MM-DDTHH:mm');
  }

  onChange(): void {
    //Sends the date back to the parent component
    this.setStartAt();
    this.onChangeDate.emit({date: this.enteredTime});
  }

}
