import {
  Component,
  Input,
  OnInit,
} from '@angular/core';

import {SharedService} from "../../../services/shared.service";
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';

@Component({
  selector: 'app-refresh-baselines-modal',
  templateUrl: './refresh-baselines-modal.component.pug',
  styleUrls: ['./refresh-baselines-modal.component.scss']
})
export class RefreshBaselinesModalComponent implements OnInit {

  prefTimeZone: string;

  constructor(private shared: SharedService) {
    this.shared.getTimeZonePref().subscribe(value => this.prefTimeZone = value);
  }

  @Input() data: any;
  passwordEntered: string = '';

  ngOnInit() {
  }

  getTimezone() {
    return this.shared.getTimeZoneName(this.data.program_time_zone);
  }

  getTimeZoneConversionDate(selectedTime, zone) {
    let localTZ = this.shared.getUsersTimeZone();
    let tz;

    switch (zone) {
      case 'local':
        tz = localTZ;
        break;
      case 'program':
        tz = this.data.program_time_zone;
        break;
      case 'utc':
        tz = 'UTC';
        break;
    }

    let convertedTime: string = this.shared.selectedTimeZoneToUTC(selectedTime, this.data.program_time_zone).toISOString();
    return moment.tz(convertedTime, tz);
  }

  getTimeZoneConversion(selectedTime, zone) {
    return selectedTime ?this.getTimeZoneConversionDate(selectedTime, zone).format(SharedService.dateFormat) : 'None';
  }

  getTimeFromChild(time) {
    this.data.timePickerData.entered_time = time.date;
  }

  confirm() {
    let hasErrors: boolean = false;
    let tpd = this.data.timePickerData;
    const enteredTime = moment(tpd.entered_time);

    const convertedTimeUtc = this.getTimeZoneConversionDate(enteredTime, this.prefTimeZone);
    tpd.errors = [];

    if (convertedTimeUtc.isValid()) {
      let now = moment.utc();

      if (convertedTimeUtc.isAfter(now) || convertedTimeUtc.isAfter(moment(tpd.max_date))) {
        tpd.errors.push('Notification time must be before event start time and before now.');
        return;
      }
    }
    this.data.confirmFunction(convertedTimeUtc);
  }

  close() {
    console.log("close called")
    this.data.close();
  }
}
