import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {SharedService} from "./shared.service";

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class FdrService {

  constructor(private http: HttpClient, private sharedService: SharedService) { }

  api = 'http://localhost:5000';

  get(url, params) : Observable<any>{
    const p = this.sharedService.transformParams(params);

    return this.http.get(this.api + url, p);
  }

  compare(a,b): number {
    if (a.name < b.name)
      return -1;
    if (a.name > b.name)
      return 1;
    return 0;
  }
}
