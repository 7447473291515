import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { MqttService } from "ngx-mqtt";
import { Subject } from "rxjs/internal/Subject";
import { takeUntil } from "rxjs/operators";
import { EPSEvent } from "../../../classes/event";
import { EnelTabsComponent } from "../../../directives/enel-tabs/enel-tabs.component";
import { AuthService } from '../../../services/auth.service';
import { EventListService } from "../../../services/event-list.service";
import { EventManagementService } from "../../../services/event-management.service";
import { FdrService } from "../../../services/fdr.service";
import { ListTab, SharedService } from "../../../services/shared.service";
import { UserService } from "../../../services/user.service";
import { HierarchySelectorComponent } from "../../hierarchy-selector/hierarchy-selector.component";

@Component({
  selector: 'app-utility-portal-list',
  templateUrl: './utility-portal-list.component.pug',
  styleUrls: ['./utility-portal-list.component.scss']
})

export class UtilityPortalListComponent implements OnInit {

  @ViewChild(HierarchySelectorComponent, {static: false}) hierarchySelectorElement: HierarchySelectorComponent;
  @ViewChild(EnelTabsComponent, {static: false}) enelTabsElement: EnelTabsComponent;

  private ngUnsubscribe: Subject<any> = new Subject();

  events: Array<EPSEvent> = null;
  workflowStatuses = null;
  eventsLoaded:boolean = false;

  eventsFiltered:boolean = false;
  filteredEvents = [];
  workflowStates = [];
  workflowStateInfo = null;
  performanceData = [];

  //Performance subscriptions
  currentEventSubs = [];

  ////// Subscriptions ////////
  eventSubscribe$;
  newEventSubscribe$;
  eventDataSubscribe$;
  eventSubscribed:boolean;

  listColumns = [];
  _selectedColumns = [];
  statuses = [];

  utilityConfig = {};

  constructor(private fdrService: FdrService, private router: Router, private sharedService: SharedService, private eventManagementService: EventManagementService, private authService: AuthService, private titleService:Title, private mqttService: MqttService,
              private epsMqttService: MqttService, private listService: EventListService, private userService: UserService) {
    this.titleService.setTitle('Event List');

    const {filteredEvents$} = this.listService;
    filteredEvents$.pipe().subscribe(
      (response) => {
        this.events = response;

        //Set the groups and whatnot for the new event
        this.filterEvents();

        if (!this.eventSubscribed) {
          this.eventSubscribed = true;
          this.listService.removeAllFilters();

          let eventIds = _.map(this.events, 'event_id');
          //Subscribe to event data off the bat
          this.subscribeToAllEventData(eventIds);
        }
      }
    );

    const {loadingEvents$} = this.listService;
    loadingEvents$.pipe().subscribe(
      resp => {
        this.eventsLoaded = !resp;
      }
    );

    const {workflowStatuses$} = this.listService;
    workflowStatuses$.pipe().subscribe(
      resp => {
        this.workflowStatuses = resp;
      }
    );
    const {newEvent$} = this.listService;
    newEvent$.pipe().subscribe((resp) => {this.handleNewEvent(resp)});
  }


  ngOnInit() {
    const controller = this;

    this.sharedService.setListTab((<any>ListTab['UTILITY_LIST']));
    this.listService.getEvents(true, true);

    this.statuses = [
      {label: 'Active', value: 'Active'},
      {label: 'Ended', value: 'Ended'}
    ];

    this.listColumns = [
      { field: 'operator_display_label', header: 'Operator', filter: true },
      { field: 'ems_program_display_label', header: 'Program', filter: true },
      { field: 'product_display_label', header: 'Product', filter: true },
      { field: 'event_type', header: 'Event Type', filter: true },
      { field: 'obligation', header: 'Total Obligation', filter: true },
      { field: 'current_performance', header: 'Current Performance', filter: true },
      { field: 'overall_performance', header: 'Overall Performance', filter: true },
      { field: 'start_time', header: 'Start Time' },
      { field: 'end_time', header: 'End Time' },
      { field: 'status', header: 'Status', filter: true, options: this.statuses }
    ];

    this._selectedColumns = this.listColumns;
    //Get workflow states
    controller.eventManagementService.get('/v1/workflow_attributes', {}).pipe(takeUntil(this.ngUnsubscribe)
    ).subscribe(response => {
      controller.workflowStates = response.data;

      //Call enuts to get the workflow using the ID we just got
      controller.eventManagementService.get('/v1/workflow_state', {}).pipe(
      ).subscribe(workflow => {

        controller.workflowStateInfo = workflow.data;

        controller.workflowStates.forEach(wfs => {
          workflow.data.forEach(state => {
            if(wfs.included_attribute_name && wfs.included_attribute_name.toLowerCase() === state.included_variable_name.toLowerCase()) {
              wfs.sequence = state.sequence;
            }
          });
        });
      });
    }, error => {
      console.dir("Error getting workflow state reference data: ");
      console.dir(error);
    });

    //Utility Config
    controller.eventManagementService.get('/v1/utility_config', {}).pipe(takeUntil(this.ngUnsubscribe)
    ).subscribe(response => {
      controller.utilityConfig = response.data;
    }, error => {
      console.dir("Error getting utility config: ");
      console.dir(error);
    });
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  hasColumn(name)
  {
    var num = _.findIndex(this._selectedColumns, c => {
      return c.header === name;
    });

    return num !== -1;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    this._selectedColumns = this.listColumns.filter(col => val.includes(col));
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  goToEventDetail(group) {
      const url = window.location.origin + '/utility/event?start_time=' +
        group.start_time + '&program=' +
        group.program_id + '&product=' +
        group.product_id + '&type=' +
        group.event_type_code;
      window.open(url, '_blank');
  }

  handleNewEvent(data){
    this.listService.getEvents(false, true);
  }

  subscribeToAllEventData(eventIds)
  {
    const controller = this;

    //Unsubscribe to the previous one and subscribe to the new batch of events
    this.currentEventSubs.forEach(sub => {
      sub.unsubscribe();
    });

    //Clear it
    this.currentEventSubs = [];

    controller.sharedService.subscribeToTopic(controller.eventSubscribe$, eventIds, "eps_event", controller.epsMqttService, msg => {
      try {
        const evID = JSON.parse(JSON.stringify(msg)).event_id;
        controller.listService.getEvent(evID, () => {
          //Update the events
          controller.filterEvents();
        }, true);

      }
      catch(err) {
        console.log("Error updating event in UI: " + JSON.stringify(err));
      }
    }, true, this.currentEventSubs, this.ngUnsubscribe, this.eventManagementService);
  }

  filterEvents() {
    const controller = this;
    controller.filteredEvents = [];
    let done = _.after(this.events.length, () => {

      //To make the filtering inception below easier on my feeble brain
      let actuallyFiltered = [];

      //Separate the events into groups based on their start times and groups
      //Try not to look directly at this
      controller.filteredEvents = _.chain(controller.filteredEvents)
        .groupBy((e) => {return e["event_start_dttm_utc"] + "_" + e["event_end_dttm_utc"] + "_" + e["product_id"]})
        .map((groups, time) => ({
          typeGroups: _.chain(groups)
            .groupBy("event_action_type_display_label")
            .map((events, type) => ({
              start_time: time.split("_")[0],
              end_time: time.split("_")[1],
              obligation: _.sumBy(events, e => {return e.obligation}),
              event_type: controller.getEventType(events[0].event_action_type, type, events[0]),
              event_type_code: events[0].event_action_type,
              events: events,
              status: events[0].status,
              operator_display_label: events[0].operator_display_label,
              product_display_label: events[0].product_display_label,
              ems_program_display_label: events[0].ems_program_display_label,
              program_id: events[0].program_id,
              product_id: events[0].product_id
            })).forEach((res) => { actuallyFiltered.push(res);}).value()
        }))
        .value();

      controller.filteredEvents = actuallyFiltered;

      _.forEach(controller.filteredEvents, ev => {
        controller.getPerformance(ev.events, ev);
      });

      //Update the params for the current page if necessary
      this.eventsFiltered = true;
    });

    _.forEach(this.events, ev => {

      if(ev.status !== "Cancelled" && (ev.event_action_type === "OFFICIAL" || ev.event_action_type === "VOLUNTARY" || (this.utilityConfig[ev.program_id] && this.utilityConfig[ev.program_id].show_drt && ev.event_action_type === "READINESS_TEST")))
        controller.filteredEvents.push(ev);

      done();
    });

    if(this.events.length === 0)
      this.eventsFiltered = true;
  }

  getWorkflowStatusesMap() {
    return Object.values(this.listService.workflowStatusesMap);
  }

  getRegistrationTypesMap() {
    return Object.values(this.listService.registrationTypesMap);
  }

  getTemplatesMap(getStorage = true) {

    if(getStorage)
      return Object.values(this.listService.templatesMap);
    else
      return Object.values(this.listService.createableTemplatesMap);

  }

  getFiltersMap(getStorage = true) {
    if(getStorage)
      return Object.values(this.listService.filterTypesMap);
    else
      return Object.values(this.listService.createableFilterTypesMap);
  }

  getEventType(code, type, event) {
    //Some programs have an override for this event type
    if(code === "VOLUNTARY") {
      if(this.utilityConfig[event.program_id] && this.utilityConfig[event.program_id].voluntary_event_type_override)
        return this.utilityConfig[event.program_id].voluntary_event_type_override;
      else
        return "Official - Emergency";
    }
    return type;
  }

  getPerformance(events:Array<EPSEvent>, group) {
    const controller = this;

    const setCurrentPerformance = function(val) {
      group.current_performance =  val;
    };
    const setOverallPerformance = function(val) {
      group.overall_performance =  val;
    };

    const getCurrentPerformance = function() {
      let performanceVal = 0;
      let registeredCapacity = 0;
      let performancePerCent = 0;

      const done = _.after(events.length, () => {
        setCurrentPerformance(performanceVal);
      });

      _.forEach(events, event => {
        performanceVal += event.last_current_performance_value;
        registeredCapacity += controller.sharedService.getCurrentObligation(event);
        done();
      });
    };

    const getOverallPerformance = function() {
      let performanceVal = 0;
      let expectedCapacity = 0;
      let performancePerCent = 0;

      const done = _.after(events.length, () => {
        setOverallPerformance(performanceVal);
      });

      _.forEach(events, event => {
        performanceVal += controller.sharedService.getOverallPerformance(event, event.product);
        expectedCapacity += event.sum_expected_capacity_value;
        done();
      });

    };
    //Some programs won't show the performance column
    if(this.utilityConfig[events[0].program_id] && !this.utilityConfig[events[0].program_id].show_performance_column) {
      setCurrentPerformance(null);
      setOverallPerformance(null);
    } else {
        if(events[0].event_progress_status == 'BEFORE') {
          setCurrentPerformance(null);
          setOverallPerformance(null);
        } else if(events[0].event_progress_status == 'DURING') {
          getCurrentPerformance();
          getOverallPerformance();

        } else {
          setCurrentPerformance(null);
          getOverallPerformance();

        }
    }

  }
}
