import { ChangeDetectorRef, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { forkJoin, ObservableInput } from 'rxjs';
import { takeUntil } from "rxjs/operators";
import { FdrService } from "../../services/fdr.service";
import { ListTab, SharedService } from "../../services/shared.service";

import * as _ from 'lodash';
import * as Moment from "moment";
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';
import { MqttService } from 'ngx-mqtt';
import { ToastrService } from 'ngx-toastr';
import { Table } from 'primeng/table';
import { Subject } from "rxjs/internal/Subject";
import { CustomTask } from "../../classes/customTask";
import { EPSEvent } from "../../classes/event";
import { EventNode, EventNodeStatus, EventNodeWrapper } from "../../classes/event_node";
import { ModalDatePickerData } from "../../classes/modalDatePickerData";
import { EventManagementService } from "../../services/event-management.service";
import { RefDataService } from '../../services/ref-data.service';
import { UserService } from '../../services/user.service';
import { CancelEventModalComponent } from '../cancel-event-modal/cancel-event-modal.component';
import { CustomTaskModalComponent } from "../modals/custom-task-modal/custom-task-modal.component";
import { CustomModalWrapper } from "../modals/enel-modal/enel-modal.component";
import { EventExtendModalComponent } from "../modals/event-extend-modal/event-extend-modal.component";
import { RefreshBaselinesModalComponent } from "../modals/refresh-baselines-modal/refresh-baselines-modal.component";
import { RegListModalComponent } from '../modals/reg-list-modal/reg-list-modal.component';


@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.pug',
  styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent implements OnInit {

  eventObj:EPSEvent = null;
  comProductObj;
  productUOM;
  maxEventDurationDisplay = '';
  eventID = null;
  eventNodes:EventNodeWrapper;
  allCustomTasks: Array<CustomTask> = [];
  savedCustomTasks: Array<CustomTask> = [];
  newCustomTasks: Array<CustomTask> = [];
  eventTypes: Array<any> = [];
  editingCustomTask:boolean = false;
  totalAvailability:number = 0;
  displayedEventNodes:EventNodeWrapper;

  eventNodeMap = {};

  me: EventDetailComponent;
  static staticMe;

  workflowStatuses = null;
  workflowStateInfo = null;
  registrationTypes = null;
  caseTypes = null;
  allTemplates = [];
  createableTemplates = [];
  allFilters = [];
  createableFilters = [];
  eventNodeStatuses;
  eventLoaded = false;
  changingEndTime = false;
  cachedEventNodeData = {};
  spentMessages=[];

  notificationTemplates = [];
  notificationPaths = [{"delivery_path":"SMS"}, {"delivery_path":"EMAIL"}, {"delivery_path":"VOICE"}];

  // Baseline Adj Window
  showBaselineAdj: boolean = false;
  weatherAdjStart: string;
  weatherAdjEnd:string;

  //Filter panel info
  activeExpected:number;
  activeCurrent:number;
  availability:number;
  numberUnderperforming:number;
  numberExceptions:number;

  //Event node list
  nodeListColumns = [];
  optedOutColumns = [];
  _selectedColumns = [];
  _selectedOptedOutColumns = [];
  statuses = [];
  controlTypes = [];
  possibleAssignees = [];

  customerOffers = [];
  offersTimeOut;

  //Filtering
  filters = {
    'active': true,
    'pending': true,
    'optedOut': true,
    'underPerforming': true,
    'exceptions': true
  };

  //Bulk activate and end
  BulkActionType = Object.freeze({
    none: 0,
    activate: 1,
    deactivate: 2,
    recurtail: 3,
    optIn: 4,
    addNote: 5,
    addOptedOutNote: 6,
    assign: 7,
    assignOptedOut: 8,
    notify: 9
  });

  bulkActionType = this.BulkActionType.none;
  bulkOptInNodes: Array<string> = [];
  bulkNotifyNodes = [];
  showBulkActivate:boolean;
  showBulkEnd:boolean;
  showBulkRecurtail:boolean;
  showBulkOptIn:boolean;
  selectAllChecked:boolean = false;
  canCancelEvent: boolean = false;
  canPauseEvent: boolean = false;
  canResumeEvent: boolean = false;
  eventPaused: boolean = false;
  showEndCalcs: boolean = false;
  calcsEnded: boolean = false;
  canAutoAssign: boolean = true;

  getEvents$;
  getCustomTasks$;
  getEventNodes$;
  getAllTemplates$;
  getAllFilters$;
  nodesLoaded = false;
  eventNodeSubscribe$;
  eventSubscribe$;
  eventPerformanceSubscribe$;
  eventDataSubscribe$;
  customTaskSubscribe$;

  performanceTimeoutBuffer:boolean = false;

  getPerformanceDataInfo = {performance_data: true, performance_aggregate_type: ''};
  overallPerfValue;
  setObligation;

  isTraining: boolean = false;
  pageTitle: string;

  @ViewChild('dt', {static: false}) activeTable: Table;
  @ViewChild('dt2', {static: false}) optedOutTable: Table;


  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private sharedService: SharedService, private route: ActivatedRoute, private fdrService: FdrService,
              private eventManagementService: EventManagementService,
              private mqttService: MqttService, private epsMqttService: MqttService, private ngZone: NgZone,
              private ref: ChangeDetectorRef, private userService: UserService, private toastr: ToastrService,
              private RDS: RefDataService) {
    EventDetailComponent.staticMe = this;
  }

  ngOnInit() {
    this.isTraining = Boolean(this.route.snapshot.data['training']) || false;
    this.pageTitle = this.isTraining ? 'Training Event Details' : 'Event Details';

    //Set moment on the window so highcharts can find it
    window['moment'] = Moment;
    this.me = this;
    const controller = this;
    this.eventNodes = new EventNodeWrapper();
    this.displayedEventNodes = new EventNodeWrapper();
    this.eventNodeStatuses = EventNodeStatus;

    const tabName = this.isTraining ? ListTab.TRAINING_DETAILS : ListTab.DETAILS;
    this.sharedService.setListTab(tabName);
    this.RDS.getEventTypes().subscribe(resp=>{this.eventTypes = resp})
    this.RDS.getWorkflowStatuses().subscribe((resp)=>{this.workflowStatuses = resp})
    this.RDS.getControlTypes().subscribe((resp=>{this.controlTypes = resp}))
    this.RDS.getCaseTypes().subscribe((resp)=>{this.caseTypes = resp})
    this.RDS.getWorkflowStates().subscribe((resp)=>{this.workflowStateInfo = resp})
    this.RDS.getRegistrationTypes().subscribe((resp)=>{this.registrationTypes = resp})
    //this.RDS.getAllFilterTypes().subscribe((resp)=>{this.allFilters = resp});
    //this.RDS.getAllTemplates().subscribe((resp)=>{this.allTemplates = resp})
    this.RDS.getCreateableFilterTypes().subscribe((resp)=>{this.createableFilters = resp});
    this.RDS.getCreateableTemplates().subscribe((resp)=>{this.createableTemplates = resp})

    //Get assignees
    controller.eventManagementService.get('/v1/event/organization/' + 'ef5d5287-4200-4f8a-bdbf-b6691bbbe7ac' + '/assignee_list', {}).pipe(takeUntil(this.ngUnsubscribe)
    ).subscribe(response => {
      controller.possibleAssignees = response.data;
    }, error => {
      console.dir("Error getting possible assignees: ");
      console.dir(error);
    });

    //Subscribe to any parameter change in the route (i.e the event ID) and if it changes, update the event object
    this.route.paramMap.subscribe(params => {
      controller.eventID = params.get("id");
    });

    controller.getEvents$ = controller.eventManagementService.get('/v1/event/' + controller.eventID, this.getPerformanceDataInfo)
      .pipe(takeUntil(this.ngUnsubscribe));

    controller.getCustomTasks$ = controller.eventManagementService.get('/v1/custom_task?event_id=' + controller.eventID, {})
      .pipe(takeUntil(this.ngUnsubscribe));

    controller.getEventNodes$ = controller.eventManagementService.get('/v1/event/' + controller.eventID + '/event_nodes', {})
      .pipe(takeUntil(this.ngUnsubscribe));

    controller.getAllFilters$ = controller.RDS.getAllFilterTypes();

    controller.getAllTemplates$ = controller.RDS.getAllTemplates();


    forkJoin([controller.getEvents$, controller.getCustomTasks$, controller.getEventNodes$, controller.getAllFilters$, controller.getAllTemplates$] as ObservableInput<any>[]).pipe(
      takeUntil(controller.ngUnsubscribe)
    ).subscribe(response => {
      controller.allFilters = response[3] as Array<any>;
      controller.allTemplates = response[4] as Array<any>;
      controller.setEvent(response[0]['data']);
      controller.nodesLoaded = true;
      //Baseline Adjustment Window start and end are stored in the nodes
      const allThemNodes: EventNode[] = _.concat(response[2]['data']['activeNodes'], response[2]['data']['pendingNodes'], response[2]['data']['optedOutNodes'], response[2]['data']['excludedNodes'], response[2]['data']['completedNodes']);

      if(allThemNodes.length && allThemNodes[0].adjustment_window_start && allThemNodes[0].adjustment_window_end) {
        this.weatherAdjStart = allThemNodes[0].adjustment_window_start;
        this.weatherAdjEnd = allThemNodes[0].adjustment_window_end;
        this.showBaselineAdj = this.weatherAdjStart != this.weatherAdjEnd
      }

      if (!controller.eventSubscribe$) {

        controller.sharedService.subscribeToTopic(controller.eventSubscribe$, [controller.eventObj.event_id], "eps_event", controller.epsMqttService, msg => {
          controller.updateEvent(msg);
        }, false, [], this.ngUnsubscribe, this.eventManagementService);

        controller.sharedService.subscribeToTopic(controller.eventDataSubscribe$, [controller.eventObj.event_id], "event", controller.mqttService, msg => {
          if (!_.find(controller.spentMessages, JSON.stringify(msg))) {
            controller.spentMessages.push(JSON.stringify(msg))
            if (msg.dataType === "event_node_workflow") {
              controller.eventNodes.updateEventNode(msg, controller.workflowStatuses, controller.allTemplates, controller.registrationTypes, controller.controlTypes);
              controller.applyFilters();
            } else if (msg.dataType === "custom_task") {
              controller.getCustomTasks$.subscribe(response => {
                controller.setCustomTasks(response['data']);
              }, error => {
                console.log("Error updating custom tasks")
              })
            } else if (msg.dataType === "ems_event") {
              controller.updateEvent(msg);
            }
        }
        }, false, [], this.ngUnsubscribe, this.eventManagementService);
      }

      //Don't update the custom tasks if we're editing or cancelling one.
      if (!controller.editingCustomTask)
        controller.setCustomTasks(response[1]['data']);

      //Don't update the nodes if we're currently selecting through them, or things get messy

        controller.eventNodes.setEventNodes(response[2]['data'], controller.workflowStatuses, controller.allTemplates, controller.registrationTypes, controller.controlTypes);

        controller.getCustomerOffers();

        let activeIDs = _.map(controller.eventNodes.activeNodes, node => {
          return node.event_node_id.toString();
        });

        let pendingIDs = _.map(controller.eventNodes.pendingNodes, node => {
          return node.event_node_id.toString();
        });

        let optedOutIDs = _.map(controller.eventNodes.optedOutNodes, node => {
          return node.event_node_id.toString();
        });

        let allIDs = (activeIDs.concat(pendingIDs)).concat(optedOutIDs);

        if (!controller.eventPerformanceSubscribe$) {
          controller.sharedService.subscribeToTopic(controller.eventPerformanceSubscribe$, allIDs, "NODE_PERFORMANCE", controller.epsMqttService, msg => {

            //EPS sends us a node_performance update for every event node we're watching. In order to not poll once for every node in the event, we set
            //a 5 second timeout when we get performance data, and won't poll again until it is up
            if(controller.performanceTimeoutBuffer === false) {
              controller.performanceTimeoutBuffer = true;

              controller.eventManagementService.post('/v1/event_performance', {event_ids: [controller.eventObj.event_id, ], performance_aggregate_type: controller.getPerformanceDataInfo.performance_aggregate_type}, {})
                .pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {

                  controller.eventObj.performanceData = response.data;

                  //Set the timeout to false after 5 seconds so we can poll for data again
                  setTimeout(function () {
                    controller.performanceTimeoutBuffer = false;
                  }, 5000)

                },
                err => {
                  console.dir("Error getting event performance data: ");
                  console.dir(err);
                });
            }

          }, false, [], this.ngUnsubscribe, this.eventManagementService);
        }

        if (!controller.eventNodeSubscribe$) {
          controller.sharedService.subscribeToTopic(controller.eventNodeSubscribe$, allIDs, "EVENT_NODE", controller.epsMqttService, msg => {
            if(!_.find(controller.spentMessages, JSON.stringify(msg))) {
              controller.spentMessages.push(JSON.stringify(msg))
              controller.eventNodes.updateEventNode(msg, controller.workflowStatuses, controller.allTemplates, controller.registrationTypes, controller.controlTypes);
              controller.applyFilters();
            }
          }, false, [], this.ngUnsubscribe, this.eventManagementService);
        }

        const doneWithLockedOut = _.after(controller.eventNodes.optedOutNodes.length, () => {
          controller.totalAvailability = response[2]['data'].totalAvailability;
          controller.applyFilters();
          controller.eventLoaded = true;
        });

        //Now that we need the locked out status for opted out nodes, we grab their registrations here. :(
        controller.eventNodes.optedOutNodes.forEach(node => {

          if (!controller.cachedEventNodeData[node.event_node_id]) {
            //GET REGISTRATION
            controller.eventManagementService.get('/v1/registration/' + node.registration_id, {}).pipe(takeUntil(controller.ngUnsubscribe)
            ).subscribe(response => {
              controller.cachedEventNodeData[node.event_node_id] = {'registration': response};
              node.locked_out = controller.cachedEventNodeData[node.event_node_id].registration.locked_out;
              doneWithLockedOut();
            }, error => {
              console.dir("Error getting registration: " + node.registration_id);
              console.dir(error);
              doneWithLockedOut();
            });
          } else {
            node.locked_out = controller.cachedEventNodeData[node.event_node_id].registration.locked_out;
            doneWithLockedOut();
          }
        });

        if (!controller.eventNodes.optedOutNodes.length)
          doneWithLockedOut();


    }, error => {
      console.dir("Error getting events and/or event nodes: ");
      console.dir(error);
    });

    this.RDS.getCreateableTemplates().subscribe((resp)=>{this.notificationTemplates = resp})

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
    clearInterval(this.setObligation);
    clearTimeout(this.offersTimeOut)
  }


  setCustomTasks(tasks) {
    const controller = this;
    this.savedCustomTasks = tasks;

    //Set the filter and template display labels
    this.savedCustomTasks.forEach(task => {
      for(let i = 0; i < this.allFilters.length; i++) {
        if(this.allFilters[i].code === task.filter_type) {
          task.filter_display_label = this.allFilters[i].display_label;
        }
      }

      for(let i = 0; i < this.allTemplates.length; i++) {
        if (this.allTemplates[i].template_name === task.action_parameter) {
          task.template_display_label = this.allTemplates[i].display_label;
        }
      }
      task.event_node_count = Object.keys(task.event_nodes).length;
      task.has_event_nodes = task.event_node_count > 0;
    });

    this.allCustomTasks = this.savedCustomTasks.concat(this.newCustomTasks);
  }

  updateEvent(data): void {
    this.eventObj = Object.assign({}, this.eventObj, data);
    this.setEvent(this.eventObj);
  }

  setEvent(data): void {
    this.eventObj = data;
    this.sharedService.setProgressStatus(this.eventObj);
    this.sharedService.setCurrentObligation(this.eventObj);
    clearInterval(this.setObligation);
    this.setObligation = setInterval(() => {
      this.sharedService.setCurrentObligation(this.eventObj);
      }, 5000);

    const controller = this;

    //status
    this.sharedService.setEventStatus(this.eventObj);


    if (this.eventObj.event_action_type) {
      this.canCancelEvent = (!this.eventObj.event_end_dttm_utc || moment(this.eventObj.event_end_dttm_utc).isAfter(moment().utc())) || this.eventObj.status === 'Paused';
      this.canPauseEvent = this.canCancelEvent && this.eventObj.status !== 'Paused';
      this.canResumeEvent = this.canCancelEvent && this.eventObj.status === 'Paused';
      const actionType = this.eventTypes.find(({code}) => code === this.eventObj.event_action_type);
      this.eventObj.event_action_type_display_label = actionType ? actionType.display_label : '';
    }
     //Get the product from COM now that we have the event. But only if we don't have it yet.
    if (!this.comProductObj) {
      this.eventManagementService.get('/v1/product/' + this.eventObj.product_id, {}).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
        response => {
          const now = moment();
          let nodesToTest = [...this.eventNodes.completedNodes, ...this.eventNodes.activeNodes];
          this.comProductObj = response.data;
          if(this.comProductObj.dispatch_conf &&
            this.comProductObj.dispatch_conf.post_bonus_minutes &&
            !this.eventObj.cancelled &&
            nodesToTest.length &&
            (!nodesToTest[0].performance_end_dttm_utc || !nodesToTest[0].performance_end_dttm_utc.length  || moment(nodesToTest[0].performance_end_dttm_utc).isAfter(now))
          ) {
            this.showEndCalcs = true;
          }
          this.overallPerfValue = this.sharedService.getOverallPerformance(this.eventObj, this.comProductObj);

          //Because we don't have the COM object yet, we can't get perf data. So set it to be an empty array for now then refetch the data instantly.
          this.eventObj.performanceData = [];
          this.getPerformanceDataInfo = {
            performance_data: true,
            performance_aggregate_type: this.comProductObj.performance_aggregate_type
          };
          this.getEvents$ = this.eventManagementService.get('/v1/event/' + this.eventID, this.getPerformanceDataInfo)
            .pipe(takeUntil(this.ngUnsubscribe));

          this.productUOM = this.sharedService.getPrezUOM(this.comProductObj)
          //Set defaults for target_type related attributes
          if (!this.comProductObj.target_type) {
            this.comProductObj.target_type = "DROP_BY";
          }
          if (!this.comProductObj.performance_target_min) {
            this.comProductObj.performance_target_min = 100;
          }
          if (this.comProductObj.target_type === "RANGE" && !this.comProductObj.performance_target_max) {
            this.comProductObj.performance_target_max = this.comProductObj.performance_target_min * 1.5;
          }

          let totalMinutes = Math.floor(Number(this.comProductObj['max_event_duration']) / 60000);
          let hours = Math.floor(totalMinutes / 60);
          let minutes = totalMinutes % 60;

          this.maxEventDurationDisplay = hours + ' Hour(s) and ' + minutes + ' Minute(s)';

          this.setPerformanceColor();

          //Get performance data for the first time
          controller.eventManagementService.post('/v1/event_performance', {event_ids: [controller.eventObj.event_id, ], performance_aggregate_type: controller.getPerformanceDataInfo.performance_aggregate_type}, {})
            .pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
              controller.eventObj.performanceData = response.data;
            },
            err => {
              console.dir("Error getting event performance data: ");
              console.dir(err);
            });
        },
        error => {
          console.dir("Error getting product");
          console.dir(error);
        }
      );
    } else {
      this.setPerformanceColor();
    }

  }

  setPerformanceColor(){
    if(this.displayedEventNodes.activeNodes && this.displayedEventNodes.activeNodes.length && this.comProductObj){
      const prod = this.comProductObj;
      const errorThreshold = prod.performance_error_threshold || 100;
      const warningThreshold = prod.performance_warning_threshold || 105;

      this.displayedEventNodes.activeNodes.forEach((node) => {
        const value = node.last_current_performance_percentage;
        if(!value){
          node.perf_color = 'rgb(158, 158, 158)'
        }
        else if(value <= errorThreshold)
        //red
          node.perf_color = 'rgb(243, 60, 77)';
        else if( value > errorThreshold && value < warningThreshold)
        //yellow
          node.perf_color = 'rgb(255, 231, 1)';
        else if(value >= warningThreshold)
        //green
          node.perf_color = 'rgb(0, 207, 18)';
        else
          node.perf_color = 'rgb(89,188,95)';
      })
    }
  }

  eventIsCancelled(event: EPSEvent): boolean {
    return event.cancelled && !!event.cancelled_dttm;
  }



  endCalcs() {

    const controller = this;

    controller.sharedService.activateModal({
      headerText: "End Calculations",
      bodyText: "Ending calculations. Are you sure?",
      buttonText: 'End Calculations',
      cancelText: 'No',
      allowCancel: true,
      confirmFunction: function () {

        controller.eventManagementService.post('/v1/events/end_event_calculations', {event_ids: [controller.eventObj.event_id]}, {product_id: controller.eventObj.product_id}).pipe().subscribe(
          (resp)=>{
            controller.showEndCalcs = false;
            if(resp.code == 207) {
              controller.sharedService.popSuccess("Some of the nodes were not eligible to update.  See console for the list of updated nodes.")
              console.log('Updated Node Ids: ' + resp.data);
            } else if(resp.code == 400) {
              controller.sharedService.popSuccess("None of the nodes were eligible to update.")
            } else {
              controller.sharedService.popSuccess("Calculations ended.")
            }

          },
          (err)=>{
            console.dir("Error ending calculations");
            console.dir(err);
          }
        )

      }
    });

  }

  refreshBaselines() {

    const controller = this;
    let timePickerData = new ModalDatePickerData(
      null,
      'Update Notification',
      moment().toISOString(),
      this.eventObj.event_start_dttm_utc,
      0,
      true,
      false,
      false,
      false
    );

    controller.sharedService.activateModal({
      headerText: "Refresh Baselines",
      bodyText: "This action will allow to refresh the baseline and recalculate its adjustment for this dispatch.",
      allowCancel: true,
      customContent: new CustomModalWrapper(RefreshBaselinesModalComponent, {
        smallBodyText: "Please note if this action is executed when data is no longer available (around 8 hours after event end time) the baseline refresh may not be reflected, but changes to the notification time will be logged.",
        passwordRequired: true,
        password: 'ennel1',
        program_time_zone: controller.comProductObj.timezone,
        showTimePicker: controller.comProductObj.collect_notification_time,
        timePickerData: timePickerData,
        confirmFunction: function (notificationDate) {
          const params = notificationDate.isValid() ? {notification_time: notificationDate.toISOString()} : {};
          controller.eventManagementService.post('/v1/events/refresh_baselines', [controller.eventObj.event_id], params)
            .pipe(takeUntil(controller.ngUnsubscribe)).subscribe(response => {
              controller.sharedService.popSuccess("Baseline Refreshed.");
              this.close();
            },
            err => {
              controller.sharedService.popError("Error occurred for refresh baseline.");
              console.dir("Error refreshing baseline: ");
              console.dir(err);
            });
        }
      })
    });

  }

  //region timeline bulk actions
/*  bulkRescheduleStep(data): void {
    const controller = this;

    const reqObj = {
      event_nodes: data.eventNodes,
    };
    let timePickerData = new ModalDatePickerData(null, '', moment().toISOString(), null, 0,true, false, false, false);

    this.sharedService.activateModal({
      headerText: 'Update Workflow Step (' + data.workflowStepInfo.name + ')',
      bodyText: "Updating workflow step for " + data.eventNodes.length + " event nodes. Are you sure?",
      customContent: new CustomModalWrapper(WorkflowStepEditModalComponent, {
        defaultTime: data.workflowStepInfo.time,
        automated: data.workflowStepInfo.automated,
        buttonText: "Update",
        showInclude: data.showInclude,
        program_time_zone: controller.comProductObj.timezone,
        confirmFunction: function(updateBody) {
          let path = '/v1/event_nodes/reschedule/' + data.attribute + '/' + updateBody.time;

          path += '?automate=' + updateBody.automated.toString();

          if(updateBody.include !== null && updateBody.include !== undefined) {
            path += '&include=' + updateBody.include.toString();
          }

          controller.eventManagementService.put(path, reqObj, {}).pipe(takeUntil(controller.ngUnsubscribe)).subscribe(
            response => {
              controller.sharedService.popSuccess("Successfully updated Event Nodes");
              controller.cancelBulkAction();
            },
            error => {
              controller.sharedService.popError("Failed to update Event Node!");
              console.dir(error)
              controller.cancelBulkAction();
            });

        },
        timePickerData: [timePickerData]
      })
    });
  }*/

  /*bulkDoNowStep(data): void {
    const controller = this;

    const reqObj = {
      event_nodes: data.eventNodes,
    };

    controller.sharedService.activateModal({
      headerText: "Triggering Workflow Step (" + data.workflowStepName + ')',
      bodyText: "Triggering workflow step for " + data.eventNodes.length + " event nodes. Are you sure?",
      buttonText: 'Trigger',
      cancelText: 'No',
      allowCancel: true,
      confirmFunction: function () {

        let path = '/v1/event_nodes/trigger/' + data.attribute + '/';

        controller.eventManagementService.put(path, reqObj, {}).pipe(takeUntil(controller.ngUnsubscribe)).subscribe(
          response => {
            controller.sharedService.popSuccess("Successfully updated Event Nodes");
            controller.cancelBulkAction();
          },
          error => {
            controller.sharedService.popError("Failed to update Event Node!");
            console.dir(error)
            controller.cancelBulkAction();
          });
      }
    });
  }*/

  /*bulkExclude(data): void {

    const controller = this;

    let reqObj = {
      event_nodes: data.eventNodes,
    };

    controller.sharedService.activateModal({
      headerText: "Exclude Workflow Step (" + data.workflowStepName + ')',
      bodyText: "Excluding workflow step for " + data.eventNodes.length + " event nodes. Are you sure?",
      warningText: (data.warnNextStepWillFire ? "Warning: excluding this step will result in the next step immediately firing. " : ""),
      buttonText: 'Exclude',
      cancelText: 'No',
      allowCancel: true,
      confirmFunction: function () {

        controller.eventManagementService.put('/v1/event_nodes/exclude/' + data.attribute, reqObj, {}).pipe(takeUntil(controller.ngUnsubscribe)).subscribe(
          response => {
            controller.sharedService.popSuccess("Successfully updated Event Nodes");
            controller.cancelBulkAction();
          },
          error => {
            controller.sharedService.popError("Failed to update Event Node!");
            console.dir(error);
            controller.cancelBulkAction();
          });
      }
    });

  }*/

 /* bulkDoNow(): void {
    this.eventManagementService.bulkDoNow(this.allCustomTasks, this.ngUnsubscribe, () => {
      this.cancelBulkAction();
    })
  }

  bulkUpdate(): void {
    this.eventManagementService.bulkUpdate(this.allCustomTasks, this.ngUnsubscribe, this.comProductObj.max_event_duration, this.eventObj.event_start_dttm_utc, this.eventObj.full_time_zone, this.createableFilters, this.createableTemplates, () => {
      this.cancelBulkAction();
    })
  }

  bulkCancel(): void {
    this.eventManagementService.bulkCancel(this.allCustomTasks, this.ngUnsubscribe, () => {
      this.cancelBulkAction();
    })
  }*/
  //endregion


  //region custom task methods
  addCustomTask() {
    let timePickerData;
    const controller = this;

    this.sharedService.activateModal({
      headerText: "Add Custom Task",
      bodyText: "",
      buttonText: 'Continue',
      cancelText: 'Cancel',
      allowCancel: true,
      password: 'ennel1',
      confirmFunction: function () {
        setTimeout(function () {
          if(controller.eventObj.event_end_dttm_utc) {
            timePickerData = new ModalDatePickerData(null, 'Schedule', moment().subtract('15', 'minutes').toISOString(), moment(controller.eventObj.event_end_dttm_utc).add('60', 'minutes').toISOString(), 0,false, true, true, false);
          } else {
            timePickerData = new ModalDatePickerData(null, 'Schedule', moment().subtract('15', 'minutes').toISOString(), moment(controller.eventObj.event_start_dttm_utc).add('60', 'minutes').add(controller.comProductObj.max_event_duration, 'ms').toISOString(), 0,false, true, true, false);
          }

          controller.sharedService.activateModal({
            headerText: "Add Custom Task",
            customContent: new CustomModalWrapper(CustomTaskModalComponent, {
              style: {
                'width': '50%',
                'mid-width': '450px'
              },
              program_time_zone: controller.comProductObj.timezone,
              timePickerData: timePickerData,
              event_start:controller.eventObj.event_start_dttm_utc,
              event_end:controller.eventObj.event_end_dttm_utc,
              allFilters: _.remove(controller.createableFilters, (o)=>{return o.code !== 'MANUAL'}),
              allTemplates: controller.createableTemplates,
              buttonText: 'Save',
              updateTask: false,
              confirmFunction: function (task) {

                task['event_ids'] = [controller.eventObj.event_id];

                controller.eventManagementService.post('/v1/custom_task', task, {}).pipe(takeUntil(controller.ngUnsubscribe)
                ).subscribe(response => {
                    controller.sharedService.popSuccess("Successfully added Custom Task. Updates may take up to 1 minute to display on this page.");
                  },
                  error => {
                    controller.sharedService.popError("Failed to add Custom Task!");
                    console.dir(error)
                  });
              }
            })
          });
        });
      }});

  }

  updateCustomTask(selectedTask) {
    const controller = this;
    let timePickerData;
    controller.editingCustomTask = true;

    this.sharedService.activateModal({
      headerText: "Update Custom Task",
      bodyText: "",
      buttonText: 'Continue',
      cancelText: 'Cancel',
      allowCancel: true,
      password: 'ennel1',
      confirmFunction: function () {
        setTimeout(function () {
          if (controller.eventObj.event_end_dttm_utc) {
            timePickerData = new ModalDatePickerData(null, 'Schedule', moment().subtract('15', 'minutes').toISOString(), moment(controller.eventObj.event_end_dttm_utc).add('60', 'minutes').toISOString(), 0, false, true, true, false);
          } else {
            timePickerData = new ModalDatePickerData(null, 'Schedule', moment().subtract('15', 'minutes').toISOString(), moment(controller.eventObj.event_start_dttm_utc).add('60', 'minutes').add(controller.comProductObj.max_event_duration, 'ms').toISOString(), 0, false, true, true, false);
          }

          controller.sharedService.activateModal({
            headerText: "Update Custom Task",
            customContent: new CustomModalWrapper(CustomTaskModalComponent, {
              style: {
                'width': '50%',
                'mid-width': '450px'
              },
              program_time_zone: controller.comProductObj.timezone,
              timePickerData: timePickerData,
              updateTask: true,
              event_start: controller.eventObj.event_start_dttm_utc,
              event_end: controller.eventObj.event_end_dttm_utc,
              buttonText: 'Save',
              task: selectedTask,
              confirmFunction: function (task) {

                controller.eventManagementService.put('/v1/custom_task/' + selectedTask.custom_task_id, task, {}).pipe(takeUntil(controller.ngUnsubscribe)
                ).subscribe(response => {
                    controller.sharedService.popSuccess("Successfully updated Custom Task. Updates may take up to 1 minute to display on this page.");
                    controller.editingCustomTask = false;
                  },
                  error => {
                    controller.sharedService.popError("Failed to update Custom Task!");
                    console.dir(error);
                    controller.editingCustomTask = false;
                  });
              }
            })
          });
        });
      }});
  }

  cancelCustomTask(taskID) {
    const controller = this;
    controller.editingCustomTask = true;

    this.sharedService.activateModal({
      headerText: "Cancel Custom Task",
      bodyText: "",
      buttonText: 'Continue',
      cancelText: 'Cancel',
      allowCancel: true,
      password: 'ennel1',
      confirmFunction: function () {
        setTimeout(function () {
          controller.sharedService.activateModal({
            headerText: "Cancel Custom Task",
            bodyText: "Are you sure?",
            buttonText: 'Cancel Custom Task',
            cancelText: 'No',
            allowCancel: true,
            confirmFunction: function () {
              controller.eventManagementService.delete('/v1/custom_task/' + taskID, {}).pipe(takeUntil(controller.ngUnsubscribe)
              ).subscribe(response => {
                  controller.sharedService.popSuccess("Successfully cancelled Custom Task. Updates may take up to 1 minute to display on this page.");

                  controller.editingCustomTask = false;
                },
                error => {
                  controller.sharedService.popError("Failed to cancel Custom Task!");
                  console.dir(error);
                  controller.editingCustomTask = false;
                });

            }});
        });
      }});
  }

  hideCustomTaskButtons() {
    if (this.eventObj.event_end_dttm_utc && moment(this.eventObj.event_end_dttm_utc).add('60', 'minutes') < moment())
      return true;
    else if(!this.eventObj.event_end_dttm_utc && this.comProductObj?.max_event_duration && moment(this.eventObj.event_start_dttm_utc).add('60', 'minutes').add(this.comProductObj.max_event_duration, 'ms') < moment())
      return true;

    return false;
  }

  customTaskTrackByFunction(index, item) {
    return item ? item.custom_task_id : undefined;
  }

  hasEventNodes(task) {
    if (!task.event_nodes) {
      return false;
    }
    return Object.keys(task.event_nodes).length > 0;
  }
  getEventNodeCount(task) {
    return Object.keys(task.event_nodes).length;
  }

  //endregion


  //region Sorting and filtering methods

  applyFilters() {
    this.activeCurrent = 0;
    this.activeExpected = 0;
    this.availability = 0;
    this.numberUnderperforming = 0;
    this.numberExceptions = 0;
    this.showBulkEnd = false;
    this.showBulkActivate  = false;
    this.showBulkRecurtail = false;
    this.showBulkOptIn = false;

    this.displayedEventNodes.clear();


    this.displayedEventNodes.activeNodes = JSON.parse(JSON.stringify(this.eventNodes.activeNodes));
    this.displayedEventNodes.pendingNodes = JSON.parse(JSON.stringify(this.eventNodes.pendingNodes));
    this.displayedEventNodes.optedOutNodes = JSON.parse(JSON.stringify(this.eventNodes.optedOutNodes));
    this.displayedEventNodes.completedNodes = JSON.parse(JSON.stringify(this.eventNodes.completedNodes));
    this.displayedEventNodes.excludedNodes = JSON.parse(JSON.stringify(this.eventNodes.excludedNodes));
    this.displayedEventNodes.cancelledNodes = JSON.parse(JSON.stringify(this.eventNodes.cancelledNodes));

    this.displayedEventNodes.activeNodes.forEach(node => {
      this.activeCurrent += node.last_current_performance_value || 0;
      this.activeExpected += node.expected_capacity_value || 0;

      //Don't go negative - that means we're performing well and it might be confusing to see a negative number in the table
      if(node.shortfall < 0)
        node.shortfall = 0;

    });

    this.displayedEventNodes.pendingNodes.forEach(node => {
      this.availability += node.realtime_availability || 0;
    });

    //This is for the timeline component, since it needs a map of event:eventNodes.
    this.eventNodeMap[this.eventObj.event_id] = JSON.parse(JSON.stringify(this.displayedEventNodes));

    this.displayedEventNodes.allNodes = this.displayedEventNodes.excludedNodes.concat(this.displayedEventNodes.completedNodes).concat(this.displayedEventNodes.pendingNodes).concat(this.displayedEventNodes.activeNodes);
    this.setPerformanceColor();
  }
  //endregion


  cancelBulkAction():void {
    this.bulkActionType = this.BulkActionType.none;
  }


  showRegList(task) {
    let arr = [];
    this.displayedEventNodes.allNodes.forEach((node: EventNode)=>{
      if(Object.keys(task.event_nodes).includes(node.event_node_id)) {
        arr.push(node.registration_display_label)
      }
    });
    this.sharedService.activateModal({
      headerText: 'Registrations Included',
      bodyText: '',
      customContent: new CustomModalWrapper(RegListModalComponent, {
        buttonText: 'OK',
        registrationLabels: arr
      })
    })
  }



  fixedDecimals(value, numDecimals = 3) {
    if(typeof(value) === 'number')
      return value.toFixed(numDecimals);
    else
      return null;
  }

  eventEndInPast(): boolean {
    let nowUTC = moment().utc().toISOString();
    let isInPast: boolean = this.eventObj.event_end_dttm_utc < nowUTC;

    return isInPast;
  }

  //region cancel, pause, resume, end event
  eventInPostBonusTime(): boolean {
    let nowUTC = moment().utc();
    if(this.eventObj.event_end_dttm_utc && this.eventObj.event_end_dttm_utc.length) {
      return this.comProductObj.dispatch_conf.post_bonus_minutes ? nowUTC.isSameOrAfter(moment(this.eventObj.event_end_dttm_utc).add(this.comProductObj.dispatch_conf.post_bonus_minutes)) : false;
    }
    return false;
  }

  cancelEvent(): void {
    const controller = this;
    const nodesToSend = {};
    nodesToSend[this.eventObj.event_id] = this.displayedEventNodes;

    this.sharedService.activateModal({
      headerText: 'Are you sure you want to cancel this event?',
      customContent: new CustomModalWrapper(CancelEventModalComponent, {
        style: {
          'width': '30%',
          'min-width': '350px'
        },
        password: "ennel1",
        event: this.eventObj,
        events: [this.eventObj.event_id],
        nodes: nodesToSend,
        onCancel: (notifyNodes)=>{
          let cancelBody = {event_ids: [this.eventObj.event_id]};
          if(notifyNodes){
            cancelBody['cancel_notification'] = true;
          };
          this.eventManagementService.post('/v1/event/' + this.eventObj.event_id + '/cancel', cancelBody, {}).pipe().subscribe(
            (response) => {
              controller.sharedService.popSuccess("Successfully cancelled Event. Updates may take up to 1 minute to display on this page.");
            },
            (error) => {
              controller.sharedService.popError("Failed to cancel Event!");
            }
          )
        }
      })
    })
  }

  pauseEvent(): void {
    const controller = this;

    this.sharedService.activateModal({
      headerText: "Pause Event", bodyText: "Are you sure? No workflow steps will happen until event is resumed.", buttonText: 'Pause Event', cancelText: 'No', allowCancel: true, confirmFunction: function () {

        controller.eventManagementService.patch('/v1/event/' + controller.eventObj.event_id + '/pause', {}, null).pipe(takeUntil(controller.ngUnsubscribe)
        ).subscribe(response => {
            controller.sharedService.popSuccess("Successfully paused Event. Updates may take up to 1 minute to display on this page.");
          },
          error => {
            controller.sharedService.popError("Failed to pause Event.!");
            console.dir(error);
          });

      }
    });
  }

  resumeEvent(): void {
    const controller = this;

    if(moment(controller.eventObj.event_end_dttm_utc).isAfter(moment().utc())) {
      this.sharedService.activateModal({
        headerText: "Resume Event", bodyText: "Are you sure? All workflow steps will be executed on their currently-defined schedule.", buttonText: 'Resume Event', cancelText: 'No', allowCancel: true, confirmFunction: function () {

          controller.eventManagementService.patch('/v1/event/' + controller.eventObj.event_id + '/resume', {}, null).pipe(takeUntil(controller.ngUnsubscribe)
          ).subscribe(response => {
              controller.sharedService.popSuccess("Successfully resumed Event. Updates may take up to 1 minute to display on this page.");
            },
            error => {
              controller.sharedService.popError("Failed to rsume Event.!");
              console.dir(error);
            });
        }
      });
    } else {

      this.sharedService.activateModal({
        headerText: "End Time has passed.", bodyText: "The event's end time has passed. You will need to update the end time before you can resume the event.", buttonText: 'OK', allowCancel: false, confirmFunction: function () {}
      });
    }


  }

  endEvent(): void {
    const controller = this;

    let timePickerDataEnd = new ModalDatePickerData(this.eventObj.event_end_dttm_utc, 'Event End', controller.eventObj.event_start_dttm_utc, moment(controller.eventObj.event_start_dttm_utc).add(this.comProductObj.max_event_duration + 43200000, 'ms').toISOString(), this.comProductObj.max_event_duration, false, false, true, false);

    this.sharedService.activateModal({
      headerText: "Change Event End Time",
      customContent: new CustomModalWrapper(EventExtendModalComponent, {
        style: {'width': '50%'},
        events: [{event: controller.eventObj, obligation: null}],
        program_time_zone: controller.comProductObj.timezone,
        product_uom: controller.sharedService.getPrezUOM(controller.comProductObj),
        timePickerData: [timePickerDataEnd],
        confirmFunction: function (time, events) {

          let body = _.map(events, (e) => {
            let bd = {event_id: e.event.event_id};

            if (e.obligation)
              bd['obligation'] = controller.sharedService.convertToKW(e.obligation, this.product_uom);

            return bd;
          });

          controller.changingEndTime = true;
          controller.eventManagementService.put('/v1/event/event_end_time/' + time[0], body, {}).pipe(takeUntil(controller.ngUnsubscribe)
          ).subscribe(response => {
              controller.sharedService.popSuccess("Successfully set Event end time. Updates may take up to 1 minute to display on this page.");
              controller.changingEndTime = false;
            },
            error => {
              controller.sharedService.popError("Failed to end Event!");
            });
        },
        buttonText: "Change Event End Time"
      })
    });
  }
  //endregion


  sendNow(task) {
    const controller = this;
    controller.editingCustomTask = true;

    let tempTask = {
      scheduled_time: moment().toISOString(),
      automated: true
    };

    this.sharedService.activateModal({
      headerText: "Send Now",
      bodyText: "",
      allowCancel: true,
      password: 'ennel1',

      confirmFunction: function () {

        controller.eventManagementService.put('/v1/custom_task/' + task.custom_task_id, tempTask, {}).pipe(takeUntil(controller.ngUnsubscribe)
        ).subscribe(response => {
            controller.sharedService.popSuccess("Successfully sent Custom Task. Updates may take up to 1 minute to display on this page.");
            controller.editingCustomTask = false;

          },
          error => {
            controller.sharedService.popError("Failed to send Custom Task!");
            console.dir(error);
            controller.editingCustomTask = false;
          });
      }
    });
  }

  getCustomerOffers() {
    let startTime;
    const now = moment().utc();
    startTime = now.toISOString();
    const controller = this;

    if(this.eventNodes.optedOutNodes && this.eventNodes.optedOutNodes.length && (!this.eventObj.event_end_dttm_utc || now.isSameOrBefore(moment(this.eventObj.event_end_dttm_utc)))) {

      this.eventManagementService.get('/v1/customer_offers/', {product_id: this.eventObj.product_id, start_dttm: startTime}).pipe(takeUntil(this.ngUnsubscribe)
      ).subscribe(response => {

        this.customerOffers = response.data;
        if(this.customerOffers.length) {
          const thirtySeconds = 30000;
          const timeToEnd = moment.duration(moment(this.customerOffers[0].offer_end_dttm_utc).diff(moment().utc())).as('milliseconds');
          this.offersTimeOut = setTimeout( () => {
            controller.getCustomerOffers();
          }, timeToEnd + thirtySeconds)
        }

      }, error => {
        console.dir("Error getting Customer Offer for product: " + this.eventObj.product_id);
        console.dir(error);
        this.customerOffers = [];
      });
    }

  }
}
