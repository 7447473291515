import {Component, Input, OnInit} from '@angular/core';
import {CustomModalData} from "../enel-modal/enel-modal.component";
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';
import {SharedService} from "../../../services/shared.service";
import {ModalDatePickerData} from "../../../classes/modalDatePickerData";

@Component({
  selector: 'app-event-extend-modal',
  templateUrl: './event-extend-modal.component.pug',
  styleUrls: ['./event-extend-modal.component.scss']
})
export class EventExtendModalComponent implements CustomModalData {

  showDurationWarning;
  durationErrorMsg:string = '';
  durationWarningMsg:string = '';
  showTimezoneTable = true;

  constructor(private shared:SharedService) { }

  @Input() data:any;

  ngOnInit() {

    if(this.data.utility && this.data.utility == true) {
      this.showTimezoneTable = false;
    }

    if(!this.data.program_time_zone)
      this.data.program_time_zone = "UTC";

    //Set the default dates if there are any
    this.data.timePickerData.forEach(tpd => {
      if(tpd.default_date) {
        let convertedTime = moment.tz(moment(tpd.default_date), this.shared.getTimeZoneName(this.data.program_time_zone, false));
        tpd.entered_time = convertedTime.format('YYYY-MM-DDTHH:mm');
      }
    });
  }

  dateIsBeforePreviousEndDate() {

    let chosenDateUTC = this.shared.selectedTimeZoneToUTC(this.data.timePickerData[0].entered_time, this.data.program_time_zone);

    if(chosenDateUTC.isSameOrBefore(moment.utc(this.data.timePickerData[0].default_date))) {
      return false;
    }

    return true;
  }

  confirm() {

    let hasErrors:boolean = false;
    let datesToSend = [];

    this.data.timePickerData.forEach(tpd => {
      const date = moment(tpd.entered_time);

      tpd.errors = [];

      if(date.isValid()) {
        let chosenDateUTC = this.shared.selectedTimeZoneToUTC(tpd.entered_time, this.data.program_time_zone);

        if(!tpd.default_date &&  moment().isSameOrAfter(moment(tpd.max_date))  && chosenDateUTC.isBefore(moment().utc().subtract(15, 'minutes'), 'minute')) {
          tpd.errors.push('Entered date cannot be more than 15 minutes in the past.');
        } else{
          if(tpd.default_date && chosenDateUTC.isBefore(moment().utc(), 'minute')) {
            tpd.errors.push('Entered date cannot be in the past.');
          }
        }


        if(tpd.min_date) {

          if(tpd.min_date_inclusive) {
            if(chosenDateUTC.isBefore(moment.utc(tpd.min_date))) {
              tpd.errors.push('Entered date must be the same as or after the event start time.');
            }
          } else {
            if(chosenDateUTC.isSameOrBefore(moment.utc(tpd.min_date))) {
              tpd.errors.push('Entered date must be after the event start time.');
            }
          }
        }
        //This is a hacky way to handle the "Activate Node" case, where the min date needs to be the selected start date from this modal
        //I pass in "null" for the second datepicker's min_date with an action name of 'End'
        else if(tpd.action_name === "End" && this.data.timePickerData[0].action_name === 'Start') {
          if(chosenDateUTC.isSameOrBefore(this.shared.selectedTimeZoneToUTC(this.data.timePickerData[0].entered_time, this.data.program_time_zone))) {
            tpd.errors.push('Entered date must be after the above entered Start Time.');
          }
        }

        if(tpd.max_date) {
          if(tpd.max_date_inclusive) {
            if(chosenDateUTC.isAfter(moment(tpd.max_date))) {
              tpd.errors.push('Entered date must be the same as or before the event end time.');
            }
          }
          else {
            if (!tpd.max_duration || tpd.max_duration == 0) {
              if (chosenDateUTC.isSameOrAfter(moment(tpd.max_date))) {
                tpd.errors.push('Entered date must be before the event end time.');
              }
            } else {
              if ((tpd.default_date || (!tpd.default_date && moment().isSameOrBefore(moment(tpd.max_date)))) && chosenDateUTC.isAfter(moment(tpd.max_date))  && !this.data['isUtility']) {
                let totalMinutes = Math.floor(Number(this.data.timePickerData[0].max_duration) / 60000);
                let hours = Math.floor(totalMinutes / 60);
                let minutes = totalMinutes % 60;
                let timeString = hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0');
                let zone = this.shared.getTimeZoneName(this.data.program_time_zone, false);

                tpd.errors.push("Event Duration cannot be longer than the Product's Max Event Duration of " + timeString + " plus 12 hours - " + moment(tpd.max_date).tz(zone).format(SharedService.dateFormat));
              }
            }
          }
        }

        if(tpd.errors.length > 0) {
          hasErrors = true;
        } else {
          datesToSend.push(chosenDateUTC.toISOString())
        }
      }
      else {
        if(!tpd.entered_time && tpd.allow_null) {
          datesToSend.push(null);
        } else {
          hasErrors = true;
          tpd.errors.push('Invalid date entered.');
        }
      }
    });

    if(hasErrors === false) {
      this.data.confirmFunction(datesToSend, this.data.events);
      this.data.close();
    }
  }

  close() {
    this.data.close();
  }

  getTimezone() {
    return this.shared.getTimeZoneName(this.data.program_time_zone);
  }

  getTimeZoneConversion(timePickerData:ModalDatePickerData, zone)
  {
    let localTZ = this.shared.getUsersTimeZone();
    let tz;

    switch (zone) {
      case 'local':
        tz = localTZ;
        break;
      case 'program':
        tz = this.data.program_time_zone;
        break;
      case 'utc':
        tz = 'UTC';
        break;
    }

    let convertedTime: string = this.shared.selectedTimeZoneToUTC(timePickerData.entered_time, this.data.program_time_zone).toISOString();
    return timePickerData.entered_time ? moment.tz(convertedTime, tz).format(SharedService.dateFormat) : 'None';
  }

  //Gets the date from the child datepicker and sets it in our corresponding timepicker data object
  getTimeFromChild(time, tpd) {

    tpd.entered_time = time.date;

    const tpData = this.data.timePickerData[0];

    if(tpData.max_duration && tpData.max_duration > 0){
      let chosenDateUTC = this.shared.selectedTimeZoneToUTC(tpd.entered_time, this.data.program_time_zone);
      let zone = this.shared.getTimeZoneName(this.data.program_time_zone, false);

      this.durationWarningMsg = "Warning: Event Duration is longer than the Product's Max Event Duration - " + moment(tpd.min_date).tz(zone).add(tpData.max_duration, 'ms').format(SharedService.dateFormat);
      this.showDurationWarning = !this.data.utility && chosenDateUTC.isAfter(moment(tpData.min_date).add(+tpData.max_duration, 'ms')) && chosenDateUTC.isBefore(moment(tpData.max_date));
    }

  }

}
