import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EPSEvent } from '../classes/event';
import { ModalDatePickerData } from '../classes/modalDatePickerData';
import { Product } from '../classes/product';
import { CustomTaskModalComponent } from '../components/modals/custom-task-modal/custom-task-modal.component';
import { WorkflowStepEditModalComponent } from '../components/modals/workflow-step-edit-modal/workflow-step-edit-modal.component';
import { EventManagementService } from './event-management.service';
import { RefDataService } from './ref-data.service';
import { SharedService } from './shared.service';
import { CustomModalWrapper } from '../components/modals/enel-modal/enel-modal.component';
import { SendTemplatesModalComponent } from '../components/modals/send-templates-modal/send-templates-modal.component';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';
import * as _ from 'lodash';
import { DateTime, Duration } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class BulkService {

  constructor(private EMS: EventManagementService, private SS: SharedService, private RDS: RefDataService) { }

  overrideControl(nodes){
    const ctrl = this;
    let putObj = {event_nodes: nodes}
    return new Promise<any>((resolve, reject) => {

      ctrl.SS.activateModal({
        headerText: "Controls Opt Out",
        bodyText: "Confirm the selected remote controlled registrations should not be toggled for this event. Note: Customers will still receive dispatch notifications.",
        warningText: nodes.length + " node(s) will have their toggle step excluded.",
        buttonText: 'Confirm',
        cancelText: 'Cancel',
        allowCancel: true,
        password: 'ennel1',
        confirmFunction: ()=>{
          ctrl.EMS.put('/v1/event_nodes/override_control',putObj, null).pipe().subscribe(
            (resp)=>{
              resolve(resp)
              ctrl.SS.popSuccess("Successfully opted out of Event Node controls. Updates may take up to 1 minute to display on this page.")
            },
            (err)=>{
              reject(err)
              this.SS.popError("Failed to opt out of Event Node controls!");
            }
          )
        }
      })
    })
  }

  cancelNodes(nodes){
    const ctrl = this;
    let putObj = {event_nodes: nodes}
    return new Promise<any>((resolve, reject) => {
      let postObj = {event_node_ids: nodes};
      let path='/v1/event_nodes/cancel';

      ctrl.SS.activateModal({
        headerText: "Are you sure you want to cancel Event Node(s)?",
        bodyText: "All workflows will move immediately to complete and no intermediate steps will happen.",

        cancelText: 'Cancel Event Nodes(s)',
        allowCancel: false,
        password: 'ennel1',
        customContent: new CustomModalWrapper(SendTemplatesModalComponent, {
          sendCancellationTemplate: true,
          willReceive: nodes.length,
          buttonText: 'Cancel Event Node(s)',
          confirmFunction: (sendTemplates)=>{

            if(sendTemplates){
              path += '?cancel_notification=true'
            }

            ctrl.EMS.post(path, postObj, null).pipe().subscribe(
              (resp)=>{
                resolve(resp);
                ctrl.SS.popSuccess("Successfully cancelled Event Node(s). Updates may take up to 1 minute to display on this page.")
              },
              (err)=>{
                reject(err)
                ctrl.SS.popError("Failed to cancel Event Node(s)!");
              }
            )
          }
        })
      })
    })
  }

  addCustomTasks(events, product){
    const ctrl = this;
    let start = events[0].event_start_dttm_utc;
    let timePickerData = new ModalDatePickerData(null, 'Schedule', moment().subtract('15', 'minutes').toISOString(), moment(start).add('60', 'minutes').add(product.max_event_duration, 'ms').toISOString(), 0, false, true, true, false);
    return new Promise<any>((resolve, reject) => {
      forkJoin(this.RDS.getCreateableFilterTypes(), this.RDS.getCreateableTemplates()).pipe().subscribe(
        ([createableFilterTypes, createableTemplates])=>{
          ctrl.SS.activateModal({
            headerText: "Bulk Add Custom Tasks",
            bodyText: "Adding this custom task to " + events.length + " events.",
            customContent: new CustomModalWrapper(CustomTaskModalComponent, {
              style: {
                'width': '50%',
                'mid-width': '450px'
              },
              program_time_zone: product.timezone,
              timePickerData: timePickerData,
              event_start: start,
              event_end: null,
              allFilters: _.remove(createableFilterTypes, (o)=>{return o.code !== 'MANUAL'}),
              allTemplates: createableTemplates,
              buttonText: 'Save',
              updateTask: false,
              confirmFunction: function (task) {

                task['event_ids'] = _.map(events, e => {return e.event_id;});

                ctrl.EMS.post('/v1/custom_task', task, {}).pipe()
                  .subscribe((resp) => {
                      resolve(resp)
                      ctrl.SS.popSuccess("Successfully added Custom Tasks. Updates may take up to 1 minute to display on this page.");
                    },
                    (err) => {
                      reject(err)
                      ctrl.SS.popError("Failed to add Custom Tasks!");
                      console.dir(err);
                    });
              }
            })
          });
        }
      )
    });
  }

  cancelCustomTasks(selectedIDs){
    const ctrl = this;
    return new Promise<any>((resolve, reject) => {
      ctrl.SS.activateModal({
        headerText: "Cancel Custom Tasks",
        bodyText: "Cancelling " + selectedIDs.length + " Custom Tasks. Are you sure?",
        buttonText: 'Cancel Custom Tasks',
        cancelText: 'No',
        allowCancel: true,
        confirmFunction: function () {

          ctrl.EMS.post('/v1/custom_tasks/cancel', {custom_task_ids:selectedIDs}, {}).pipe(
          ).subscribe(resp => {
              resolve(resp)
              ctrl.SS.popSuccess("Successfully cancelled Custom Tasks. Updates may take up to 1 minute to display on this page.");

            },
            (err) => {
              reject(err)
              ctrl.SS.popError("Failed to cancel Custom Tasks!");
              console.dir(err);
            });
        }
      });
    })

  }

  updateCustomTasks(tasks: Array<any>, events: Array<EPSEvent>, product: Product){
    const ctrl = this;
    let start = events[0].event_start_dttm_utc;
    return new Promise<any>((resolve, reject) => {
      const filterTypes$: Observable<any> = this.RDS.getCreateableFilterTypes();
      const templates$: Observable<any> = this.RDS.getCreateableTemplates();
      forkJoin(filterTypes$, templates$).pipe().subscribe(
        ([createableFilterTypesMap, createableTemplatesMap])=>{

          //let start = controller.eventObj.event_start_dttm_utc;
          let timePickerData = new ModalDatePickerData(null, 'Schedule', moment().subtract('15', 'minutes').toISOString(), moment(start).add('60', 'minutes').add(product.max_event_duration, 'ms').toISOString(), 0, false, true, true, false);

          ctrl.SS.activateModal({
            headerText: "Update Custom Tasks",
            bodyText: "Updating " + tasks.length + " Custom Tasks.",
            customContent: new CustomModalWrapper(CustomTaskModalComponent, {
              style: {
                'width': '50%',
                'mid-width': '450px'
              },
              program_time_zone: product.timezone,
              timePickerData: timePickerData,
              allFilters: createableFilterTypesMap,
              allTemplates: createableTemplatesMap,
              updateTask: true,
              event_start: events[0].event_start_dttm_utc,
              event_end: null,
              buttonText: 'Save',
              showTemplate: true,
              task: tasks[0],
              confirmFunction: function (task) {

                let done = _.after(tasks.length, () => {

                  ctrl.EMS.put('/v1/custom_tasks/', tasks, {}).pipe(
                  ).subscribe(resp => {
                      resolve(resp)
                      ctrl.SS.popSuccess("Successfully updated Custom Tasks. Updates may take up to 1 minute to display on this page.");
                    },
                    (err) => {
                      reject(err);
                      ctrl.SS.popError("Failed to update Custom Tasks!");
                      console.dir(err);
                    });
                });

                //The modal returns a single task to us, since we're making them update all selected tasks with the same values
                //So we loop through all of the tasks they originally selected and set their values to the values they picked in the modal
                _.forEach(tasks, t => {
                  t = Object.assign(t, task);
                  done();
                });
              }
            })
          });
        });
    })
  }

  doNowCustomTasks(tasks){
    const ctrl = this;
    tasks.forEach((task)=>{
      task.scheduled_time = DateTime.utc().toISO();
      task.automated = true;
    })
    return new Promise<any>((resolve, reject) => {
      ctrl.SS.activateModal({
        headerText: "Send Now", bodyText: "Sending " + tasks.length + " Custom Tasks. Are you sure?", allowCancel: true,
        confirmFunction: function () {
          ctrl.EMS.put('/v1/custom_tasks/', tasks, {}).pipe(
          ).subscribe((resp) => {
              resolve(resp)
              ctrl.SS.popSuccess("Successfully sent Custom Tasks. Updates may take up to 1 minute to display on this page.");

            },
            (err) => {
              reject(err)
              ctrl.SS.popError("Failed to send Custom Task!");
              console.dir(err);

            });
        }
      });
    });
  }

  doNowStep(name, event_node_ids, workflow_state_name){
    const ctrl = this;
    return new Promise<any>((resolve, reject) => {
      const reqObj = {
        event_nodes: event_node_ids,
      };

      ctrl.SS.activateModal({
        headerText: "Triggering Workflow Step (" + name + ')',
        bodyText: "Triggering workflow step for " + event_node_ids.length + " event nodes. Are you sure?",
        buttonText: 'Trigger',
        cancelText: 'No',
        allowCancel: true,
        confirmFunction: function () {

          let path = '/v1/event_nodes/trigger/' + workflow_state_name + '/';

          ctrl.EMS.put(path, reqObj, {}).pipe().subscribe(
            (resp) => {
              resolve(resp);
              ctrl.SS.popSuccess("Successfully updated Event Nodes");
            },
            (err) => {
              reject(err);
              ctrl.SS.popError("Failed to update Event Nodes!");
              console.dir(err);
            });
        }
      });


    })
  }

  excludeStep(name, event_node_ids, workflow_state_name, warnNextStepWillFire){
    const ctrl = this;
    return new Promise<any>((resolve, reject) => {
      let reqObj = {
        event_nodes: event_node_ids,
      };

      ctrl.SS.activateModal({
        headerText: "Exclude Workflow Step (" + name + ')',
        bodyText: "Excluding workflow step for " + event_node_ids.length + " event nodes. Are you sure?",
        warningText: (warnNextStepWillFire ? "Warning: excluding this step will result in the next step immediately firing. " : ""),
        buttonText: 'Exclude',
        cancelText: 'No',
        allowCancel: true,
        confirmFunction: function () {
          ctrl.EMS.put('/v1/event_nodes/exclude/' + workflow_state_name, reqObj, {}).pipe().subscribe(
            (resp) => {
              resolve(resp);
              ctrl.SS.popSuccess("Successfully updated Event Nodes");

            },
            (err) => {
              reject(err);
              ctrl.SS.popError("Failed to update Event Node!");
              console.dir(err);
            });
        }
      });
    })
  }

  rescheduleStep(name, time, event_node_ids, automated, included, timezone,  workflow_state_name){
    const ctrl = this;
    return new Promise<any>((resolve, reject) => {
      const reqObj = {
        event_nodes: event_node_ids,
      };
      let timePickerData = new ModalDatePickerData(null, '', DateTime.now().toISO(), null, 0,true, false, false, false);
      ctrl.SS.activateModal({
          headerText: 'Update Workflow Step (' + name+ ')',
          bodyText: "Updating workflow step for " + event_node_ids.length + " event nodes. Are you sure?",
          customContent: new CustomModalWrapper(
            WorkflowStepEditModalComponent, {
              defaultTime: time,
              automated: automated,
              buttonText: "Update",
              showInclude: included === false,
              program_time_zone: timezone,
              confirmFunction: function(updateBody) {
                let path = '/v1/event_nodes/reschedule/' + workflow_state_name + '/' + updateBody.time;

                path += '?automate=' + updateBody.automated.toString();

                if(updateBody.include !== null && updateBody.include !== undefined) {
                  path += '&include=' + updateBody.include.toString();
                }

                ctrl.EMS.put(path, reqObj, {}).pipe().subscribe(
                  (resp) => {
                    resolve(resp);
                    ctrl.SS.popSuccess("Successfully updated Event Nodes. Updates may take up to 1 minute to display on this page.");
                  },
                  (err) => {
                    reject(err);
                    ctrl.SS.popError("Failed to update Event Nodes!");
                    console.dir(err);
                  });

              },
              timePickerData: [timePickerData]
            }
          )
        }
      )
    })
  }
}
