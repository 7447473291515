import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ApiEndpointsService {

  constructor() { }

  get globalNavUrl(): string {
    return environment.globalNavUrl;
  }
}

