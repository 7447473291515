import { Component, Input, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import * as Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
import Heatmap from 'highcharts/modules/heatmap';
import Tree from 'highcharts/modules/treemap';
import { Subject } from "rxjs/internal/Subject";
import { AuthService } from '../../services/auth.service';
import { EventListService } from '../../services/event-list.service';
import { EventManagementService } from "../../services/event-management.service";
import { FdrService } from "../../services/fdr.service";
import { SharedService } from "../../services/shared.service";

More(Highcharts);
Tree(Highcharts);
Heatmap(Highcharts);

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.pug',
  styleUrls: ['./event-list.component.scss']
})


export class EventListComponent implements OnInit {

  @Input()
  private isTraining: Boolean = false;

  private ngUnsubscribe: Subject<any> = new Subject();
  setObligations;
  eventsLoaded:boolean = false;
  Tabs = Object.freeze({
    list: 1,
    heatmap: 2
  });
  currentTab = this.Tabs.heatmap;

  Highcharts = Highcharts;
  chart = null;
  updateChart:boolean = false;

  chartOptions = {
    chart: {
      borderWidth: 1,
      borderColor: '#d8d8d8',
      spacingBottom: 15,
      spacingTop: 10,
      spacingLeft: 10,
      spacingRight: 10,
      style: {
        color: '#461e7d',
        fontFamily: 'RoobertENEL-Light, sans-serif'
      }
    },
    series: [{
        type: 'area',
        color: '#d2aaff',
        name: 'Event Status BEFORE'
      }, {
        type: 'area',
        color: '#461e7d',
        name: 'Event Status AFTER'
      }, {
        type: 'area',
        color: '#00CF12',
        name: 'Performing'
      }, {
        type: 'area',
        color: '#FFE701',
        name: 'Close to underperforming'
      }, {
        type: 'area',
        color: '#F33C4D',
        name: 'Underperforming'
      }, {
        type: 'area',
        color: '#9e9e9e',
        name: 'No performance data'
      },
      {
        type: 'treemap',
        layoutAlgorithm: 'squarified',
        name: 'Performance',
        allowPointSelect: true,
        point: {
          events: {
            select: this.onPointSelect.bind(this),
          }
        },
      }
    ],
    tooltip:
      {
        useHTML: true,
        formatter: function() {
          return ' ' +
            'Event Name: ' + this.point.name + '<br />' +
            'Event Progress Status: ' + this.point.status + '<br />' +
            'Obligation: ' + (this.point.product ? SharedService.me.convertProductUOM(this.point.obligation, this.point.product, true, 3, false, true) : 'Error getting Product') + '<br />' +
            'Performance: ' + (this.point.performance ? this.point.performance.toFixed(3) + '%' : '');
        },
        style: {
          fontFamily: "RoobertENEL-Light, sans-serif"
        }
    },
    title: {
      text: 'Event Obligations',
      y: 35,
      floating: true,
      align: 'left',
      style: {
        color: '#461e7d',
        fontFamily: 'RoobertENEL-Light'
      }
    },
    credits: {
      enabled: false,
    },
    legend:{
      align: 'right',
      verticalAlign: 'top',
      title: {
        text: "Active Event Performance (%):",
        style: {
          color: '#717171',
          fontFamily: "RoobertENEL-Light, sans-serif"
        }
      }
    }
  };

  tabData = [
    {
      name: 'Event List',
      value: this.Tabs.list
    },
    {
      name: 'Event Obligation Map',
      value: this.Tabs.heatmap
    },
  ];

  filteredEvents;

  constructor(
    private fdrService: FdrService,
    private router: Router,
    private sharedService: SharedService,
    private eventManagementService: EventManagementService,
    private authService: AuthService,
    private listService: EventListService)
  {
    const {filteredEvents$} = this.listService;
    filteredEvents$.pipe().subscribe(
      (response) => {
        this.setEvents(response)
      }
    );
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
    clearInterval(this.setObligations);
  }

  setEvents(events){
    if(events && events != this.filteredEvents){
      this.filteredEvents = events;
      this.eventsLoaded = true;
      clearInterval(this.setObligations)
      this.setObligations = setInterval(() => {
        this.filteredEvents.map((ev) => {
          this.sharedService.setCurrentObligation(ev);
        })}, 5000)
      this.setChartData();
    }

  }
  onPointSelect(target) {
    this.goToEventDetail(target.target.options.event);
  }

  goToEventDetail(event) {
    const eventUrlSnippet = this.isTraining ? '/training/event/' : '/event/';
    const url = window.location.origin + eventUrlSnippet + event.event_id;
    window.open(url, '_blank');
  }

  setChart(chart: Highcharts.Chart) {
    this.chart = chart;
    this.setChartData();
  };

  setChartData(){
    let data = [];

    const controller = this;

    const determineColorValue = function(point){
      const errorThreshold = point.product?.performance_error_threshold || 100;
      const warningThreshold = point.product?.performance_warning_threshold || 105;

      switch(point.event['event_progress_status']) {

        case 'BEFORE':
          point['color'] = '#d2aaff';
          break;
        case 'AFTER':
          point['color'] = '#461e7d';
          break;
        case 'DURING':
          if(!point.performance){
            point['color'] = '#9e9e9e';
          }
          else if(point.performance <= errorThreshold)
            //red
            point['color'] = '#F33C4D';
          else if(point.performance > errorThreshold && point.performance < warningThreshold)
            //yellow
            point['color'] = '#FFE701';
          else if( point.performance >= warningThreshold)
            //green
            point['color'] = '#00CF12';
          return;
        default:
          point['color'] = '#9e9e9e';
          break;
      }
    };

    if(this.filteredEvents) {
      this.filteredEvents.forEach(ev => {
        let thisDataPoint = {
          event: ev,
          name: ev.event_name,
          value: ev.obligation,
          colorValue: ev['event_performance_percent'],
          obligation: ev.obligation,
          performance: ev['event_performance_percent'],
          event_id: ev.event_id,
          status: ev['event_progress_status'],
          product: ev.product
        };

        determineColorValue(thisDataPoint);
        data.push(thisDataPoint);
      });

      this.updateChartData(data);
    }
  }

  updateChartData(data) {
    if(this.chart && this.chart.series) {
      const lastIdx = this.chart.series.length - 1;
      this.chart.series[lastIdx].setData(data);
    }
  }
}
