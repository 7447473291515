import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-em-search-parent',
  templateUrl: './search-parent.component.pug',
  styleUrls: ['./search-parent.component.scss']
})
export class SearchParentComponent implements OnInit {


  activeTabName = "CONTACT";

  constructor() { }

  ngOnInit(): void {
  }

}
