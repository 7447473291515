import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-timeline-bulk-action-modal',
  templateUrl: './timeline-bulk-action-modal.component.pug',
  styleUrls: ['./timeline-bulk-action-modal.component.scss']
})
export class TimelineBulkActionModalComponent implements OnInit {

  constructor() { }

  @Input() data:any;
  passwordEntered:string;

  ngOnInit() {
  }

  clickButton(next) {
    this.data.close();

    //Give the previous modal a chance to close first
    setTimeout(() => {
      next();
    }, 100);
  }

  close() {
    this.data.close();
  }
}
