import * as _ from 'lodash';

export interface iColumnFilter {
  column: Column;
}

export interface Column{
  field: string;
  label: string;
  type: FieldType;
  sortOrder: number;
  icon?: string;
}

export interface ColumnFilter extends iColumnFilter{
  column: Column;
  operator: string;
  value: string | number;
}

export interface EnumColumnFilter extends iColumnFilter{
  column: Column;
  enums: Array<ColumnFilter>;
}

export enum FieldType {
  MULTI, NUMBER, DATE
}

export const columns:Array<Column> = [
  {field: 'operator_display_label', label: 'Operator', type: FieldType.MULTI, sortOrder: 1},
  {field: 'ems_program_display_label', label: 'Program', type: FieldType.MULTI, sortOrder: 2},
  {field: 'product_display_label', label: 'Product', type: FieldType.MULTI, sortOrder: 3},
  {field: 'event_action_type_display_label', label: 'Event Type', type: FieldType.MULTI, sortOrder: 4},
  {field: 'portfolio_display_label', label: 'Portfolio', type: FieldType.MULTI, sortOrder: 5},
  {field: 'obligation', label: 'Obligation', type: FieldType.NUMBER, sortOrder: 6},
  {field: 'event_performance_percent', label: 'Performance', type: FieldType.NUMBER, sortOrder: 7},
  {field: 'event_start_dttm_utc', label: 'Start Time', type: FieldType.DATE, sortOrder: 8},
  {field: 'event_end_dttm_utc', label: 'End Time', type: FieldType.DATE, sortOrder: 9},
  {field: 'num_participating_units', label: '# of Registrations', type: FieldType.NUMBER, sortOrder: 10},
  {field: 'created_by_name', label: 'Created By', type: FieldType.MULTI, sortOrder: 11},
  {field: 'created_dttm', label: 'Created Time', type: FieldType.DATE, sortOrder: 12},
  {field: 'status', label: 'Status', type: FieldType.MULTI, sortOrder: 13},
];

export const operatorNames = {
  eq: '=',
  gt: '>',
  lt: '<',
  gte: '>=',
  lte: '<=',
  contains: 'contains',
  is_null: 'is null',
  is_not_null: 'is not null'
};

export const enumDefaults:Array<EnumColumnFilter> = [
  {column: _.find(columns, {field: 'operator_display_label'}), enums:[{column: _.find(columns, {field: 'operator_display_label'}), operator: operatorNames.contains, value:"All"}]},
  {column: _.find(columns, {field: 'ems_program_display_label'}), enums:[{column: _.find(columns, {field: 'program_display_label'}), operator: operatorNames.contains, value:"All"}]},
  {column: _.find(columns, {field: 'product_display_label'}), enums:[{column: _.find(columns, {field: 'product_display_label'}), operator: operatorNames.contains, value:"All"}]},
  {column: _.find(columns, {field: 'event_action_type_display_label'}), enums:[{column: _.find(columns, {field: 'event_action_type_display_label'}), operator: operatorNames.contains, value:"All"}]},
  {column: _.find(columns, {field: 'portfolio_display_label'}), enums:[{column: _.find(columns, {field: 'portfolio_display_label'}), operator: operatorNames.contains, value:"All"}]},
  {column: _.find(columns, {field: 'created_by_name'}), enums:[{column: _.find(columns, {field: 'created_by_name'}), operator: operatorNames.contains, value:"All"}]},
  {column: _.find(columns, {field: 'status'}), enums:[{column: _.find(columns, {field: 'status'}), operator: operatorNames.contains, value:"Active"}]}
];



